import React, { useEffect, useState, useRef, useLayoutEffect, RefObject } from "react";
import { parse, serialize } from "parse5";
import { Document, ChildNode } from "parse5/dist/tree-adapters/default";
import postcss from "postcss";
import prefixer from "postcss-prefix-selector";

type ParsingNode = Document | ChildNode

const walk = (node: ParsingNode, cb: (child: ParsingNode) => void) => {
    cb(node);
    if (!("childNodes" in node) || !node?.childNodes) return;
    node.childNodes.forEach(child => walk(child, cb));
}

const prefixCSS = (html: string, prefix: string) => {
    let document = parse(html);
    walk(document, node => {
        if (node.nodeName !== "style") return;

        node.childNodes = node.childNodes.map((child: ChildNode) => {
            if (child.nodeName !== "#text" || !("value" in child)) return child;

            child.value = postcss().use(prefixer({
                prefix,
                transform: (prefix, selector, prefixedSelector) => {
                    if (["html", "body"].includes(selector)) return prefix;
                    return prefixedSelector;
                }
            })).process(child.value).css;

            return child;
        });
    });

    return serialize(document);
}

export function useHTMLPreview(html: string, prefix: string): [string, RefObject<HTMLDivElement>, number] {
    const previewRef = useRef<HTMLDivElement>(null);
    const [parsedHTML, setParsedHTML] = useState<string>("");
    const [previewHeight, setPreviewHeight] = useState<number>(0);

    useEffect(() => {
        if (html && prefix) setParsedHTML(prefixCSS(html, prefix));
    }, [html, prefix]);

    useLayoutEffect(() => {
        setPreviewHeight(previewRef.current?.clientHeight! * 0.4);
    }, [parsedHTML]);

    return [parsedHTML, previewRef, previewHeight];
}