import { AppSetting, AppSettingKey } from "../Models";

export function isArkoseEnabled(appSettings: AppSetting[]) {
    return appSettings
        && (appSettings.find(d => d.key === AppSettingKey.ArkosePublicKey)?.value ?? "").length > 0
        && appSettings.find(d => d.key === AppSettingKey.IsArkoseEnabled)?.value === "True";
}

export function getArkosePublicKey(appSettings: AppSetting[]) {
    return appSettings.find(d => d.key === AppSettingKey.ArkosePublicKey)?.value ?? "";
}