import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { getErrorNotification } from 'Utilities';
import { UserGuideListItemDto, SelectedLanguageDetails } from '../Models';
import { useTranslation } from "react-multi-lang";
import { AreaLoader, UserGuideTile } from "../Components";
import { userGuideService } from "../Services";
import Notifications from 'react-notification-system-redux';
import { ApplicationState } from "../Store";

interface StoreProps {
    notifyError: Notifications.NotificationShow;
    loggedIn?: boolean;
    selectedLanguage?: SelectedLanguageDetails;
};

const mapStateToProps = ({ auth, user: userState, app }: ApplicationState) => {
    return {
        loggedIn: auth?.loggedIn ?? {},
        user: userState?.user ?? {},
        selectedLanguage: app.selectedLanguage
    };
}
const mapDispatchToProps = {
    notifyError: Notifications.error,
};

const UserGuidePage = ({
    loggedIn,
    notifyError,
    selectedLanguage
}: StoreProps) => {
    const t = useTranslation();
    const [loading, setLoading] = useState(true);
    const [userGuides, setUserGuides] = useState<Array<UserGuideListItemDto>>([]);

    useEffect(() => {
        loadUserGuides();
    }, []);

    useEffect(() => {
        loadUserGuides();
    }, [selectedLanguage]);

    const loadUserGuides = async () => {
        setLoading(true);
        try {
            const data = await userGuideService.getUserGuides();
            setUserGuides(data.filter(u => u.languageName === selectedLanguage?.name));
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderHistoryFailure")));
        }
        setLoading(false);
    }

    return (
        <div>
            <AreaLoader show={loading} message={t("UserGuide.UserGuideLoading")} />
            <div className="pr-md-2 pt-2 mr-md-2 pb-3 pl-3">
                <h1>{t("UserGuide.UserGuideTitleLabel")}</h1>
                <p>{t("UserGuide.UserGuideSubTitleLabel")}</p>
                <p>{t("UserGuide.UserGuideDescriptionLabel")}</p>
                {!loggedIn &&
                    <p className="font-weight-bold">{t("UserGuide.UserGuideLoginPromptLabel")}</p>
                }
            </div>
            <div className="d-flex justify-content-center flex-wrap align-items-start pt-2">
                {userGuides.map(userGuide => (
                    <UserGuideTile key={userGuide.id} userGuide={userGuide}/>
                ))}
            </div>
        </div>
    );
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(UserGuidePage);

export {
    ConnectedComponent as UserGuidePage
}
