import { DigitalOrderDetails, BaseVoucherType, CodeDeliveryMethod } from "Models";
import { DeliveryMethod } from "Store/Reducers/DigitalOrderReducer";

export type CodeCounts = {
    codeCount: number,
    smsOnly: number,
    emailOnly: number,
    emailAndSmsOnly: number,
    smsTotal: number,
    emailTotal: number
}

export function getOrderItemCounts(
    order?: DigitalOrderDetails,
    codeType?: BaseVoucherType,
    deliveryMethod?: DeliveryMethod,
    orderDistributionMethod?: CodeDeliveryMethod
): CodeCounts {
    let codes;
    let codeCount = 0;
    let emailOnly = 0;
    let smsOnly = 0;
    let emailAndSmsOnly = 0;

    // Count the codes and their delivery types
    if (order && codeType) {
        switch (Number(codeType)) {
            case BaseVoucherType.ECode:
                switch (deliveryMethod) {
                    case DeliveryMethod.Email:
                        codes = order.fileUploadECodeItems;
                        codeCount = codes.length;
                        emailOnly = codes.reduce((count, { emailAddress, mobileNumber }) => count += emailAddress && (!mobileNumber || mobileNumber.toString().trim() === "") ? 1 : 0, 0);
                        smsOnly = codes.reduce((count, { emailAddress, mobileNumber }) => count += mobileNumber && (!emailAddress || emailAddress.toString().trim() === "") ? 1 : 0, 0);
                        emailAndSmsOnly = codes.reduce((count, { emailAddress, mobileNumber }) => count += emailAddress && mobileNumber && emailAddress.toString().trim() !== "" && mobileNumber.toString().trim() !== "" ? 1 : 0, 0);
                        break;
                    case DeliveryMethod.Form:
                        codes = order.fileUploadBulkOrderItems;
                        codeCount = codes.length;
                        break;
                }
                break;
            case BaseVoucherType.PrintReady:
                switch (deliveryMethod) {
                    case DeliveryMethod.Email:
                        codes = order.fileUploadPrintReadyItems;
                        codeCount = codes.length;
                        emailOnly = codes.reduce((count, { recipientEmailAddress }) => count += recipientEmailAddress ? 1 : 0, 0);
                        break;
                    case DeliveryMethod.Form:
                        codes = order.bulkItems;
                        codeCount = codes.reduce((count, item) => count += Number(item.quantity), 0);
                        break;
                }
                break;
        }
    }

    // Consider project's code delivery method
    let smsTotal = smsOnly + emailAndSmsOnly;
    let emailTotal = emailOnly + emailAndSmsOnly;
    switch (orderDistributionMethod) {
        case CodeDeliveryMethod.Email:
            emailOnly = emailTotal;
            smsOnly = 0;
            emailAndSmsOnly = 0;
            smsTotal = 0;
            break;
        case CodeDeliveryMethod.Sms:
            smsOnly = smsTotal;
            emailOnly = 0;
            emailAndSmsOnly = 0;
            emailTotal = 0;
            break;
    }

    return {
        codeCount,
        smsOnly,
        emailOnly,
        emailAndSmsOnly,
        smsTotal,
        emailTotal
    };
}