import { ActionType, createAction, createReducer } from "typesafe-actions";
import { AppSetting, LanguageDto, SelectedLanguageDetails } from "Models";
import { setLanguage } from "react-multi-lang";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AppState {
    language: LanguageDto[];
    selectedLanguage?: SelectedLanguageDetails;
    appSettings: AppSetting[];
}

export const initialState: AppState = {
    language: [],
    appSettings: []
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    typeof actionCreators.loadLocalizedContent |
    typeof actionCreators.setLocalization |
    typeof actionCreators.setAppSettings

type ActionsType = ActionType<
    typeof actionCreators.loadLocalizedContent |
    typeof actionCreators.setLocalization |
    typeof actionCreators.setAppSettings
>;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// Action definitions

const actionCreators = {
    loadLocalizedContent: createAction("LOAD_LOCALIZED_CONTENT")<LanguageDto[]>(),
    setLocalization: createAction("SET_LOCALIZATION")<SelectedLanguageDetails>(),
    setAppSettings: createAction("SET_APP_SETTINGS")<AppSetting[]>()
};

export const actions = {
    ...actionCreators
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer = createReducer<AppState, ActionsType>(initialState)
    .handleAction(actionCreators.loadLocalizedContent, (state: AppState, action) => {
        return {
            ...state,
            language: action.payload
        }
    })
    .handleAction(actionCreators.setLocalization, (state: AppState, action) => {

        setLanguage(action.payload.languageCode);

        return {
            ...state,
            selectedLanguage: action.payload
        }
    })
    .handleAction(actionCreators.setAppSettings, (state: AppState, action) => {
                
        return {
            ...state,
            appSettings: action.payload
        }
    });