
export interface AppSetting {
    key: AppSettingKey;
    value: string;
}

export enum AppSettingKey {
    ArkosePublicKey = "ArkosePublicKey",
    IsArkoseEnabled = "IsArkoseEnabled"
}

export type ArkoseLabsResponse = {
    completed: boolean;
    suppresed: boolean;
    token: string;
}

export type ArkoseLabsAccessibility = {
    key: string;
    value: boolean;
}

export type Arkose = {
    setConfig: (config: any) => void;
    getConfig: () => void;
    reset: () => void;
    run: () => void;
}