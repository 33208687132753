import axios from "axios";
import { getAuthHeader } from "./getAuthHeader";
import { OrderHistoryItemDto, SearchResults, OrderDetailsDto, DigitalOrderItemDto, OrderSummaryDto, EmailTemplateDto, FileUploadRowItem, FileUploadObject } from "Models";
import { FilterByOptions, SortByOptions } from "../Components";
import { FileUploadECodeRowItem } from '../Models/FileUploadECodeRowItem';
import { FileUploadPrintReadyRowItem } from '../Models/FileUploadPrintReadyRowItem';
import { DistributorDto } from 'Models';

export const orderHistoryService = {
    getOrderHistory,
    getOrderSummary,
    getOrderDetails,
    getOrderItems,
    getProformaInvoiceHtml,
    getEmailTemplate,
    getSmsTemplate,
    getBulkOrderFileUploadRows,
    getBatchOrderDistributors,
    getBatchOrderDistributorRecipients,
    getBulkOrderDistributors
};

async function getOrderHistory(pageIndex: number, pageSize: number, sortBy: SortByOptions[], searchTerm: string, filterBy: FilterByOptions[]) {

    const response = await axios.post<SearchResults<OrderHistoryItemDto>>(`api/OrderHistory/GetOrders`, {
        pageIndex,
        pageSize,
        sortBy,
        searchTerm,
        filterBy
    }, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getOrderSummary(orderId: number) {  
    const response = await axios.get<OrderSummaryDto>(`api/OrderHistory/GetOrderSummary/${orderId}`, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getOrderDetails(orderId: number) {

    const response = await axios.get<OrderDetailsDto>(`api/OrderHistory/GetOrderDetails/${orderId}`, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getOrderItems(orderId: number) {

    const response = await axios.get<DigitalOrderItemDto[]>(`api/OrderHistory/GetOrderItems/${orderId}`, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getProformaInvoiceHtml(projectId: number, orderId: number) {

    const response = await axios.get<string>(`api/OrderHistory/${projectId}/ProForma/${orderId}`, {        
        headers: getAuthHeader()
    });

    return response.data;
}

async function getEmailTemplate(projectId: string | undefined, orderId: number) {

    const resp = await axios.get<EmailTemplateDto>(`api/OrderHistory/${projectId}/GetTemplate/${orderId}`, {
        headers: getAuthHeader(),
        params: {
           projectId,
           orderId
        }
    });

    return resp.data;
}

async function getSmsTemplate(projectId: string | undefined, orderId: number) {

    const resp = await axios.get<EmailTemplateDto>(`api/OrderHistory/${projectId}/GetSmsTemplate/${orderId}`, {
        headers: getAuthHeader(),
        params: {
            projectId,
            orderId
        }
    });

    return resp.data;
}

async function getBulkOrderFileUploadRows(projectId: string | undefined, orderId: number) {

    const resp = await axios.get<FileUploadObject<FileUploadECodeRowItem | FileUploadPrintReadyRowItem>>(`api/OrderHistory/${projectId}/getBulkOrderFileUploadRows/${orderId}`, {
        headers: getAuthHeader()
    });



    return resp.data;

}

async function getBatchOrderDistributors(orderId: number) {
    const resp = await axios.get<DistributorDto[]>(`api/OrderHistory/GetBatchOrderDistributors/${orderId}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function getBatchOrderDistributorRecipients(orderId: number, distributorUserId: number, pageIndex: number, pageSize: number) {
    const resp = await axios.post<SearchResults<FileUploadPrintReadyRowItem>>(`api/OrderHistory/GetBatchOrderDistributorRecipients/${orderId}/${distributorUserId}`, {
        pageIndex,
        pageSize,
    }, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function getBulkOrderDistributors(orderId: number) {
    const resp = await axios.get<DistributorDto[]>(`api/OrderHistory/GetBulkOrderDistributors/${orderId}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}