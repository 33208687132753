import React from "react";
import { useState } from "react";
import { bemNames, getClaimValue, getErrorNotification, getSuccessNotification } from 'Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import { ApplicationState } from "Store";
import { ClaimTypes, UserDetails } from "Auth";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { BsCaretDownFill } from "react-icons/bs";
import Notifications from 'react-notification-system-redux';
import { useTranslation } from "react-multi-lang";
import { UserLogOutCheck } from "Components";

import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";

interface StoreProps {
    loggedIn: boolean;
    refreshToken?: string;
    user?: UserDetails;
    logout: AuthenticationReducer.LogoutAction;
    clearUser: typeof UserReducer.actions.clearUser;
    notifySuccess: Notifications.NotificationShow;
    notifyError: Notifications.NotificationShow;
    setLoggingOut: typeof UserReducer.actions.setLoggingOut;
}

const mapStateToProps = ({ auth, user: userState }: ApplicationState) => ({
    loggedIn: auth.loggedIn,
    refreshToken: auth.refreshToken,
    user: userState.user
})

const mapDispatchToProps = {
    logout: AuthenticationReducer.actions.logout,
    clearUser: UserReducer.actions.clearUser,
    notifySuccess: Notifications.success,
    notifyError: Notifications.error,
    setLoggingOut: UserReducer.actions.setLoggingOut
}

const ProfileBlock = ({
    loggedIn,
    refreshToken,
    user,
    logout,
    clearUser,
    notifySuccess,
    notifyError,
    setLoggingOut
}: StoreProps) => {

    const t = useTranslation();
    const bem = bemNames.create("profile-block");
   
    const onLogout = async () => {

        const result = await logout(refreshToken ?? "");

        if (result) {
            notifySuccess(getSuccessNotification(t("NotificationMessage.LogoutSuccess")));
            setLoggingOut(true);            
            clearUser();
        } else {
            notifyError(getErrorNotification(t("NotificationMessage.LogoutFailure")))
        }
    }

    return (
        loggedIn
            ? (
                <>
                <UserLogOutCheck />
                <UncontrolledDropdown>
                    <DropdownToggle nav className="pl-0">
                        <div className={bem.b()}>

                            <div className={bem.e("profile-image")}>
                                <FontAwesomeIcon icon={faUserCircle} size="2x" />
                            </div>

                            <div className={bem.e("welcome-copy", "d-flex align-items-center")}>
                                {t("GreetingLabel")}<div className={bem.e("user-name", "pl-1")}>
                                    {`${getClaimValue(user, ClaimTypes.Name)} ${getClaimValue(user, ClaimTypes.Surname)}`}</div>
                            </div>

                            <div className={bem.e("profile-image", "pl-2 pt-1 pr-0")}>
                                <BsCaretDownFill />
                            </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            onClick={onLogout}                           
                        >{t("LogoutButton")}</DropdownItem>
                    </DropdownMenu>
                    </UncontrolledDropdown>
                </>
            )
            : <>               
            </>
    )
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ProfileBlock);

export {
    ConnectedComponent as ProfileBlock
}
