import React, { useState } from "react";
import { useTranslation } from "react-multi-lang";
import { Breadcrumb, BreadcrumbItem, Collapse, Container, Navbar, NavItem, NavLink } from "reactstrap";
import { bemNames } from "../Utilities";
import { Link } from "react-router-dom";
import { AccountDetails } from "Components";

enum Tab {
    AccountDetails
}

export const EditUserPage = () => {
    const bem = bemNames.create("edit-user");
    const t = useTranslation();

    const [activeTab, setActiveTab] = useState<Tab>(Tab.AccountDetails);

    return (
        <>
            <div className={bem.b()}>

                <Breadcrumb>
                    <BreadcrumbItem><Link to="/manage-users/">{t("UserManagement.ManageUsersTitleLabel")}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{t("UserManagement.EditUserLabel")}</BreadcrumbItem>
                </Breadcrumb>

                <h1>{t("UserManagement.EditUserLabel")}</h1>

                <div className={bem.e("section")}>
                    <div className={bem.e("section-header-tabs", "font-weight-bold")}>
                        <Navbar className={bem.e("section-header-tabs", "font-weight-bold navbar-expand-sm navbar-toggleable-sm")} dark>
                            <div className="d-block justify-content-between">
                                <Collapse className="d-sm-inline-flex" isOpen={true} navbar>
                                    <ul className="navbar-nav flex-grow">
                                        <Container>
                                            <NavItem>
                                                <NavLink tag={Link} to="#" className="text-dark text-uppercase" onClick={() => setActiveTab(Tab.AccountDetails)}>{t("UserManagement.AccountDetailsLabel")}</NavLink>
                                            </NavItem>
                                        </Container>
                                    </ul>
                                </Collapse>
                            </div>
                        </Navbar>
                    </div>

                    {activeTab == Tab.AccountDetails &&
                        <AccountDetails />
                    }
                </div>
            </div>
        </>
    );
}
