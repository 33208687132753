import React, { useEffect } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { connect } from 'react-redux';
import { bemNames } from 'Utilities';
import { ApplicationState } from "Store";
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import { DeliveryMethod } from "Store/Reducers/DigitalOrderReducer";
import { HiCheckCircle } from "react-icons/hi";
import { useTranslation } from "react-multi-lang";
import { BaseVoucherType } from "../Models";
import PaymentWidget from '@bhn/oc-payments-js/dist/core/payment';

import EmailCodesImage from 'Assets/Images/Direct-send-X1-1.png';
import DownloadCodesImage from 'Assets/Images/Download-codes-X1.png';
import OnlineFormImage from 'Assets/Images/Online-form-X1.png';
import UploadSpreadsheetImage from 'Assets/Images/Upload-spreadsheet-X1.png';
import SingleContactImage from 'Assets/Images/Single-contact-X1.png';
import MultipleContactImage from 'Assets/Images/Multiple-contact-X1.png';

interface StoreProps {
    deliveryMethod?: DeliveryMethod;
    currentStep?: number;
    attemptGoToStepNumber?: number;
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep;
    setDeliveryMethod: typeof OrderReducer.actions.setDeliveryMethod;
    setCurrentStep: typeof OrderReducer.actions.setCurrentStep;
    setOrder: typeof OrderReducer.actions.setOrder;
    setDistributorDetails: typeof OrderReducer.actions.setDistributorDetails;
}

const mapStateToProps = ({ digitalOrder }: ApplicationState) => ({
    deliveryMethod: digitalOrder.deliveryMethod,
    attemptGoToStepNumber: digitalOrder.attemptGoToStepNumber,
    order: digitalOrder.order,
})

const mapDispatchToProps = {
    setDeliveryMethod: OrderReducer.actions.setDeliveryMethod,
    setCurrentStep: OrderReducer.actions.setCurrentStep,
    attemptGoToStep: OrderReducer.actions.attemptGoToStep,
    setOrder: OrderReducer.actions.setOrder,
    setDistributorDetails: OrderReducer.actions.setDistributorDetails
}
interface Props extends StoreProps, Partial<StepWizardChildProps> {
    codeType?: BaseVoucherType;
}

const DeliveryMethodStep = ({
    goToStep,
    deliveryMethod,
    setDeliveryMethod,
    setDistributorDetails,
    setCurrentStep,
    isActive,
    attemptGoToStep,
    attemptGoToStepNumber,
    setOrder,
    codeType
}: Props) => {

    const bem = bemNames.create("digital-order-wizard");
    const t = useTranslation();

    useEffect(() => {
        if (attemptGoToStepNumber === 2) {
            goToStep?.(2);
        }
        if (attemptGoToStepNumber === 3) {
            goToStep?.(3);
        }
    }, [attemptGoToStepNumber])

    useEffect(() => {
        clearOrder();
    }, [deliveryMethod])

    if (isActive) {
        setCurrentStep(1)
    }

    const clearOrder = () => {        
        setOrder({
            ...OrderReducer.initialState.order,
            bulkItems: [{ value: "", quantity: "" }],
            fileUploadECodeItems: [],
            fileUploadPrintReadyItems: [],
            fileUploadBulkOrderItems: [],
            itemsTotalValue: 0,
        });
        setDistributorDetails({ firstName: "", lastName: "", emailAddress: "" });
    }

    return (
        <div className="card w-100">
            <div className="card-body">
                <div className={bem.e("delivery d-block w-100")}>
                    <h2>{t("OrderingProcess.DeliveryMethod")}</h2>
                    <div className="d-flex flex-column flex-lg-row justify-content-between pt-4 btn-group">
                       
                        <button
                            type="button"
                            data-automation-id="downloadCodesButton"
                            onClick={() => setDeliveryMethod(DeliveryMethod.Form)}
                            disabled={!isActive}
                            className={"btn btn-light large w-100 mb-3 mb-lg-0 mr-lg-3 px-4 px-lg-5 py-2 border rounded border-solid d-flex justify-content-start align-items-center text-left"
                                + (deliveryMethod === DeliveryMethod.Form ? " active" : "")}>
                            {deliveryMethod === DeliveryMethod.Form &&
                                <HiCheckCircle size={28} color="limegreen" className="check" />
                            }
                            <img className="mr-4" src={codeType == BaseVoucherType.PrintReady ? SingleContactImage : DownloadCodesImage} alt="placeholder" />
                            {codeType == BaseVoucherType.ECode && t("OrderingProcess.DownloadListOfCodes")}
                            {codeType == BaseVoucherType.PrintReady && t("OrderingProcess.DownloadListOfCodesPrintReady")}
                        </button>

                        <button
                            type="button"
                            data-automation-id="emailCodesButton"
                            onClick={() => setDeliveryMethod(DeliveryMethod.Email)}
                            disabled={!isActive}
                            className={"btn btn-light large w-100 ml-lg-3 px-4 px-lg-5 py-2 border rounded border-solid d-flex justify-content-start align-items-center text-left"
                                + (deliveryMethod === DeliveryMethod.Email ? " active" : "")}>
                            {deliveryMethod === DeliveryMethod.Email &&
                                <HiCheckCircle size={28} color="limegreen" className="check" />
                            }
                            <img className="mr-4" src={codeType == BaseVoucherType.PrintReady ? MultipleContactImage :EmailCodesImage} alt="placeholder" />
                            {codeType == BaseVoucherType.ECode && t("OrderingProcess.EmailToRecipients")}
                            {codeType == BaseVoucherType.PrintReady && t("OrderingProcess.EmailToRecipientPrintReady")}
                        </button>

                    </div>
                    <hr className="mt-5 mb-4" />

                    {deliveryMethod === DeliveryMethod.Form &&
                        <div className="w-100">
                            <h2>{t("OrderingProcess.OrderMethod")}</h2>
                            {codeType == BaseVoucherType.ECode &&
                                <div>
                                    <div className="d-block flex-grow-0 w-100">
                                        <p>{t("OrderingProcess.DownloadListOfCodesInfoText")}</p>
                                    </div>
                                    <div className="d-flex flex-column flex-lg-row justify-content-between pt-4 btn-group">
                                        <button type="button" onClick={() => attemptGoToStep(2)} className="btn btn-light large w-100 mb-3 mb-lg-0 mr-lg-3 px-4 px-lg-5 py-2 border rounded border-solid d-flex justify-content-start align-items-center text-left" disabled={!isActive}> <img className="mr-4" src={OnlineFormImage} alt="placeholder" /> {t("OrderingProcess.UseOrderForm")} </button>
                                        <button type="button" onClick={() => attemptGoToStep(3)} className="btn btn-light large w-100 mb-3 mb-lg-0 mr-lg-3 px-4 px-lg-5 py-2 border rounded border-solid d-flex justify-content-start align-items-center text-left" disabled={!isActive}><img className="mr-4" src={UploadSpreadsheetImage} alt="placeholder" />{t("OrderingProcess.UploadSpreadsheet")}</button>
                                    </div>
                                </div>
                            }
                            {codeType == BaseVoucherType.PrintReady &&
                                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start">
                                    <div className="d-block flex-grow-0 w-100">
                                        <p>{t("OrderingProcess.DownloadListOfCodesInfoText")}</p>
                                    </div>
                                    <div className="w-100 pl-lg-4">
                                        <button type="button" onClick={() => attemptGoToStep(2)} className="w-100 btn btn-light large px-4 px-lg-5 py-2 border rounded border-solid d-flex justify-content-start align-items-center text-left" disabled={!isActive}><img className="mr-4" src={OnlineFormImage} alt="placeholder" />{t("OrderingProcess.UseOrderForm")}</button>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {deliveryMethod === DeliveryMethod.Email &&
                        <div className="w-100">
                            <h2>{t("OrderingProcess.OrderMethod")}</h2>
                            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start">

                                <div className="d-block flex-grow-0 w-100">
                                    <p>{t("OrderingProcess.EmailToRecipientsInfoText")}</p>
                                </div>

                                <div className="w-100 pl-lg-4">
                                <button type="button" onClick={() => attemptGoToStep(3)} className="w-100 btn btn-light large px-4 px-lg-5 py-2 border rounded border-solid d-flex justify-content-start align-items-center text-left" disabled={!isActive}><img className="mr-4" src={UploadSpreadsheetImage} alt="placeholder" />{t("OrderingProcess.UploadSpreadsheet")}</button>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(DeliveryMethodStep);

export {
    ConnectedComponent as DeliveryMethodStep
}