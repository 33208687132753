import React from "react";
import { ProjectOrderingDetails } from "../Models";
import { useTranslation } from "react-multi-lang";

interface props {
    project?: ProjectOrderingDetails
}

export function AccountRestrictions({ project }: props) {

    const t = useTranslation();
    const remainingAllowance = (project?.orderingLimit.orderingLimit! - project?.orderingLimit.orderingLimitRunningTotal!);
    const remainingCredit = (project?.clientCreditBalance! - project?.clientRunningTotal!);
    const showRestrictions = (project?.orderingLimit.orderingLimit || project?.isClientCreditLimitEnabled);

    if (showRestrictions) {
        return (
            <div className="card mb-3" >
                <div className="card-body">
                    <div className="mb-2 text-uppercase">
                        <strong>{t("OrderingProcess.accountRestrictionsLabel")}</strong>
                    </div>

                    {project?.isClientCreditLimitEnabled &&
                        <>
                            <div className="mb-2 text-uppercase">
                                {t("OrderingProcess.creditBalanceLabel")}
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <div className="box flex-grow-1 mr-2 p-3 text-center" style={{ borderRadius: "8px" }}>
                                    <div className="pb-1" style={{ fontSize: "1.5rem", lineHeight: "1.625rem" }}>{project?.currencySymbol.trim()}{remainingCredit.toFixed(2)}</div>
                                    <div style={{ fontSize: "0.813rem" }}>{t("OrderingProcess.remainingCreditLabel")}</div>
                                </div>
                            </div>
                        </ >
                    }

                    {(project?.orderingLimit.orderingLimit || project?.orderingLimit.orderingLimit == 0) &&
                        <>
                            <div className="mb-2 text-uppercase" style={{ marginTop: "10px" }}>
                                {t("OrderingProcess.spendLimitLabel")}
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <div className="box flex-grow-1 mr-2 p-3" style={{ borderRadius: "8px" }}>
                                    <div className="pb-1" style={{ fontSize: "1.5rem", lineHeight: "1.625rem" }}>{project?.currencySymbol.trim()}{project?.orderingLimit.orderingLimit}</div>
                                    <div style={{ fontSize: "0.813rem" }}>{t("OrderingProcess.projectSpendLimitLabel")}</div>
                                </div>
                                <div className="box flex-grow-1 ml-2 p-3" style={{ borderRadius: "8px" }}>
                                    <div className="pb-1" style={{ fontSize: "1.5rem", lineHeight: "1.625rem" }}>{project?.currencySymbol.trim()}{remainingAllowance}</div>
                                    <div style={{ fontSize: "0.813rem" }}>{t("OrderingProcess.remainingAllowanceLabel")}</div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    } else {
        return(<></>)
    }
}

