import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import { bemNames } from "../Utilities/bemNames";

type ColorType =
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";

type Props = {
    color?: ColorType;
    show: boolean;
    showByDefault?: boolean;
    message?: string;
    delay?: number;
    hasDelay?: boolean;
} & React.AllHTMLAttributes<HTMLDivElement>

export function AreaLoader({
    color = "primary",
    show,
    showByDefault,
    message,
    delay = 200,
    hasDelay = true,
    ...restProps
}: Props) {

    const timeoutRef = useRef<NodeJS.Timeout>();

    const bem = bemNames.create("area-loader");

    const style: CSSProperties = {
        position: "fixed",
        background: "rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexFlow: "column",
        backdropFilter: "blur(2px)"
    }

    const [internalShow, setInternalShow] = useState(false);

    useEffect(() => {

        if (timeoutRef.current) {

            clearTimeout(timeoutRef.current);
        }

        if (show) {

            if (!showByDefault && hasDelay) {

                timeoutRef.current = setTimeout(() => {
                    setInternalShow(true);
                }, delay);

            } else {

                setInternalShow(true);
            }
        } else {
            setInternalShow(false);
        }

        return () => {
            if (timeoutRef.current) {

                clearTimeout(timeoutRef.current);
            }
        }

    }, [show]);

    if (!internalShow) {
        return (<></>);
    }

    return (
        <div className={bem.b()} {...restProps} style={style} >
            <Spinner color={color} />
            { message &&
                <span>
                    {message}
                </span>
            }
        </div >
    );
}