import React from 'react';
import Moment from 'react-moment';
import { useTranslation } from 'react-multi-lang';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';

// Import Models
import { ItemGroupStatusLogDto } from '../Models';

interface Props {
    isModalOpen: boolean;
    closeModal: () => void;
    title: string;
    status: ItemGroupStatusLogDto;
}

export function ItemGroupStatusLogDialog({
    isModalOpen,
    closeModal,
    title,
    status
}: Props) {

    const t = useTranslation();

    return (

        <Modal
            isOpen={isModalOpen}
            toggle={() => closeModal()}
            style={{ maxWidth: "max-content" }}
        >
            <ModalHeader toggle={() => closeModal()}>
                {title}
            </ModalHeader>

            <ModalBody>
                <Table>
                    <thead>
                        <tr>
                            <th>{t("DateLabel")}</th>
                            <th>{t("OrderDetails.StatusLabel")}</th>
                            <th>{t("OrderDetails.OrderIdLabel")}</th>
                            <th>{t("OrderDetails.CommentsLabel")}</th>
                            <th>{t("OrderDetails.ItemsLabel")}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>{status
                                ? <Moment format="ll HH:mm">{status?.orderDate}</Moment>
                                : ""}</td>
                            <td>{status?.orderStatus}</td>
                            <td>{status?.externalOrderId}</td>
                            <td>{status?.comments}</td>
                            <td>{status?.items?.map((item, index) =>
                                <div key={index}><strong>{t("OrderDetails.QuantityLabel")}: {item.quantity} {t("OrderDetails.NameLabel")}: {item.productTitle} {t("OrderDetails.StatusLabel")}: {item.status}</strong></div>
                            )
                            }</td>
                        </tr>

                    </tbody>
                </Table>
            </ModalBody>

            <ModalFooter className="flex-row-reverse">
                <Button
                    onClick={() => closeModal()}
                >{t("BackButton")}</Button>
            </ModalFooter>
        </Modal>
    );
}