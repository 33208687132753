import React, { useEffect } from "react";
import { useState } from 'react';
import { connect } from 'react-redux';
import { bemNames, getBatchTypeLocalizationKey, getDownloadableBatchStatusLocalizationKey, getDownloadableBatchTypeLocalizationKey, getErrorNotification, nameOf } from 'Utilities';
import { BatchStatus, DownloadableBatchStatus, YourDownloadsItemDto, BatchTypeIconSize} from 'Models';
import { IoPrintOutline } from "react-icons/io5";
import { useTranslation } from "react-multi-lang";
import { AreaLoader, DataTable, FilterByOptions, SortByOptions, BatchTypeIconImage } from "../Components";
import { Cell, Column } from "react-table";

import { yourDownloadsService } from "../Services";
import classNames from "classnames";
import Notifications from 'react-notification-system-redux';
import { Link } from "react-router-dom";
import Moment from "react-moment";


interface StoreProps {
    notifyError: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
}

const YourDownloadsPage = ({ notifyError }: StoreProps) => {
    const t = useTranslation();
    const bem = bemNames.create("your-downloads-page");
    const [yourDownloadsItems, setYourDownloadsItems] = useState<YourDownloadsItemDto[]>([]);
    const [yourDownloadsItemCount, setYourDownloadsItemCount] = useState<number>(0);
    const [projectNames, setProjectNames] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadProjectNames();
    }, []);

    const loadProjectNames = async () => {



        try {

            const data = await yourDownloadsService.getYourDownloadsProjectNames();

            setProjectNames(data);

        } catch (e) {
            console.error(e);

            notifyError(getErrorNotification(t("NotificationMessage.LoadYourDownloadsFailure")));
        }


    }

    const loadYourDownloads = async (pageIndex: number, pageSize: number, sortBy: SortByOptions[], searchTerm: string, filterBy: FilterByOptions[] = []) => {

        setLoading(true);
        try {
            const data = await yourDownloadsService.getYourDownloads(
                pageIndex,
                pageSize,
                sortBy,
                searchTerm,
                filterBy
            );

            setYourDownloadsItems(data?.items ?? []);
            setYourDownloadsItemCount(data?.itemCount ?? 0);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadYourDownloadsFailure")));
        }

        setLoading(false);
    }

    const columnDefinitions: Column<YourDownloadsItemDto>[] = [
        {
            id: "icon",
            Header: '',
            accessor: (model) => model.projectName,
            Cell: (cell: Cell<YourDownloadsItemDto>) => {
                const order = cell.row.original;
                return (
                    <div className={bem.e("order-tile")}>
                        <div className="icon-wrap">
                            <BatchTypeIconImage batchType={order.batchTypeId} size={BatchTypeIconSize.small} />                            
                        </div>
                    </div>
                );
            }
        },
        {
            id: nameOf<YourDownloadsItemDto>("projectName"),
            Header: '',
            accessor: (model) => model.projectName,
            Cell: (cell: Cell<YourDownloadsItemDto>) => {
                const order = cell.row.original;
                return <>
                    <div className={bem.e("order-tile name")}>
                        <div className="top">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileProjectNameLabel")}</strong>
                            {order.projectName}<br />
                            ID: {order.projectId}
                        </div>
                    </div>
                </>;
            }
        },
        {
            id: nameOf<YourDownloadsItemDto>("orderId"),
            Header: '',
            accessor: (model) => model.orderId,
            Cell: (cell: Cell<YourDownloadsItemDto>) => {
                const order = cell.row.original;
                return <>
                    <div className={bem.e("order-tile")}>
                        <div className="top">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderIdLabel")}</strong>
                            {order.orderId}
                        </div>
                        <div className="bottom">
                            <strong className="text-uppercase">{t("YourDownloads.CodeType")}</strong>
                            {order.codeType}
                        </div>
                    </div>
                </>;
            }
        },
        {
            id: nameOf<YourDownloadsItemDto>("orderDate"),
            Header: '',
            accessor: (model) => model.orderDate,
            Cell: (cell: Cell<YourDownloadsItemDto>) => {
                const order = cell.row.original;
                return <>
                    <div className={bem.e("order-tile")}>
                        <div className="top">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderDateLabel")}</strong>
                            {order?.orderDate && <Moment format="ll HH:mm">{order.orderDate}</Moment>}
                        </div>
                        <div className="bottom">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderTypeLabel")}</strong>
                            {t(getBatchTypeLocalizationKey(order.batchTypeId))}
                        </div>
                    </div>
                </>;
            }
        },
        {
            id: nameOf<YourDownloadsItemDto>("orderedByFirstName"),
            Header: '',
            accessor: (model) => model.orderedByInternalAdmin ? "Internal" : `${model.orderedByFirstName} ${model.orderedByLastName}`,
            Cell: (cell: Cell<YourDownloadsItemDto>) => {
                const order = cell.row.original;
                return <>
                    <div className={bem.e("order-tile ordered-by")}>
                        <div className="top">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderValueLabel")}</strong>
                            {order.currency}{order.orderValue}

                        </div>
                        <div className="bottom">
                            <strong className="text-uppercase">{t("YourDownloads.NoOfCodes")}</strong>
                            {order.numberOfCodes}
                        </div>
                    </div>

                </>;
            }
        },
        {
            id: nameOf<YourDownloadsItemDto>("batchStatusId"),
            Header: '',
            accessor: (model) => t(getDownloadableBatchStatusLocalizationKey(model.batchStatusId)),
            Cell: (cell: Cell<YourDownloadsItemDto>) => {
                const order = cell.row.original;
                return <>
                    <div className={bem.e("order-tile")}>
                        <strong className="text-uppercase">{t("OrderHistory.OrderTileStatusLabel")}</strong>
                        <div className={classNames("status",
                            `${order.batchStatusId === DownloadableBatchStatus.Active ? "bg-success" :
                                (order.batchStatusId === DownloadableBatchStatus.Cancelled || order.batchStatusId === DownloadableBatchStatus.Expired ? "bg-danger" : "bg-warning")}`)}
                        >{t(getDownloadableBatchStatusLocalizationKey(order.batchStatusId))}</div>
                    </div>
                </>;
            }
        },
        {
            id: "actions",
            Header: '',
            accessor: (model) => model.projectName,
            Cell: (cell: Cell<YourDownloadsItemDto>) => {
                const order = cell.row.original;
                return <>
                    <div className="float-right d-print-none">
                        <Link
                            aria-disabled={order.batchStatusId !== DownloadableBatchStatus.Active}
                            to={`/downloads/download-details/${order.orderId}`} aria-label={order.projectName + "" + order.orderValue + " view order"}
                            className={`btn btn-primary text-uppercase ${order.batchStatusId !== DownloadableBatchStatus.Active ? "disabled" : ""}`}
                        >{t("OrderHistory.OrderTileViewDetailsButton")}</Link>
                    </div>
                </>;
            }
        },
        // Hidden columns for search only
        {
            id: nameOf<YourDownloadsItemDto>("projectId"),
            Header: '',
            accessor: (model) => model.projectId
        },
        {
            id: nameOf<YourDownloadsItemDto>("batchTypeId"),
            Header: '',
            accessor: (model) => t(getBatchTypeLocalizationKey(model.batchTypeId))
        },
        {
            id: nameOf<YourDownloadsItemDto>("orderValue"),
            Header: '',
            accessor: (model) => model.currency + model.orderValue
        },
    ]

    return (
        <div className={bem.b()}>
            <div className="top d-flex flex-row justify-content-start align-items-center">
                <h1>{t("YourDownloads.YourDownloadsLabel")}</h1>
                <button type="button" onClick={() => window.print()} aria-label="Download order history" className={bem.e("download", "text-uppercase")}><IoPrintOutline fontSize="20" />{t("OrderHistory.PrintButton")}</button>
            </div>
            <div className={bem.e("orders-container")}>
                <AreaLoader show={loading} message={t("YourDownloads.LoadingYourDownloadsMessage")} />
                <DataTable<YourDownloadsItemDto>
                    columnDefinitions={columnDefinitions}
                    fetchData={loadYourDownloads}
                    items={yourDownloadsItems}
                    totalItemCount={yourDownloadsItemCount}
                    sortByOptions={[
                        {
                            sortByColumnId: "orderDate",
                            sortByDescText: t("OrderHistory.FilterSortByDateDescLabel"),
                            sortByAscText: t("OrderHistory.FilterSortByDateAscLabel")
                        }
                    ]}
                    sortByDefaultOption={{ sortByColumnId: "orderDate", isAsc: false, sortByText: t("OrderHistory.FilterSortByDateDescLabel") }}
                    columnFilters={{
                        projectName: {
                            label: t("Projects.ProjectsLabel"),
                            options: [
                                { label: t("OrderHistory.AllProjectsDropdownLabel"), value: "" },
                                ...projectNames
                                    .map(p => ({ label: p, value: p }))
                                    .sort((a, b) => a.label.localeCompare(b.label))
                            ]
                        }
                    }}
                    hiddenColumns={["projectId", "batchTypeId", "orderValue"]}
                    resultsElId="downloadResults"
                />
            </div>
        </div>
    )
}

const ConnectedComponent = connect(null, mapDispatchToProps)(YourDownloadsPage);

export {
    ConnectedComponent as YourDownloadsPage
}