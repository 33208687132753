import React from "react";

import { useTranslation } from "react-multi-lang";
import { bemNames, getBatchStatusLocalizationKey } from "../Utilities";

import { BatchStatus } from "../Models";

interface Props {
    batchStatus: BatchStatus;
}


export function OrderStatusBadge({
    batchStatus
}: Props) {

    const t = useTranslation();
    const bem = bemNames.create("order-status-badge");
        
    return (
        <div className={bem.b(
            `${(batchStatus === BatchStatus.Allocated
                || batchStatus === BatchStatus.SentToMvb )
                ? "bg-success"
                : ((batchStatus === BatchStatus.Cancelled || batchStatus === BatchStatus.FailedRiskReview)
                    ? "bg-danger"
                    : "bg-warning")}`)}>
            {t(getBatchStatusLocalizationKey(batchStatus))}
        </div>
    )
}