import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import * as Yup from 'yup';
import { CgInfo } from "react-icons/cg";
import { isEmailDelivery, isSmsDelivery } from "Utilities/communicationHelper";
import { Input } from 'reactstrap';

// Import Models
import { AssociatedEmail, CodeDeliveryMethod } from '../Models';

interface Props {
    isModalOpen: boolean,
    closeModal: () => void,
    title: string,
    associatedEmails?: AssociatedEmail[],
    codeDeliveryMethod?: CodeDeliveryMethod,
    mobileNumberPrefix?: string,
    onSubmit: (newEmailAddressorMobileNumber?: string, updateOriginalOrder?: boolean) => void;
}

export function ResendCommunicationDialog({
    isModalOpen,
    closeModal,
    title,
    associatedEmails,
    codeDeliveryMethod,
    mobileNumberPrefix,
    onSubmit
}: Props) {

    const t = useTranslation();
    const [associatedEmail, setAssociatedEmail] = useState<AssociatedEmail>();
    const [associatedSms, setAssociatedSms] = useState<AssociatedEmail>();
    const [updateCommunication, setUpdateCommunication] = useState(false);
    const [showUpdateWarning, setshowUpdateWarning] = useState(false);

    interface FormValues {
        emailAddress: string;
        mobileNumber: string;
    }

    const formRef = useRef<FormikProps<FormValues> | null>(null);

    useEffect(() => {

        if (associatedEmails
            && associatedEmails.length > 0
            && associatedEmails.filter(d => !d.isReminder && isEmailDelivery(d.codeDeliveryMethod)).length > 0) {

            const lastAssociatedEmail = associatedEmails.filter(d => !d.isReminder && isEmailDelivery(d.codeDeliveryMethod)).sort((a, b) => a.id < b.id ? 1 : -1)[0];
            setAssociatedEmail(lastAssociatedEmail);
        }

        if (associatedEmails
            && associatedEmails.length > 0
            && associatedEmails.filter(d => !d.isReminder && isSmsDelivery(d.codeDeliveryMethod)).length > 0) {

            const lastAssociatedSms = associatedEmails.filter(d => !d.isReminder && isSmsDelivery(d.codeDeliveryMethod)).sort((a, b) => a.id < b.id ? 1 : -1)[0];
            setAssociatedSms(lastAssociatedSms);
        }
    }, [associatedEmails, associatedEmails?.length]);

    const requestCloseModal = () => {

        setshowUpdateWarning(false);
        setUpdateCommunication(false);
        closeModal();
    }
    const onChangeEmailAddress = (e: any) => {
        if (associatedEmail?.recipient !== e.target.value) {
            setshowUpdateWarning(true);
        } else {
            setshowUpdateWarning(false);
        }
        formRef.current?.setFieldValue("emailAddress", e.target.value);
    }

    const onChangeMobileNumber = (e: any) => {
        if (associatedSms?.mobileNumberWithoutPrefix !== e.target.value) {
            setshowUpdateWarning(true);
        } else {
            setshowUpdateWarning(false);
        }
        formRef.current?.setFieldValue("mobileNumber", e.target.value);
    }

    const isEmailEnabled = associatedEmails && isEmailDelivery(codeDeliveryMethod!);
    const isSmsEnabled = associatedEmails && isSmsDelivery(codeDeliveryMethod!);

    let validationSchemaShape: any = {};
    if (isEmailEnabled) {
        validationSchemaShape["emailAddress"] = Yup.string()
            .email(t("ValidationError.InvalidEmailAddressMessage"))
            .min(3, t("ValidationError.InvalidEmailAddressMessage"))
            .required(t("ValidationError.EmailAddressRequiredMessage"))
    }
    if (isSmsEnabled) {
        validationSchemaShape["mobileNumber"] = Yup.string()
            .required(t("ValidationError.MobileNumberRequiredMessage"))
    }
    const validationSchema = Yup.object().shape(validationSchemaShape);

    return (

        <Modal
            isOpen={isModalOpen}
            toggle={() => requestCloseModal()}
        >
            <Formik
                innerRef={formRef}
                initialValues={{
                    emailAddress: associatedEmail?.recipient ?? "",
                    mobileNumber: associatedSms?.mobileNumberWithoutPrefix ?? ""
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    onSubmit(codeDeliveryMethod == CodeDeliveryMethod.Email ? values.emailAddress :
                        codeDeliveryMethod == CodeDeliveryMethod.Sms ? values.mobileNumber : "", updateCommunication);
                    setshowUpdateWarning(false);
                    setUpdateCommunication(false);
                    closeModal();
                }}
            >
                <Form>
                    <ModalHeader toggle={() => requestCloseModal()}>
                        {title}
                    </ModalHeader>

                    <ModalBody>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    {associatedEmails
                                        ?
                                        <>
                                            {isEmailEnabled &&
                                                <>
                                                    <label
                                                        className="control-label"
                                                        htmlFor="emailAddress"
                                                    >{t("OrderDetails.EmailAddressLabel")}</label>
                                                    <Field
                                                        className="form-control"
                                                        id="emailAddress"
                                                        name="emailAddress"
                                                        onChange={onChangeEmailAddress}
                                                    />
                                                    <ErrorMessage
                                                        className="validation-error"
                                                        name="emailAddress"
                                                        component="div"
                                                    />
                                                    {showUpdateWarning &&
                                                        <div className="alert alert-warning" style={{ marginTop: 15 }}>
                                                            <strong><CgInfo />{" " + t("IssuanceDetails.ChangedEmailAddressWrng")}</strong>
                                                            <div style={{ marginTop: 10 }}>
                                                                <span style={{ marginRight: 10 }}>{t("IssuanceDetails.UpdateEcodeEmailWrng")}</span>
                                                                <input
                                                                    style={{ cursor: "pointer" }}
                                                                    onChange={() => setUpdateCommunication(!updateCommunication)}
                                                                    type="checkbox"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {isSmsEnabled &&
                                                <>
                                                    <label
                                                        className="control-label"
                                                        htmlFor="mobileNumber"
                                                    >{t("OrderDetails.MobileNumberLabel")}</label>
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <Input
                                                            type="text"
                                                            style={{ width: "6.9rem" }}
                                                            className="form-control"
                                                            disabled
                                                            value={mobileNumberPrefix ?? ""}
                                                        /><Field
                                                            className="form-control"
                                                            id="mobileNumber"
                                                            name="mobileNumber"
                                                            onChange={onChangeMobileNumber}
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        className="validation-error"
                                                        name="mobileNumber"
                                                        component="div"
                                                    />
                                                    {showUpdateWarning &&
                                                        <div className="alert alert-warning" style={{ marginTop: 15 }}>
                                                            <strong><CgInfo />{" " + t("IssuanceDetails.ChangedMobileNumberWrng")}</strong>
                                                            <div style={{ marginTop: 10 }}>
                                                                <span style={{ marginRight: 10 }}>{t("IssuanceDetails.UpdateEcodeMobileWrng")}</span>
                                                                <input
                                                                    style={{ cursor: "pointer" }}
                                                                    onChange={() => setUpdateCommunication(!updateCommunication)}
                                                                    type="checkbox"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        <p>{t("OrderDetails.ResendMigratedCodeNotificationMessage")}</p>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>

                    <ModalFooter className="justify-content-between">
                        <Button
                            outline
                            onClick={() => requestCloseModal()}
                        >{t("BackButton")}</Button>

                        <Button
                            color="primary"
                            type="submit"
                        >{t("OrderDetails.EmailResendButton")}</Button>
                    </ModalFooter>
                </Form>
            </Formik>
        </Modal>
    );
}