import React from "react";
import { useState, useEffect } from 'react';
import { bemNames } from "Utilities";
import { RedemptionSearchResultDetails, IssuanceSearchResultDetails, IClientDto } from 'Models';
import { useTranslation } from "react-multi-lang";
import { AreaLoader, InputWithValidations, ReactstrapDataTable, ClientFilter } from "../Components";
import { Cell, Column } from "react-table";
import { clientService, customerServicesService } from "../Services";
import * as Yup from "yup";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import Moment from "react-moment";
import { getInfoNotification, nameOf } from "../Utilities";
import { Link, Redirect } from "react-router-dom";
import Notifications from 'react-notification-system-redux';
import { connect } from "react-redux";

interface NotificationStoreProps {
    notifyInfo: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyInfo: Notifications.info
}

function CustomerServicePageComponent({
    notifyInfo
}: NotificationStoreProps) {

    const t = useTranslation();
    const bem = bemNames.create("customer-service-page");

    const [loading, setLoading] = useState(true);

    const [showOperations, setShowOperations] = useState(true);
    const [redemptionSearchResults, setRedemptionSearchResults] = useState<RedemptionSearchResultDetails[]>();
    const [issuanceSearchResults, setIssuanceSearchResults] = useState<IssuanceSearchResultDetails[]>();
    const [redirectToOrderDetails, setRedirectToOrderDetails] = useState<number>();
    const [redirectToIssuanceDetails, setRedirectToIssuanceDetails] = useState<number>();

    const [availableCountryCodes, setAvailableCountryCodes] = useState<string[]>();

    const [clients, setClients] = useState<IClientDto[]>([]);
    const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
    const [selectedClients, setSelectedClients] = useState<string[]>([]);

    const IssuanceSearchResultsColumnDefinitions: Column<IssuanceSearchResultDetails>[] = [
        {
            id: nameOf<IssuanceSearchResultDetails>("projectId"),
            Header: t("ProjectDetails.ProjectId"),
            accessor: (model) => model.projectId,
        },
        {
            id: nameOf<IssuanceSearchResultDetails>("projectName"),
            Header: t("ProjectDetails.ProjectName"),
            accessor: (model) => model.projectName,
        },
        {
            id: nameOf<IssuanceSearchResultDetails>("issuanceDate"),
            Header: t("OrderDetails.IssuanceDateLabel"),
            accessor: (model) => model.issuanceDate ? new Date(model.issuanceDate).getTime().toString() : "",
            Cell: (cell: Cell<IssuanceSearchResultDetails>) => {
                const value = cell.row.original.issuanceDate;

                return value
                    ? <Moment format="ll HH:mm">{value}</Moment>
                    : "-";
            }
        },
        {
            id: nameOf<IssuanceSearchResultDetails>("name"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => model.name
        },
        {
            id: nameOf<IssuanceSearchResultDetails>("emailAddress"),
            Header: t("OrderDetails.EmailAddressLabel"),
            accessor: (model) => model.emailAddress
        },
        {
            id: nameOf<IssuanceSearchResultDetails>("value"),
            Header: t("OrderDetails.CodeValueLabel"),
            accessor: (model) => model.value?.toFixed(2),
            Cell: (cell: Cell<IssuanceSearchResultDetails>) => {
                const data = cell.row.original;

                return `${data.currencySymbol}${data.value?.toFixed(2)}`;
            }
        },
        {
            id: "actions",
            Cell: (cell: Cell<IssuanceSearchResultDetails>) => {

                return <Link to={`/customer-service/issuance-details/${cell.row.original.redemptionCodeId}`}>
                    <Button color="primary">{t("ViewButton")}</Button>
                </Link>;
            }
        },
    ];

    const RedemptionSearchResultsColumnDefinitions: Column<RedemptionSearchResultDetails>[] = [
        {
            id: nameOf<RedemptionSearchResultDetails>("orderDate"),
            Header: t("OrderDetails.RedemptionDateLabel"),
            accessor: (model) => model.orderDate ? new Date(model.orderDate).getTime().toString() : "",
            Cell: (cell: Cell<RedemptionSearchResultDetails>) => {
                const value = cell.row.original.orderDate;

                return value
                    ? <Moment format="ll HH:mm">{value}</Moment>
                    : "-";
            }
        },
        {
            id: nameOf<RedemptionSearchResultDetails>("scmOrderId"),
            Header: t("OrderDetails.OrderIdLabel"),
            accessor: (model) => `SCR-${model.scmOrderId}`,
        },
        {
            id: nameOf<RedemptionSearchResultDetails>("firstName"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => `${model.firstName} ${model.lastName}`,
        },
        {
            id: nameOf<RedemptionSearchResultDetails>("emailAddress"),
            Header: t("OrderDetails.EmailAddressLabel"),
            accessor: (model) => model.emailAddress,
        },
        {
            id: nameOf<RedemptionSearchResultDetails>("orderValue"),
            Header: t("OrderHistory.OrderTileOrderValueLabel"),
            accessor: (model) => model.orderValue?.toFixed(2),
            Cell: (cell: Cell<RedemptionSearchResultDetails>) => {
                const data = cell.row.original;

                return `${data.currencySymbol}${data.orderValue?.toFixed(2)}`;
            }
        },
        {
            id: "actions",
            Cell: (cell: Cell<RedemptionSearchResultDetails>) => {

                return <Link to={`/customer-service/redemption-order-details/${cell.row.original.scmOrderId}`}>
                    <Button color="primary">{t("ViewButton")}</Button>
                </Link>;
            }
        },
    ];

    const EmailAddressValidationSchema = Yup.object().shape({
        emailAddress: Yup.string()
            .email(t("ValidationError.InvalidEmailAddressMessage"))
            .required(t("ValidationError.EmailAddressRequiredMessage"))
    });

    const MobileNumberValidationSchema = Yup.object().shape({
        mobileNumber: Yup.string()
            .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, t("ValidationError.InvalidMobileNumberMessage"))
            .required(t("ValidationError.MobileNumberRequiredMessage"))
    });

    const LastNameValidationSchema = Yup.object().shape({
        lastName: Yup.string()
            .required(t("ValidationError.LastNameRequiredMessage"))
    });
    const CodeValidationSchema = Yup.object().shape({
        code: Yup.string()
            .required(t("ValidationError.CodeRequired"))
    });
    const StaffNumberValidationSchema = Yup.object().shape({
        staffNumber: Yup.string()
            .required(t("ValidationError.StaffNumberRequired"))
    });
    const OrderIdValidationSchema = Yup.object().shape({
        orderId: Yup.string()
            .required(t("ValidationError.OrderIdRequiredMessage"))
    });

    useEffect(() => {
        (async () => {
            setAvailableCountryCodes(await customerServicesService.getClientCountryCodes());
        })();
    }, []);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            loadClients()
        ]).then(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setSelectedClients(clients.map(c => c.tenantId));
    }, [clients]);

    const loadClients = async (): Promise<void> => {
        try {
            setClients(await clientService.getClients());
        } catch (error) {
            return error;
        }
    }

    const searchRedemptions = async ({ emailAddress, orderId }: { emailAddress?: string, orderId?: number }) => {
        try {
            const results = await customerServicesService.searchRedemptions({ tenantIds: selectedClients.map(c => +c), emailAddress, orderId });

            if (results && results.length === 1) {
                setRedirectToOrderDetails(results[0].scmOrderId);
                return;
            }

            if (results && results.length > 0) {
                setRedemptionSearchResults(results);
                setShowOperations(false);
            } else {
                noResultsFound();
            }

        } catch (e) {
            console.error(e);
            noResultsFound();
        }
    }

    const searchIssuances = async ({ emailAddress, lastName, code, staffNumber, mobileNumber }: { emailAddress?: string, lastName?: string, code?: string, staffNumber?: string, mobileNumber?: string }) => {
        try {

            var results = await customerServicesService.searchIssuances({ tenantIds: selectedClients.map(c => +c), emailAddress, lastName, code, staffNumber, mobileNumber });

            if (results && results.length === 1) {
                setRedirectToIssuanceDetails(results[0].redemptionCodeId);
                return;
            }

            if (results && results.length > 0) {

                setIssuanceSearchResults(results);
                setShowOperations(false);
            } else {
                noResultsFound();
            }


        } catch (e) {

            console.error(e);
            noResultsFound();
        }
    }

    function onGoToSearch() {
        setShowOperations(true);

        setIssuanceSearchResults(undefined);
        setRedemptionSearchResults(undefined);

        setRedirectToIssuanceDetails(undefined);
        setRedirectToOrderDetails(undefined);
    }

    function noResultsFound() {
        notifyInfo(getInfoNotification(t("NotificationMessage.NoResultsFoundMessage"), { position: "tc" }));
    }

    if (redirectToOrderDetails) {
        return <Redirect to={`/customer-service/redemption-order-details/${redirectToOrderDetails}`} />;
    }

    if (redirectToIssuanceDetails) {
        return <Redirect to={`/customer-service/issuance-details/${redirectToIssuanceDetails}`} />;
    }

    return (
        <div className={bem.b()}>

            <AreaLoader show={loading} />

            <div className={bem.e("top")}>
                <h1>{t("CustomerService.CustomerServiceLabel")}</h1>

                <div className={bem.e("filters")}>
                    {showOperations &&
                        <ClientFilter
                            clients={clients}
                            selected={selectedClients}
                            setSelected={setSelectedClients}
                            isOpen={isClientDropdownOpen}
                            setIsOpen={setIsClientDropdownOpen}
                            alignDropdownLeft={true}
                        />
                    }
                </div>

            </div>

            <Container>
                <Row className="flex-column">

                    {showOperations &&
                        <Container>
                            <Row>
                                <Card className="w-100 mt-3">
                                    <CardHeader>
                                        <h2>{t("CustomerService.SearchRedemptionsLabel")}</h2>
                                    </CardHeader>

                                    <CardBody>

                                        <Formik
                                            initialValues={{
                                                emailAddress: ""
                                            }}
                                            validationSchema={EmailAddressValidationSchema}
                                            onSubmit={async (values) => {
                                                await searchRedemptions({ emailAddress: values.emailAddress });
                                            }}
                                        >

                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <AreaLoader show={isSubmitting} message={t("SearchingLabel")} />
                                                    <Row className="d-flex">
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="text-uppercase font-weight-bold"
                                                                    for="emailAddress"
                                                                >{t("CustomerService.SearchRedemptionsByEmailAddressLabel")}</Label>

                                                                <Field
                                                                    name="emailAddress"
                                                                    disabled={isSubmitting}
                                                                    component={InputWithValidations}
                                                                    autoComplete="off"
                                                                />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Button
                                                                    color="primary"
                                                                    className="text-uppercase"
                                                                    type="submit"
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        marginTop: "30px"
                                                                    }}
                                                                    disabled={isSubmitting}
                                                                >{t("SearchLabel")}</Button>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                </Form>
                                            )}

                                        </Formik>

                                        <Formik
                                            initialValues={{
                                                orderId: ""
                                            }}
                                            validationSchema={OrderIdValidationSchema}
                                            onSubmit={async (values) => {
                                                await searchRedemptions({ orderId: +values.orderId });
                                            }}
                                        >

                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <AreaLoader show={isSubmitting} message={t("SearchingLabel")} />
                                                    <Row className="d-flex">
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="text-uppercase font-weight-bold"
                                                                    for="orderId"
                                                                >{t("CustomerService.SearchRedemptionsByOrderIdLabel")}</Label>

                                                                <Field
                                                                    name="orderId"
                                                                    disabled={isSubmitting}
                                                                    component={InputWithValidations}
                                                                    autoComplete="off"
                                                                    inputPrefix="SCR-"
                                                                />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Button
                                                                    color="primary"
                                                                    className="text-uppercase"
                                                                    type="submit"
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        marginTop: "30px"
                                                                    }}
                                                                    disabled={isSubmitting}
                                                                >{t("SearchLabel")}</Button>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                </Form>
                                            )}

                                        </Formik>

                                    </CardBody>
                                </Card>

                            </Row>
                            <Row>
                                <Card className="w-100 mt-3">
                                    <CardHeader>
                                        <h2>{t("CustomerService.SearchIssuancesLabel")}</h2>
                                    </CardHeader>

                                    <CardBody>

                                        <Formik
                                            initialValues={{
                                                code: ""
                                            }}
                                            validationSchema={CodeValidationSchema}
                                            onSubmit={async (values) => {
                                                await searchIssuances({ code: values.code });
                                            }}
                                        >

                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <AreaLoader show={isSubmitting} message={t("SearchingLabel")} />
                                                    <Row className="d-flex">
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="text-uppercase font-weight-bold"
                                                                    for="code"
                                                                >{t("CustomerService.SearchByCodeLabel")}</Label>

                                                                <Field
                                                                    name="code"
                                                                    disabled={isSubmitting}
                                                                    component={InputWithValidations}
                                                                    autoComplete="off"
                                                                />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Button
                                                                    color="primary"
                                                                    className="text-uppercase"
                                                                    type="submit"
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        marginTop: "30px"
                                                                    }}
                                                                    disabled={isSubmitting}
                                                                >{t("SearchLabel")}</Button>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                </Form>
                                            )}

                                        </Formik>

                                        <Formik
                                            initialValues={{
                                                lastName: ""
                                            }}
                                            validationSchema={LastNameValidationSchema}
                                            onSubmit={async (values) => {
                                                await searchIssuances({ lastName: values.lastName });
                                            }}
                                        >

                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <AreaLoader show={isSubmitting} message={t("SearchingLabel")} />
                                                    <Row className="d-flex">
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="text-uppercase font-weight-bold"
                                                                    for="lastName"
                                                                >{t("CustomerService.SearchByLastNameLabel")}</Label>

                                                                <Field
                                                                    name="lastName"
                                                                    disabled={isSubmitting}
                                                                    component={InputWithValidations}
                                                                    autoComplete="off"
                                                                />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Button
                                                                    color="primary"
                                                                    className="text-uppercase"
                                                                    type="submit"
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        marginTop: "30px"
                                                                    }}
                                                                    disabled={isSubmitting}
                                                                >{t("SearchLabel")}</Button>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                </Form>
                                            )}

                                        </Formik>

                                        <Formik
                                            initialValues={{
                                                emailAddress: ""
                                            }}
                                            validationSchema={EmailAddressValidationSchema}
                                            onSubmit={async (values) => {
                                                await searchIssuances({ emailAddress: values.emailAddress });
                                            }}
                                        >

                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <AreaLoader show={isSubmitting} message={t("SearchingLabel")} />
                                                    <Row className="d-flex">
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="text-uppercase font-weight-bold"
                                                                    for="emailAddress"
                                                                >{t("CustomerService.SearchRedemptionsByEmailAddressLabel")}</Label>

                                                                <Field
                                                                    name="emailAddress"
                                                                    disabled={isSubmitting}
                                                                    component={InputWithValidations}
                                                                    autoComplete="off"
                                                                />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Button
                                                                    color="primary"
                                                                    className="text-uppercase"
                                                                    type="submit"
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        marginTop: "30px"
                                                                    }}
                                                                    disabled={isSubmitting}
                                                                >{t("SearchLabel")}</Button>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                </Form>
                                            )}

                                        </Formik>

                                        <Formik
                                            initialValues={{
                                                mobileNumber: ""
                                            }}
                                            validationSchema={MobileNumberValidationSchema}
                                            onSubmit={async (values) => {
                                                await searchIssuances({ mobileNumber: values.mobileNumber });
                                            }}
                                        >

                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <AreaLoader show={isSubmitting} message={t("SearchingLabel")} />
                                                    <Row className="d-flex">
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="text-uppercase font-weight-bold"
                                                                    for="mobileNumber"
                                                                >{t("CustomerService.SearchRedemptionsByMobileNumberLabel")}</Label>

                                                                <Field
                                                                    name="mobileNumber"
                                                                    disabled={isSubmitting}
                                                                    component={InputWithValidations}
                                                                    autoComplete="off"
                                                                    type="tel"
                                                                    countryCodes={availableCountryCodes}
                                                                />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Button
                                                                    color="primary"
                                                                    className="text-uppercase"
                                                                    type="submit"
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        marginTop: "30px"
                                                                    }}
                                                                    disabled={isSubmitting}
                                                                >{t("SearchLabel")}</Button>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                </Form>
                                            )}

                                        </Formik>

                                        <Formik
                                            initialValues={{
                                                staffNumber: ""
                                            }}
                                            validationSchema={StaffNumberValidationSchema}
                                            onSubmit={async (values) => {
                                                await searchIssuances({ staffNumber: values.staffNumber });
                                            }}
                                        >

                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <AreaLoader show={isSubmitting} message={t("SearchingLabel")} />
                                                    <Row className="d-flex">
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="text-uppercase font-weight-bold"
                                                                    for="staffNumber"
                                                                >{t("CustomerService.SearchByStaffNoLabel")}</Label>

                                                                <Field
                                                                    name="staffNumber"
                                                                    disabled={isSubmitting}
                                                                    component={InputWithValidations}
                                                                    autoComplete="off"
                                                                />

                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Button
                                                                    data-automation-id="staffNumberSearchButton"
                                                                    color="primary"
                                                                    className="text-uppercase"
                                                                    type="submit"
                                                                    style={{
                                                                        maxWidth: "150px",
                                                                        marginTop: "30px"
                                                                    }}
                                                                    disabled={isSubmitting}
                                                                >{t("SearchLabel")}</Button>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                </Form>
                                            )}

                                        </Formik>

                                    </CardBody>
                                </Card>
                            </Row>
                        </Container>
                    }

                    {issuanceSearchResults &&
                        <Card className="w-100 mt-3">
                            <CardHeader>
                                <h2>{t("CustomerService.SearchResultsLabel")}</h2>
                            </CardHeader>

                            <CardBody>

                                <ReactstrapDataTable<IssuanceSearchResultDetails>
                                    columnDefinitions={IssuanceSearchResultsColumnDefinitions}
                                    items={issuanceSearchResults}
                                    totalItemCount={issuanceSearchResults.length}
                                    defaultSortByColumnIndex={2}
                                    defaultSortByAscending={false}
                                />

                                <Button
                                    className="text-uppercase"
                                    color="secondary"
                                    onClick={onGoToSearch}
                                >{t("GoBackButton")}</Button>

                            </CardBody>
                        </Card>
                    }

                    {redemptionSearchResults &&
                        <Card className="w-100 mt-3">
                            <CardHeader>
                                <h2>{t("CustomerService.SearchResultsLabel")}</h2>
                            </CardHeader>

                            <CardBody>

                                <ReactstrapDataTable<RedemptionSearchResultDetails>
                                    columnDefinitions={RedemptionSearchResultsColumnDefinitions}
                                    items={redemptionSearchResults}
                                    totalItemCount={redemptionSearchResults.length}
                                    defaultSortByColumnIndex={0}
                                    defaultSortByAscending={false}
                                />

                                <Button
                                    className="text-uppercase"
                                    color="secondary"
                                    onClick={onGoToSearch}
                                >{t("GoBackButton")}</Button>

                            </CardBody>
                        </Card>
                    }
                </Row>
            </Container>
        </div>
    )
}

export const CustomerServicePage = connect(null, mapDispatchToProps)(CustomerServicePageComponent);