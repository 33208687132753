export enum ClaimTypes {
    //default types
    Email= "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
    Name = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
    Surname = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",

    // custom types
    TenantGuids = "TenantGuids",
    UserId = "UserId",
    PasswordChangeRequired = "PasswordChangeRequired",
    IsFirstLogin = "IsFirstLogin",
    TermsAccepted = "TermsAccepted",
    TermsUpdated = "TermsUpdated",
    ConsentCaptureRequired = "ConsentCaptureRequired",
    MfaAuthType = "MfaAuthType",
    MfaPhoneNumber = "MfaPhoneNumber",
    MfaAuthPassed = "MfaAuthPassed",
    UserType = "UserType",
    ProjectPermissions = "ProjectPermissions",
    AllowMfaBypass = "AllowMfaBypass",
    IsSSO = "IsSSO"
}