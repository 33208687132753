import React from "react";
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bemNames, getErrorNotification } from 'Utilities';
import { ProjectDetailsDto, ProjectSetupType } from 'Models';
import { useTranslation } from "react-multi-lang";
import { AreaLoader } from "../Components";
import { FaCamera } from "react-icons/fa";
import Notifications from 'react-notification-system-redux';
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Table } from "reactstrap";
import { projectService } from "../Services/ProjectService";
import { IoCalendarClearOutline, IoPieChartOutline, IoBagOutline, IoPricetagOutline, IoCardOutline } from "react-icons/io5";
import { ReactComponent as DigitalProjectIcon } from 'Assets/Images/icons/Digital-project.svg';
import { ReactComponent as PhysicalProjectIcon } from 'Assets/Images/icons/Physical-project.svg';
import { ReactComponent as PrintReadyProjectIcon } from 'Assets/Images/icons/Print-Ready-project.svg';
import moment from "moment";

interface StoreProps {
    notifyError: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
}

const ProjectDetailsPage = ({
    notifyError
}: StoreProps) => {
    const t = useTranslation();
    const bem = bemNames.create("project-details");
    const [loading, setLoading] = useState(true);
    const [projectDetails, setProjectDetails] = useState<ProjectDetailsDto>(
        {
            billingFreq: "",
            perCodeFee: 0,
            smsFee: 0,
            codeType: "",
            currency: "",
            discountPercentage: 0,
            handlingFeePercentage: 0,
            isPayOnAccount: null,
            projectId: 0,
            projectName: "",
            tenantId: 0,
            tenantName: "",
            programmeStart: undefined,
            programmeEnd: undefined,
            breakageRebate: 0,
            projectSetupType: -1,
            isProjectClosed: false
        });
    // url params
    const { projectId } = useParams<{ projectId: string }>();

    useEffect(() => {
        loadProjectDetails();
    }, [projectId]);

    const loadProjectDetails = async () => {
        setLoading(true);
        try {
            const data = await projectService.getProjectDetails(projectId);
            setProjectDetails(data);
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("ProjectDetails.LoadProjectDetailsFailure")));
        }
        setLoading(false);
    }

    return (
        <div className={bem.b()}>
            <AreaLoader
                show={loading}
                message={t("NotificationMessage.LoadProjectDetails")}
            />
            <Breadcrumb>
                <BreadcrumbItem><Link to="/Projects/">{t("Projects.ProjectsLabel")}</Link></BreadcrumbItem>
                <BreadcrumbItem active>{t("OrderSummary.ProjectDetailsLabel")}</BreadcrumbItem>
            </Breadcrumb>
            <div className="d-flex flex-column">
                <h1>{t("OrderSummary.ProjectDetailsLabel")}</h1>
            </div>
            <div className={bem.e("row", "mt-4")}>
                <div className={bem.e("row-body", "w-100")}>
                    <div className="d-flex flex-column flex-md-row justify-content-between flex-wrap">
                        <div className="col">
                            <div className="d-flex flex-column">
                                <strong className="text-uppercase">{t("ProjectDetails.ProjectId")}</strong>
                                {projectDetails?.projectId}
                            </div>
                            <div className="d-flex flex-column">
                                <div className={bem.e("icon-wrapper")}>
                                    {projectDetails.codeType == "Physical" && <PhysicalProjectIcon />}
                                    {projectDetails.codeType == "PrintReady" && <PrintReadyProjectIcon className="print-icon" />}
                                    {projectDetails.codeType == "eCode" && <DigitalProjectIcon />}
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column">
                                <strong className="text-uppercase">{t("ProjectDetails.ProjectName")}</strong>
                                {projectDetails?.projectName}
                            </div>
                            <div className="d-flex flex-column mt-2">
                                <strong className="text-uppercase">{t("ProjectDetails.ProgrammeStartDate")}</strong>
                                {projectDetails?.programmeStart ? moment.utc(projectDetails.programmeStart).format("ll") : ""}
                            </div>
                            <div className="d-flex flex-column mt-2">
                                <strong className="text-uppercase">{t("ProjectDetails.ProgrammeEndDate")}</strong>
                                {projectDetails?.programmeEnd ? moment.utc(projectDetails.programmeEnd).format("ll") : ""}
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column">
                                <strong className="text-uppercase">{t("ProjectDetails.CodeType")}</strong>
                                {projectDetails?.codeType}
                            </div>
                            <div className="d-flex flex-column mt-2">
                                <strong className="text-uppercase">{t("ProjectDetails.Currency")}</strong>
                                {projectDetails?.currency}
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column">
                                <Link
                                    to={`/order-history`}>
                                    <button
                                        aria-label={projectDetails?.projectName + " order history button"}
                                        className={`btn btn-primary text-uppercase`}>
                                        {t("ProjectDetails.OrderHistory")}
                                    </button>
                                </Link>
                            </div>
                            <div className="d-flex flex-column mt-2">
                                {projectDetails?.isProjectClosed &&
                                    <button
                                        aria-label={projectDetails?.projectName + " project closed"}
                                        type="button"
                                        disabled={projectDetails?.isProjectClosed}
                                        className="btn btn-secondary text-uppercase">
                                        {t("Projects.ProjectClosedButton")}
                                    </button>
                                }

                                {!projectDetails?.isProjectClosed &&
                                    <Link
                                        to={`/projects/order-codes/${projectDetails?.projectId}`}>
                                        <button
                                            aria-label={projectDetails?.projectName + " order button"}
                                            type="button"
                                            className="btn btn-primary text-uppercase">
                                            {t("Projects.ProjectTileOrderButton")}
                                        </button>
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-lg-row">
                <div className={bem.e("row", "mt-4 d-flex flex-column mr-lg-3")}>
                    <div className={bem.e("row-header font-weight-bold", "p-3 text-uppercase")} style={{ lineHeight: "1rem" }}>
                        {t("ProjectDetails.BillingDetails")}
                    </div>
                    <div className={bem.e("row-body", "p-3")}>
                        <Table style={{ maxWidth: "46rem" }}>
                            <thead>
                                <tr>
                                    <th><IoCalendarClearOutline fontSize="20" /></th>
                                    <th>{t("ProjectDetails.BillingFrequency")}</th>
                                    <th>{projectDetails?.billingFreq}</th>
                                </tr>
                                {projectDetails.projectSetupType == ProjectSetupType.BillOnIssuance &&
                                    <>
                                        <tr>
                                            <th><IoPieChartOutline fontSize="20" /></th>
                                            <th>{t("ProjectDetails.HandlingFeePercentage")}</th>
                                            <th>{projectDetails?.handlingFeePercentage?.toFixed(2) + "%" ?? "0.00%"}</th>
                                        </tr>
                                        <tr>
                                            <th><IoBagOutline fontSize="20" /></th>
                                            <th>{t("ProjectDetails.perCodeFee")}</th>
                                            <th>{projectDetails?.perCodeFee?.toFixed(2) ?? "0.00"}</th>
                                        </tr>
                                    </>
                                }
                                {projectDetails?.discountPercentage != null && projectDetails?.discountPercentage !== 0 &&
                                    <tr>
                                        <th><IoPricetagOutline fontSize="20" /></th>
                                        <th>{t("ProjectDetails.DiscountPercentage")}</th>
                                        <th>{projectDetails.discountPercentage.toFixed(2) + "%"}</th>
                                    </tr>
                                }
                                {projectDetails?.breakageRebate != null && projectDetails?.breakageRebate !== 0 &&
                                    <tr>
                                        <th><IoPricetagOutline fontSize="20" /></th>
                                        <th>{t("ProjectDetails.BreakageRebate")}</th>
                                        <th>{projectDetails.breakageRebate.toFixed(2) + "%"}</th>
                                    </tr>
                                }
                                {projectDetails.isPayOnAccount != null &&
                                    <tr>
                                        <th><IoCardOutline fontSize="20" /></th>
                                        <th>{t("ProjectDetails.PaymentMethods")}</th>
                                        <th>
                                            {projectDetails?.isPayOnAccount === true
                                                ? t("ProjectDetails.OnAccount")
                                                : t("ProjectDetails.PrePay")
                                            }
                                        </th>
                                    </tr>
                                }
                            </thead>
                        </Table>
                    </div>
                </div>
                <div className={bem.e("row order-costs", "mt-4 d-flex flex-column ml-lg-3 pb-5")} style={{ position: "relative" }}>
                    <div className={bem.e("row-header font-weight-bold", "p-3 text-uppercase")} style={{ lineHeight: "1rem" }}>
                        {t("ProjectDetails.ClientDetails")}
                    </div>
                    <div className={bem.e("row-body", "w-100 pt-1")}>
                        <div className="d-flex flex-column flex-md-row justify-content-between flex-wrap">
                            <div className="col">
                                <div className="d-flex flex-column">
                                    <strong className="text-uppercase">{t("ProjectDetails.CompanyName")}</strong>
                                    {projectDetails?.tenantName}
                                </div>
                                <div className="d-flex flex-column mt-2">
                                    <strong className="text-uppercase">{t("ProjectDetails.ClientId")}</strong>
                                    {projectDetails?.tenantId}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ConnectedComponent = connect(null, mapDispatchToProps)(ProjectDetailsPage);

export {
    ConnectedComponent as ProjectDetailsPage
}