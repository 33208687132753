import React, { useEffect, useState } from "react";
import {
    useTable, useSortBy, useGlobalFilter, useFilters, usePagination, useAsyncDebounce,
    Column, TableInstance, UseGlobalFiltersInstanceProps, UsePaginationInstanceProps,
    UseTableOptions, UsePaginationOptions, TableState, UsePaginationState, UseSortByState,
    UseGlobalFiltersState, UseSortByColumnProps, HeaderGroup, UseFiltersColumnProps, Row,
    UseFiltersColumnOptions, UseFiltersOptions, UseFiltersState, useRowSelect, UseRowSelectOptions,
    UseRowSelectState, Cell, UseRowSelectInstanceProps, UseTableColumnProps, UseSortByInstanceProps,
    IdType, UseFiltersInstanceProps
} from "react-table";

// Utils
import { AreaLoader, FilterByOptions, FilteredColumn, RowSelectionInput, SortByOptions } from "Components";
import { Input, Label, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { bemNames } from "../Utilities";
import Select from "react-select";
import { useTranslation } from "react-multi-lang";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";

// Extend/compose react-table interfaces
interface MyHeaderGroup<D extends object = {}>
    extends HeaderGroup<D>,
    UseSortByColumnProps<D>,
    UseFiltersColumnProps<D> { }

interface MyTableOptions<D
    extends object>
    extends UseTableOptions<D>,
    UsePaginationOptions<D>,
    UseFiltersOptions<D>,
    UseRowSelectOptions<D> { }

interface MyTableInstance<D extends object = {}>
    extends TableInstance<D>,
    UseGlobalFiltersInstanceProps<D>,
    UsePaginationInstanceProps<D>,
    UseSortByInstanceProps<D>,
    UseFiltersInstanceProps<D>,
    UseRowSelectInstanceProps<D> { state: MyTableState<D> }

interface MyTableState<D extends object = {}>
    extends TableState<D>,
    UsePaginationState<D>,
    UseFiltersState<D>,
    UseSortByState<D>,
    UseGlobalFiltersState<D>,
    UseRowSelectState<D> { sortBy: SortByOptions[] }


interface SelectOption<D> {
    label: string;
    value: D;
}

enum Order {
    Ascending,
    Descending
}

// Define a default UI for global filtering
function GlobalFilter({
    id,
    setGlobalFilter
}: {
    id: string,
    setGlobalFilter: (filterValue: any) => void
}
) {
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined) // Set undefined to remove the filter entirely
    }, 300)

    return (
        <Input
            id={id}
            onChange={e => {

                onChange(e.target.value);
            }}
            type="search"
        //placeholder="Search..."
        />
    )
}

function Paging({ pages, gotoPage, pageIndex }: { pages: Array<number>, gotoPage: (page: number) => void, pageIndex: number }) {

    const firstPage = pages[0];
    const lastPage = pages[pages.length - 1];

    const Item = (pageNo: number) =>
        <PaginationItem
            key={pageNo}
            active={pageIndex === pageNo}
        >
            <PaginationLink
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    gotoPage(pageNo);
                }}
            >
                {pageNo + 1}
            </PaginationLink>
        </PaginationItem>;

    const Dots = () => <PaginationItem>
        <PaginationLink
            href="#"
            tabIndex={0}
            onClick={e => e.preventDefault()}
        >
            ...
        </PaginationLink>
    </PaginationItem>;

    const MiddleItems = (pages: Array<number>, pageIndex: number) => {

        if (pageIndex < 4) {
            return [
                Item(pages[1]),
                Item(pages[2]),
                Item(pages[3]),
                Item(pages[4]),
                Dots()
            ]
        } else if (pageIndex > pages.length - 5) {
            return [
                Dots(),
                Item(pages[pages.length - 5]),
                Item(pages[pages.length - 4]),
                Item(pages[pages.length - 3]),
                Item(pages[pages.length - 2]),
            ]
        } else {
            return [
                Dots(),
                Item(pages[pageIndex - 1]),
                Item(pages[pageIndex]),
                Item(pages[pageIndex + 1]),
                Dots()
            ]
        }
    }

    return (
        <>
            {pages.length <= 7 ?
                (
                    // 7 or less pages
                    pages.map(pageNo => Item(pageNo))

                ) : (
                    // more than 7 pages
                    [
                        Item(firstPage),
                        MiddleItems(pages, pageIndex),
                        Item(lastPage)
                    ]
                )

            }
        </>
    );
}

type FilterColumnType = UseFiltersColumnProps<any> & UseFiltersColumnOptions<any> & UseTableColumnProps<any>

const Filter = ({ column }: { column: FilterColumnType }) => {

    return (
        <>
            {column.canFilter && column.Filter ?

                <div className="filterWrap">
                    <ul>
                        <li>
                            <i className="fas fa-filter"></i>
                            <ul>
                                <li>
                                    <div className="filter"
                                        onClick={e => {

                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        {column.render("Filter")}
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>


                : <></>
            }
        </>
    )
}

interface SortByOption<D> {
    sortByColumnId: keyof D;
    sortByAscText: string;
    sortByDescText: string;
}

interface Props<D extends object = {}> {
    columnDefinitions: Array<Column<D> | FilteredColumn<D>>;
    items: D[];
    useRowSelection?: boolean;
    onSelectedRowsChanged?: (selectedRows: D[]) => void;
    fetchData?: (pageIndex: number, pageSize: number, sortBy: SortByOptions[], searchTerm: string, filterBy?: FilterByOptions) => void;
    loading?: boolean;
    totalItemCount: number;
    showHeaders?: boolean;
    defaultSortByColumnIndex?: number;
    defaultSortByAscending?: boolean;
    debug?: boolean;
    sortByOptions?: SortByOption<D>[];
    hideSearch?: boolean;
    columnFilter?: {
        columnId: keyof D;
        values: {
            value: string; id: string;
        }[];
    };
    //hiddenColumns?: (keyof D)[];
    pageIndexChanged?: (pageIndex: number) => void;
    initialPageIndex?: number;
}

const customCaseInsensitive = (prev: Row, curr: Row, columnId: string) => {
    const valueA = prev.values[columnId]?.toString()?.toLowerCase();
    const valueB = curr.values[columnId]?.toString()?.toLowerCase();
    return (valueA > valueB)
        ? 1
        : (
            (valueA < valueB)
                ? -1
                : 0
        );
}

export function ReactstrapDataTable<D extends object = {}>({
    columnDefinitions,
    useRowSelection,
    onSelectedRowsChanged,
    items,
    fetchData,
    loading,
    hideSearch,
    totalItemCount = 0,
    showHeaders = false,
    defaultSortByColumnIndex = undefined,
    defaultSortByAscending = undefined,
    debug = false,
    sortByOptions = undefined,
    columnFilter = undefined,
    //hiddenColumns = undefined,
    pageIndexChanged = undefined,
    initialPageIndex = 0,
}: Props<D>) {

    const bem = bemNames.create("reactstrap-data-table")

    const [controlledPageCount, setControlledPageCount] = useState(0);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,

        rows,
        page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
        setGlobalFilter,

        // Paging props
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        initialState,
        // row selection        
        selectedFlatRows, // the actual selected objects
        setSortBy,
        toggleSortBy,
        setFilter
    }: MyTableInstance<D> = useTable({
        columns: columnDefinitions,
        data: items,
        defaultColumn: { Filter: undefined },
        initialState: ({
            pageIndex: initialPageIndex,
            selectedRowIds: {},
            sortBy: (defaultSortByColumnIndex !== undefined
                ? [
                    {
                        id: columnDefinitions[defaultSortByColumnIndex].id,
                        desc: defaultSortByAscending != undefined ? !defaultSortByAscending : false
                    }
                ]
                : []),
            //hiddenColumns: hiddenColumns

        } as MyTableState<D>),
        manualPagination: !!fetchData, // Tell the usePagination hook that we'll handle our own data fetching
        // This means we'll also have to provide our own pageCount.
        pageCount: controlledPageCount,
        disableMultiSort: true,
        manualSortBy: !!fetchData,
        manualFilters: !!fetchData,
        manualGlobalFilter: !!fetchData,
        autoResetPage: !(!!fetchData),
        //useFilters options
        //defaultCanFilter: false,
        //disableFilters: true,    
        sortTypes: { alphanumeric: customCaseInsensitive },
    } as MyTableOptions<D>,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {

            if (useRowSelection) {

                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',

                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<D>) => (

                            <RowSelectionInput {...getToggleAllRowsSelectedProps()} />

                        ),

                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: (cell: Cell) => (
                            <RowSelectionInput {...(cell.row as any).getToggleRowSelectedProps()} /> // cast to any as the proper row type cas circular references to Cell type
                        ),
                    },
                    ...columns,
                ])
            }
        }
    ) as MyTableInstance<D>

    const t = useTranslation();

    useEffect(() => {

        onSelectedRowsChanged?.(selectedFlatRows.map(d => d.original));
    }, [selectedFlatRows.length]);

    const { pageIndex, pageSize, sortBy, globalFilter, filters } = state;

    // Listen for changes in pagination and use the state to fetch our new data
    if (fetchData) {

        const filterBy = filters && filters.length > 0
            ? {
                id: filters[0].id,
                value: filters[0].value
            } as FilterByOptions
            : undefined;

        useEffect(() => {

            fetchData(pageIndex, pageSize, sortBy, globalFilter, filterBy);

        }, [pageIndex, pageSize, globalFilter, JSON.stringify(sortBy), JSON.stringify(filters)]);

    } else {
        totalItemCount = rows.length;
    }

    // Calculate pages
    const [pages, setPages] = useState<number[]>([]);

    useEffect(() => {

        const intPages: number[] = [];
        const pageCountCalc = Math.ceil(totalItemCount / pageSize);

        for (var i = 0; i < pageCountCalc; i++) {
            intPages.push(i);
        }

        setControlledPageCount(pageCountCalc);

        if (intPages.length === 0) {
            intPages.push(0);
        }

        setPages(intPages);

    }, [pageCount, totalItemCount, pageSize]);

    const gotoPageWrapper = (pageNo: number) => {
        if (pageIndexChanged != undefined) {
            pageIndexChanged(pageNo);
        }
        gotoPage(pageNo);
    }

    // Render the UI for your table
    return (

        <div className={bem.b()}>

            {/* First filter row */}
            <div className="row d-print-none">
                {!hideSearch &&

                    <div className={bem.e("filter-block", "mb-2 w-100")}>
                        <div className="d-flex flex-column col-sm-6 float-right">

                            <Label
                                for="global-filter-input"
                                className={bem.e("label", "mb-0 font-weight-bold text-uppercase")}
                            >{t("DataTable.SearchByLabel")}</Label>

                            <GlobalFilter
                                id="global-filter-input"
                                setGlobalFilter={setGlobalFilter}
                            />
                        </div>
                    </div>
                }

                {(sortByOptions && sortByOptions.length > 0) &&
                    <div className={bem.e("filter-block", "col-sm-auto")}>
                        <div className="d-flex flex-column">

                            <div className="mb-2">

                                <Label
                                    for="sort-by-select"
                                    className={bem.e("label", "mb-0 font-weight-bold text-uppercase")}
                                >{t("DataTable.SortByLabel")}</Label>

                                {/*I left the reactstrap select here in case we want to go back to it instead of the react-select input*/}
                                {/*<Input*/}
                                {/*    id="sort-by-select"*/}
                                {/*    type="select"*/}
                                {/*    onChange={e => {*/}
                                {/*        const value = e.target.value;*/}
                                {/*        const columnId = value.split("::")[0];*/}
                                {/*        const order = value.split("::")[1];*/}

                                {/*        if (columnId && order) {*/}

                                {/*            toggleSortBy(columnId as IdType<D>,*/}
                                {/*                order === Order.Descending.toString());*/}

                                {/*        } else {*/}

                                {/*            setSortBy([]);*/}
                                {/*        }                                       */}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <option*/}
                                {/*        key={`::`}*/}
                                {/*        value={`::`}*/}
                                {/*    >*/}

                                {/*    </option>*/}

                                {/*    {sortByOptions.map(o =>*/}

                                {/*        [Order.Ascending, Order.Descending].map(order => (*/}
                                {/*            <option*/}
                                {/*                key={`${o.sortByColumnId}::${order}`}*/}
                                {/*                value={`${o.sortByColumnId}::${order}`}*/}
                                {/*            >*/}
                                {/*                {order === Order.Ascending ? o.sortByAscText : o.sortByDescText}*/}
                                {/*            </option>*/}
                                {/*        ))*/}
                                {/*    )*/}
                                {/*    }*/}

                                {/*</Input>*/}

                                <Select
                                    id="sort-by-select"
                                    isMulti={false}
                                    isClearable={true}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    onChange={e => {

                                        const columnId = e?.value.split("::")[0];
                                        const order = e?.value.split("::")[1];

                                        if (columnId && order) {

                                            toggleSortBy(columnId as IdType<D>,
                                                order === Order.Descending.toString());

                                        } else {

                                            setSortBy([]);
                                        }
                                    }}
                                    placeholder=""
                                    options={sortByOptions.map(o =>

                                        [Order.Ascending, Order.Descending].map(order => (
                                            {
                                                label: order === Order.Ascending ? o.sortByAscText : o.sortByDescText,
                                                value: `${o.sortByColumnId}::${order}`
                                            } as SelectOption<string>
                                        ))
                                    ).flat(1)}
                                    aria-label={t("DataTable.SortByLabel")}
                                />
                            </div>
                        </div>
                    </div>
                }

            </div>

            {/* Main table row */}
            <div className="row">

                <div className="col-sm-12">

                    <AreaLoader show={loading || false} />

                    <Table {...getTableProps()} >
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((c: HeaderGroup<D>) => {

                                        const column = c as MyHeaderGroup<D>;

                                        // Add the sorting props to control sorting.
                                        return (
                                            <th
                                                // Add a sort direction indicator
                                                className={column.isSorted ? column.isSortedDesc ? "sorting_desc" : "sorting_asc" : "sorting"}
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                            >


                                                <div //className="col-title"
                                                >
                                                    {column.render('Header')}
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <FaSortAmountDownAlt className="ml-1" />
                                                            : <FaSortAmountUpAlt className="ml-1" />
                                                        : <></>
                                                    }
                                                    <Filter column={column} />
                                                </div>
                                            </th>);
                                    })}
                                </tr>
                            ))}
                        </thead>

                        <tbody
                            {...getTableBodyProps()}
                        >

                            {page.map(
                                (row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()} >
                                                    {cell.render('Cell')}
                                                </td>
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>

                    </Table>

                </div>
            </div>

            {/* Bottom paging row */}
            <div className="row d-print-none">

                <div className="col-sm-4">
                    <div className="d-flex flex-row">

                        <Label
                            className={bem.e("label", "mb-0")}
                            for="items-selector"
                        >{t("DataTable.ShowEntriesPrefixLabel")}</Label>

                        <Input
                            className={bem.e("items-selector", "ml-2 mr-2")}
                            id="items-selector"
                            type="select"
                            value={state.pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </Input>

                        <Label
                            className={bem.e("label", "mb-0")}
                            for="items-selector"
                        >{t("DataTable.ShowEntriesSuffixLabel")}</Label>
                    </div>
                </div>

                <div className="col-sm-4">
                    <p
                        className="font-weight-bold text-center"
                        role="status"
                        aria-live="polite"
                    >
                        {t("DataTable.ShowEntriesRangeWithTokensLabel")
                            .replace("[[RangeFrom]]", (!totalItemCount ? totalItemCount : (pageIndex * pageSize) + 1).toString())
                            .replace("[[RangeTo]]", (totalItemCount < ((pageIndex + 1) * pageSize) ? totalItemCount : ((pageIndex + 1) * pageSize)).toString())
                            .replace("[[Total]]", totalItemCount.toString())
                        }
                    </p>
                </div>

                <div className="col-sm-4 d-flex flex-row-reverse">

                    <Pagination>
                        <PaginationItem
                            disabled={!canPreviousPage}
                        >
                            <PaginationLink
                                previous
                                href="#"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (pageIndexChanged != undefined) {
                                        pageIndexChanged(state.pageIndex - 1);
                                    }
                                    previousPage();
                                }}
                            />
                        </PaginationItem>

                        <Paging
                            pages={pages}
                            gotoPage={gotoPageWrapper}
                            pageIndex={state.pageIndex}
                        />

                        <PaginationItem
                            disabled={!canNextPage}
                        >
                            <PaginationLink
                                next
                                href="#"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (pageIndexChanged != undefined) {
                                        pageIndexChanged(state.pageIndex + 1);
                                    }
                                    nextPage();
                                }}
                            />
                        </PaginationItem>

                    </Pagination>

                </div>
            </div>


            {
                debug &&

                <>
                    <h3>Debug details:</h3>
                    <div>
                        <pre>
                            <code>
                                {JSON.stringify(
                                    {
                                        state,
                                        pageCount,
                                        canNextPage,
                                        canPreviousPage,
                                        selectedRowIds: state.selectedRowIds,
                                        'selectedFlatRows[].original': selectedFlatRows.map(
                                            d => d.original
                                        ),
                                    },
                                    null,
                                    2
                                )}
                            </code>
                        </pre>
                    </div>
                </>
            }

        </div >

    )
}





/* EXAMLE WITH SERVER SIDE PAGING
 *
 *    <DataTable
                    columnDefinitions={this.columns}
                    items={this.state.data || []}

                    fetchData={this.loadPageData}
                    pageCount={5}
                />
 *
 *
 *   type datatype = {
          firstName: string,
          lastName: string,
    };

    data = (seed: number = 0) => {

        var items = [];
        for (var i = seed + 0; i < seed + 10; i++) {

            items.push(
                {
                    firstName: "firstName" + (i + 1),
                    lastName: "lastName" + (i + 1)
                }
            );
        }
        return items;
    }

    columns: Array<HeaderColumn<datatype>> =
        [

            {
                id: nameof<IAddressForm>("firstName"),
                Header: 'First Name',
                accessor: (model) => model.firstName,
            },
            {
                id: nameof<IAddressForm>("lastName"),
                Header: 'Last Name',
                accessor: (model) => model.lastName,
            },
            {
                id: "action",
                Header: 'Actions',
                accessor: () => "", // Empty accessor for action columns
                Cell: (cell) => {

                    // Use this to inject ids etc in links
                    const data = cell.row.original as datatype;

                    return (<div>
                        <button className="btn btn-primary">Action</button>
                        <button className="btn btn-primary">Action2</button>
                        <span>{data.firstName}</span>
                    </div>)
                }
            },
        ];


    loadPageData = (pageIndex: number, pageSize: number, sortBy: ISortByOptions[], searchTerm: string) => {

        this.setState({
            data: this.data((pageIndex * 10) + 1)
        })
    }



*/