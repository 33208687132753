import React, { ReactNode } from "react";
import { Theme } from 'Models';

const ThemeSelector: React.FC<{ children?: ReactNode, theme: Theme }> = props => {
    //create component using React.lazy for different Themes
    let SelectedTheme = React.lazy(() => import("Themes/defaultTheme"));

    if (props.theme === Theme.Sso) {
        SelectedTheme = React.lazy(() => import("Themes/HMTheme"));
    }

    return (
        <>
            <React.Suspense fallback={<></>}>
                {<SelectedTheme />}
            </React.Suspense>
            {props.children}
        </>
    )
}

export default ThemeSelector;