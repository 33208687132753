import React, {useState} from "react";
import { UserGuideListItemDto } from '../Models';
import { useTranslation } from "react-multi-lang";
import { bemNames } from 'Utilities';
import {GrDocumentDownload} from "react-icons/gr";
import fileDownload from "js-file-download";
import { userGuideService } from "../Services";
import { AreaLoader } from "../Components";
import moment from 'moment';

interface Props {
    userGuide: UserGuideListItemDto
}


export const UserGuideTile = ({userGuide}: Props) => {

    const [loading, setLoading] = useState(false);

    const t = useTranslation();
    const bem = bemNames.create("user-guide-tile");

    const NewDate = moment(userGuide.lastUpdatedDate).format('ll HH:mm');

    const downloadGuide = async (id: number, title: string) => {
        setLoading(true);
        var data = await userGuideService.getUserGuidePdf(id);
        fileDownload(data, `${title}.pdf`);
        setLoading(false);
    }

    return (
        <div className={bem.b()}>
            <AreaLoader show={loading} message={t("UserGuide.UserGuideLoading")} />
            <div className={bem.e("tile-top")}>
                <GrDocumentDownload/>
            </div>
            <div className={bem.e("tile-bottom", "d-flex flex-column align-items-center pb-3")}>
                <div className="title">
                    {userGuide.title}
                </div>
                <div className="lastUpdated pt-2" style={{"color":  "dimgrey"}}>
                {t("UserGuide.UserGuideTableHeaderLastUpdated")}: {NewDate}
                </div>
                <div>
                    <button onClick={() => downloadGuide(userGuide.id!, userGuide.title)} aria-label={userGuide.title + " download button"} type="button" className="btn btn-primary text-uppercase">{t("UserGuide.UserGuideTableDownload")}</button>
                </div>
            </div>            
        </div>
    )
}