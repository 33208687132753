import React from "react";
import ContentLoader from 'react-content-loader'

interface Props {
lines?: number
}

export function LineLoader({
    lines = 1
}: Props) {

    var items = [];

    for (var i = 0; i < lines; i++) {
        const width = Math.random() * (250 - 100) + 100;

        items.push(<rect
            key={i}
            y={(i * 30) + 5}
            width={width}
            height="20"
        />)
    }

    return <ContentLoader
        width="100%"
        height={40 * lines}
    >
        {
            items.map(i => i)
        }
    </ContentLoader>
}