import React from "react";
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bemNames, getErrorNotification, getSuccessNotification, nameOf,  } from 'Utilities';
import { IssuanceDetailsDto, AssociatedEmail, BatchType, RedemptionStatus, CodeDeliveryMethod } from 'Models';
import { useTranslation } from "react-multi-lang";
import { AreaLoader, ReactstrapDataTable, ResendCommunicationDialog } from "../Components";
import { isSmsDelivery, isEmailDelivery } from "Utilities/communicationHelper";

import { customerServicesService } from "../Services";

import Notifications from 'react-notification-system-redux';
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, InputGroup, Label, PopoverBody, Row, UncontrolledPopover, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Cell } from "react-table";
import { BsCheck, BsX, BsPencil } from "react-icons/bs";
import { IoHandLeftOutline } from "react-icons/io5";
import { GoMailRead, GoDeviceMobile } from "react-icons/go";
import moment from "moment";
import { borderRadius } from "react-select/src/theme";
import Moment from "react-moment";
import { BsInfoCircle } from "react-icons/bs";
import { hasProjectPermission } from "../Utilities";
import { UserDetails } from "Auth";
import { RolePermission } from "../Models/RolePermission";
import { ApplicationState } from "../Store";
interface StoreProps {
    notifyError: Notifications.NotificationShow;
    user?: UserDetails;
    notifySuccess: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
    notifySuccess: Notifications.success,
}
function mapStateToProps({ user: userState }: ApplicationState) {
    return {
        user: userState.user
    };
}


const IssuanceDetailsPage = ({
    notifyError,
    notifySuccess,
    user

}: StoreProps) => {

    const t = useTranslation();
    const bem = bemNames.create("issuance-details");

    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [issuanceDetails, setIssuanceDetails] = useState<IssuanceDetailsDto>();

    const [isResendCommunicationDialogOpen, setIsResendCommunicationDialogOpen] = useState(false);
    const [selectedResendDeliveryMethod, setSelectedResendDeliveryMethod] = useState<CodeDeliveryMethod>();

    const [canEditIssuanceDate, setCanEditIssuanceDate] = useState(false);
    const [isEditingIssuanceDate, setIsEditingIssuanceDate] = useState(false);
    const issuanceDateEditButtonRef = useRef<HTMLButtonElement>(null);
    const issuanceDateInputRef = useRef<HTMLInputElement>(null);
    const [isUpdatingIssuanceDate, setIsUpdatingIssuanceDate] = useState(false);
    const [isUpdateIssuanceDateDialogOpen, setIsUpdateIssuanceDateDialogOpen] = useState(false);
    const [newIssuanceDate, setNewIssuanceDate] = useState<moment.Moment | null>(null);
    const minIssuanceDate = moment.utc().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const isNewIssuanceDateInvalid = minIssuanceDate.isAfter(newIssuanceDate);
    const [originalTime, setOriginalTime] = useState<moment.Moment | null>(null);
    const [userLocale, setUserLocale] = useState('default');

    // url params
    const { id: orderIdParam } = useParams<{ id: string }>();

    const redemptionCodeId = +orderIdParam;

    useEffect(() => {

        if (redemptionCodeId) {
            (async () => {
                setLoading(true);

                try {
                    const data = await customerServicesService.getIssuanceDetails(redemptionCodeId);
                    setIssuanceDetails(data);

                    if (data.issuanceDate) {
                        const issuanceMoment = moment.utc(data.issuanceDate);
                        setOriginalTime(issuanceMoment);
                    }

                    const now = moment.utc();
                    const minIssuanceDate = moment.utc().startOf('day');
                    const isFutureIssuanceDate = moment.utc(data.issuanceDate).isAfter(minIssuanceDate);
                    const isActivatedStatus = data.redemptionStatusId === RedemptionStatus.Activated;
                    setCanEditIssuanceDate(isFutureIssuanceDate && isActivatedStatus);

                } catch (e) {
                    console.error(e);
                    notifyError(getErrorNotification(t("NotificationMessage.LoadIssuanceDetailsFailure")));
                }

                setLoading(false);
            })();
        }
    }, [redemptionCodeId]);

    useEffect(() => {
        const locale = navigator.language || 'en-GB';
        setUserLocale(locale);
    }, []);


    async function loadIssuanceDetails() {

        try {

            const data = await customerServicesService.getIssuanceDetails(redemptionCodeId);
            setIssuanceDetails(data);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadIssuanceDetailsFailure")));
        }
    }
    const canUserEditIssuanceDate = (user?: UserDetails) => {
        if (!user) return false;
        const hasOrderPlacement = hasProjectPermission(user, RolePermission.OrderPlacement, issuanceDetails?.projectId);
        const hasCustomerService = hasProjectPermission(user, RolePermission.CustomerService, issuanceDetails?.projectId);
        return (hasOrderPlacement && hasCustomerService);
    };

    const ColumnDefinitions = [
        {
            id: nameOf<AssociatedEmail>("codeDeliveryMethod"),
            Header: t("OrderDetails.MethodLabel"),
            accessor: (model: AssociatedEmail) => model.codeDeliveryMethod,
            Cell: (cell: Cell<AssociatedEmail>) => {
                const data = cell.row.original;
                return isEmailDelivery(data.codeDeliveryMethod) ? "Email"
                    : isSmsDelivery(data.codeDeliveryMethod) ? "Sms" : "";
            }
        },
        {
            id: nameOf<AssociatedEmail>("recipient"),
            Header: t("OrderDetails.RecipientLabel"),
            accessor: (model: AssociatedEmail) => model.recipient
        },
        {
            id: nameOf<AssociatedEmail>("generatedBy"),
            Header: t("OrderDetails.GeneratedByLabel"),
            accessor: (model: AssociatedEmail) => model.generatedBy
        },
        {
            id: nameOf<AssociatedEmail>("sentTime"),
            Header: t("OrderDetails.TimeSentLabel"),
            accessor: (model: AssociatedEmail) => model.sentTime,
            Cell: (cell: Cell<AssociatedEmail>) => {
                const data = cell.row.original;

                return data.sentTime
                    ? <Moment format="ll HH:mm">{data.sentTime}</Moment>
                    : "-";
            }
        },
        {
            id: nameOf<AssociatedEmail>("logStatus"),
            Header: t("OrderDetails.StatusLabel"),
            accessor: (model: AssociatedEmail) => model.logStatus,
            Cell: (cell: Cell<AssociatedEmail>) => {
                const data = cell.row.original;
                return data.hasError
                    ? (<>{data.logStatus}
                        <Button className="btn-unstyled" style={{ marginLeft: "5px", marginTop: "-8px", cursor: "pointer" }} id="PopoverFocus">
                            <BsInfoCircle />
                        </Button>                    
                        <UncontrolledPopover placement="bottom" target="PopoverFocus" trigger="focus">
                            <PopoverBody>{t("IssuanceDetails.GetInTouch")}</PopoverBody>
                        </UncontrolledPopover>
                    </>)
                    : data.logStatus;
            }
        },
        {
            id: nameOf<AssociatedEmail>("type"),
            Header: t("OrderDetails.TypeLabel"),
            accessor: (model: AssociatedEmail) => model.type
        },
    ]

    async function onResendEmail(newEmailAddress?: string, updateOriginalOrder?: boolean) {

        if (!newEmailAddress) {
            return;
        }

        try {
            setLoading(true);
            setLoadingMessage(t("OrderDetails.ResendingEmailMessage"));

            setIsResendCommunicationDialogOpen(false);

            let resendResponse = await customerServicesService.resendCodeEmail(issuanceDetails?.redemptionCodeId ?? 0, newEmailAddress, updateOriginalOrder);

            if (resendResponse.status != 200) {
                notifyError(getErrorNotification(t(resendResponse.data)));
            } else {
                await loadIssuanceDetails();
                notifySuccess(getSuccessNotification(t("NotificationMessage.EmailResentSuccessfully")));
            }
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.EmailResentFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    async function onResendSms(newMobileNumber?: string, updateOriginalOrder?: boolean) {

        if (!newMobileNumber) {
            return;
        }

        try {
            setLoading(true);
            setLoadingMessage(t("OrderDetails.ResendingSmsMessage"));

            setIsResendCommunicationDialogOpen(false);

            let resendResponse = await customerServicesService.resendCodeSms(issuanceDetails?.redemptionCodeId ?? 0, newMobileNumber, updateOriginalOrder);

            if (resendResponse.status != 200) {
                notifyError(getErrorNotification(t(resendResponse.data)));
            } else {
                await loadIssuanceDetails();
                notifySuccess(getSuccessNotification(t("NotificationMessage.SMSResentSuccessfully")));
            }
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.SMSResentFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    async function onPutOnHold() {

        try {
            setLoading(true);
            setLoadingMessage(t("NotificationMessage.PutCodeOnHoldMessage"));

            await customerServicesService.putCodeOnHold(issuanceDetails?.redemptionCodeId ?? 0);

            await loadIssuanceDetails();

            notifySuccess(getSuccessNotification(t("NotificationMessage.PutCodeOnHoldSuccess")));
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.PutCodeOnHoldFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    async function onRemoveOnHold() {

        try {
            setLoading(true);
            setLoadingMessage(t("NotificationMessage.RemoveCodeOnHoldMessage"));

            await customerServicesService.removeCodeOnHold(issuanceDetails?.redemptionCodeId ?? 0);

            await loadIssuanceDetails();

            notifySuccess(getSuccessNotification(t("NotificationMessage.RemoveCodeOnHoldSuccess")));
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.RemoveCodeOnHoldFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    async function onIssuanceDateUpdate() {
        if (isUpdatingIssuanceDate || !newIssuanceDate || !issuanceDetails?.projectId || !issuanceDetails?.redemptionCodeId) return;

        try {
            setIsUpdateIssuanceDateDialogOpen(false);
            setIsUpdatingIssuanceDate(true);
            setLoading(true);
            setLoadingMessage(t("NotificationMessage.UpdatingIssuanceDetailsMessage"));

            await customerServicesService.updateCodeIssuanceDate(issuanceDetails.projectId, issuanceDetails.redemptionCodeId, newIssuanceDate.toDate());

            await loadIssuanceDetails();

            setIsEditingIssuanceDate(false);
            setIsUpdatingIssuanceDate(false);

            const minIssuanceDate = moment.utc().startOf('day');
            const isFutureIssuanceDate = moment.utc(newIssuanceDate.toDate()).isAfter(minIssuanceDate);
            setCanEditIssuanceDate(isFutureIssuanceDate);
            notifySuccess(getSuccessNotification(t("NotificationMessage.UpdateIssuanceDetailsSuccess")));
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.UpdateIssuanceDetailsFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    return (
        <div className={bem.b()}>

            <AreaLoader
                show={loading}
                message={loadingMessage ?? t("NotificationMessage.LoadIssuanceDetailsMessage")}
            />

            <Breadcrumb>
                <BreadcrumbItem><Link to="/customer-service/">{t("CustomerService.CustomerServiceLabel")}</Link></BreadcrumbItem>
                <BreadcrumbItem active>{t("IssuanceDetails.IssuanceDetailsLabel")}</BreadcrumbItem>
            </Breadcrumb>

            <Container>
                <Row>
                    <Card className="w-100 mb-3">
                        <CardHeader>
                            <h2>{t("IssuanceDetails.IssuanceDetailsLabel")}</h2>
                        </CardHeader>

                        <CardBody>

                            <Row>
                                <Col xs={6}>

                                    <FormGroup>
                                        <Label>{t("OrderDetails.ValueLabel")}</Label>
                                        <Input type="text" defaultValue={issuanceDetails?.value} readOnly />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>{t("OrderDetails.IssuanceDateLabel")}</Label>
                                        {canEditIssuanceDate && isEditingIssuanceDate ? (
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    innerRef={issuanceDateInputRef}
                                                    type="date"
                                                    min={minIssuanceDate.format("YYYY-MM-DD")}
                                                    value={newIssuanceDate ? newIssuanceDate.format("YYYY-MM-DD") : ""}
                                                    readOnly={isUpdatingIssuanceDate}
                                                    onChange={e => {
                                                        const { valueAsDate } = e.target;
                                                        if (valueAsDate && originalTime) {
                                                            const newDateWithTime = moment.utc(valueAsDate).set({
                                                                hour: originalTime.hour(),
                                                                minute: originalTime.minute(),
                                                                second: originalTime.second(),
                                                                millisecond: originalTime.millisecond()
                                                            });
                                                            setNewIssuanceDate(newDateWithTime);
                                                        }
                                                    }}
                                                    invalid={isNewIssuanceDateInvalid}
                                                />
                                                <div role="group" className="btn-group">
                                                    <button
                                                        key="confirm"
                                                        className="btn"
                                                        type="button"
                                                        onClick={() => {
                                                            if (!newIssuanceDate) return;

                                                            if (isNewIssuanceDateInvalid) {
                                                                console.error(`Selected date ${newIssuanceDate.format("ll HH:mm:SSS")} must be after ${minIssuanceDate.format("ll HH:mm:SSS")}`);
                                                                return;
                                                            }

                                                            setIsUpdateIssuanceDateDialogOpen(true);
                                                        }}
                                                    ><BsCheck /></button>
                                                    <button
                                                        key="cancel"
                                                        onClick={() => {
                                                            setIsEditingIssuanceDate(false);
                                                            setTimeout(() => {
                                                                if (issuanceDateEditButtonRef?.current) issuanceDateEditButtonRef?.current.focus();
                                                            });
                                                        }}
                                                        className="btn"
                                                        type="button"
                                                    ><BsX /></button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <Input type="text" value={issuanceDetails?.issuanceDate ? moment.utc(issuanceDetails?.issuanceDate).format("ll HH:mm") : ""} readOnly />
                                                    {canUserEditIssuanceDate(user) && canEditIssuanceDate && issuanceDetails?.issuanceDate && (
                                                        <div role="group" className="btn-group">
                                                            <button
                                                                key="edit"
                                                                ref={issuanceDateEditButtonRef}
                                                                className="btn"
                                                                type="button"
                                                                onClick={() => {
                                                                    setIsEditingIssuanceDate(true);
                                                                    setNewIssuanceDate(newIssuanceDate || moment.utc(issuanceDetails?.issuanceDate));
                                                                    setTimeout(() => {
                                                                        if (issuanceDateInputRef?.current) issuanceDateInputRef?.current.focus();
                                                                    });
                                                                }}
                                                            ><BsPencil /></button>
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>{t("IssuanceDetails.ExpiryDateLabel")}</Label>
                                        <Input type="text" defaultValue={issuanceDetails?.expiryDate ? moment.utc(issuanceDetails?.expiryDate).format("ll HH:mm") : ""} readOnly />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t("OrderDetails.StatusLabel")}</Label>
                                        {issuanceDetails?.redemptionStatusId == RedemptionStatus.Redeemed &&
                                            issuanceDetails?.scmOrderId > 0
                                            ? <InputGroup>
                                                <Input type="text" defaultValue={issuanceDetails?.orderStatus} readOnly />
                                                <Link to={`/customer-service/redemption-order-details/${issuanceDetails?.scmOrderId}`}>
                                                    <Button
                                                        color="primary"
                                                        style={{ borderRadius: "0.25rem" }}
                                                    >
                                                        {t("OrderHistory.OrderTileViewDetailsButton")}
                                                    </Button>
                                                </Link>
                                            </InputGroup>
                                            : <Input type="text" defaultValue={issuanceDetails?.orderStatus} readOnly />
                                        }
                                    </FormGroup>

                                </Col>

                                <Col xs={6}>
                                    <Card>
                                        <CardHeader>
                                            {t("IssuanceDetails.RecipientDetailsLabel")}
                                        </CardHeader>

                                        <CardBody>
                                            <Row>
                                                <Col xs={12}>

                                                    <FormGroup>
                                                        <Label>{t("OrderDetails.FirstNameLabel")}</Label>
                                                        <Input type="text" defaultValue={issuanceDetails?.firstName} readOnly />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{t("OrderDetails.LastNameLabel")}</Label>
                                                        <Input type="text" defaultValue={issuanceDetails?.lastName} readOnly />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{t("OrderDetails.StaffNumberLabel")}</Label>
                                                        <Input type="text" defaultValue={issuanceDetails?.staffNumber} readOnly />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{t("OrderDetails.EmailAddressLabel")}</Label>
                                                        <Input type="text" defaultValue={issuanceDetails?.emailAddress} readOnly />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>{t("OrderDetails.MobileNumberLabel")}</Label>
                                                        <Input type="text" defaultValue={issuanceDetails?.mobileNumber} readOnly />
                                                    </FormGroup>

                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-sm-inline mt-3">
                                <ReactstrapDataTable<AssociatedEmail>
                                    columnDefinitions={ColumnDefinitions}
                                    items={issuanceDetails?.associatedEmails ?? []}
                                    totalItemCount={issuanceDetails?.associatedEmails?.length ?? 0}
                                />
                            </Row>
                            <Row className="mt-3 d-flex justify-content-center">
                                <ButtonGroup>

                                    {issuanceDetails &&
                                        issuanceDetails.isEcode &&
                                        (issuanceDetails.redemptionStatusId === RedemptionStatus.Activated || issuanceDetails.redemptionStatusId === RedemptionStatus.Expired) &&
                                        issuanceDetails.batchTypeId !== BatchType.PrintReadyBatch &&
                                        issuanceDetails.batchTypeId !== BatchType.PrintReadyBulk &&
                                        isEmailDelivery(issuanceDetails?.codeDeliveryMethod) &&
                                        issuanceDetails?.associatedEmails.filter(d => isEmailDelivery(d.codeDeliveryMethod)).sort((a, b) => a.id < b.id ? 1 : -1).length > 0 &&
                                        <Button
                                            color="info"
                                            className="ml-1 mr-1 text-uppercase"
                                            style={{ borderRadius: "0.25rem" }}
                                            onClick={() => {
                                                setSelectedResendDeliveryMethod(CodeDeliveryMethod.Email);
                                                setIsResendCommunicationDialogOpen(true)
                                            }}
                                        >
                                            <GoMailRead style={{ verticalAlign: "middle", marginRight: "4px" }} size={18} />{t("OrderDetails.RequestEmailResendLabel")}
                                        </Button>
                                    }

                                    {issuanceDetails &&
                                        issuanceDetails.isEcode &&
                                        (issuanceDetails.redemptionStatusId === RedemptionStatus.Activated || issuanceDetails.redemptionStatusId === RedemptionStatus.Expired) &&
                                        issuanceDetails.batchTypeId !== BatchType.PrintReadyBatch &&
                                        issuanceDetails.batchTypeId !== BatchType.PrintReadyBulk &&
                                        isSmsDelivery(issuanceDetails?.codeDeliveryMethod) &&
                                        issuanceDetails?.associatedEmails.filter(d => isSmsDelivery(d.codeDeliveryMethod)).sort((a, b) => a.id < b.id ? 1 : -1).length > 0 &&
                                        <button
                                            color="info"
                                            className="ml-1 mr-1 text-uppercase"
                                            style={{ borderRadius: "0.25rem" }}
                                            onClick={() => {
                                                setSelectedResendDeliveryMethod(CodeDeliveryMethod.Sms);
                                                setIsResendCommunicationDialogOpen(true);
                                            }}
                                        >
                                            <GoDeviceMobile style={{ verticalAlign: "middle", marginRight: "4px" }} size={18} />{t("OrderDetails.RequestSmsResendLabel")}
                                        </button>
                                    }

                                    {issuanceDetails &&
                                        (issuanceDetails.redemptionStatusId === RedemptionStatus.Activated || issuanceDetails.redemptionStatusId === RedemptionStatus.Expired) &&
                                        !issuanceDetails.onHoldDate &&
                                        <Button
                                            color="warning"
                                            className="ml-1 mr-1 text-uppercase"
                                            onClick={onPutOnHold}
                                        >
                                            <IoHandLeftOutline style={{ verticalAlign: "middle", marginRight: "4px" }} size={18} />{t("IssuanceDetails.PutCodeOnHoldLabel")}
                                        </Button>
                                    }

                                    {issuanceDetails && issuanceDetails.onHoldDate &&
                                        <Button
                                            color="success"
                                            className="ml-1 mr-1 text-uppercase"
                                            onClick={onRemoveOnHold}
                                        >
                                            <IoHandLeftOutline style={{ verticalAlign: "middle", marginRight: "4px" }} size={18} />{t("IssuanceDetails.RemoveCodeOnHoldLabel")}
                                        </Button>
                                    }

                                </ButtonGroup>
                            </Row>
                        </CardBody>
                    </Card>

                </Row>

                <Row>
                    <Card className="w-100">
                        <CardHeader>
                            <h2>{t("OrderDetails.SourceLabel")}</h2>
                        </CardHeader>

                        <CardBody>

                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label>{t("OrderDetails.ClientNameLabel")}</Label>
                                        <Input type="text" defaultValue={issuanceDetails?.clientName || ""} readOnly />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t("ProjectDetails.ProjectName")}</Label>
                                        <Input type="text" defaultValue={issuanceDetails?.projectName || ""} readOnly />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t("OrderDetails.OrderIdLabel")}</Label>
                                        <Input type="text" defaultValue={issuanceDetails?.batchId || ""} readOnly />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>

            </Container>

            <ResendCommunicationDialog
                isModalOpen={isResendCommunicationDialogOpen}
                title={isEmailDelivery(selectedResendDeliveryMethod!) ? "Resend Email" :
                    isSmsDelivery(selectedResendDeliveryMethod!) ? "Resend Sms" : "Error"}
                associatedEmails={issuanceDetails?.associatedEmails ?? []}
                mobileNumberPrefix={issuanceDetails?.mobileNumberPrefix ?? ""}
                codeDeliveryMethod={selectedResendDeliveryMethod!}
                onSubmit={isEmailDelivery(selectedResendDeliveryMethod!) ? onResendEmail :
                    isSmsDelivery(selectedResendDeliveryMethod!) ? onResendSms : onResendEmail}
                closeModal={() => setIsResendCommunicationDialogOpen(false)}
            />

            <Modal
                isOpen={isUpdateIssuanceDateDialogOpen}
                toggle={() => setIsUpdateIssuanceDateDialogOpen(false)}
            >
                <ModalHeader toggle={() => setIsUpdateIssuanceDateDialogOpen(false)}>
                    {(t("NotificationMessage.ChangeIssuanceDateConfirmationTitle"))}
                </ModalHeader>

                <ModalBody>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <p>{t("NotificationMessage.ChangeIssuanceDateConfirmationBody").replace("[[Date]]", newIssuanceDate ? newIssuanceDate.format("LLL") : "")}</p>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>

                <ModalFooter className="justify-content-between">
                    <button
                        className="btn btn-default pull-left"
                        onClick={() => setIsUpdateIssuanceDateDialogOpen(false)}
                    >{t("BackButton")}</button>

                    <button
                        className="btn btn-success pull-right"
                        onClick={() => onIssuanceDateUpdate()}
                    >{t("ManageUsers.ConfirmBtnLbl")}</button>
                </ModalFooter>
            </Modal>
        </div >
    )
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(IssuanceDetailsPage);

export {
    ConnectedComponent as IssuanceDetailsPage
}