import React, { useState, useEffect, useReducer } from "react";
import { connect } from 'react-redux';
import { Cell, Column } from "react-table";
import { AdditionalUserSearchArguments, UsersListItem, UserType, UserTypeLabel } from "../Models";
import { serviceUtils, userManagementService } from "../Services";
import { bemNames, getClaimValue, getErrorNotification, getSuccessNotification, nameOf } from "Utilities";
import { AreaLoader, ReactstrapDataTable, InviteUser, MultiSelectDropdown } from "../Components";
import Select from "react-select";
import { Collapse, Container, Col, Row, FormGroup, Input, Label, Button, NavLink, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, NavItem } from "reactstrap";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import Notifications from 'react-notification-system-redux';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { ClaimTypes } from "../Auth";
import { IClientDto, IProjectDto } from "Models";

enum Tab {
    SearchUsers,
    InviteUser
}

interface StoreProps {
    notifyError: Notifications.NotificationShow;
    notifySuccess: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
    notifySuccess: Notifications.success,
}

interface FilterAction extends AdditionalUserSearchArguments {
    setOrClear: "set" | "clear";
}

const ManageUsersPage = ({ notifyError, notifySuccess}: StoreProps) => {
    const [usersListItems, setusersListItems] = useState<UsersListItem[]>([]);

    const [userTypeDropdownItems, setUserTypeDropdownItems] = useState<any[]>();
    const [userTypeSelect, setUserTypeSelect] = useState<any>();
    const [firstNameInput, setFirstNameInput] = useState<any>();
    const [lastNameInput, setLastNameInput] = useState<any>();
    const [emailInput, setEmailInput] = useState<any>();

    const [loading, setLoading] = useState(true);
    const [searchBoxOpen, setSearchBoxOpen] = useState(true);
    const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<string>();
    const [deletingUser, setDeletingUser] = useState(false);
    const [activeTab, setActiveTab] = useState<Tab>(Tab.SearchUsers);

    const [isInitialising, setIsInitialising] = useState(true);
    const [projects, setProjects] = useState<IProjectDto[]>([]);
    const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
    const [clients, setClients] = useState<IClientDto[]>([]);
    const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);

    const bem = bemNames.create("manage-users-list");
    const t = useTranslation();

    const setOrClearArgs = (argsState: AdditionalUserSearchArguments, setArgs: FilterAction): AdditionalUserSearchArguments => {
        if (setArgs.setOrClear === "set") {
            return {
                projectIds: setArgs.projectIds ?? argsState.projectIds,
                userType: setArgs.userType ?? argsState.userType,
                emailAddress: setArgs.emailAddress ?? argsState.emailAddress,
                firstName: setArgs.firstName ?? argsState.firstName,
                lastName: setArgs.lastName ?? argsState.lastName
            };
        }
        return {};
    }
    const [additionalSearchArguments, setAdditionalSearchArguments] = useReducer(setOrClearArgs, {});
    const loggedinUser = serviceUtils.loggedInUser();
    const currentUsrId = getClaimValue(loggedinUser, ClaimTypes.UserId);

    useEffect(() => {
        (async () => {
            setIsInitialising(true);
            const accessibleProjects = await userManagementService.getProjectDetails();

            // Map UserPermissionItems to IProjectDto
            const mappedProjects: IProjectDto[] = accessibleProjects.map<any>((project) => {
                return {
                    projectId: project.projectId.toString(),
                    projectName: project.projectName,
                    projectType: project.projectType,
                    jobNumber: project.jobNumber,
                    tenantId: project.tenantId,
                    tenantName: project.tenantName
                }
            });

            // Group projects by tenantId
            const projectsByTenant: { [key: string]: IProjectDto[] } = mappedProjects.reduce((acc, project) => {
                if (!acc[project.tenantId]) {
                    acc[project.tenantId] = [];
                }
                acc[project.tenantId].push(project);
                return acc;
            }, {} as { [key: string]: IProjectDto[] });

            // Set projects
            setProjects(mappedProjects);

            // Extract unique tenants
            const uniqueTenants: IClientDto[] = Object.values(projectsByTenant).map(projects => ({
                tenantId: projects[0].tenantId,
                tenantName: projects[0].tenantName,
                isClientBlocked: false,
                projects: projects
            }));

            // Set clients
            setClients(uniqueTenants);
            setIsInitialising(false);
        })();
        setUserTypeDropdownItems(
            Array.from(UserTypeLabel.entries())
                .filter(entry => [UserType.SelfServiceUser, UserType.SelfServiceSuperUser].includes(entry[0]))
                .map((entry) => { return { value: entry[0], label: t(entry[1]) } })
        );
    }, []);

    useEffect(() => {
        retrieveUsersList(additionalSearchArguments);
    }, []);

    useEffect(() => {
        if (!selectedClientIds.length) return;

        setSelectedProjectIds(selectedProjectIds
            .filter(projectId => {
                const project = projects.find(p => p.projectId === projectId);
                if (!project) return false;

                return selectedClientIds.includes(project.tenantId);
            })
        );

    }, [selectedClientIds]);

    const clearAdditionalParams = () => {
        setAdditionalSearchArguments({ setOrClear: "clear" });

        setSelectedClientIds([]);
        setSelectedProjectIds([]);
        userTypeSelect.select.clearValue();
        firstNameInput.value = "";
        lastNameInput.value = "";
        emailInput.value = "";

        retrieveUsersList({}, true);
    };

    const retrieveUsersList = async (additionalSearchArguments: AdditionalUserSearchArguments, resetArgs?: boolean) => {
        setLoading(true);

        try {
            // Use selected clients to specify projects if some clients selected, but no specific projects selected
            const allClientsSelected = selectedClientIds.length === 0 || selectedClientIds.length === clients.length;
            let filteredProjectIds: number[] | undefined;
            if (!resetArgs) {
                if (!allClientsSelected && !selectedProjectIds.length) {
                    filteredProjectIds = availableProjects
                        .filter(p => selectedClientIds.includes(p.tenantId))
                        .map(p => Number(p.projectId))
                } else if (selectedProjectIds.length > 0) {
                    filteredProjectIds = selectedProjectIds.map(projectId => Number(projectId));
                }
            }

            const searchResults = await userManagementService.getUsersList({
                ...additionalSearchArguments,
                projectIds: filteredProjectIds
            });
            setusersListItems(searchResults);
        }
        catch {
            notifyError(getErrorNotification(t("UserManagement.CouldNotLoadUserListNotification")));
        }

        setLoading(false);
    }

    const columnDefinitions: Column<UsersListItem>[] = [
        {
            id: nameOf<UsersListItem>("firstName"),
            Header: t("FirstNameLabel"),
            accessor: (model) => model.firstName,
            
            Cell: (cell: Cell<UsersListItem>) => {
                const userFirstName = cell.row.original.firstName;
                return <>{userFirstName}</>;
            }
        },
        {
            id: nameOf<UsersListItem>("lastName"),
            Header: t("LastNameLabel"),
            accessor: (model) => model.lastName,
            Cell: (cell: Cell<UsersListItem>) => {
                const userLastName = cell.row.original.lastName;
                return <>{userLastName}</>;
            }
        },
        {
            id: nameOf<UsersListItem>("emailAddress"),
            Header: t("Login.EmailAddressLabel"),
            accessor: (model) => model.emailAddress,
            Cell: (cell: Cell<UsersListItem>) => {
                const userEmail = cell.row.original.emailAddress;
                return <>{userEmail}</>;
            }
        },
        {
            id: nameOf<UsersListItem>("userRole"),
            Header: t("UserTypeLabel"),
            accessor: (model) => model.userRole,
            Cell: (cell: Cell<UsersListItem>) => {
                const userRole = cell.row.original.userRole;
                return <>{t(UserTypeLabel.get(userRole) ?? "")}</>;
            }
        },
        {
            id: 'rowActions',
            Cell: (cell: Cell<UsersListItem>) => {
                const userId = cell.row.original.id;
                // TODO: when editing and deletion features are in development change the relevant link!
                return <>
                    <div>
                        <NavLink className="row-action-icon" style={{ display: "inline-block" }} tag={Link} to={`/manage-users/edit-user/${userId}`}><HiOutlinePencilAlt size={24} /></NavLink>
                        {currentUsrId != userId && 
                            <button
                            className="btn btn-link delete"
                                style={{ marginLeft: "10px" }}
                                onClick={() => showDeleteConfirmationModal(userId)}
                            ><BsTrash size={24} /></button>
                        }
                    </div>
                </>;
            }
        }
    ]

    const showDeleteConfirmationModal = (userId: string) => {
        setSelectedUserId(userId);
        setIsDeleteUserModalOpen(true);
    }

    const closeDeleteUserModal = () => {
        setSelectedUserId(undefined);
        setIsDeleteUserModalOpen(false);
    }

    const handleDeletingUser = async () => {

        setIsDeleteUserModalOpen(false);
        if (selectedUserId) {
            setDeletingUser(true);

            try {

                const result = await userManagementService.deleteUser(selectedUserId);

                if (!result.success) {

                    notifyError(getErrorNotification(t("ManageUsers.DeleteUserErrorMsg")));
                    return;
                }
                notifySuccess(getSuccessNotification(t("ManageUsers.DeleteUserSuccessMsg")));
                retrieveUsersList(additionalSearchArguments);
            } catch (e) {
                notifyError(getErrorNotification(t("ManageUsers.DeleteUserErrorMsg")));
            }
            finally {
                setDeletingUser(false);
            }
        }
    }

    const updateParentTab = (newTab: Tab) => {
        setActiveTab(newTab)
    }

    const availableProjects = projects.filter(p => {
        const client = clients.find(c => c.tenantId === p.tenantId);
        return !client?.isClientBlocked;
    });

    const filteredProjects = availableProjects
        .filter(client => selectedClientIds.length > 0 ? selectedClientIds.map(c => c.toString()).includes(client.tenantId.toString()) : true);

    return (
        <div className={bem.b()}>
            <AreaLoader show={isInitialising || loading} message={t("UserManagement.LoadingUsersListLabel")} />
            <AreaLoader show={deletingUser} message={"Deleting User"} />
            <h1>{t("UserManagement.ManageUsersTitleLabel")}</h1>
           
             <div className={bem.e("section-header-tabs", "font-weight-bold")}>
                <Navbar className={bem.e("section-header-tabs", "font-weight-bold navbar-expand-sm navbar-toggleable-sm")} dark>
                    <div className="d-block justify-content-between">
                        <Collapse className="d-sm-inline-flex" isOpen={true} navbar>
                            <ul className="navbar-nav flex-grow">
                                <Container>
                                    <NavItem>
                                        <NavLink tag={Link} to="#" className="text-dark text-uppercase" onClick={() => setActiveTab(Tab.SearchUsers)}>{t("UserManagement.Users")}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="#" className="text-dark text-uppercase" onClick={() => setActiveTab(Tab.InviteUser)}>{t("UserManagement.InviteUsers")}</NavLink>
                                    </NavItem>
                                </Container>
                            </ul>
                        </Collapse>
                    </div>
                </Navbar>
            </div>
            
            {activeTab == Tab.SearchUsers &&
                <>
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title mb-0">
                                <Row>
                                    <Col xs="11"><h2>{t("UserManagement.SearchUsersLabel")}</h2></Col>
                                    <Col xs="1"> {searchBoxOpen ?
                                        <FaChevronUp size={24} className="collapse-button" onClick={() => setSearchBoxOpen(!searchBoxOpen)} /> :
                                        <FaChevronDown size={24} className="collapse-button" onClick={() => setSearchBoxOpen(!searchBoxOpen)} />}
                                    </Col>
                                </Row>
                            </div>
                            <div className={bem.e("section-content")}>
                                <Collapse isOpen={searchBoxOpen}>
                                {(clients.length > 1 || filteredProjects.length > 1) &&
                                    <Row>
                                        {clients.length > 1 &&
                                            <Col xs="12" sm="6" lg="3">
                                                <FormGroup>
                                                    <Label for="companies-search-filter" className={bem.e("label", "mb-1 font-weight-bold text-uppercase")}>{t("Projects.CompanyLabel")}</Label>
                                                    <MultiSelectDropdown
                                                        id="companies-search-filter"
                                                        message={!selectedClientIds.length ? t("Projects.AllSelectedCompanies") : t("Projects.SelectedCompanyCount").replace("[[Count]]", selectedClientIds.length.toString())}
                                                        options={clients
                                                            .map(c => ({ label: c.tenantName, value: c.tenantId }))
                                                            .sort((a, b) => a.label.localeCompare(b.label))
                                                        }
                                                        chosenOptions={selectedClientIds
                                                            .map(clientId => ({ label: clients.find(c => c.tenantId === clientId)?.tenantName || "", value: clientId }))
                                                        }
                                                        onChange={values => setSelectedClientIds(values.map(v => v.value))}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        }
                                        {filteredProjects.length > 1 &&
                                            <Col xs="12" sm="6" lg="3">
                                                <FormGroup>
                                                    <Label for="projects-search-filter" className={bem.e("label", "mb-1 font-weight-bold text-uppercase")}>{t("Projects.ProjectsLabel")}</Label>
                                                    <MultiSelectDropdown
                                                        id="projects-search-filter"
                                                        message={!selectedProjectIds.length ? t("Projects.AllSelectedProjects") : t("Projects.SelectedProjectCount").replace("[[Count]]", selectedProjectIds.length.toString())}
                                                        options={filteredProjects
                                                            .map(p => ({ label: p.projectName, value: p.projectId }))
                                                            .sort((a, b) => a.label.localeCompare(b.label))
                                                        }
                                                        chosenOptions={selectedProjectIds
                                                            .map(projectId => ({ label: filteredProjects.find(p => p.projectId === projectId)?.projectName || "", value: projectId }))
                                                        }
                                                        onChange={values => setSelectedProjectIds(values.map(v => v.value))}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        }
                                    </Row>
                                }
                                    <Row>
                                        <Col xs="12" sm="6" lg="3">
                                            <FormGroup>
                                                <Label for="first-name-search-filter" className={bem.e("label", "mb-1 font-weight-bold text-uppercase")}>{t("FirstNameLabel")}</Label>
                                                <Input innerRef={(ref) => setFirstNameInput(ref)} id="first-name-search-filter" onChange={e => setAdditionalSearchArguments({ setOrClear: "set", firstName: e.target.value })} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="6" lg="3">
                                            <FormGroup>
                                                <Label for="last-name-search-filter" className={bem.e("label", "mb-1 font-weight-bold text-uppercase")}>{t("LastNameLabel")}</Label>
                                                <Input innerRef={(ref) => setLastNameInput(ref)} id="last-name-search-filter" onChange={e => {
                                                    setAdditionalSearchArguments({ setOrClear: "set", lastName: e.target.value });
                                                }} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="6" lg="3">
                                            <FormGroup>
                                                <Label for="email-search-filter" className={bem.e("label", "mb-1 font-weight-bold text-uppercase")}>{t("Login.EmailAddressLabel")}</Label>
                                                <Input innerRef={(ref) => setEmailInput(ref)} id="email-search-filter" onChange={e => setAdditionalSearchArguments({ setOrClear: "set", emailAddress: e.target.value })} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="6" lg="3">
                                            <FormGroup>
                                                <Label for="user-type-select" className={bem.e("label", "mb-1 font-weight-bold text-uppercase")}>{t("UserTypeLabel")}</Label>
                                                <Select
                                                    id="user-type-select"
                                                    ref={(ref) => setUserTypeSelect(ref)}
                                                    options={userTypeDropdownItems}
                                                    placeholder={t("UserManagement.SelectUserTypePlaceholder")}
                                                    onChange={
                                                        (selectedItem) => {
                                                            if (selectedItem != null) {
                                                                setAdditionalSearchArguments({ setOrClear: "set", userType: Number(selectedItem?.value) });
                                                            }
                                                        }} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="0" sm="6" lg="8" />
                                        <Col xs="12" sm="6" lg="4">
                                            <Row>
                                                <Col xs="6">
                                                    <Button color="primary" className="text-uppercase float-right" onClick={() => clearAdditionalParams()}>{t("ClearLabel")}</Button>
                                                </Col>
                                                <Col xs="6">
                                                    <Button color="primary" className="text-uppercase float-right" onClick={() => retrieveUsersList(additionalSearchArguments)}>{t("SearchLabel")}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="card-title" style={{ lineHeight: "1rem" }}>
                                <h2>{t("UserManagement.AllUsersLabel")}</h2>
                            </div>
                            <div className={bem.e("section-content")}>

                                <Row>
                                    <Col style={{ overflowX: "scroll" }}>
                                        <ReactstrapDataTable<UsersListItem>
                                            columnDefinitions={columnDefinitions}
                                            items={usersListItems}
                                            totalItemCount={usersListItems.length}
                                            hideSearch={true}
                                        />
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={isDeleteUserModalOpen}
                        toggle={() => closeDeleteUserModal()}
                    >
                        <ModalHeader toggle={() => closeDeleteUserModal()}>
                            {t("ManageUsers.DeleteUserConfirmationTitle")}
                        </ModalHeader>

                        <ModalBody>
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        <p>{t("ManageUsers.DeleteUserConfirmationBody")}</p>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>

                        <ModalFooter className="justify-content-between">
                            <button
                                className="btn btn-default pull-left"
                                onClick={() => closeDeleteUserModal()}
                            >{t("BackButton")}</button>

                            <button
                                className="btn btn-success pull-right"
                                onClick={() => handleDeletingUser()}
                            >{t("ManageUsers.ConfirmBtnLbl")}</button>
                        </ModalFooter>
                    </Modal>
                </>
            }
            {activeTab == Tab.InviteUser &&
                <InviteUser parentActiveTab={updateParentTab} />
            }
            
        </div>
    );
}

const ConnectedComponent = connect(null, mapDispatchToProps)(ManageUsersPage);

export { ConnectedComponent as ManageUsersPage };