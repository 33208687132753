import classNames from "classnames";

export const createBEM = (namespace: string | undefined) => {
    return {
        create: (blockName: any) => {
            let block = blockName;

            if (namespace && namespace.length > 0) {
                block = `${namespace}-${blockName}`;
            }

            return {
                b: (...more: any) => {
                    return classNames(block, more);
                },
                e: (elementName: string, ...more: string[]) => {
                    return classNames(`${block}__${elementName}`, more);
                },
                m: (modifierName: string, ...more: string[]) => {
                    return classNames(`${block}--${modifierName}`, more);
                },
                em: (elementName: string, modifierName: string, ...more: string[]) => {

                    if (!modifierName) {

                        return classNames(`${block}__${elementName}`, more);
                    }

                    return classNames(`${block}__${elementName}`, `${block}__${elementName}--${modifierName}`, more);
                }
            }
        }
    }
}

export const bemNames = createBEM("c");

// For how BEM works please refer to: https://howchoo.com/css/how-bem-works-and-why-its-awesome

/* Used like this:
 * 
 * // TestPage.tsx
import { bemNames } from '../Utilities';
...
...
const bem = bemNames.create("test-page"); // root component of a file

    return (
        <Page
            className={bem.b()} // this gives the root class of the BEM to the component
        >
...
...
<div
    className={bem.e("nested-component")} // this generates a nested class inside the parent component with a suffix for the nested component
    >
    ...
    ...
    </div>

 */