import axios from "axios";
import { getAuthHeader } from "./getAuthHeader";
import { UserGuideListItemDto } from "Models";

export const userGuideService = {
    getUserGuides,
    getUserGuidePdf
};

async function getUserGuides() {

    const response = await axios.post<UserGuideListItemDto[]>(`api/UserGuide/GetUserGuides`, {}, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getUserGuidePdf(id: number) {
    const response = await axios.get(`api/UserGuide/DownloadUserGuide/${id}`, {
        headers: getAuthHeader(),
        responseType: "blob"
    });

    return response.data;
}
