import React from "react";
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bemNames, getErrorNotification, getSuccessNotification, getValueFromStorage, hasProjectPermission, nameOf, removeValueFromStorage, saveValueToStorage } from 'Utilities';
import { DigitalOrderItemDto, OrderDetailsDto, PaymentMethod, ProjectSetupType, EmailTemplateDto, FileUploadObject, BatchType, ProjectOrderingDetails, BatchStatus, SearchResults } from 'Models';
import { IoPrintOutline } from "react-icons/io5";
import { useTranslation } from "react-multi-lang";
import { FileUploadECodeRowItem } from 'Models/FileUploadECodeRowItem';
import { FileUploadBulkOrderItem } from 'Models/FileUploadBulkOrderItem';
import { FileUploadPrintReadyRowItem } from 'Models/FileUploadPrintReadyRowItem';
import { DistributorDto, Denomination } from 'Models';
import { AreaLoader, OrderSummary, ReactstrapDataTable } from "../Components";
import { getAuthHeader, orderHistoryService, orderingService, serviceUtils } from "../Services";
import Notifications from 'react-notification-system-redux';
import { Link, useParams, Redirect } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button, Table, Modal, ModalBody, ModalHeader, ModalFooter, Alert, Container, Row, Col } from "reactstrap";
import { Column, Cell } from "react-table";
import { FileUploadPhysicalRowItem } from "../Models/FileUploadPhysicalRowItem";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import { DeliveryMethod } from 'Store/Reducers/DigitalOrderReducer';
import { useHTMLPreview } from "Hooks";
import { MdEmail, MdSms } from "react-icons/md";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { RolePermission } from "../Models/RolePermission";
import axios from "axios";
import moment from "moment";

interface StoreProps {
    notifyError: Notifications.NotificationShow;
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep;
    location: any;
    notifySuccess: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    attemptGoToStep: OrderReducer.actions.attemptGoToStep,
    notifyError: Notifications.error,
    notifySuccess: Notifications.success
}

const OrderDetailsPage = ({
    notifyError,
    attemptGoToStep,
    location,
    notifySuccess
}: StoreProps) => {

    const t = useTranslation();
    const bem = bemNames.create("order-details");

    // redirection state
    const { customMessage } = location.state || {};
    const boldAlert = customMessage?.boldpart;
    const normalAlert = customMessage?.normalpart;

    // url params
    const { orderId: orderIdParam } = useParams<{ orderId: string }>();
    const orderId = +orderIdParam;

    const [loadingCount, setLoadingCount] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [orderDetails, setOrderDetails] = useState<OrderDetailsDto>();
    const [orderItems, setOrderItems] = useState<DigitalOrderItemDto[]>();
    const [fileUploadObject, setFileUploadObject] = useState<FileUploadObject<FileUploadECodeRowItem | FileUploadPrintReadyRowItem | FileUploadPhysicalRowItem>>();
    const [bulkOrderEmailTemplateDetails, setBulkOrderEmailTemplateDetails] = useState<EmailTemplateDto>();
    const [smsTemplateDetails, setSmsTemplateDetails] = useState<EmailTemplateDto>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [cancelModal, setCancelModal] = useState<boolean>(false);
    const toggleCancelModal = () => setCancelModal(!cancelModal);
    const [selectedDistributorEmail, setSelectedDistributorEmail] = useState<string>("");
    const [selectedDistributor, setSelectedDistributor] = useState<DistributorDto>();
    const [redirectToPayments, setRedirectToPayments] = useState(false);
    const [isPaymentMessageVisible, setIsPaymentMessageVisible] = useState<boolean>(false);
    const [boldMessage, setBoldMessage] = useState<string | undefined>(boldAlert);
    const [normalMessage, setNormalMessage] = useState<string | undefined>(normalAlert);
    const [refreshOrderCount, setRefreshOrderCount] = useState<number>(1);
    const [templateHTML, templateRef, templateHeight] = useHTMLPreview(bulkOrderEmailTemplateDetails?.html!, ".preview-html");
    const [distributors, setDistributors] = useState<DistributorDto[]>();
    const [isFileUploadBulkEcode, setIsFileUploadBulkEcode] = useState<boolean>(false);
    const [isCodeSummaryModalOpen, setIsCodeSummaryModalOpen] = useState<boolean>(false);
    const [batchOrderDistributorRecipients, setBatchOrderDistributorRecipients] = useState<SearchResults<FileUploadPrintReadyRowItem>>();

    const [isChangeIssuanceDateModalOpen, setIsChangeIssuanceDateModalOpen] = useState<boolean>(false);
    const [newIssuanceDateValue, setNewIssuanceDateValue] = useState<string>('');
    const [issuanceUpdateCodeId, setIssuanceUpdateCodeId] = useState<number>(-1);
    const [minLimit, setMinLimit] = useState<string>('');
    const [isChangingIssuanceDate, setIsChangingIssuanceDate] = useState<boolean>(false);
    const [currentRowIssuanceDateValueString, setCurrentRowIssuanceDateValueString] = useState<string>('');
    const [newSelectedIssuanceDateValueString, setNewSelectedIssuanceDateValueString] = useState<string>('');
    const isNewIssuanceDateInvalid = moment.utc( minLimit).isAfter(moment.utc(newIssuanceDateValue));
    const [userLocale, setUserLocale] = useState('default');

    const loading = loadingCount > 0;
    const setLoading = (enable: boolean) => setLoadingCount(count => enable ? count + 1 : count - 1);

    const isOrderProcessed = [BatchStatus.SentToMvb, BatchStatus.Allocated].includes(orderDetails?.batchStatusId!);

    attemptGoToStep(1);
    useEffect(() => {
        if (!orderId) return;
        setMinLimit(moment.utc().format("YYYY-MM-DD"));
        (async () => await loadOrderDetails())();
    }, [orderId, refreshOrderCount]);

    useEffect(() => {
        if ([BatchType.ElectronicBatch].includes(orderDetails?.batchTypeId!)) {
            getBulkOrderFileUploadRows();
        }
        if ([BatchType.PrintReadyBatch].includes(orderDetails?.batchTypeId!)) {
            if (isOrderProcessed) {
                loadBatchOrderDistributors();
            } else {
                getBulkOrderFileUploadRows();
            }
        }
        if ([BatchType.ElectronicBatch, BatchType.PrintReadyBulk, BatchType.PrintReadyBatch].includes(orderDetails?.batchTypeId!)) {
            getEmailTemplate();
            getSmsTemplate();
        }

        let _isFileUploadBulkEcode = false;
        if (orderDetails?.batchTypeId! == BatchType.ElectronicBulk && orderDetails?.deliveryEmail == null) {
            loadBulkOrderDistributors();
            _isFileUploadBulkEcode = true;
        }
        setIsFileUploadBulkEcode(_isFileUploadBulkEcode);

        if (shouldShowOrderItems(_isFileUploadBulkEcode)) {
            loadOrderItems();
        }
    }, [orderDetails])

    useEffect(() => {
        setIsPaymentMessageVisible(shouldUserSeeCurrentMessage());
    }, [orderId])

    useEffect(() => {
        const locale = navigator.language || 'en-GB';
        setUserLocale(locale);
    }, []);

    const togglePrintReadyDistributorModal = (distributorEmail: string) => {
        setSelectedDistributorEmail(distributorEmail);
        setIsModalOpen(true);
    };

    const closePrintReadyDistributorModal = () => {
        setSelectedDistributorEmail("");
        setIsModalOpen(false);
    };

    const toggleCodeSummaryModal = (row: DistributorDto) => {
        setSelectedDistributor(row);
        setIsCodeSummaryModalOpen(true);
    };

    const closeCodeSummaryModal = () => {
        setSelectedDistributor(undefined);
        setIsCodeSummaryModalOpen(false);
    };

    const loadOrderDetails = async () => {
        try {
            setLoading(true);
            setOrderDetails(await orderHistoryService.getOrderDetails(orderId));
            setLoading(false);
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderDetailsFailure")));
        }
    }
    const onPaymentSuccessDismiss = async () => {
        setIsPaymentMessageVisible(false);
    }
    const loadOrderItems = async () => {

        try {

            const data = await orderHistoryService.getOrderItems(orderId);

            setOrderItems(data);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderItemsFailure")));
        }
    }

    const onGetProformaInvoice = async () => {

        setLoading(true);
        setLoadingMessage(t("NotificationMessage.LoadProformaInvoiceMessage"));

        try {

            const htmlContent = await orderHistoryService.getProformaInvoiceHtml(+(orderDetails?.projectId ?? "0"), orderId);

            var tab = window.open("", "_blank");

            if (tab) {
                tab.document.write(htmlContent);
                tab.document.close();
            }
        } catch (e) {
            console.error(e)

            notifyError(getErrorNotification(t("NotificationMessage.LoadProformaInvoiceFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    const onCancelOrder = async () => {

        setLoading(true);
        setLoadingMessage(t("NotificationMessage.CancelOrderInProcess"));
        setBoldMessage("OrderDetails.CancelledDebitCreditMessage");
        setNormalMessage(undefined);
        setIsPaymentMessageVisible(true);
        try {
            await orderingService.cancelOrder(orderDetails?.projectId, orderId);
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.CancelOrderFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
        setRefreshOrderCount(i => i + 1); // trigger re-retrieval of all order details
    }

    const onPayNow = async () => {

        setLoading(true);

        removeValueFromStorage(getCurrentMessageKey()); // allow user to potentially see the same message again

        setRedirectToPayments(true);

        setLoading(false);
    }

    const getEmailTemplate = async () => {
        setLoading(true);
        setLoadingMessage(t("NotificationMessage.LoadOrderTemplateMessage"));

        try {
            const templateDetails = await orderHistoryService.getEmailTemplate(orderDetails?.projectId, orderId);
            setBulkOrderEmailTemplateDetails(templateDetails);

        } catch (e) {
            console.error(e)
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderTemplateMessageFailure")));
        }
        setLoading(false);
        setLoadingMessage(undefined);
    }

    const getSmsTemplate = async () => {
        setLoading(true);
        setLoadingMessage(t("NotificationMessage.LoadOrderTemplateMessage"));

        try {
            const templateDetails = await orderHistoryService.getSmsTemplate(orderDetails?.projectId, orderId);
            setSmsTemplateDetails(templateDetails);

        } catch (e) {
            console.error(e)
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderTemplateMessageFailure")));
        }
        setLoading(false);
        setLoadingMessage(undefined);
    }

    const getBulkOrderFileUploadRows = async () => {

        setLoading(true);

        try {

            const data = await orderHistoryService.getBulkOrderFileUploadRows(orderDetails?.projectId, orderId);

            setFileUploadObject(data);


        } catch (e) {
            console.error(e);

            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderRowsMessageFailure")));
        }

        setLoading(false);
    }

    const loadBatchOrderDistributors = async () => {
        setLoading(true);

        try {
            setDistributors(await orderHistoryService.getBatchOrderDistributors(orderId));
        } catch (e) {
            console.error(e);

            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderRowsMessageFailure")));
        }

        setLoading(false);
    }

    const fetchPrintReadyDistributorRecipients = async (pageIndex: number, pageSize: number) => {
        const selectedDistributorId = (distributors || [])
            .find(d => d.emailAddress === selectedDistributorEmail)?.distributorUserId;
        if (!selectedDistributorId) return;

        setLoading(true);
        try {
            setBatchOrderDistributorRecipients(await orderHistoryService.getBatchOrderDistributorRecipients(orderId, selectedDistributorId, pageIndex, pageSize));
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderHistoryFailure")));
        }

        setLoading(false);
    }

    const loadBulkOrderDistributors = async () => {
        setLoading(true);

        try {
            setDistributors(await orderHistoryService.getBulkOrderDistributors(orderId));
        } catch (e) {
            console.error(e);

            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderRowsMessageFailure")));
        }

        setLoading(false);
    }

    const getCurrentMessageKey = (): string => {
        return `user-has-seen-${orderId}-${boldAlert}-${normalAlert}`;
    }

    const shouldUserSeeCurrentMessage = (): boolean => {

        // Don't show message if there's no alerts
        if (!boldAlert && !normalAlert) return false;

        const currentMessageKey = getCurrentMessageKey();

        // Don't show message again if user has already been shown it
        if (getValueFromStorage(currentMessageKey) === "1") return false;

        // Save value so user doesn't see it again
        saveValueToStorage(currentMessageKey, "1");

        return true;
    }

    const shouldShowOrderItems = (_isFileUploadBulkEcode: boolean) => {
        return orderDetails?.batchTypeId! == BatchType.PrintReadyBulk || (orderDetails?.batchTypeId! == BatchType.ElectronicBulk && !_isFileUploadBulkEcode);
    }

    if (redirectToPayments) {
        return <Redirect to={`/PaymentPlusPage/project/${orderDetails?.projectId}/order/${orderId}`} />
    }

    const columnECodeDefinitions: Column<FileUploadECodeRowItem>[] = [
        {
            id: "CodeDeliverMethod",
            Header: t("OrderDetails.DeliveryMethodHeading"),
            accessor: (model) => model.emailAddress,
            Cell: (cell: Cell<FileUploadECodeRowItem>) => {
                const item = cell.row.original;

                return <>
                    <div className="d-flex">
                        {IsEmailDelivery(item.emailAddress) && <div className="mr-1">{getEmailElement()}</div>}
                        {IsSmsDelivery(item.mobileNumber) && <div>{getSmsElement()}</div>}
                    </div>
                </>
            }
        },
        {
            id: nameOf<FileUploadECodeRowItem>("firstName"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => model.firstName,
            Cell: (cell: Cell<FileUploadECodeRowItem>) => {

                const row = cell.row.original;

                return (
                    <>{row.firstName} {row.lastName}</>
                );
            }
        },
        {
            id: nameOf<FileUploadECodeRowItem>("emailAddress"),
            Header: t("Login.EmailAddressLabel"),
            accessor: (model) => model.emailAddress
        },
        {
            id: nameOf<FileUploadECodeRowItem>("mobileNumber"),
            Header: t("OrderDetails.PhoneNumberLabel"),
            accessor: (model) => model.mobileNumber
        },
        {
            id: nameOf<FileUploadECodeRowItem>("company"),
            Header: t("OrderDetails.CompanyLabel"),
            accessor: (model) => model.company
        },
        {
            id: nameOf<FileUploadECodeRowItem>("staffNumber"),
            Header: t("OrderDetails.StaffNumberLabel"),
            accessor: (model) => model.staffNumber
        },
        {
            id: nameOf<FileUploadECodeRowItem>("awardReason"),
            Header: t("OrderDetails.ReasonForAwardLabel"),
            accessor: (model) => model.awardReason
        },
        {
            id: nameOf<FileUploadECodeRowItem>("awardType"),
            Header: t("OrderDetails.AwardTypeLabel"),
            accessor: (model) => model.awardType
        },
        {
            id: nameOf<FileUploadECodeRowItem>("awardValue"),
            Header: t("OrderDetails.ValueLabel"),
            accessor: (model) => model.awardValue
        },
        {
            id: nameOf<FileUploadECodeRowItem>("awardOriginator"),
            Header: t("OrderDetails.OriginatorLabel"),
            accessor: (model) => model.awardOriginator
        }
    ]
     
    const columnPrintReadyDistributorDefinitions: Column<DistributorDto>[] = [
        {
            id: nameOf<DistributorDto>("firstName"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => model.firstName,
            Cell: (cell: Cell<DistributorDto>) => {
                const item = cell.row.original;

                return <>{item.firstName} {item.lastName}</>
            }
        },
        {
            id: nameOf<DistributorDto>("emailAddress"),
            Header: t("Login.EmailAddressLabel"),
            accessor: (model) => model.emailAddress
        },
        {
            id: nameOf<DistributorDto>("phoneNumber"),
            Header: t("OrderDetails.DistributorPhoneNumberLabel"),
            accessor: (model) => model.phoneNumber
        },
        {
            id: "numberOfCodes",
            Header: t("YourDownloads.NumberOfCodes"),
            accessor: (model) => model.numberOfCodes,
            Cell: (cell: Cell<DistributorDto>) => {
                const item = cell.row.original;

                return <>{item.numberOfCodes}</>;

            }
        },
        {
            id: nameOf<DistributorDto>("totalValue"),
            Header: t("OrderDetails.TotalValueLabel"),
            accessor: (model) => model.totalValue,
            Cell: (cell: Cell<DistributorDto>) => {
                const item = cell.row.original;

                return <>{`${orderDetails?.currency}${item.totalValue}`}</>

            }
        },
        {
            id: nameOf<DistributorDto>("denominations"),
            Header: '',
            accessor: (model) => model.denominations,
            Cell: (cell: Cell<DistributorDto>) => {
                const item = cell.row.original;

                return <div className="text-right">
                    <Button onClick={() => togglePrintReadyDistributorModal(item.emailAddress)}>{t("OrderingProcess.ViewRecipientsModalButton")}</Button>
                </div>
            }
        }
    ]

    const columnPrintReadyDistributorRecipientDefinitions: Column<FileUploadPrintReadyRowItem>[] = [
        {
            id: nameOf<FileUploadPrintReadyRowItem>("firstName"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => model.firstName,
            Cell: (cell: Cell<FileUploadPrintReadyRowItem>) => {
                const item = cell.row.original;

                return <>{item.firstName} {item.lastName}</>
            }
        },
        {
            id: nameOf<FileUploadPrintReadyRowItem>("recipientEmailAddress"),
            Header: t("Login.EmailAddressLabel"),
            accessor: (model) => model.recipientEmailAddress
        },
        {
            id: nameOf<FileUploadPrintReadyRowItem>("company"),
            Header: t("OrderSummary.CompanyLabel"),
            accessor: (model) => model.company
        },
        {
            id: nameOf<FileUploadPrintReadyRowItem>("staffNumber"),
            Header: t("OrderDetails.StaffNumberLabel"),
            accessor: (model) => model.staffNumber
        },
        {
            id: nameOf<FileUploadPrintReadyRowItem>("awardReason"),
            Header: t("OrderDetails.ReasonForAwardLabel"),
            accessor: (model) => model.awardReason
        },
        {
            id: nameOf<FileUploadPrintReadyRowItem>("awardType"),
            Header: t("OrderDetails.AwardTypeLabel"),
            accessor: (model) => model.awardType
        },
        {
            id: nameOf<FileUploadPrintReadyRowItem>("awardOriginator"),
            Header: t("OrderDetails.OriginatorLabel"),
            accessor: (model) => model.awardOriginator
        },
        {
            id: nameOf<FileUploadPrintReadyRowItem>("awardValue"),
            Header: t("OrderDetails.ValueLabel"),
            accessor: (model) => model.awardOriginator,
            Cell: (cell: Cell<FileUploadPrintReadyRowItem>) => {
                const item = cell.row.original;

                return <>{`${orderDetails?.currency}${item.awardValue}`}</>
            }
        },

    ]

    const CodeSummaryColumnDefinitions: Column<Denomination>[] = [
        {
            id: nameOf<FileUploadBulkOrderItem>("codeValue"),
            Header: t("OrderDetails.ValueLabel"),
            accessor: (model) => model.value
        },
        {
            id: nameOf<FileUploadBulkOrderItem>("quantity"),
            Header: t("YourDownloads.NumberOfCodes"),
            accessor: (model) => model.quantity
        }
    ]

    const EcodeBulkFileUploadColumnDefinition: Column<DistributorDto>[] =
        [
            {
                id: nameOf<DistributorDto>("firstName"),
                Header: t("OrderDetails.NameLabel"),
                accessor: (model) => model.firstName,
                Cell: (cell: Cell<DistributorDto>) => {
                    const item = cell.row.original;

                    return <>{item.firstName} {item.lastName}</>
                }
            },
            {
                id: nameOf<DistributorDto>("emailAddress"),
                Header: t("OrderDetails.EmailAddressLabel"),
                accessor: (model) => model.emailAddress
            },
            {
                id: nameOf<DistributorDto>("totalValue"),
                Header: t("OrderDetails.TotalValueLabel"),
                accessor: (model) => model.totalValue,
                Cell: (cell: Cell<DistributorDto>) => {
                    const item = cell.row.original;

                    return <>{`${distributors?.filter(i => i.emailAddress === item.emailAddress).reduce((acc, item) => acc + +item.totalValue, 0)}`}</>
                }
            },
            {
                id: nameOf<DistributorDto>("numberOfCodes"),
                Header: t("YourDownloads.NumberOfCodes"),
                accessor: (model) => model.numberOfCodes,
                Cell: (cell: Cell<DistributorDto>) => {
                    const item = cell.row.original;

                    return <>{`${distributors?.filter(i => i.emailAddress === item.emailAddress).reduce((acc, item) => acc + +item.numberOfCodes, 0)}`}</>
                }
            },
            {
                id: nameOf<DistributorDto>("lastName"),
                Header: '',
                accessor: (model) => model.emailAddress,
                Cell: (cell: Cell<DistributorDto>) => {
                    const item = cell.row.original;

                    return <div className="text-right">
                        <Button
                            onClick={async () => {
                                toggleCodeSummaryModal(item);
                            }}>{t("OrderingProcess.ViewCodesModalButton")}
                        </Button>
                    </div>
                }
            }
        ]

    const codeSummaryRows = selectedDistributor?.denominations;

    const unprocessedPrintReadyDistributors = fileUploadObject?.items
        .map(v => v as FileUploadPrintReadyRowItem)
        .filter((v, i, a) => a.findIndex(t => (t.distributorEmailAddress === (v as FileUploadPrintReadyRowItem).distributorEmailAddress)) === i)
        .map(v => {
            const codes = fileUploadObject?.items
                .filter(i => (i as FileUploadPrintReadyRowItem)?.distributorEmailAddress === v.distributorEmailAddress);

            return ({
                distributorUserId: 0,
                firstName: v.firstName,
                lastName: v.lastName,
                emailAddress: v.distributorEmailAddress,
                phoneNumber: v.distributorPhoneNumber,
                numberOfCodes: codes.length,
                totalValue: codes.reduce((acc, item) => acc + +item.awardValue, 0),
                denominations: []
            } as DistributorDto);
        });
    const unprocessedPrintReadyRecipients = fileUploadObject?.items
        .map(v => v as FileUploadPrintReadyRowItem)
        .filter(v => v.distributorEmailAddress === selectedDistributorEmail);

    const IsEmailDelivery = (emailAddress: string) => { return !!(bulkOrderEmailTemplateDetails?.id) && !!emailAddress && emailAddress.trim() !== "" };

    const IsSmsDelivery = (mobileNumber: string) => { return !!(smsTemplateDetails?.id) && !!mobileNumber && mobileNumber.toString().trim() !== "" };

    const numberOfEmailOnlyCodes = fileUploadObject?.items.filter((value) => IsEmailDelivery((value as FileUploadECodeRowItem).emailAddress) && !IsSmsDelivery((value as FileUploadECodeRowItem).mobileNumber)).length ?? 0;

    const numberOfSmsOnlyCodes = fileUploadObject?.items.filter((value) => !IsEmailDelivery((value as FileUploadECodeRowItem).emailAddress) && IsSmsDelivery((value as FileUploadECodeRowItem).mobileNumber)).length ?? 0;

    const numberOfEmailAndSmsCodes = fileUploadObject?.items.filter((value) => IsEmailDelivery((value as FileUploadECodeRowItem).emailAddress) && IsSmsDelivery((value as FileUploadECodeRowItem).mobileNumber)).length ?? 0;

    const hasSendDate = fileUploadObject?.items?.some((value) => !!(value as FileUploadECodeRowItem).scheduledSendDate?.trim());
    const isLaterThanToday = (d: Date) => {
        var result = false;
        var now = new Date();

        if (d.getUTCFullYear() > now.getUTCFullYear()) {
            result = true;
        }
        else if (d.getUTCFullYear() === now.getUTCFullYear()) {
            if (d.getUTCMonth() > now.getUTCMonth()) {
                result = true;
            }
            else if (d.getUTCMonth() === now.getUTCMonth()) {
                result = d.getUTCDate() > now.getUTCDate();
            }
        }

        return result;
    }
    const loggedinUser = serviceUtils.loggedInUser();

    const canUserEditIssuanceDate = () => {
        if (!loggedinUser) return false;
        const hasOrderPlacement = hasProjectPermission(loggedinUser, RolePermission.OrderPlacement, +(orderDetails?.projectId!));
        const isAdminUser = hasProjectPermission(loggedinUser, RolePermission.SuperUser, +(orderDetails?.projectId!));
        return (hasOrderPlacement || isAdminUser);
    };

    const onChangeIssuanceDateClicked = async (ecodeId: number) => {
        const rowItem = (fileUploadObject?.items as FileUploadECodeRowItem[]).find(c => c.codeId == ecodeId);
        setIssuanceUpdateCodeId(rowItem?.codeId!);

        const currentScheduleDateString = new Date(rowItem?.scheduledSendDate!).toLocaleDateString(userLocale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        setCurrentRowIssuanceDateValueString(currentScheduleDateString);
        setNewSelectedIssuanceDateValueString(currentScheduleDateString);

        setNewIssuanceDateValue(new Date(rowItem?.scheduledSendDate!).toISOString().substring(0, 10));
        openChangeIssuanceDateModal();
    }

    const openChangeIssuanceDateModal = () => {
        setIsChangeIssuanceDateModalOpen(true);
    }

    const closeChangeIssuanceDateModal = () => {
        setIsChangeIssuanceDateModalOpen(false);
        setIssuanceUpdateCodeId(-1);
        setCurrentRowIssuanceDateValueString('');
        setNewSelectedIssuanceDateValueString('');
        setNewIssuanceDateValue('');
        setIsChangingIssuanceDate(false);
    }
    const handleNewIssuanceDateValue = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputDate = event.target.value;
        setNewIssuanceDateValue(inputDate);

        const localizedDate = new Date(inputDate).toLocaleDateString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' });
        setNewSelectedIssuanceDateValueString(localizedDate);
    }

    const handleChangeCodeIssuanceDate = async () => {
        if (isNewIssuanceDateInvalid)
            return;
        setIsChangeIssuanceDateModalOpen(false);
        setIsChangingIssuanceDate(true);

            try {
                const changedDate = new Date(newIssuanceDateValue);
                const result = await axios.post(`api/CustomerServices/UpdateCodeIssuanceDate/${orderDetails?.projectId}/${issuanceUpdateCodeId}`, {
                        date: changedDate
                    },
                    {
                        headers: getAuthHeader()
                    }
                );
                if (result.status != 200) {

                    notifyError(getErrorNotification(t("NotificationMessage.UpdateIssuanceDetailsFailure")));
                    return;
                }
                notifySuccess(getSuccessNotification(t("NotificationMessage.UpdateIssuanceDetailsSuccess")));
                getBulkOrderFileUploadRows();
            } catch (e) {
                notifyError(getErrorNotification(t("NotificationMessage.UpdateIssuanceDetailsFailure")));
            }
            finally {
                closeChangeIssuanceDateModal();
            }
    }

    if (hasSendDate) {
        columnECodeDefinitions.push({
            id: "scheduledSendDate",
            Header: t("OrderDetails.ScheduledSendDateLabel"),
            accessor: (model) => {
                const scheduledSendDate = model.scheduledSendDate;
                if (scheduledSendDate) {
                    const date = new Date(scheduledSendDate);
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    return <>
                        <div className="d-flex">
                            {date.toLocaleDateString(userLocale, options)}
                            {model.codeId > 0 && model.codeStatusId == 2 && canUserEditIssuanceDate() && isLaterThanToday(date) &&  
                                <button
                                    className="btn btn-link"
                                    style={{ marginLeft: "5px", padding: "inherit" }}
                                    onClick={() => onChangeIssuanceDateClicked(model.codeId)}
                                ><HiOutlinePencilAlt size={24} /></button>
                            }
                            </div>
                    </>
                }
                return t("OrderDetails.ASAPLabel");
            }
        });
    }

    const getEmailElement = () => {
        return <span style={{ display: "inline-block", marginTop: "0" }} className="c-order-status-badge bg-info">Email</span>;
    }

    const getSmsElement = () => {
        return <span style={{ display: "inline-block", marginTop: "0" }} className="c-order-status-badge bg-warning">Sms</span>;
    }
    const selectedTemplateNames = {
        email: !!bulkOrderEmailTemplateDetails?.id && (numberOfEmailOnlyCodes + numberOfEmailAndSmsCodes > 0) ? bulkOrderEmailTemplateDetails.name : null,
        sms: !!smsTemplateDetails?.id && (numberOfSmsOnlyCodes + numberOfEmailAndSmsCodes > 0) ? smsTemplateDetails.name : null,
        printReady: bulkOrderEmailTemplateDetails?.id && [BatchType.PrintReadyBulk, BatchType.PrintReadyBatch].includes(orderDetails?.batchTypeId!) ? bulkOrderEmailTemplateDetails?.name : null
    }

    let deliveryMethod: DeliveryMethod | null = null;
    switch (orderDetails?.batchTypeId!) {
        case BatchType.PrintReadyBatch:
            deliveryMethod = DeliveryMethod.Email;
            break;
        case BatchType.PrintReadyBulk:
            deliveryMethod = DeliveryMethod.Form;
            break;
        case BatchType.ElectronicBatch:
            deliveryMethod = DeliveryMethod.Email;
            break;
        case BatchType.ElectronicBulk:
            deliveryMethod = orderDetails?.deliveryEmail == null ? DeliveryMethod.Email : DeliveryMethod.Form;
            break;
    }

    let distributorCount: number | undefined;
    let singleDistributorEmailAddress: string | undefined;
    switch (orderDetails?.batchTypeId!) {
        case BatchType.PrintReadyBatch:
            if (isOrderProcessed) {
                distributorCount = distributors?.length || 0;
            } else {
                distributorCount = (fileUploadObject?.items || [])
                    .map(item => (item as FileUploadPrintReadyRowItem).distributorEmailAddress)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .length;
            }
            break;
        case BatchType.PrintReadyBulk:
            singleDistributorEmailAddress = orderDetails?.deliveryEmail;
            break;
        case BatchType.ElectronicBulk:
            switch (deliveryMethod) {
                case DeliveryMethod.Form:
                    singleDistributorEmailAddress = orderDetails?.deliveryEmail;
                    break;
                case DeliveryMethod.Email:
                    distributorCount = distributors?.length || 0;
                    break;
            }
            break;
    }

    return (
        <div className={bem.b()}>

            <AreaLoader
                show={loading}
                message={loadingMessage ?? t("NotificationMessage.LoadOrderDetailsMessage")}
            />
            <AreaLoader show={isChangingIssuanceDate} message={t("NotificationMessage.UpdatingIssuanceDetailsMessage")} />

            <Breadcrumb>
                <BreadcrumbItem><Link to="/order-history/">{t("OrderHistory.OrderHistoryLabel")}</Link></BreadcrumbItem>
                <BreadcrumbItem active>{t("OrderDetails.OrderDetailsLabel")}</BreadcrumbItem>
            </Breadcrumb>

            <div className="row justify-content-between">
                <div className="col-md-4 col-lg-3">
                    <div className="d-flex flex-row justify-content-between w-60">
                        <h1>{t("OrderDetails.OrderDetailsLabel")}</h1>
                    </div>
                </div>


                {orderDetails?.paymentMethodId === PaymentMethod.DebitCreditCard && orderDetails.batchStatusId === 11 &&
                    <div className="col-md-6 col-lg-4 row row">
                        <Button className={bem.e("payment-options", "bg-danger col-md-6")} onClick={toggleCancelModal}>
                            Cancel order
                        </Button>
                        <Button data-automation-id="payNowButton" className={bem.e("payment-options", "bg-success col-md-6")} onClick={onPayNow}>
                            Pay now
                        </Button>
                        <Modal isOpen={cancelModal} toggle={toggleCancelModal} >
                            <ModalHeader toggle={toggleCancelModal}>Cancel order</ModalHeader>
                            <ModalBody>
                                <div className="cancel-confirm-box">
                                    Are you sure you want to cancel this order?
                                </div>
                            </ModalBody>
                            <ModalFooter className="flex-row justify-content-between">
                                <Button outline onClick={toggleCancelModal}>
                                    No
                                </Button>
                                <Button color="primary" onClick={onCancelOrder}>
                                    Yes
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                }

                {isPaymentMessageVisible &&
                    <div className="col-12">
                        <Alert color="success" dismissible="true" className="mt-3" isOpen={isPaymentMessageVisible} toggle={onPaymentSuccessDismiss}>
                            {boldMessage && <strong>{t(boldMessage)}</strong>}
                            {normalMessage && t(normalMessage)}
                        </Alert>
                    </div>
                }

                {orderDetails?.paymentMethodId === PaymentMethod.BacsChaps &&
                    <div className="col-md-8 col-lg-9 float-md-right">
                        <div className="alert alert-info d-flex align-items-start mt-2" role="alert">
                            <div>
                                <BsFillExclamationCircleFill fontSize="20" width="20px" className="mb-1" />
                            </div>
                            <p className="pl-2 mb-0 d-flex flex-column flex-md-row align-items-center align-items-md-start">
                                {t("OrderDetails.UniqueReferenceBannerMessage")}
                                <Button
                                    className="text-uppercase p-0 ml-md-3 text-left mt-2 mt-md-0"
                                    color="link"
                                    onClick={onGetProformaInvoice}
                                    style={{ whiteSpace: "pre" }}
                                >
                                    <IoPrintOutline className="mr-2" fontSize="25" />{t("OrderDetails.PrintProformaButton")}
                                </Button>
                            </p>

                        </div>
                    </div>
                }
            </div>

            <div className={bem.e("row", "mt-3")}>

                {/* Order Summary */}
                <div className={bem.e("row-body", "w-100")}>

                    <OrderSummary key={refreshOrderCount} orderId={orderId} />

                </div>
            </div>


            {/* Order Details */}
            <div className="d-flex flex-column flex-lg-row">
                <div className={bem.e("row", "mt-4 d-flex flex-column mr-lg-3")}>
                    <div className={bem.e("row-header font-weight-bold", "p-3 text-uppercase")} style={{ lineHeight: "1rem" }}>
                        {t("OrderDetails.InvoiceSummaryLabel")}
                    </div>
                    <div className={bem.e("row-body", "w-100 pt-1")}>
                        <div className="d-flex flex-column flex-sm-row">
                            <div className="col">
                                <h2>{t("OrderDetails.PaymentInformationLabel")}</h2>
                                <div className="d-flex flex-column">
                                    <strong className="text-uppercase">{t("OrderDetails.PaymentMethodLabel")}</strong>
                                    {orderDetails?.paymentMethodId === PaymentMethod.BacsChaps && t("PaymentMethod.BacsChaps")}
                                    {orderDetails?.paymentMethodId === PaymentMethod.PaymentOnAccount && t("PaymentMethod.PaymentOnAccount")}
                                    {orderDetails?.paymentMethodId === PaymentMethod.DebitCreditCard && t("PaymentMethod.DebitCredit")}
                                </div>
                                <div className="d-flex flex-column mt-3">
                                    <strong className="text-uppercase">{t("OrderDetails.ReferencePoNumberLabel")}</strong>
                                    {orderDetails?.referenceOrPoNumber}
                                </div>

                                {orderDetails?.otherReference != "" &&
                                    <div className="mt-3">
                                        <label className="mb-0 font-weight-bold text-uppercase">{t("OrderDetails.OtherReferenceLabel")}</label>
                                        <div className="d-block">{orderDetails?.otherReference}</div>
                                    </div>
                                }

                                {orderDetails?.paymentMethodId === PaymentMethod.BacsChaps &&
                                    <div className="d-flex flex-column mt-3">
                                        <strong className="text-uppercase">{t("OrderDetails.BillingDetailsLabel")}</strong>
                                        {orderDetails.invoiceAddress?.addressLine1}<br />
                                        {orderDetails.invoiceAddress?.addressLine2}{orderDetails.invoiceAddress?.addressLine2 !== "" ? <br /> : null}
                                        {orderDetails.invoiceAddress?.addressLine3}{orderDetails.invoiceAddress?.addressLine3 !== "" ? <br /> : null}
                                        {orderDetails.invoiceAddress?.cityTown}<br />
                                        {orderDetails.invoiceAddress?.postCode}
                                    </div>
                                }

                            </div>
                            <div className={`col ${(orderDetails?.batchTypeId! >= 5 && orderDetails?.batchTypeId! != 8) ? "preview-area" : ""}`}>
                                <h2>{t("OrderDetails.DeliveryInformationLabel")}</h2>

                                {orderDetails?.batchTypeId! === BatchType.ElectronicBatch &&
                                    <div>
                                        <p>{t("OrderDetails.DeliveryMethodHeading")}</p>
                                        <div className="d-flex">
                                            {numberOfEmailOnlyCodes > 0 && <div className="ml-2"> x{numberOfEmailOnlyCodes}{" "}{getEmailElement()}{numberOfSmsOnlyCodes + numberOfEmailAndSmsCodes > 0 ? "," : ""}</div>}
                                            {numberOfSmsOnlyCodes > 0 && <div className="ml-2"> x{numberOfSmsOnlyCodes}{" "}{getSmsElement()}{numberOfEmailAndSmsCodes > 0 ? "," : ""}</div>}
                                            {numberOfEmailAndSmsCodes > 0 && <div className="ml-2"> x{numberOfEmailAndSmsCodes}{" "}{getEmailElement()}{" & "}{getSmsElement()} </div>}
                                        </div>
                                    </div>
                                }

                                {singleDistributorEmailAddress && <div>{t("OrderDetails.DeliveryDestinationLabel")} {singleDistributorEmailAddress}</div>}

                                {distributorCount != undefined && <div>{t("OrderDetails.DeliveryToLabel").replace("[[Count]]", distributorCount.toString())}</div>}

                                {Object.values(selectedTemplateNames).filter(Boolean).length > 0 &&
                                    <>
                                        <h2 className="mt-3">{t("OrderDetails.TemplateSelectionLabel")}</h2>

                                        {selectedTemplateNames.email && <p>{t("OrderDetails.EmailTemplateNamePrefix")} {selectedTemplateNames.email}</p>}
                                        {selectedTemplateNames.sms && <p>{t("OrderDetails.SmsTemplateNamePrefix")} {selectedTemplateNames.sms}</p>}
                                        {selectedTemplateNames.printReady && <p>{t("OrderDetails.PrintReadyTemplateNamePrefix")} {selectedTemplateNames.printReady}</p>}
                                    </>
                                }
                            </div>

                        </div>

                        {orderDetails?.paymentMethodId === PaymentMethod.BacsChaps &&
                            <div className="alert alert-info d-flex align-items-start mt-2" role="alert">
                                <div>
                                    <BsFillExclamationCircleFill fontSize="20" width="20px" className="mb-1" />
                                </div>
                                <p className="pl-2 mb-0">{t("OrderingProcess.UniqueReferenceNotificationMessage")}</p>
                            </div>
                        }

                    </div>
                </div>
                <div className={bem.e("row order-costs", "mt-4 d-flex flex-column ml-lg-3 pb-5")} style={{ position: "relative" }}>
                    <div className={bem.e("row-header font-weight-bold", "p-3 text-uppercase")} style={{ lineHeight: "1rem" }}>
                        {t("OrderDetails.OrderCostsLabel")}
                    </div>
                    <div className={bem.e("row-body", "w-100 pt-1")}>
                        <div className="p-3">

                            <div className="d-flex justify-content-between w-100">
                                <div>{t("OrderDetails.TotalValueLabel")}</div>
                                <div>{orderDetails?.currency}{orderDetails?.totalItemAmount?.toFixed(2) || 0.00}</div>
                            </div>

                            {orderDetails && (orderDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                || orderDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption) &&
                                (orderDetails.handlingFeePercentage ?? 0) > 0 &&

                                <div className="d-flex justify-content-between w-100 mt-2">
                                    <div>{t("OrderDetails.HandlingFeeLabel")} ({orderDetails?.handlingFeePercentage?.toFixed(1)}%)</div>
                                    <div>{orderDetails?.currency}{orderDetails?.handlingFeeAmount?.toFixed(2) || 0.00}</div>
                                </div>
                            }

                            {orderDetails && (orderDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                || orderDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption)
                                && (orderDetails?.totalCostPerCodeAmount ?? 0) > 0 &&

                                <div className="d-flex justify-content-between w-100 mt-2">
                                    <div>{t("OrderDetails.TotalCostPerCodeLabel")}</div>
                                    <div>{orderDetails?.currency}{orderDetails?.totalCostPerCodeAmount?.toFixed(2) || 0.00}</div>
                                </div>
                            }

                            {orderDetails && (orderDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                || orderDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption)
                                && (orderDetails?.costPerSmsAmount ?? 0) > 0
                                && (numberOfSmsOnlyCodes + numberOfEmailAndSmsCodes) > 0 &&

                                <div className="d-flex justify-content-between w-100 mt-2">
                                    <div>{t("OrderDetails.TotalCostPerSmsLabel")}</div>
                                    <div>{orderDetails?.currency}{((numberOfSmsOnlyCodes + numberOfEmailAndSmsCodes) * (orderDetails.costPerSmsAmount!))?.toFixed(2) || 0.00}</div>
                                </div>
                            }

                            {orderDetails && (((orderDetails?.discountPercentage ?? 0) > 0)) && ((orderDetails?.discountAmount ?? 0) > 0) &&

                                <div className="d-flex justify-content-between w-100 mt-2">
                                    <div>{t("OrderDetails.DiscountLabel")} ({orderDetails?.discountPercentage?.toFixed(2)}%)</div>
                                    <div>{orderDetails?.currency}{orderDetails?.discountAmount && `-${orderDetails?.discountAmount?.toFixed(2) || 0.00}`}</div>
                                </div>
                            }

                            {orderDetails && (((orderDetails?.vatPercentage ?? 0) > 0)) && ((orderDetails?.vatAmount ?? 0) > 0) &&

                                <div className="d-flex justify-content-between w-100 mt-2">
                                    <div>{t("OrderDetails.VatRateLabel")} ({orderDetails?.vatPercentage?.toFixed(1)}%)</div>
                                    <div>{orderDetails?.currency}{orderDetails?.vatAmount.toFixed(2) || 0.00}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="card border-top-0 w-100" style={{ position: "absolute", bottom: "0px", left: "0px", borderRadius: "0 0 0.25rem 0.25rem", fontSize: "1.5rem", backgroundColor: "#CBCFDB" }}>
                        <div className="card-body py-2">
                            <div className="d-flex large justify-content-between">
                                <div>{t("OrderDetails.TotalLabel")}</div>
                                <div>{orderDetails?.currency}{orderDetails && orderDetails.totalOrderAmount?.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Order Items */}
            <div className={bem.e("row", "mt-4 d-flex flex-column")}>
                <div className={bem.e("row-header font-weight-bold", "p-3 text-uppercase")} style={{ lineHeight: "1rem" }}>
                    {t("OrderDetails.OrderItemsLabel")}
                </div>

                <div className={bem.e("row-body", "p-3")}>
                    {shouldShowOrderItems(isFileUploadBulkEcode) &&
                        <Table style={{ maxWidth: "46rem" }}>
                            <thead>
                                <tr>
                                    <th>{t("OrderDetails.DenominationLabel")}</th>
                                    <th>{t("OrderDetails.QuantityLabel")}</th>
                                    <th>{t("OrderDetails.ValueLabel")}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {orderItems?.map((item, index) =>
                                    <tr key={index}>
                                        <td>{orderDetails?.currency}{(+item.value)?.toFixed(2)}</td>
                                        <td>{item.quantity}</td>
                                        <td>{orderDetails?.currency}{(+item.value * +item.quantity)?.toFixed(2)}</td>
                                    </tr>
                                )
                                }
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td className="font-weight-bold">{t("OrderDetails.TotalLabel")}</td>
                                    <td className="font-weight-bold">{orderItems?.reduce((acc, item) => acc + +item.quantity, 0)}</td>
                                    <td className="font-weight-bold">{orderDetails?.currency}{orderItems?.reduce((acc, item) => acc + (+item.value * +item.quantity), 0)?.toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    }
                    {orderDetails?.batchTypeId === BatchType.ElectronicBulk && isFileUploadBulkEcode &&
                        <div className={bem.e("rows-container")}>

                            <ReactstrapDataTable
                                columnDefinitions={EcodeBulkFileUploadColumnDefinition}
                                items={distributors as DistributorDto[] ?? []}
                                totalItemCount={distributors?.length ?? 0}
                            />

                            <Modal className="preview-modal" style={{ maxWidth: "max-content" }} isOpen={isCodeSummaryModalOpen} toggle={closeCodeSummaryModal}>
                                <ModalHeader className="border-0" toggle={closeCodeSummaryModal}><span style={{ fontSize: "1.5rem" }}>{t("OrderingProcess.CodeSummaryTitle")}</span></ModalHeader>
                                <ModalBody>
                                    <ReactstrapDataTable
                                        columnDefinitions={CodeSummaryColumnDefinitions}
                                        items={codeSummaryRows! ?? []}
                                        totalItemCount={codeSummaryRows?.length || 0}
                                        hideSearch={true}
                                    />
                                </ModalBody>
                            </Modal>
                        </div>
                    }
                    {orderDetails?.batchTypeId === BatchType.ElectronicBatch &&
                        <div className={bem.e("rows-container")}>
                            <div className="d-flex align-content-center">
                                <div style={{ marginTop: "3px" }}>{t("OrderDetails.DeliveryMethodHeading")}</div>
                                {numberOfEmailOnlyCodes > 0 && <div className="ml-3"> x{numberOfEmailOnlyCodes}{" "}{getEmailElement()}{numberOfSmsOnlyCodes + numberOfEmailAndSmsCodes > 0 ? "," : ""}</div>}
                                {numberOfSmsOnlyCodes > 0 && <div className="ml-3"> x{numberOfSmsOnlyCodes}{" "}{getSmsElement()}{numberOfEmailAndSmsCodes > 0 ? "," : ""}</div>}
                                {numberOfEmailAndSmsCodes > 0 && <div className="ml-3"> x{numberOfEmailAndSmsCodes}{" "}{getEmailElement()}{" & "}{getSmsElement()} </div>}
                            </div>
                            <ReactstrapDataTable
                                columnDefinitions={columnECodeDefinitions}
                                items={fileUploadObject?.items as FileUploadECodeRowItem[] ?? []}
                                totalItemCount={fileUploadObject?.items.length ?? 0}
                            />
                            <Modal
                                isOpen={isChangeIssuanceDateModalOpen}
                                toggle={() => closeChangeIssuanceDateModal()}
                            >
                                <ModalHeader toggle={() => closeChangeIssuanceDateModal()}>
                                    {(t("NotificationMessage.ChangeIssuanceDateConfirmationTitle"))}
                                </ModalHeader>

                                <ModalBody>
                                    <Container>
                                        <Row>
                                            <Col md={12}>
                                                <p>{t("OrderDetails.IssuanceDateLabel")}</p>
                                                <input value={newIssuanceDateValue || ""}
                                                    onChange={handleNewIssuanceDateValue}
                                                    min={minLimit} max="9999-12-31"
                                                    className={"form-control" + (isNewIssuanceDateInvalid ? " is-invalid" : "")}
                                                    type="date" />
                                                <br />
                                                {currentRowIssuanceDateValueString != newSelectedIssuanceDateValueString &&
                                                    <p className="alert alert-warning" dangerouslySetInnerHTML={{__html:
                                                        t("OrderDetails.IssuanceDateChangeConfirmation")
                                                            .replace("[[fromDate]]", "<strong>" + currentRowIssuanceDateValueString + "</strong>")
                                                            .replace("[[toDate]]", "<strong>" + newSelectedIssuanceDateValueString + "</strong>")
                                                        } }>
                                                    </p>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </ModalBody>

                                <ModalFooter className="justify-content-between">
                                    <button
                                        className="btn btn-default pull-left"
                                        onClick={() => closeChangeIssuanceDateModal()}
                                    >{t("BackButton")}</button>

                                    <button
                                        className="btn btn-success pull-right"
                                        onClick={() => handleChangeCodeIssuanceDate()}
                                    >{t("ManageUsers.ConfirmBtnLbl")}</button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }
                    {orderDetails?.batchTypeId === BatchType.PrintReadyBatch &&
                        <div className={bem.e("rows-container")}>
                            {isOrderProcessed ? (
                                <>
                                    <ReactstrapDataTable
                                        columnDefinitions={columnPrintReadyDistributorDefinitions}
                                        items={distributors || []}
                                        totalItemCount={(distributors || []).length ?? 0}
                                    />
                                    <Modal className="preview-modal" style={{ maxWidth: "max-content" }} isOpen={isModalOpen} toggle={closePrintReadyDistributorModal}>
                                        <ModalHeader className="border-0" toggle={closePrintReadyDistributorModal}><span style={{ fontSize: "1.5rem" }}>{t("OrderingProcess.DistributorModalTitle")}</span></ModalHeader>
                                        <ModalBody>
                                            <ReactstrapDataTable
                                                columnDefinitions={columnPrintReadyDistributorRecipientDefinitions}
                                                fetchData={fetchPrintReadyDistributorRecipients}
                                                loading={loading}
                                                items={batchOrderDistributorRecipients?.items || []}
                                                totalItemCount={batchOrderDistributorRecipients?.itemCount || 0}
                                                sortByOptions={[]}
                                                hideSearch={true}
                                            />
                                        </ModalBody>
                                    </Modal>
                                </>
                            ) : (
                                <>
                                    <ReactstrapDataTable
                                        columnDefinitions={columnPrintReadyDistributorDefinitions}
                                        items={unprocessedPrintReadyDistributors || []}
                                        totalItemCount={unprocessedPrintReadyDistributors?.length || 0}
                                    />
                                    <Modal className="preview-modal" style={{ maxWidth: "max-content" }} isOpen={isModalOpen} toggle={closePrintReadyDistributorModal}>
                                        <ModalHeader className="border-0" toggle={closePrintReadyDistributorModal}><span style={{ fontSize: "1.5rem" }}>{t("OrderingProcess.DistributorModalTitle")}</span></ModalHeader>
                                        <ModalBody>
                                            <ReactstrapDataTable
                                                columnDefinitions={columnPrintReadyDistributorRecipientDefinitions}
                                                items={unprocessedPrintReadyRecipients || []}
                                                totalItemCount={unprocessedPrintReadyRecipients?.length || 0}
                                                hideSearch={true}
                                            />
                                        </ModalBody>
                                    </Modal>
                                </>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const ConnectedComponent = connect(null, mapDispatchToProps)(OrderDetailsPage);

export {
    ConnectedComponent as OrderDetailsPage
}

function notifySuccess(arg0: any) {
    throw new Error("Function not implemented.");
}
