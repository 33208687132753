import React from "react";
import { useTranslation } from "react-multi-lang";
import SelectLogo from 'Assets/Images/select-logo-blue.svg';


export function Footer() {
    const t = useTranslation();

    return (
        <div className="bg-white px-3 px-xl-5 py-4">
            <div className="container d-flex flex-column flex-sm-row align-items-center">

                <div className="pr-sm-5">
                    <img
                        width="140"
                        height="57"
                        alt="Select"
                        src={SelectLogo}
                    />
                </div>

                <div
                    className="ml-sm-5 pt-5 pt-sm-0 d-flex flex-column align-items-center flex-sm-row flex-grow"
                    style={{ fontSize: '0.9375rem' }}
                >
                    <a
                        target="_blank"
                        href={t("FooterLinks.PrivacyPolicyLink")}
                        className="text-uppercase pb-2 pb-sm-0 pl-sm-5 text-decoration-none"
                        style={{ color: 'rgb(33, 37, 41)' }}
                    >{t("FooterLinks.PrivacyPolicyLabel")}</a>

                    <span
                        className="text-uppercase pl-sm-3 pb-2 pb-sm-0"
                        id="teconsent"
                    ></span>
                </div>
            </div>
        </div>
    )
}