import React, { useEffect } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { CSVUploader } from "Components/CSVUploader";
import { connect } from 'react-redux';
import { bemNames } from 'Utilities';
import { ApplicationState } from "Store";
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import { UserDetails } from "../Auth";
import { FileUploadECodeRowItem } from 'Models/FileUploadECodeRowItem';
import { FileUploadPrintReadyRowItem } from 'Models/FileUploadPrintReadyRowItem';
import { FileUploadPhysicalRowItem } from 'Models/FileUploadPhysicalRowItem';
import { DigitalOrderDetails, FileUploadObject, BaseVoucherType, CodeDeliveryMethod } from "../Models";
import { BsDownload } from "react-icons/bs";
import { useTranslation } from "react-multi-lang";
import { Card, CardBody } from "reactstrap";
import { DeliveryMethod } from "Store/Reducers/DigitalOrderReducer";

interface StoreProps {
    setCurrentStep: typeof OrderReducer.actions.setCurrentStep;
    setOrder: typeof OrderReducer.actions.setOrder;
    order: DigitalOrderDetails;
    setDistributorDetails: typeof OrderReducer.actions.setDistributorDetails;
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep;
    attemptGoToStepNumber?: number,
    user?: UserDetails;
    fileUploadList?: FileUploadObject<FileUploadECodeRowItem | FileUploadPrintReadyRowItem | FileUploadPhysicalRowItem>;
}

const mapStateToProps = ({ digitalOrder, user: userState }: ApplicationState) => ({
    order: digitalOrder.order,
    attemptGoToStepNumber: digitalOrder.attemptGoToStepNumber,
    user: userState.user
})

const mapDispatchToProps = {
    setCurrentStep: OrderReducer.actions.setCurrentStep,
    setOrder: OrderReducer.actions.setOrder,
    setDistributorDetails: OrderReducer.actions.setDistributorDetails,
    attemptGoToStep: OrderReducer.actions.attemptGoToStep
}

interface Props extends StoreProps, Partial<StepWizardChildProps> {
    codeType?: BaseVoucherType,
    requireStaffNumberOnUpload?: boolean,
    deliveryMethod?: OrderReducer.DeliveryMethod,
    codeDeliveryMethod?: CodeDeliveryMethod,
    countryIso2?: string
}


const FileUpload = ({
    setCurrentStep,
    isActive,
    attemptGoToStepNumber,
    goToStep,
    codeType,
    requireStaffNumberOnUpload,
    deliveryMethod,
    codeDeliveryMethod,
    countryIso2
}: Props) => {

    const bem = bemNames.create("digital-order-wizard");
    const t = useTranslation();

    useEffect(() => {
        if (attemptGoToStepNumber === 4) {
            goToStep?.(4);
        }
    }, [attemptGoToStepNumber])

    if (isActive) {
        setCurrentStep(3);
    }


    return (
        <Card className="w-100">
            <CardBody>
                <div className={bem.e("form-entry d-block w-100")}>
                    <h2>{t("OrderingProcess.DirectOrders")}</h2>
                    <div className="mt-4">
                        {t("OrderingProcess.FileUploadChooseFile") != "OrderingProcess.FileUploadChooseFile" && <h3>{t("OrderingProcess.FileUploadChooseFile")}</h3>}                        
                        <p>{t("OrderingProcess.FileUploadInfoText").replace("[[AdditionalInfoSign]]", "*").replace("[[NumberOfRecipients]]", "XXX")}</p>
                        <CSVUploader codeType={codeType} requireStaffNumberOnUpload={requireStaffNumberOnUpload} isActive={isActive!} deliveryMethod={deliveryMethod} codeDeliveryMethod={codeDeliveryMethod} countryIso2={countryIso2} />
                        <div className="alert alert-secondary mt-4 text-center text-md-left d-flex align-items-center" role="alert">
                            * {t("OrderingProcess.FileUploadAdditionalInfoText")}

                            {(codeType == BaseVoucherType.ECode || codeType == BaseVoucherType.PrintReady) &&
                                <a
                                    color="primary"
                                    className={`text-uppercase btn btn-link ml-md-4 p-0 d-block d-md-inline mt-2 mt-md-0 ml-0 ${isActive ? "" : "disabled"}`}
                                    href={codeType == BaseVoucherType.ECode
                                    ? deliveryMethod === DeliveryMethod.Form ? t("OrderingProcess.eCodeBulkFileTemplateLocation") : t("OrderingProcess.eCodeFileTemplateLocation") 
                                    : t("OrderingProcess.printReadyFileTemplateLocation")}
                                    download
                                    tabIndex={isActive ? 0 : -1}
                                >
                                    <BsDownload className="mx-2 mb-1" size="24px" />{t("Download")}</a>
                            }
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(FileUpload);

export {
    ConnectedComponent as FileUploadStep
}