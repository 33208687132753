/**
 * Attempt to get value from storage
 */
export function getValueFromStorage(key: string): string|undefined|null {
    if (!hasStorage()) return null;
    try {
        return sessionStorage.getItem(key);
    } catch (e: any) {
        console.error(e?.message || 'Unable to get from session storage.');
        return undefined;
    }
}

/**
 * Attempt to save value to storage
 */
export function saveValueToStorage(key: string, value: string): boolean {
    if (!hasStorage()) return false;
    try {
        sessionStorage.setItem(key, value);
        return true;
    } catch (e: any) {
        console.error(e?.message || 'Unable to save to session storage.');
        return false;
    }
}

/**
 * Attempt to remove value to storage
 */
export function removeValueFromStorage(key: string): boolean {
    if (!hasStorage()) return false;
    try {
        sessionStorage.removeItem(key);
        return true;
    } catch (e: any) {
        console.error(e?.message || 'Unable to save to session storage.');
        return false;
    }
}

/**
 * Whether user has browser storage to use
 */
export function hasStorage(): boolean {
    return typeof Storage !== void (0);
}