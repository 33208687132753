import React, { CSSProperties, useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { getArkosePublicKey, getErrorNotification, getSuccessNotification, isArkoseEnabled } from 'Utilities';
import { AppSetting, Arkose, ContactUsDetails, Unit, SelectedLanguageDetails } from '../Models';
import { useTranslation } from "react-multi-lang";
import { AreaLoader, InputWithValidations } from "../Components";
import { editUserService } from "../Services";
import Notifications from 'react-notification-system-redux';
import { Button, Col, Container, Label, Row } from "reactstrap";
import Select from "react-select";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";
import * as UserReducer from 'Store/Reducers/UserReducer';
import { ApplicationState } from "../Store";

interface StoreProps {
    notifySuccess: Notifications.NotificationShow;
    notifyError: Notifications.NotificationShow;
    loadUser: typeof UserReducer.actions.loadUser;
    loggedIn?: boolean;
    appSettings: AppSetting[];
    selectedLanguage?: SelectedLanguageDetails;
};

const mapStateToProps = ({ auth, user: userState, app }: ApplicationState) => {

    return {
        loggedIn: auth?.loggedIn ?? {},
        user: userState?.user ?? {},
        appSettings: app?.appSettings ?? [],
        selectedLanguage: app?.selectedLanguage
    };
}
const mapDispatchToProps = {
    notifyError: Notifications.error,
    notifySuccess: Notifications.success,
    loadUser: UserReducer.actions.loadUser
};

const ContactUsPage = ({
    notifyError,
    notifySuccess,
    loggedIn,
    appSettings,
    selectedLanguage
}: StoreProps) => {

    const t = useTranslation();
    const [submitting, setSubmitting] = useState(false);
    const [cuArkose, setCuArkose] = useState<Arkose>();

    const formRef = useRef<FormikProps<{
        name: string,
        emailAddress: string,
        companyName: string,
        comments: string,
        orderid: string,
        project: Unit[],
        topic: Unit[]
        projectField: string,
    }> | null>(null);

    const topicSelect = useRef<Select>(null);

    const [loading, setLoading] = useState(true);
    const [topicDropdownItems, setTopicDropdownItems] = useState<any[]>();
    const [topicTypeSelect, setTopicTypeSelect] = useState<any>();
    const [projectItems, setProjectItems] = useState<any[]>();
    const [projectSelect, setProjectSelect] = useState<any>();
    const [isTopicSelected, setIsTopicSelected] = useState(false);
    const [contactDetail, setContactDetail] = useState<ContactUsDetails>();

    async function setupEnforcement(enforcement: Arkose) {
        setCuArkose(enforcement);
    }

    const ARKOSE_LABS_API = 'arkose-labs-api'
    const ARKOSE_LABS_DATA_CALLBACK = 'setupEnforcement';
    const ARKOSE_LABS_HOSTNAME = 'bhn-api.arkoselabs.com';
    const ARKOSE_LABS_API_SCRIPT_NAME = 'api.js';
    const ARKOSE_LABS_API_VERSION = 'v2';

    function generateArkoseLabsScriptSrc(publicKey: string, version: string) {
        return `https://${ARKOSE_LABS_HOSTNAME}/${version}/${publicKey}/${ARKOSE_LABS_API_SCRIPT_NAME}`;
    }

    function loadArkoseScript(publicKey: string) {

        var scriptElement = document.querySelector(`#${ARKOSE_LABS_API}`);
        if (scriptElement) {
            scriptElement.remove()
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = generateArkoseLabsScriptSrc(publicKey, ARKOSE_LABS_API_VERSION);
        script.setAttribute('data-callback', ARKOSE_LABS_DATA_CALLBACK);
        script.async = true;
        script.defer = true;
        script.id = ARKOSE_LABS_API;

        document.head.append(script);
    }


    let arkoseRunning = false;
    async function setupAndRunArkose() {
        if (!loggedIn && isArkoseEnabled(appSettings)) {
            cuArkose?.setConfig({
                onHide: function () {
                    setSubmitting(false);
                },
                onCompleted:
                    async (t: any) => {
                        if (!arkoseRunning) {
                            arkoseRunning = true;
                            await arkoseCompletedCallback(t.token);
                        }
                    },
                onReady: function () {
                    cuArkose?.run();
                }
            });
        }
    }

    useEffect(() => {
        LoadCurrentUserDetails();
    }, []);

    useEffect(() => {
        LoadCurrentUserDetails();
        if (topicTypeSelect) {
            topicTypeSelect.select.clearValue();
        }
       
    }, [selectedLanguage]);


    useEffect(() => {
        if (!loggedIn && appSettings && isArkoseEnabled(appSettings)) {
            (window as any).setupEnforcement = setupEnforcement;
            loadArkoseScript(getArkosePublicKey(appSettings));
        }
    }, []);

    async function arkoseCompletedCallback(token: string) {

        if (token && token.length > 0 && formRef.current) {

            const values = formRef.current.values;

            try {
                if (values) {

                    let project = null;

                    if (loggedIn) {
                        project = values?.project;
                    } else {
                        project = [{ id: 0, description: values?.projectField } as unknown as Unit];
                    }

                    let errorMessage = await editUserService.submitContactFormSubmission({
                        ...contactDetail,
                        name: values?.name,
                        comments: values?.comments,
                        companyName: values?.companyName,
                        emailAddress: values?.emailAddress,
                        orderId: values?.orderid == '' ? 0 : +(values.orderid),
                        topics: values?.topic,
                        projects: project,
                        arkoseToken: token,
                        isArkoseEnabled: !!(token && token.length > 0)
                    });

                    if (!errorMessage) {
                        notifySuccess(getSuccessNotification(t("ContactUs.ContactFromSubmittedLabel")));

                        await formRef.current?.resetForm();
                        setProjectSelect(undefined);
                        setTopicTypeSelect(undefined);
                    }
                    else if (errorMessage === 'ValidationError.DefaultCountryRiskErrorMessage') {
                        notifyError(getErrorNotification(t(errorMessage)));
                    } else {
                        notifyError(getErrorNotification(t("NotificationMessage.ContactUsFormSubmitFailure")));
                    }                    
                }
            }
            catch (e) {
                console.error(e);
                notifyError(getErrorNotification(t("NotificationMessage.ContactUsFormSubmitFailure")));
            }

            setSubmitting(false);
        }

        arkoseRunning = false;
    }

    const LoadCurrentUserDetails = async () => {

        setLoading(true);
        try {
            const data = await editUserService.getContactUsDetail(selectedLanguage!.languageCode);
            const detail = new ContactUsDetails();
            detail.name = data.name;
            detail.emailAddress = data.emailAddress;
            detail.companyName = data.companyName;

            setTopicDropdownItems(data.topics);
            setProjectItems(data.projects);
            setContactDetail(detail);
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadDownloadDetailsFailure")));
        }

        setLoading(false);
    }

    const ContactUsValidationSchema = Yup.object().shape({
        emailAddress: Yup.string()
            .email(t("ValidationError.InvalidEmailAddressMessage"))
            .required(t("ValidationError.EmailAddressRequiredMessage"))
            .max(200, t("ValidationError.ContactUsFormEmailMaxChars")),
        
        name: Yup.string()
            .required(t("ValidationError.NameRequired"))
            .max(200, t("ValidationError.ContactUsFormNameMaxChars")),
        
        companyName: Yup.string()
            .max(100, t("ValidationError.ContactUsFormCompanyMaxChars")),

        topic: Yup.array()
            .min(1, t("ValidationError.TopicRequired"))
            .required(t("ValidationError.TopicRequired")),
        comments: Yup.string()
            .required(t("ValidationError.CommentsRequired"))
            .max(4000, t("ValidationError.ContactUsFormCommentsMaxChars")),

        orderid: Yup.string()
            .max(30, t("ValidationError.ContactUsFormOrderIdMaxChars")),
        projectField: Yup.string()
            .max(30, t("ValidationError.ContactUsFormProjectIdMaxChars")),
        
    });

    const RequiredFieldStyle: CSSProperties = {

        color: '#dc3545',
        fontSize: '80%',
        marginTop: '0.25 rem',
    };

    return (
        <div>
            <Container>
                <AreaLoader show={loading} message={t("ContactUs.LoadingDetailsMessage")} />

                <div className="d-flex flex-column flex-md-row w-100">

                    <div className="pr-md-2 pt-2 mr-md-2 pb-2 pb-md-0">
                        <h1>{t("ContactUs.ContactUsTitleLabel")}</h1>
                        <p>{t("ContactUs.ContactUsSubTitleLabel")}</p>
                        <p>{t("ContactUs.ContactUsDescriptionLabel")}</p>
                    </div>

                    <div className="card flex-grow w-100">
                        <div className="card-body">
                            <div className="px-2 py-3">
                                <Formik
                                    innerRef={formRef}
                                    enableReinitialize={true}
                                    initialValues={{
                                        name: contactDetail?.name || '',
                                        emailAddress: contactDetail?.emailAddress || '',
                                        companyName: contactDetail?.companyName || '',
                                        comments: contactDetail?.comments || '',
                                        orderid: '',
                                        project: [],
                                        topic: [],
                                        projectField: ''
                                    }}
                                    validationSchema={ContactUsValidationSchema}
                                    onSubmit={async (values, actions) => {
                                        setSubmitting(true);
                                        if (!loggedIn && isArkoseEnabled(appSettings)) {
                                            await setupAndRunArkose();
                                        } else {
                                            await arkoseCompletedCallback("PlaceholderToken");
                                        }
                                    }}
                                >

                                    {({ errors, setFieldValue }) => (
                                        <Form className="d-flex flex-column">

                                            <AreaLoader show={submitting} message={t("ContactUs.ContactSubmittingDetailsLabel")} />

                                            <Row>
                                                <Col className="mb-3">
                                                    <div className="d-flex justify-content-start">
                                                        <Label id="lblRequired-contactus" className="text-uppercase" style={RequiredFieldStyle}>{t("RequiredLabel")} <span >*</span></Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" md="6" className="mb-3">
                                                    <Label for="first-name-contactus" className="text-uppercase">{t("OrderDetails.NameLabel")} <span style={RequiredFieldStyle}>*</span></Label>
                                                    <Field id="first-name-contactus" name="name" component={InputWithValidations} />
                                                </Col>
                                                <Col xs="12" md="6" className="mb-3">
                                                    <Label for="email-contactus" className="text-uppercase">{t("OrderDetails.EmailAddressLabel")} <span style={RequiredFieldStyle}>*</span></Label>
                                                    <Field id="email-contactus" name="emailAddress" component={InputWithValidations} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs="12" md="6" className="mb-3">
                                                    <Label for="company-name-contactus" className="text-uppercase">{t("ProjectDetails.CompanyName")}</Label>
                                                    <Field id="company-name-contactus" name="companyName" component={InputWithValidations} />
                                                </Col>
                                                <Col xs="12" md="6" className="mb-3">
                                                    {loggedIn &&
                                                        <>
                                                            <Label for="project-type-select" className="text-uppercase">{t("UserManagement.ProjectDropdownPlaceholder")}</Label>
                                                            <Select
                                                                id="project-type-select"
                                                                name="project"
                                                                ref={(ref) => setProjectSelect(ref)}
                                                                options={projectItems}
                                                                getOptionLabel={option => option.description + " (" + option.id + ")"}
                                                                getOptionValue={option => option.id}
                                                                placeholder={t("ContactUs.SelectProjectPlaceholder")}

                                                                onChange={
                                                                    (selectedItem: Unit) => {
                                                                        if (selectedItem) {
                                                                            setFieldValue("project", [selectedItem]);
                                                                        }
                                                                    }}
                                                            />
                                                        </>
                                                    }
                                                    {!loggedIn &&
                                                        <><Label for="project-field" className="text-uppercase">{t("ProjectDetails.ProjectId")}</Label>
                                                            <Field id="project-field" name="projectField" component={InputWithValidations} /></>
                                                    }
                                                </Col>
                                            </Row>

                                            <Row>

                                                <Col xs="12" md="6" className="mb-3">
                                                    <Label for="orderid-contactus" className="text-uppercase" >{t("OrderDetails.OrderIdLabel")}</Label>
                                                    <Field id="orderid-contactus" name="orderid" component={InputWithValidations} />
                                                </Col>

                                                <Col xs="12" md="6" className="mb-3">
                                                    <Label for="topic-type-select" className="text-uppercase">{t("ContactUs.TopicLabel")} <span style={RequiredFieldStyle}>*</span></Label>
                                                    <Select
                                                        id="topic-type-select"
                                                        ref={(ref) => setTopicTypeSelect(ref)}
                                                        name="topic"
                                                        options={topicDropdownItems}
                                                        getOptionLabel={option => option.description}
                                                        getOptionValue={option => option.id}
                                                        placeholder={t("ContactUs.SelectTopicPlaceholder")}                                                        
                                                        onChange={
                                                            (selectedItem: Unit) => {
                                                                if (selectedItem) {

                                                                    setFieldValue("topic", [selectedItem]);
                                                                    setIsTopicSelected(true);
                                                                }
                                                            }} />
                                                    {errors.topic && !isTopicSelected && <div style={RequiredFieldStyle}>{errors.topic}</div>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" className="mb-3">
                                                    <Label for="comments-contactus" className="text-uppercase">{t("OrderDetails.CommentsLabel")} <span style={RequiredFieldStyle}>*</span></Label>
                                                    <Field id="comments-contactus" name="comments" type="textarea" component={InputWithValidations} />
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button
                                                        color="primary"
                                                        className="align-self-center mt-2"
                                                        type="submit"
                                                        style={{ maxWidth: "150px" }}
                                                        disabled={submitting}
                                                    >{t("Login.SubmitButton")}</Button>
                                                </Col>
                                            </Row>

                                        </Form>
                                    )}

                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

        </div>
    );

}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);

export {
    ConnectedComponent as ContactUsPage
}
