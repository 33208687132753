import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from 'Store';

export const persistConfig = {
    key: "root-state",
    storage,
    whitelist: ["auth", "localization"]
};

export let store: Store;

export function configureStore(history: History, initialState?: ApplicationState) {

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers as any,
        router: connectRouter(history),
        user: reducers.user
    });

    const enhancers = [];

    const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any

    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer);
    // Create persisted reducers using redux-persist

    store = createStore(
        persistedReducer,
        initialState  as any,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    const persistor = persistStore(store as any);

    return {
        store,
        persistor
    };
}