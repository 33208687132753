export enum RedemptionStatus {
    Unallocated = 1,
    Activated = 2,
    Redeemed = 3,
    Expired = 4,
    VoucherCancelled = 5,
    ClosedExpired = 6,
    ClosedProgramme = 7,
    RedemptionCancelled = 8
}
