import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { FiGlobe } from "react-icons/fi";
import { bemNames, getErrorNotification } from 'Utilities';
import { ApplicationState } from "Store";
import { LanguageDto, SelectedLanguageDetails } from "Models";
import * as AppReducer from "Store/Reducers/AppReducer";
import { AreaLoader } from "Components";
import { userService } from "../Services";
import { useTranslation } from 'react-multi-lang'
import Notifications from 'react-notification-system-redux';

interface StoreProps {
    setLocalization: typeof AppReducer.actions.setLocalization;
    language?: LanguageDto[];
    selectedLanguage?: SelectedLanguageDetails;
    notifyError: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    setLocalization: AppReducer.actions.setLocalization,
    notifyError: Notifications.error
}

function mapStateToProps({ app }: ApplicationState) {

    return {
        language: app?.language,
        selectedLanguage: app?.selectedLanguage
    };
}

function LocalizationSelector({
    setLocalization,
    language,
    selectedLanguage,
    notifyError
}: StoreProps) {

    const bem = bemNames.create("localization-selector");

    useEffect(() => {
        if (selectedLanguage) {
            (async () => {
                await updateUserLanguage();
            })();
        }
    }, [selectedLanguage])

    const t = useTranslation();

    const [isBusy, setIsBusy] = useState(false);


    const updateUserLanguage = async () => {
        setIsBusy(true);
        try {
            if (selectedLanguage && selectedLanguage.languageCode) {
                await userService.updateUserLanguage(selectedLanguage.languageCode);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsBusy(false);
        }
    }

    const onLocalizationClicked = async (language: LanguageDto) => {
        setLocalization({
            name: language.name,
            languageCode: language.languageCode,
            imageUrl: language.imageUrl
        });
    }

    if (!language || language.length <= 1) {
        return <></>;
    }

    return (
        <>
            <AreaLoader show={isBusy} message={t("Localization.UpdatingUserLanguageLabel")} />

            <UncontrolledDropdown
                className={bem.b()}
                direction="left"
            >
                <DropdownToggle nav className="pl-0 pr-0">
                    {selectedLanguage
                        ? <div className="d-flex">
                            <img
                                title={selectedLanguage.name}
                                className={bem.e("flag-icon", "mr-2")}
                                src={`/Images/Flags/${selectedLanguage.imageUrl}`}
                            />
                            <div className={bem.e("iso", "pt-1")}>
                                {selectedLanguage.languageCode}
                            </div>
                        </div>
                        : <FiGlobe
                            size={30}
                            color="#dd2e68"
                        />
                    }
                </DropdownToggle>
                <DropdownMenu>
                    {(language && language.length > 0) &&
                        language.map(c =>
                            c.isEnabled &&
                            <DropdownItem
                                onClick={() => onLocalizationClicked(c)}
                                key={language.indexOf(c)}
                            >
                                <img title={c.name} className={bem.e("flag-icon", "mr-2")} src={`/Images/Flags/${c.imageUrl}`} />{c.name}
                            </DropdownItem>
                        )
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(LocalizationSelector);

export {
    ConnectedComponent as LocalizationSelector
}
