import axios from "axios";
import { getAuthHeader } from ".";
import { EmailMessageLogDto, ItemStatusLogDto, ItemGroupStatusLogDto, ScmOrderSearchDetailsDto, RedemptionSearchResultDetails, IssuanceSearchResultDetails, IssuanceDetailsDto } from "../Models";


export const customerServicesService = {
    searchRedemptions,
    searchIssuances,
    getScmOrderDetails,
    getItemEmailLog,
    getItemStatus,
    getGroupStatus,
    resendEmailMessage,
    getIssuanceDetails,
    putCodeOnHold,
    removeCodeOnHold,
    updateCodeIssuanceDate,
    resendCodeEmail,
    resendCodeSms,
    getClientCountryCodes
}

async function searchRedemptions({ emailAddress, orderId, tenantIds }: { emailAddress?: string, orderId?: number, tenantIds?: number[] }) {

    const resp = await axios.post<RedemptionSearchResultDetails[]>("api/CustomerServices/SearchRedemptions", {
        emailAddress,
        orderId,
        tenantIds
    },
        {
            headers: getAuthHeader()
        }
    );

    return resp.data;
}

async function searchIssuances({ emailAddress, lastName, code, staffNumber, mobileNumber, tenantIds }: { emailAddress?: string, lastName?: string, code?: string, staffNumber?: string, mobileNumber?: string, tenantIds?: number[] }) {

    const resp = await axios.post<IssuanceSearchResultDetails[]>("api/CustomerServices/SearchIssuances", {
        emailAddress,
        lastName,
        code,
        staffNumber,
        mobileNumber,
        tenantIds
    },
        {
            headers: getAuthHeader()
        }
    );

    return resp.data;
}

async function getScmOrderDetails(scmOrderId?: number) {

    const resp = await axios.get<ScmOrderSearchDetailsDto>(`api/CustomerServices/GetScmOrderDetails/${scmOrderId}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function getIssuanceDetails(redemptionCodeId: number) {

    const resp = await axios.get<IssuanceDetailsDto>(`api/CustomerServices/GetIssuanceDetails/${redemptionCodeId}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function getItemEmailLog(scmOrderItemId: number) {

    const resp = await axios.get<EmailMessageLogDto[]>(`api/CustomerServices/GetItemEmailLog/${scmOrderItemId}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function resendEmailMessage(emailMessageId: number, scmOrderEGiftId: number, newEmailAddress: string) {

    const resp = await axios.post<EmailMessageLogDto[]>(`api/CustomerServices/ResendEmailMessage/${scmOrderEGiftId}`, {
        emailMessageId: emailMessageId,
        newEmailAddress: newEmailAddress
    },
        {
            headers: getAuthHeader()
        });
}

async function putCodeOnHold(redemptionCodeId: number) {

    const resp = await axios.put(`api/CustomerServices/PutCodeOnHold/${redemptionCodeId}`,
        {},
        {
            headers: getAuthHeader()
        });
}

async function removeCodeOnHold(redemptionCodeId: number) {

    const resp = await axios.put(`api/CustomerServices/RemoveCodeOnHold/${redemptionCodeId}`, 
        {},
        {
            headers: getAuthHeader()
        });
}

async function updateCodeIssuanceDate(projectId: number, redemptionCodeId: number, date: Date) {

    return await axios.post<string>(`api/CustomerServices/UpdateCodeIssuanceDate/${projectId}/${redemptionCodeId}`, {
        date
    },
        {
            headers: getAuthHeader()
        });
}

async function resendCodeEmail(redemptionCodeId: number, emailAddress?: string, updateOriginalOrder?: boolean) {

    return await axios.post<string>(`api/CustomerServices/ResendCodeEmail/${redemptionCodeId}`, {
        emailAddress: emailAddress,
        updateOriginalOrder: updateOriginalOrder
    },
    {
        headers: getAuthHeader()
    });
}

async function resendCodeSms(redemptionCodeId: number, mobileNumber?: string, updateOriginalOrder?: boolean) {

    return await axios.post<string>(`api/CustomerServices/ResendCodeSms/${redemptionCodeId}`, {
        mobileNumber: mobileNumber,
        updateOriginalOrder: updateOriginalOrder
    },
        {
            headers: getAuthHeader()
        });
}

async function getItemStatus(scmOrderId: number, scmOrderItemId: number) {

    const resp = await axios.get<ItemStatusLogDto[]>(`api/CustomerServices/GetItemStatus/${scmOrderId}/${scmOrderItemId}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function getGroupStatus(scmOrderId: number) {

    let resp = await axios.get<ItemGroupStatusLogDto>(`api/CustomerServices/GetGroupStatus/${scmOrderId}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function getClientCountryCodes() {

    const resp = await axios.get<string[]>(`api/CustomerServices/GetClientCountryCodes`, {
        headers: getAuthHeader()
    });

    return resp.data;
}