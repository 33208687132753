import React, { forwardRef, MutableRefObject, useEffect, useState } from "react";
import {
    useTable, useSortBy, useGlobalFilter, useFilters, usePagination, useAsyncDebounce,
    Column, TableInstance, UseGlobalFiltersInstanceProps, UsePaginationInstanceProps,
    UseTableOptions, UsePaginationOptions, TableState, UsePaginationState, UseSortByState,
    UseGlobalFiltersState, UseSortByColumnProps, HeaderGroup, UseFiltersColumnProps,
    UseFiltersColumnOptions, UseFiltersOptions, UseFiltersState, ColumnInterface,
    useRowSelect, UseRowSelectOptions, UseRowSelectState, Cell, TableToggleCommonProps,
    UseRowSelectInstanceProps, UseTableColumnProps, UseSortByInstanceProps, IdType, UseFiltersInstanceProps, Row
} from "react-table";

// Utils
import { AreaLoader, MultiSelectDropdown } from "Components";
import { Input, Label, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { bemNames } from "../Utilities";
import Select, { components, ContainerProps, GroupTypeBase } from "react-select";
import { useTranslation } from "react-multi-lang";
import classNames from "classnames";

// Extend/compose react-table interfaces
interface MyHeaderGroup<D extends object = {}>
    extends HeaderGroup<D>,
    UseSortByColumnProps<D>,
    UseFiltersColumnProps<D> { }

interface MyTableOptions<D
    extends object>
    extends UseTableOptions<D>,
    UsePaginationOptions<D>,
    UseFiltersOptions<D>,
    UseRowSelectOptions<D> { }

interface MyTableInstance<D extends object = {}>
    extends TableInstance<D>,
    UseGlobalFiltersInstanceProps<D>,
    UsePaginationInstanceProps<D>,
    UseSortByInstanceProps<D>,
    UseFiltersInstanceProps<D>,
    UseRowSelectInstanceProps<D> { state: MyTableState<D> }

interface MyTableState<D extends object = {}>
    extends TableState<D>,
    UsePaginationState<D>,
    UseFiltersState<D>,
    UseSortByState<D>,
    UseGlobalFiltersState<D>,
    UseRowSelectState<D> { sortBy: SortByOptions[] }

export type FilteredColumn<D extends object = {}> = Column<D> | Column<D> & UseFiltersColumnOptions<D>


export interface SortByOptions {

    /* The name of the field the sorting has been applied to */
    id: string,

    /* Sorting direction */
    desc: boolean
}

export interface FilterByOptions {

    /* The name of the field the filtering has been applied to */
    id: string,

    /* Filtering value*/
    value: string
}

interface SelectOption<D> {
    label: string;
    value: D;
}

enum Order {
    Ascending,
    Descending
}

// Define a default UI for global filtering
function GlobalFilter({
    id,
    setGlobalFilter
}: {
    id: string,
    setGlobalFilter: (filterValue: any) => void
}
) {
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined) // Set undefined to remove the filter entirely
    }, 300)

    return (
        <Input
            id={id}
            onChange={e => {

                onChange(e.target.value);
            }}
            type="search"
        />
    )
}

type FilterComponentProps = { column: UseFiltersColumnProps<any> & ColumnInterface<any> }

// Define a default UI for column filtering
export const DefaultColumnFilter = ({
    column: {
        filterValue,
        setFilter,
        preFilteredRows: { length },
    },
}: FilterComponentProps) => {

    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined); // Set undefined to remove the filter entirely
    }, 300)

    return (
        <Input
            className="form-control input-sm"
            value={filterValue || ""}
            onChange={e => {

                onChange(e.target.value);
            }}
            type="search"
        />
    )
}

// Define a default UI for row selection

export const RowSelectionInput = forwardRef(
    ({ indeterminate, ...rest }: TableToggleCommonProps, ref) => {

        const defaultRef = React.useRef()
        const resolvedRef = (ref || defaultRef) as MutableRefObject<any>;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input
                    ref={resolvedRef}
                    className="form-control input-sm"
                    type="checkbox"
                    {...rest}
                />
            </>
        )
    }
)

export const SelectColumnFilter = ({
    column: {
        filterValue,
        setFilter,
        preFilteredRows,
        id
    },
}: FilterComponentProps) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set<any>()
        let idn = id ?? 0;
        preFilteredRows.forEach((row: any) => {
            options.add(row.values[idn])
        })
        return [...Array.from(options.values())]
    }, [id, preFilteredRows])


    // Render a select box
    return (
        <Input
            type="select"
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </Input>

    )
}

function Paging({ pages, gotoPage, pageIndex }: {
    pages: Array<number>, gotoPage: (page: number) =>
        void, pageIndex: number
}) {

    const firstPage = pages[0];
    const lastPage = pages[pages.length - 1];

    const Item = (pageNo: number) =>
        <PaginationItem
            key={pageNo}
            active={pageIndex === pageNo}
        >
            <PaginationLink
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    gotoPage(pageNo);
                }}
            >
                {pageNo + 1}
            </PaginationLink>
        </PaginationItem>;

    const Dots = (key: string = "dots") => <PaginationItem key={key}>
        <PaginationLink
            href="#"
            tabIndex={0}
            onClick={e => e.preventDefault()}
        >
            ...
        </PaginationLink>
    </PaginationItem>;

    const MiddleItems = (pages: Array<number>, pageIndex: number) => {

        if (pageIndex < 4) {
            return [
                Item(pages[1]),
                Item(pages[2]),
                Item(pages[3]),
                Item(pages[4]),
                Dots()
            ]
        } else if (pageIndex > pages.length - 5) {
            return [
                Dots(),
                Item(pages[pages.length - 5]),
                Item(pages[pages.length - 4]),
                Item(pages[pages.length - 3]),
                Item(pages[pages.length - 2]),
            ]
        } else {
            return [
                Dots("firstDots"),
                Item(pages[pageIndex - 1]),
                Item(pages[pageIndex]),
                Item(pages[pageIndex + 1]),
                Dots("lastDots")
            ]
        }
    }

    return (
        <>
            {pages.length <= 7 ?
                (
                    // 7 or less pages
                    pages.map(pageNo => Item(pageNo))

                ) : (
                    // more than 7 pages
                    [
                        Item(firstPage),
                        MiddleItems(pages, pageIndex),
                        Item(lastPage)
                    ]
                )

            }
        </>
    );
}

type FilterColumnType = UseFiltersColumnProps<any> & UseFiltersColumnOptions<any> & UseTableColumnProps<any>

const Filter = ({ column }: { column: FilterColumnType }) => {

    return (
        <>
            {column.canFilter && column.Filter ?

                <div className="filterWrap">
                    <ul>
                        <li>
                            <i className="fas fa-filter"></i>
                            <ul>
                                <li>
                                    <div className="filter"
                                        onClick={e => {

                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        {column.render("Filter")}
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>


                : <></>
            }
        </>
    )
}

interface SortByOption<D> {
    sortByColumnId: keyof D;
    sortByAscText: string;
    sortByDescText: string;
}

interface SortByOptionDefault<D> {
    sortByColumnId: keyof D;
    sortByText: string;
    isAsc: boolean;
}

type ColumnFilters<D> = {
    [key in keyof D]?: ColumnFilter;
}

type ColumnFilter<IsMultiple = boolean> = IsMultiple extends true ? {
    label: string;
    selectedMessage?: string;
    options: { label: string; value: string; }[];
    value: string[];
    onChange?: (value: string[]) => void;
    isMultiple: IsMultiple;
} : {
    label: string;
    selectedMessage?: string;
    options: { label: string; value: string; } [];
    value: string;
    onChange ?: (value: string) => void;
    isMultiple: IsMultiple;
}

interface Props<D extends object = {}> {
    resultsElId?: string;
    columnDefinitions: Array<Column<D> | FilteredColumn<D>>;
    items: D[];
    useRowSelection?: boolean;
    onSelectedRowsChanged?: (selectedRows: D[]) => void;
    fetchData?: (pageIndex: number, pageSize: number, sortBy: SortByOptions[], searchTerm: string, filterBy: FilterByOptions[]) => void;
    loading?: boolean;
    totalItemCount: number;
    showHeaders?: boolean;
    defaultSortByColumnIndex?: number;
    debug?: boolean;
    sortByOptions?: SortByOption<D>[];
    sortByDefaultOption?: SortByOptionDefault<D>;
    columnFilters?: ColumnFilters<D>;
    hiddenColumns?: (keyof D)[];
    showSearchBar?: boolean;
    disableStyling?: boolean;
}

const customCaseInsensitive = (prev: Row, curr: Row, columnId: string) => {
    const valueA = prev.values[columnId]?.toString().toLowerCase();
    const valueB = curr.values[columnId]?.toString().toLowerCase();
    return (valueA > valueB)
        ? 1
        : (
            (valueA < valueB)
                ? -1
                : 0
        );
}


export function DataTable<D extends object = {}>({
    resultsElId,
    columnDefinitions,
    useRowSelection,
    onSelectedRowsChanged,
    items,
    fetchData,
    loading,
    totalItemCount = 0,
    showHeaders = false,
    defaultSortByColumnIndex = undefined,
    debug = false,
    sortByOptions = undefined,
    sortByDefaultOption = undefined,
    columnFilters,
    hiddenColumns = undefined,
    showSearchBar = true,
    disableStyling = false
}: Props<D>) {

    const bem = bemNames.create("data-table")

    const [controlledPageCount, setControlledPageCount] = useState(0);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,

        rows,
        page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
        setGlobalFilter,

        // Paging props
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        initialState,
        // row selection        
        selectedFlatRows, // the actual selected objects
        setSortBy,
        toggleSortBy,
        setFilter
    }: MyTableInstance<D> = useTable({
        columns: columnDefinitions,
        data: items,
        defaultColumn: { Filter: undefined },
        initialState: ({
            pageIndex: 0,
            selectedRowIds: {},
            sortBy: (defaultSortByColumnIndex !== undefined
                ? [
                    {
                        id: columnDefinitions[defaultSortByColumnIndex].id,
                        desc: false
                    }
                ]
                : []),
            hiddenColumns: hiddenColumns

        } as MyTableState<D>),
        manualPagination: !!fetchData, // Tell the usePagination hook that we'll handle our own data fetching
        // This means we'll also have to provide our own pageCount.
        pageCount: controlledPageCount,
        disableMultiSort: true,
        manualSortBy: !!fetchData,
        manualFilters: !!fetchData,
        manualGlobalFilter: !!fetchData,
        autoResetPage: !(!!fetchData),
        sortTypes: { alphanumeric: customCaseInsensitive },

    } as MyTableOptions<D>,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {

            if (useRowSelection) {

                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',

                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<D>) => (

                            <RowSelectionInput {...getToggleAllRowsSelectedProps()} />

                        ),

                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: (cell: Cell) => (
                            <RowSelectionInput {...(cell.row as any).getToggleRowSelectedProps()} /> // cast to any as the proper row type cas circular references to Cell type
                        ),
                    },
                    ...columns,
                ])
            }
        }
    ) as MyTableInstance<D>

    const t = useTranslation();

    if (!fetchData) totalItemCount = rows.length;

    useEffect(() => {

        onSelectedRowsChanged?.(selectedFlatRows.map(d => d.original));
    }, [selectedFlatRows.length]);

    const { pageIndex, pageSize, sortBy, globalFilter, filters } = state;

    const filterBy = (filters || [])
        .map(f => ({
            id: f.id,
            value: f.value
        } as FilterByOptions));

    const sortByOption = sortBy && sortBy.length > 0
        ? sortBy
        : sortByDefaultOption
            ? [{
                id: sortByDefaultOption.sortByColumnId,
                desc: !sortByDefaultOption.isAsc
            } as SortByOptions]
            : sortBy;

    // Calculate pages
    const [pages, setPages] = useState<number[]>([]);

    useEffect(() => {

        const intPages: number[] = [];
        const pageCountCalc = Math.ceil(totalItemCount / pageSize);

        for (var i = 0; i < pageCountCalc; i++) {
            intPages.push(i);
        }

        setControlledPageCount(pageCountCalc);

        if (intPages.length === 0) {
            intPages.push(0);
        }

        setPages(intPages);

    }, [pageCount, totalItemCount, pageSize]);

    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
        if (!fetchData) return;
        fetchData(pageIndex, pageSize, sortByOption, globalFilter, filterBy);
    }, [pageIndex, pageSize, globalFilter, JSON.stringify(sortBy), JSON.stringify(filters)]);

    const FilterSelectContainer = ({
        children,
        ...props
    }: ContainerProps<SelectOption<string>, false, GroupTypeBase<SelectOption<string>>>) => (
        <components.SelectContainer {...props}>
            {children}
        </components.SelectContainer>
    );

    const SortSelectContainer = ({
        children,
        ...props
    }: ContainerProps<SelectOption<string>, false>) => (
        <components.SelectContainer {...props}>
            {children}
        </components.SelectContainer>
    );

    // Render the UI for your table
    return (

        <div className={bem.b("d-flex flex-column w-100")}>

            {/* First filter row */}
            <div className="row d-print-none">

                {columnFilters && (Object.entries(columnFilters)).map(([columnId, columnFilter]) => {
                    if (!columnFilter) return null;

                    const { label, isMultiple } = columnFilter as ColumnFilter;

                    if (isMultiple) {
                        const { selectedMessage, options, value, onChange } = columnFilter as ColumnFilter<true>;
                        const chosenValues = options.filter(o => value.includes(o.value));

                        if (options.length <= 1 && !value.length) return null;

                        return (
                            <div className={bem.e("filter-block", "col-sm-auto")} key={columnId.toString()}>
                                <div className="d-flex flex-column" style={{ visibility: options.length > 0 ? "visible" : "hidden" }}>

                                    {label && (
                                        <Label
                                            for={`filter-by-${columnId}`}
                                            className={bem.e("label", "mb-0 font-weight-bold text-uppercase")}
                                        >{label}</Label>
                                    )}

                                    <MultiSelectDropdown
                                        id={`filter-by-${columnId}`}
                                        message={selectedMessage}
                                        options={options}
                                        chosenOptions={chosenValues}
                                        onChange={chosenOptions => {
                                            const values = chosenOptions.map(o => o.value);
                                            if (onChange) onChange(values);
                                            setFilter(columnId as IdType<D>, values.join(","));
                                        }}
                                    />

                                </div>
                            </div>
                        )

                    } else {
                        const { options, value, onChange } = columnFilter as ColumnFilter<false>;
                        const chosenValue = options.find(o => o.value === (value || ""));

                        return (
                            <div className={bem.e("filter-block", "col-sm-auto")} key={columnId.toString()}>
                                <div className="d-flex flex-column" style={{ visibility: options.length > 0 ? "visible" : "hidden" }}>

                                    {label && (
                                        <Label
                                            for={`filter-by-${columnId}`}
                                            className={bem.e("label", "mb-0 font-weight-bold text-uppercase")}
                                        >{label}</Label>
                                    )}

                                    <Select
                                        id={`filter-by-${columnId}`}
                                        isMulti={false}
                                        isClearable={false}
                                        classNamePrefix="react-select"
                                        className="react-select-container"
                                        value={chosenValue}
                                        onChange={e => {
                                            const choice = e?.value || "";
                                            if (onChange) onChange(choice);
                                            setFilter(columnId as IdType<D>, choice);
                                        }}
                                        defaultValue={options[0] || undefined}
                                        placeholder=""
                                        options={options}
                                        components={{ SelectContainer: FilterSelectContainer }}
                                    />

                                </div>
                            </div>
                        );
                    }
                })}

                {showSearchBar && 
                    <div className={bem.e("filter-block", "col")}>
                        <div className="d-flex flex-column">

                            <Label
                                for="global-filter-input"
                                className={bem.e("label", "mb-0 font-weight-bold text-uppercase")}
                            >{t("DataTable.SearchByLabel")}</Label>

                            <GlobalFilter
                                id="global-filter-input"
                                setGlobalFilter={setGlobalFilter}
                            />

                        </div>
                    </div>
                }

                {(sortByOptions && sortByOptions.length > 0) &&
                    <div className={bem.e("filter-block", "col-sm-auto")}>
                        <div className="d-flex flex-column">

                            <div className="mb-2">

                                <Label
                                    for="sort-by-select"
                                    className={bem.e("label", "mb-0 font-weight-bold text-uppercase")}
                                >{t("DataTable.SortByLabel")}</Label>

                                <Select
                                    id="sort-by-select"
                                    isMulti={false}
                                    isClearable={false}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    onChange={e => {

                                        const columnId = e?.value.split("::")[0];
                                        const order = e?.value.split("::")[1];

                                        if (columnId && order) {
                                            toggleSortBy(columnId as IdType<D>, order === Order.Descending.toString());
                                        } else {

                                            setSortBy([]);
                                        }
                                    }}
                                    placeholder=""
                                    options={sortByOptions.map(o =>

                                        [Order.Ascending, Order.Descending].map(order => (
                                            {
                                                label: order === Order.Ascending ? o.sortByAscText : o.sortByDescText,
                                                value: `${o.sortByColumnId}::${order}`
                                            } as SelectOption<string>
                                        ))
                                    ).flat(1)}
                                    aria-label={t("DataTable.SortByLabel")}
                                    defaultValue={sortByDefaultOption ? {
                                        label: sortByDefaultOption.sortByText,
                                        value: `${sortByDefaultOption.sortByColumnId}::${sortByDefaultOption.isAsc ? Order.Ascending : Order.Descending}`
                                    } as SelectOption<string> : undefined}
                                    components={{ SelectContainer: SortSelectContainer }}
                                />
                            </div>
                        </div>
                    </div>
                }

            </div>

            {/* Main table row */}
            <div className="row">

                <div className="col-sm-12">

                    <AreaLoader show={loading || false} />

                    <Table
                        {...getTableProps()}
                        borderless
                    >
                        {showHeaders &&
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {headerGroup.headers.map((c: HeaderGroup<D>) => {

                                            const column = c as MyHeaderGroup<D>;

                                            // Add the sorting props to control sorting.
                                            return (
                                                <th
                                                    // Add a sort direction indicator
                                                    className={column.isSorted ? column.isSortedDesc ?
                                                        "sorting_desc" : "sorting_asc" : "sorting"}
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                >


                                                    <div className="col-title">
                                                        {column.render('Header')}
                                                        <Filter column={column} />
                                                    </div>
                                                </th>);
                                        })}
                                    </tr>
                                ))}
                            </thead>
                        }

                        <tbody
                            data-automation-id={resultsElId}
                            {...getTableBodyProps()}
                        >

                            {page.map(
                                (row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className={disableStyling ? row.getRowProps()?.className ?? "" : bem.e("row", row.getRowProps()?.className ?? "", "mt-2 d-flex justify-content-between w-100")}
                                        >
                                            {row.cells.map(cell => {
                                                return <td
                                                    {...cell.getCellProps()}
                                                    className={disableStyling ? cell.getCellProps().className : classNames(cell.getCellProps().className, "col")}
                                                >{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>

                    </Table>

                </div>
            </div>

            {/* Bottom paging row */}
            <div className="row d-print-none">

                <div className="col-sm-4">
                    <div className="d-flex flex-row">

                        <Label
                            className={bem.e("label", "mb-0")}
                            for="items-selector"
                        >{t("DataTable.ShowEntriesPrefixLabel")}</Label>

                        <Input
                            className={bem.e("items-selector", "ml-2 mr-2")}
                            id="items-selector"
                            type="select"
                            value={state.pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </Input>

                        <Label
                            className={bem.e("label", "mb-0")}
                            for="items-selector"
                        >{t("DataTable.ShowEntriesSuffixLabel")}</Label>
                    </div>
                </div>

                <div className="col-sm-4">
                    <p
                        className="font-weight-bold text-center"
                        role="status"
                        aria-live="polite"
                    >
                        {t("DataTable.ShowEntriesRangeWithTokensLabel")
                            .replace("[[RangeFrom]]", (!totalItemCount ? totalItemCount : (pageIndex * pageSize) + 1).toString())
                            .replace("[[RangeTo]]", (totalItemCount < ((pageIndex + 1) * pageSize) ? totalItemCount : ((pageIndex + 1) * pageSize)).toString())
                            .replace("[[Total]]", totalItemCount.toString())
                        }
                    </p>
                </div>

                <div className="col-sm-4 d-flex flex-row-reverse">

                    <Pagination>
                        <PaginationItem
                            disabled={!canPreviousPage}
                        >
                            <PaginationLink
                                previous
                                href="#"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.preventDefault();
                                    previousPage();
                                }}
                            />
                        </PaginationItem>

                        <Paging
                            pages={pages}
                            gotoPage={gotoPage}
                            pageIndex={state.pageIndex}
                        />

                        <PaginationItem
                            disabled={!canNextPage}
                        >
                            <PaginationLink
                                next
                                href="#"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.preventDefault();
                                    nextPage();
                                }}
                            />
                        </PaginationItem>

                    </Pagination>

                </div>
            </div>


            {
                debug &&

                <>
                    <h3>Debug details:</h3>
                    <div>
                        <pre>
                            <code>
                                {JSON.stringify(
                                    {
                                        state,
                                        pageCount,
                                        canNextPage,
                                        canPreviousPage,
                                        selectedRowIds: state.selectedRowIds,
                                        'selectedFlatRows[].original': selectedFlatRows.map(
                                            d => d.original
                                        ),
                                    },
                                    null,
                                    2
                                )}
                            </code>
                        </pre>
                    </div>
                </>
            }

        </div >

    )
}





/* EXAMLE WITH SERVER SIDE PAGING
 *
 *    <DataTable
                    columnDefinitions={this.columns}
                    items={this.state.data || []}

                    fetchData={this.loadPageData}
                    pageCount={5}
                />
 *
 *
 *   type datatype = {
          firstName: string,
          lastName: string,
    };

    data = (seed: number = 0) => {

        var items = [];
        for (var i = seed + 0; i < seed + 10; i++) {

            items.push(
                {
                    firstName: "firstName" + (i + 1),
                    lastName: "lastName" + (i + 1)
                }
            );
        }
        return items;
    }

    columns: Array<HeaderColumn<datatype>> =
        [

            {
                id: nameof<IAddressForm>("firstName"),
                Header: 'First Name',
                accessor: (model) => model.firstName,
            },
            {
                id: nameof<IAddressForm>("lastName"),
                Header: 'Last Name',
                accessor: (model) => model.lastName,
            },
            {
                id: "action",
                Header: 'Actions',
                accessor: () => "", // Empty accessor for action columns
                Cell: (cell) => {

                    // Use this to inject ids etc in links
                    const data = cell.row.original as datatype;

                    return (<div>
                        <button className="btn btn-primary">Action</button>
                        <button className="btn btn-primary">Action2</button>
                        <span>{data.firstName}</span>
                    </div>)
                }
            },
        ];


    loadPageData = (pageIndex: number, pageSize: number, sortBy: ISortByOptions[], searchTerm: string) => {

        this.setState({
            data: this.data((pageIndex * 10) + 1)
        })
    }



*/