import React from 'react';

import { Notification } from "react-notification-system";
import { FaCheckCircle, FaTimesCircle, FaInfoCircle, FaExclamationCircle } from 'react-icons/fa';

interface NotificationConfig {
    isStatic?: boolean;
    position?: "tr" | "tl" | "tc" | "br" | "bl" | "bc";
}

export const getSuccessNotification = (message: string, config?: NotificationConfig): Notification => {
    return {
        title: <FaCheckCircle />,
        message: message,
        level: "success",
        autoDismiss: config && !!config.isStatic ? 0 : 5, // default is 5 secs
        position: config?.position ?? (config && config.isStatic) ? "tc" : "tr"
    }
}

export const getErrorNotification = (message: string, config?: NotificationConfig): Notification => {
    return {
        title: <FaTimesCircle />,
        message: message,
        level: "error",
        autoDismiss: config && !!config.isStatic ? 0 : 5, // default is 5 secs
        position: config?.position ?? (config && config.isStatic) ? "tc" : "tr"
    }
}

export const getInfoNotification = (message: string, config?: NotificationConfig): Notification => {
    return {
        title: <FaInfoCircle />,
        message: message,
        level: "info",
        autoDismiss: config && !!config.isStatic ? 0 : 5, // default is 5 secs
        position: config?.position ?? (config && config.isStatic) ? "tc" : "tr"
    }
}

export const getWarningNotification = (message: string, config?: NotificationConfig): Notification => {
    return {
        title: <FaExclamationCircle />,
        message: message,
        level: "warning",
        autoDismiss: config && !!config.isStatic ? 0 : 5, // default is 5 secs
        position: config?.position ?? (config && config.isStatic) ? "tc" : "tr"
    }
}

// Scrolling to top of the page (main div with id=root) if payment error occurs
// Doing this so that error is visible to user while making payments
export const scrollToTopOfThePage = () => {
    const section = document.querySelector('#root');
    if (section) {
        section!.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};