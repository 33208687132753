import React from "react";
import { Spinner } from "reactstrap";
import { bemNames } from "Utilities/bemNames";

type ColorType =
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";

interface Props {
    color?: ColorType;
}

export const PageLoader = ({ color = "primary" }: Props) => {


    const bem = bemNames.create("page-loader");


    return (

        <div className={bem.b()}>
            <Spinner color={color} />
        </div>

    );

}