import React from "react";
import { Container, Row } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect, useLocation, useParams } from "react-router";
import { useTranslation } from 'react-multi-lang'

import { ApplicationState } from "Store";
import * as UserReducer from 'Store/Reducers/UserReducer';
import * as AuthenticationReducer from 'Store/Reducers/AuthenticationReducer';
import { AreaLoader } from "Components";
import { getHomepage } from "../Utilities/claimsHelper";
import { useState } from "react";
import { UserDetails } from "../Auth";
import { useEffect } from "react";
import { userService } from "../Services";


interface StoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
    loginSuccess: (accessToken: string, refreshToken: string) => void;
    showUserNotification: typeof UserReducer.actions.showUserNotification;
    loggedIn?: boolean;
    user?: UserDetails;
}

const mapStateToProps = ({ auth, user: userState }: ApplicationState) => ({
    loggedIn: auth.loggedIn,
    user: userState.user
});

const mapDispatchToProps = {
    loadUser: UserReducer.actions.loadUser,
    loginSuccess: AuthenticationReducer.actions.loginSuccess
}

const ResetPasswordPage = ({
    loggedIn,
    user,
    loadUser,
    loginSuccess
}: StoreProps) => {

    const t = useTranslation();

    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [redirectToChangePassword, setRedirectToChangePassword] = useState(false);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    useEffect(() => {
        let isSubscribed = true;
        (async () => {


            if (token) {

                try {
                    const userDetails = await userService.validateResetPassword(token)
                    loadUser(userDetails);
                    loginSuccess(userDetails.accessToken, userDetails.refreshToken);

                    if (isSubscribed) {
                        setRedirectToChangePassword(true);
                    }
                    return;
                } catch (e) {
                    console.error(e);
                }

                setRedirectToLogin(true);
            }

        })();
        return () => { isSubscribed = false } 
    }, [token])

    if (loggedIn && !redirectToChangePassword) {
        var path = getHomepage(user);
        return <Redirect to={{ pathname: path }} />;
    }

    if (redirectToLogin) {
        return <Redirect to="/login" />;
    }
    if (redirectToChangePassword) {
        return <Redirect to="/update-password" />;
    }

    return (
        <div>
            <Container>
                <Row>

                    <AreaLoader
                        show={true}
                        message={t("Login.LoggingInMessage")}
                    />

                </Row>
            </Container>

        </div>
    );
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

export {
    ConnectedComponent as ResetPasswordPage
}
