import { CodeDeliveryMethod } from "../Models/CodeDeliveryMethod";

export function isSmsDelivery(deliveryMethod: CodeDeliveryMethod) {
    return deliveryMethod !== null && deliveryMethod !== undefined && (deliveryMethod == CodeDeliveryMethod.Sms || deliveryMethod == CodeDeliveryMethod.EmailAndSms);
}

export function isEmailDelivery(deliveryMethod: CodeDeliveryMethod) {
    return deliveryMethod !== null && deliveryMethod !== undefined && (deliveryMethod == CodeDeliveryMethod.Email || deliveryMethod == CodeDeliveryMethod.EmailAndSms);
}

export function appendPrefix(val: string | undefined, prefix: string | undefined) {
    if (prefix == null || prefix == undefined)
        return val;
    else if (val == null || val == undefined)
        return "";
    else
        return prefix + val;
}