import React from "react";

import { Link } from 'react-router-dom';
import { bemNames } from 'Utilities';
import { IProjectDto, BaseVoucherType } from "Models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as DigitalProjectIcon } from 'Assets/Images/icons/Digital-project.svg';
import { ReactComponent as PhysicalProjectIcon } from 'Assets/Images/icons/Physical-project.svg';
import { ReactComponent as PrintReadyProjectIcon } from 'Assets/Images/icons/Print-Ready-project.svg';
import { useTranslation } from "react-multi-lang";


interface Props {
    project: IProjectDto
}

export const ProjectTile = ({ project }: Props) => {

    const t = useTranslation();
    const bem = bemNames.create("projects-tile");


    let orderButton;
    if (project.isProjectClosed) {
        orderButton = <button aria-label={project.projectName + " project closed"} type="button" disabled={project.isProjectClosed} className="btn btn-secondary text-uppercase">{t("Projects.ProjectClosedButton")}</button>
    } else if (project.projectType == BaseVoucherType.ECode || project.projectType == BaseVoucherType.PrintReady) {
        orderButton = <Link to={`/projects/order-codes/${project.projectId}#delivery-method`} aria-label={project.projectName + " order button"} className="btn btn-primary text-uppercase">{t("Projects.ProjectTileOrderButton")}</Link>
    } else {
        orderButton = <button aria-label={project.projectName + " order button"} type="button" className="btn btn-primary text-uppercase">{t("Projects.ProjectTileOrderButton")}</button>
    }

    return (
        <div className={bem.b()}>

            <div className={bem.e("tile-top")}>
                <div className={bem.e("options")}>
                    <Link
                        to={`/projects/project/${project?.projectId}`}
                        aria-label={project?.projectName + " project details"}
                    ><HiOutlineDotsHorizontal color="#878EA0" /></Link>                    
                </div>
                {project.projectType == BaseVoucherType.Physical && <PhysicalProjectIcon />}
                {project.projectType == BaseVoucherType.ECode && <DigitalProjectIcon />}
                {project.projectType == BaseVoucherType.PrintReady && <PrintReadyProjectIcon className="print-icon" />}
            </div>

            <div className={bem.e("tile-bottom")}>
                <div className="project-name">{project.projectName}</div>
                <div className="project-id text-uppercase"><strong>{t("Projects.ProjectTileProjectIdLabel")}</strong> {project.projectId}</div>                
                {orderButton}
            </div>

        </div>
    )
}

