import { ActionType, createAction, createReducer } from "typesafe-actions";
import { UserDetails } from "Auth";
import { UserNotification } from "../../Models";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {
    user?: UserDetails;
    userNotifications: UserNotification[];
    loggingOut?: boolean;
}

export const initialState: UserState = {
    userNotifications: [] 
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    typeof actionCreators.loadUser |
    typeof actionCreators.clearUser |
    typeof actionCreators.showUserNotification |
    typeof actionCreators.hideUserNotification |
    typeof actionCreators.setLoggingOut;

export type ActionsType = ActionType<
    typeof actionCreators.loadUser |
    typeof actionCreators.clearUser |
    typeof actionCreators.showUserNotification |
    typeof actionCreators.hideUserNotification |
    typeof actionCreators.setLoggingOut
>;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// Action definitions

const actionCreators = {
    loadUser: createAction("LOAD_USER")<UserDetails>(),
    clearUser: createAction("CLEAR_USER")(),
    showUserNotification: createAction("SHOW_USER_NOTIFICATION")<UserNotification>(),
    hideUserNotification: createAction("HIDE_USER_NOTIFICATION")<UserNotification>(),
    setLoggingOut: createAction("SET_LOGGING_OUT")<boolean>()
};

export const actions = {
    ...actionCreators
}

//export type LoadUserAction = () => Promise<boolean>
//export type LoginAction = (username: string, password: string) => Promise<void>


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer = createReducer<UserState, ActionsType>(initialState)
    .handleAction(actionCreators.loadUser, (state: UserState, action) => {
        return {
            ...state,
            user: action.payload
        }
    })
    .handleAction(actionCreators.clearUser, (state: UserState, action) => {
        return {
            ...state,
            user: undefined
        }
    })
    .handleAction(actionCreators.showUserNotification, (state: UserState, action) => {
        return {
            ...state,
            userNotifications: [...state.userNotifications, action.payload]
        }
    })
    .handleAction(actionCreators.hideUserNotification, (state: UserState, action) => {
        return {
            ...state,
            userNotifications: state.userNotifications.filter(d => d !== action.payload)
        }
    })
    .handleAction(actionCreators.setLoggingOut, (state: UserState, action) => {
        return {
            ...state,
            loggingOut: action.payload
        }
    });