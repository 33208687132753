import React from "react";
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bemNames, getErrorNotification, getSuccessNotification, nameOf } from 'Utilities';
import { ScmOrderSearchDetailsDto, ScmOrderItemSummaryDto, ScmConfigurationType, ProductBaseTypeEnum, EmailMessageLogDto, ItemStatusLogDto, ItemGroupStatusLogDto } from 'Models';
import { useTranslation } from "react-multi-lang";
import { AreaLoader, EmailMessageLogDialog, ItemGroupStatusLogDialog, ItemStatusLogDialog, OrderSummary, ReactstrapDataTable } from "../Components";

import { customerServicesService } from "../Services";

import Notifications from 'react-notification-system-redux';
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Table } from "reactstrap";
import { Cell } from "react-table";
import { AiOutlineFileSearch } from "react-icons/ai";
import moment from "moment";

interface StoreProps {
    notifyError: Notifications.NotificationShow;
    notifySuccess: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
    notifySuccess: Notifications.success,
}

const SearchOrderDetailsPage = ({
    notifyError,
    notifySuccess

}: StoreProps) => {

    const t = useTranslation();
    const bem = bemNames.create("order-details");

    const rootRef = useRef<HTMLDivElement>(null);

    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [orderDetails, setOrderDetails] = useState<ScmOrderSearchDetailsDto>();
    const [itemStatusLog, setItemStatusLog] = useState<ItemStatusLogDto[]>([]);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [groupStatusLog, setGroupStatusLog] = useState<ItemGroupStatusLogDto>();
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    const [emailMessageLog, setEmailMessageLog] = useState<EmailMessageLogDto[]>([]);
    const [isEmailMessageLogModalOpen, setIsEmailMessageLogModalOpen] = useState(false);

    // url params
    const { scmOrderId: orderIdParam } = useParams<{ scmOrderId: string }>();

    const scmOrderId = +orderIdParam;

    useEffect(() => {

        if (scmOrderId) {
            (async () => {

                setLoading(true);

                await loadOrderDetails();

                setLoading(false);
            })();
        }
    }, [scmOrderId]);

    const loadOrderDetails = async () => {

        try {

            const data = await customerServicesService.getScmOrderDetails(scmOrderId);
            setOrderDetails(data);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderDetailsFailure")));
        }
    }

    const getItemStatus = async (scmOrderId: number, scmOrderItemId: number) => {
        setLoading(true);
        setLoadingMessage(t("OrderDetails.LoadingStatusLogMessage"));

        try {
            var data = await customerServicesService.getItemStatus(scmOrderId, scmOrderItemId);

            setItemStatusLog(data);
            setIsStatusModalOpen(true);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadItemStatusFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    const getGroupStatus = async (orderId: number) => {
        setLoading(true);
        setLoadingMessage(t("OrderDetails.LoadingStatusLogMessage"));

        try {
            var data = await customerServicesService.getGroupStatus(scmOrderId);

            setGroupStatusLog(data);
            setIsGroupModalOpen(true);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadItemStatusFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    const onViewEmailLog = async (scmOrderItem: ScmOrderItemSummaryDto) => {
        setLoading(true);
        setLoadingMessage(t("OrderDetails.LoadingEmailLogMessage"));

        try {
            var results = await customerServicesService.getItemEmailLog(scmOrderItem.id);

            setEmailMessageLog(results);
            setIsEmailMessageLogModalOpen(true);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadEmailLogFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    const onResendEmailMessage = async (scmOrderEGiftId: number, emailMessageId: number, newEmailAddress: string) => {
        setLoading(true);
        setLoadingMessage(t("OrderDetails.ResendingEmailMessage"));

        try {
            await customerServicesService.resendEmailMessage(emailMessageId, scmOrderEGiftId, newEmailAddress)

            notifySuccess(getSuccessNotification(t("NotificationMessage.EmailResentSuccessfully")));
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.EmailResentFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }


    const OrderItemsColumnDefinitions = [
        {
            id: "[0].productName", //nameOf<ScmOrderItemSummaryDto[]>("productName"),
            Header: <div className="text-uppercase">{t("OrderDetails.ProductNameLabel")}</div>,
            accessor: (model: ScmOrderItemSummaryDto[]) => model[0].productName,
            Cell: (cell: Cell<ScmOrderItemSummaryDto[]>) => {
                const data = cell.row.original;

                return (
                    data.map(d =>
                        <div
                            key={data.indexOf(d)}
                            style={data.length > 1 ? { lineHeight: "2rem", padding: "8px 0px" } : {}}
                        >{d.currency}{d.value} {d.productName}</div>
                    )

                )
            }
        },
        {
            id: "[0].externalOrderReference",//nameOf<ScmOrderItemSummaryDto>("externalOrderReference"),
            Header: <div className="text-uppercase">{t("OrderDetails.ExternalOrderRefLabel")}</div>,
            accessor: (model: ScmOrderItemSummaryDto[]) => model[0].externalOrderReference,
            Cell: (cell: Cell<ScmOrderItemSummaryDto[]>) => {
                const data = cell.row.original;

                return (
                    orderDetails?.scmConfigurationTypeId === ScmConfigurationType.Go2
                        ? <div>{data[0].externalOrderReference}</div>
                        : data.map(d =>
                            <div key={data.indexOf(d)}>{d.externalOrderReference}</div>
                        )

                )
            }
        },
        {
            id: "[0].orderStatus",//nameOf<ScmOrderItemSummaryDto>("orderStatus"),
            Header: <div className="text-uppercase">{t("OrderDetails.OrderStatusLabel")}</div>,
            accessor: (model: ScmOrderItemSummaryDto[]) => model[0].orderStatus,
            Cell: (cell: Cell<ScmOrderItemSummaryDto[]>) => {
                const data = cell.row.original;

                return (
                    orderDetails?.scmConfigurationTypeId === ScmConfigurationType.Go2
                        && data.length > 1 && data[0].isGrouped
                        ?
                        (
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    getGroupStatus(orderDetails?.scmOrderId ?? 0);
                                }}
                            ><AiOutlineFileSearch></AiOutlineFileSearch>{data[0].orderStatus}</a>
                        )
                        :
                        (
                            data.map(d =>
                                <a
                                    key={data.indexOf(d)}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getItemStatus(orderDetails?.scmOrderId ?? 0, d.id);
                                    }}
                                ><AiOutlineFileSearch></AiOutlineFileSearch>{d.orderStatus || t("OrderDetails.ViewLogButton")}</a>
                            )
                        )
                )
            }
        },
        {
            id: "emailLog",
            Header: <div className="text-uppercase">{t("OrderDetails.EmailLogLabel")}</div>,
            accessor: () => "",
            Cell: (cell: Cell<ScmOrderItemSummaryDto[]>) => {
                const data = cell.row.original;

                return data.map(d => d.isGrouped || d.productBaseType !== ProductBaseTypeEnum.ECode
                    ? <></>
                    : <div key={data.indexOf(d)} > <a href="#" onClick={(e) => {
                        e.preventDefault();
                        onViewEmailLog(d);
                    }
                    } ><AiOutlineFileSearch></AiOutlineFileSearch>{t("ViewButton")}</a></div>);
            }
        }]

    const totalValue = orderDetails?.scmOrderItems?.reduce((a, b) => a + b.value!, 0) ?? 0;

    const addressAggregate = [
        orderDetails?.addressDetails?.organisationName,
        orderDetails?.addressDetails?.addressLine1,
        orderDetails?.addressDetails?.addressLine2,
        orderDetails?.addressDetails?.addressLine3,
        orderDetails?.addressDetails?.cityTown,
        orderDetails?.addressDetails?.county,
        orderDetails?.addressDetails?.postCode,
        orderDetails?.addressDetails?.county
    ].filter(Boolean).join(", ");

    const groupedItems = orderDetails?.scmOrderItems?.filter(d => d.isGrouped);
    const ungroupedItems = orderDetails?.scmOrderItems?.filter(d => !d.isGrouped);

    const allItems: ScmOrderItemSummaryDto[][] = [];

    if (groupedItems && groupedItems.length > 0) {

        allItems.push(groupedItems);
    }

    if (ungroupedItems && ungroupedItems.length > 0) {

        ungroupedItems.map(d => [d])
            .forEach(g => allItems.push(g));
    }


    return (
        <div className={bem.b()} ref={rootRef}>

            <AreaLoader
                show={loading}
                message={loadingMessage ?? t("NotificationMessage.LoadOrderDetailsMessage")}
            />

            <Breadcrumb>
                <BreadcrumbItem><Link to="/customer-service/">{t("CustomerService.CustomerServiceLabel")}</Link></BreadcrumbItem>
                <BreadcrumbItem active>{t("OrderDetails.OrderDetailsLabel")}</BreadcrumbItem>
            </Breadcrumb>

            <Container>
                <Row>
                    <Card className="w-100 mb-3">
                        <CardHeader>
                            <h2>{t("OrderDetails.RedemptionLabel")}</h2>
                        </CardHeader>

                        <CardBody>

                            <Row>
                                <Col xs={5}>
                                    <FormGroup>
                                        <Label>{t("OrderDetails.RedemptionDateLabel")}</Label>
                                        <Input type="text" defaultValue={(orderDetails?.redemptionDate ? moment.utc(orderDetails.redemptionDate).format("ll HH:mm") : undefined)} readOnly />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t("OrderDetails.OrderDetailsLabel")}</Label>

                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    SCR-
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="text" defaultValue={orderDetails?.scmOrderId || ""} readOnly />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t("OrderDetails.OrderTotalLabel")}</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    {orderDetails?.scmOrderItems?.map(o => o.currency).slice(0, 1)}
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="text" defaultValue={totalValue || ""} readOnly />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>

                                <Col xs={7}>
                                    <Card>
                                        <CardHeader>
                                            {t("OrderDetails.DeliveryInformationLabel")}
                                        </CardHeader>

                                        <CardBody>
                                            <Row>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <Label>{t("OrderDetails.NameLabel")}</Label>
                                                        <Input type="text" defaultValue={orderDetails?.addressDetails && (orderDetails?.addressDetails?.firstName + " " + orderDetails?.addressDetails?.lastName)} readOnly />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <Label>{t("OrderDetails.EmailAddressLabel")}</Label>
                                                        <Input type="text" defaultValue={orderDetails?.addressDetails?.emailAddress || ""} readOnly />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={12}>
                                                    <FormGroup>
                                                        <Label>{t("OrderDetails.AddressLabel")}</Label>
                                                        <Input type="text" defaultValue={addressAggregate || ""} readOnly />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="d-sm-inline mt-3">
                                <ReactstrapDataTable<ScmOrderItemSummaryDto[]>
                                    columnDefinitions={OrderItemsColumnDefinitions}
                                    items={allItems}
                                    totalItemCount={orderDetails?.scmOrderItems?.length ?? 0}
                                />
                            </Row>
                        </CardBody>
                    </Card>

                </Row>

                <Row>
                    <Card className="w-100">
                        <CardHeader>
                            <h2>{t("OrderDetails.SourceLabel")}</h2>
                        </CardHeader>

                        <CardBody>

                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label>{t("OrderDetails.ClientNameLabel")}</Label>
                                        <Input type="text" defaultValue={orderDetails?.clientName || ""} readOnly />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t("ProjectDetails.ProjectName")}</Label>
                                        <Input type="text" defaultValue={orderDetails?.projectName || ""} readOnly />
                                    </FormGroup>
                                </Col>

                                <Col xs={6}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th className="text-uppercase">{t("OrderDetails.CodeOrCardLabel")}</th>
                                                <th className="text-uppercase">{t("OrderDetails.ValueLabel")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {orderDetails?.paymentSources.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.cardCodeNumber ?? item.employeeNumber}</td>
                                                        <td>{orderDetails.scmOrderItems.map(orderItem => orderItem.currency)[0]}{item.value?.toFixed(2)}</td>
                                                        <td>{!item.cardCodeNumber?.includes('XXXX-') && <Link to={`/customer-service/issuance-details/${item.redemptionCodeId}`}><Button color="primary">{t("DetailsButton")}</Button></Link>}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>

            </Container>

            <ItemStatusLogDialog
                isModalOpen={isStatusModalOpen}
                title={t("OrderDetails.StatusLogLabel")}
                closeModal={() => setIsStatusModalOpen(false)}
                statuses={itemStatusLog}
            />

            {groupStatusLog &&

                <ItemGroupStatusLogDialog
                    isModalOpen={isGroupModalOpen}
                    title={t("OrderDetails.OrderGroupStatusLogLabel")}
                    closeModal={() => setIsGroupModalOpen(false)}
                    status={groupStatusLog}
                />
            }

            <EmailMessageLogDialog
                isModalOpen={isEmailMessageLogModalOpen}
                closeModal={() => setIsEmailMessageLogModalOpen(false)}
                title={t("OrderDetails.EmailMessageLogLabel")}
                onSubmit={onResendEmailMessage}
                logItems={emailMessageLog}
            />

        </div>
    )
}

const ConnectedComponent = connect(null, mapDispatchToProps)(SearchOrderDetailsPage);

export {
    ConnectedComponent as SearchOrderDetailsPage
}