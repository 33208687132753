import axios from "axios";
import { getAuthHeader } from "./getAuthHeader";
import { AdditionalUserSearchArguments, ProjectDetail, UsersListItem, UserPermissionItem, InviteUserDto, LanguageDto, TenantGroup, IClientDto } from "Models";


async function getProjectDetails() {

    const response = await axios.get<UserPermissionItem[]>(`api/UserManagement/AccessibleProjects`, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getTenantsWithNoProjects() {
    const response = await axios.get<IClientDto[]>(`api/UserManagement/TenantsWithNoProjects`, {
        headers: getAuthHeader()
    });

    return response.data;
}


async function getUsersList(additionalSearchArguments?: AdditionalUserSearchArguments) {

    const response = await axios.post<UsersListItem[]>(`api/UserManagement/UsersList`, additionalSearchArguments,
    {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getUserPermissions(userId: string) {

    const response = await axios.get<TenantGroup[]>(`api/UserManagement/GetUserPermissions/${userId}`, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function setUserPermissions(userId: string, tenantProjectsPermissions: TenantGroup[]) {

    const response = await axios.post(`api/UserManagement/SetUserPermissions/${userId}`, tenantProjectsPermissions,
        {
            headers: getAuthHeader()
        });
    return response.data;
}

async function deleteUser(userId: string) {
    const response = await axios.delete(`api/UserManagement/${userId}`,
        {
            headers: getAuthHeader()
        });

    return response.data;
}

async function inviteUser(userDetails: InviteUserDto) {

    const response = await axios.post<string>(`api/UserManagement/InviteUser`, userDetails,
        {
            headers: getAuthHeader()
        }
    );

    return response.data;
}

const getLanguages = async () =>

    (await axios.get<LanguageDto[]>(`api/UserManagement/GetLanguages`,
        {
            headers: getAuthHeader()
        }
    )).data;


export const userManagementService = {
    getProjectDetails,
    getUsersList,
    getUserPermissions,
    setUserPermissions,
    deleteUser,
    inviteUser,
    getLanguages,
    getTenantsWithNoProjects
};
