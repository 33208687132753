import React from "react";
import { Style } from "react-notification-system";
import Notifications, { NotificationsState } from "react-notification-system-redux";
import { connect } from "react-redux";
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { UserNotification, Theme } from "../Models";
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import * as UserReducer from "Store/Reducers/UserReducer";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { serviceUtils } from "Services/ServiceUtils";

import ThemeSelector from 'Components/ThemeSelector';

interface StoreProps {
    notifications: NotificationsState;
    userNotifications: UserNotification[];
    hideUserNotification: typeof UserReducer.actions.hideUserNotification;
}

interface Props extends StoreProps {
    children?: React.ReactNode
}

const NOTIFICATION_SYSTEM_STYLE: Style = {
    NotificationItem: {
        DefaultStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            borderRadius: '4px',
            fontSize: '14px',
        },

        success: {
            borderTop: 0,
            backgroundColor: '#45b649',
            WebkitBoxShadow: "0",
            MozBoxShadow: "0",
            boxShadow: "0",
        },

        error: {
            borderTop: 0,
            backgroundColor: '#f85032',
            WebkitBoxShadow: "0",
            MozBoxShadow: "0",
            boxShadow: "0",
        },

        warning: {
            borderTop: 0,
            backgroundColor: '#ffd700',
            WebkitBoxShadow: "0",
            MozBoxShadow: "0",
            boxShadow: "0",
        },

        info: {
            borderTop: 0,
            background: '#007bff',
            WebkitBoxShadow: "0",
            MozBoxShadow: "0",
            boxShadow: "0",
        },
    },

    Title: {
        DefaultStyle: {
            margin: 0,
            padding: 0,
            paddingRight: 5,
            color: '#fff',
            display: 'inline-flex',
            fontSize: 20,
            fontWeight: 'bold',
            // left: '15px',
            // position: 'absolute',
            // top: '50%',
        },
    },

    MessageWrapper: {
        DefaultStyle: {
            display: 'block',
            color: '#fff',
            width: '100%',
        },
    },

    Dismiss: {
        DefaultStyle: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'inherit',
            fontSize: 20,
            color: '#f2f2f2',
            position: 'relative',
            margin: 0,
            padding: 0,
            background: 'none',
            borderRadius: 0,
            opacity: 1,
            width: 20,
            height: 20,
            textAlign: 'initial',
            float: 'none',
            top: 'unset',
            right: 'unset',
            lineHeight: 'inherit',
        },
    },

    Action: {
        DefaultStyle: {
            background: '#fff',
            borderRadius: '2px',
            padding: '6px 20px',
            fontWeight: 'bold',
            margin: '10px 0 0 0',
            border: 0,
        },

        success: {
            backgroundColor: '#45b649',
            color: '#fff',
        },

        error: {
            backgroundColor: '#f85032',
            color: '#fff',
        },

        warning: {
            backgroundColor: '#ffd700',
            color: '#fff',
        },

        info: {
            backgroundColor: '#00c9ff',
            color: '#fff',
        },
    },

    ActionWrapper: {
        DefaultStyle: {
            margin: 0,
            padding: 0,
        },
    },
};

const mapStateToProps = ({ notifications, user }: { notifications: NotificationsState, user: UserReducer.UserState }) => ({

    notifications: notifications,
    userNotifications: user.userNotifications,
});

const mapDispatchToProps = {
    hideUserNotification: UserReducer.actions.hideUserNotification,
}

function Layout({
    children,
    notifications,
    userNotifications,
    hideUserNotification
}: Props) {

    const t = useTranslation();

    const [showModal, setShowModal] = useState(true);

    //Uncomment these lines for theme selection
    const isSSO = serviceUtils.hasUserLoggedInThroughSSO();
    const theme = isSSO ? Theme.Sso : Theme.Default;

    useEffect(() => {

        if (userNotifications.length > 0) {
            setShowModal(true);
        }
    }, [userNotifications])

    useEffect(() => {
        document.title = t("SiteSettings.DocumentTitle")
    })

    const onToggleModal = () => {
        setShowModal(false);

        if (userNotifications && userNotifications.length > 0) {
            hideUserNotification(userNotifications[0]);
        }
    }

    return (
        <ThemeSelector theme={theme}>
            <>
                <div className="d-flex flex-column h-100">
                    <NavMenu isSSO={isSSO} />
                    <Container className="page-content flex-grow-1" >
                        <main>
                            {children}
                        </main>
                    </Container>
                    {!isSSO && <Footer />}
                </div>

                <div
                    id="consent_blackbar"
                    style={{ position: 'fixed', bottom: '0', width: '100%' }}
                ></div>

                <Notifications
                    notifications={notifications}
                    // Can be styled like this
                    style={NOTIFICATION_SYSTEM_STYLE}
                />

                {userNotifications && userNotifications.length > 0 &&

                    <Modal
                        centered
                        isOpen={showModal}
                        toggle={onToggleModal}
                    >
                        <ModalHeader toggle={onToggleModal}>
                            {userNotifications[0].title}
                        </ModalHeader>
                        <ModalBody>
                            {userNotifications[0].message}
                        </ModalBody>
                    </Modal>
                }
            </>
        </ThemeSelector>
    );
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(Layout);

export {
    ConnectedComponent as Layout
}