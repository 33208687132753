import React, { FormEvent, useEffect, useReducer, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useTranslation } from "react-multi-lang";
import { useParams } from "react-router";
import { Button, Col, Container, FormGroup, Input, Label, Row, Table, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { AreaLoader, InputWithValidations, MultiSelectDropdown } from "../Components";
import { AccountDetails, LanguageLabel, UserType, UserTypeLabel, UserPermissionItem, ProjectTypeLabel, TenantGroup, IClientDto, IProjectDto } from "../Models";
import { editUserService, userManagementService } from "../Services";
import { bemNames, getErrorNotification, getSuccessNotification } from "../Utilities";
import Notifications from "react-notification-system-redux";
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";
import classnames from 'classnames';

enum Tab {
    SearchUsers,
    InviteUser
}

interface StoreProps {
    notifySuccess: Notifications.NotificationShow;
    notifyError: Notifications.NotificationShow;
};

const mapDispatchToProps = {
    notifyError: Notifications.error,
    notifySuccess: Notifications.success,
};

interface AccountUpdateModel {
    userGuid?: string
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    department?: string;
    jobTitle?: string;
    userType?: number;
    languageId?: number;
};

interface AccountDataErrors {
    firstNameError?: string;
    lastNameError?: string;
    emailError?: string;
    departmentError?: string;
    jobTitleError?: string;
};

// refactoring this page made me cry

function accountDetails({
    notifyError,
    notifySuccess
}: StoreProps) {

    const t = useTranslation();
    const bem = bemNames.create("edit-user");

    // Id of the viewed user
    const { id } = useParams<{ id: string }>();

    const [isBusy, setIsBusy] = useState(false);
    const [isBusyMessage, setIsBusyMessage] = useState<string>();
    const [userDetails, setUserDetails] = useState<AccountDetails>();

    const [activeTab, setActiveTab] = useState<Tab>(Tab.SearchUsers);
    const [tenantProjects, setTenantProjects] = useState<TenantGroup[]>([]);
    const [userType, setUserType] = useState<UserType>(UserType.SelfServiceUser);
    const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);
    const [showErrorForMultiSelectTenants, setShowErrorForMultiSelectTenants] = useState<boolean>(false);

    useEffect(() => {
        loadAccountDetails();
    }, []);

    // Loading & saving
    const loadAccountDetails = async () => {
        setIsBusy(true);

        try {
            const result = await editUserService.getAccountDetails(id);

            setUserDetails(result);
            setUserType(result.roleId);

            const tenantGroupsMap = await userManagementService.getUserPermissions(id);
            setTenantProjects(Object.values(tenantGroupsMap));

            if ((result.roleId == UserType.SelfServiceSuperUser)) {
                setSelectedClientIds(tenantGroupsMap.filter(t => t.hasAdminPermission)
                    .map(t => t.tenantId.toString()));
            }
            else if ((result.roleId == UserType.SelfServiceUser)) {
                setSelectedClientIds(tenantGroupsMap.filter(t => t.projects?.some(i => i.hasCustomerServicePermission || i.hasOrderPlacementPermission))
                    .map(t => t.tenantId.toString()));
            }
        }
        catch {
            notifyError(getErrorNotification(t("UserManagement.FailedToLoadAccountDetailsMessage")));
        }
        finally {
            setIsBusy(false);
        }
    };


    const UserDetailsValidationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(t("ValidationError.FirstNameRequired"))
            .max(100, t("ValidationError.FirstNameMaxChars")),

        lastName: Yup.string()
            .required(t("ValidationError.LastNameRequired"))
            .max(100, t("ValidationError.LastNameMaxChars")),

        emailAddress: Yup.string()
            .email(t("ValidationError.InvalidEmailAddressMessage"))
            .required(t("ValidationError.EmailAddressRequiredMessage")),

        department: Yup.string()
            .max(100, t("ValidationError.DepartmentMaxChars")),

        jobTitle: Yup.string()
            .max(100, t("ValidationError.DepartmentMaxChars")),

        languageId: Yup.number()
            .required(t("ValidationError.LanguageRequired"))
    });

    let filteredProjects: TenantGroup[] = [];
    selectedClientIds.forEach(tenantId => {

        var tenant = tenantProjects.find(tenant => tenant.tenantId === Number(tenantId));
        filteredProjects.push(tenant!);
    });

    return (<div className={bem.e("section-content")}>
        <Formik
            enableReinitialize={true}
            initialValues={{
                firstName: userDetails?.firstName ?? "",
                lastName: userDetails?.lastName ?? "",
                emailAddress: userDetails?.emailAddress ?? "",
                department: userDetails?.department ?? "",
                jobTitle: userDetails?.jobTitle ?? "",
                userType: userDetails?.roleId ?? UserType.SelfServiceUser,
                languageId: userDetails?.languageId ?? 1
            }}
            validationSchema={UserDetailsValidationSchema}
            onSubmit={async (values, actions) => {
                try {
                    if (!userDetails) {

                        return;
                    }

                    setIsBusy(true);
                    setIsBusyMessage(t("UserManagement.UpdateUserLabel"));

                    let emailAlreadyUsed: boolean;
                    try {
                        emailAlreadyUsed = await editUserService.checkIfEmailAlreadyAssigned(userDetails.userGuid, values.emailAddress);
                    }
                    catch {
                        notifyError(getErrorNotification(t("UserManagement.EmailCheckFailedMessage")));
                        setIsBusy(false);
                        setIsBusyMessage(undefined);
                        return;
                    }

                    if (emailAlreadyUsed) {
                        actions.setFieldError("emailAddress", t("EmailAlreadyAssignedMessage"));
                        notifyError(getErrorNotification(t("ErrorsOnFormMessage")));
                    }

                    if (values.userType == UserType.SelfServiceSuperUser) {
                        filteredProjects.forEach(tenant => {
                            tenant.hasAdminPermission = true;
                        });
                    }

                    // Check if there are any projects with customer service or order placement permission
                    const hasProjectsPermission = filteredProjects?.some(tenantProject =>
                        tenantProject.projects?.some(project =>
                            project?.hasCustomerServicePermission || project?.hasOrderPlacementPermission
                        )
                    );

                    // Check if there's at least one tenant with admin permission
                    const hasAdminPermission = filteredProjects?.some(tenantProject => tenantProject.hasAdminPermission);

                    // Check if user type is SelfServiceUser and there are no project permission
                    if (values.userType === UserType.SelfServiceUser && !hasProjectsPermission) {
                        notifyError(getErrorNotification(t("UserPermissions.SubmitPermissionsFailure")));
                        setIsBusy(false);
                        setIsBusyMessage(undefined);
                        return;
                    }

                    // Check if user type is SelfServiceSuperUser, there are multiple tenant, and no admin permission
                    if (values.userType === UserType.SelfServiceSuperUser && filteredProjects && filteredProjects?.length > 0 && !hasAdminPermission) {
                        notifyError(getErrorNotification(t("UserPermissions.SubmitPermissionsFailure")));
                        setIsBusy(false);
                        setIsBusyMessage(undefined);
                        return;
                    }

                    const errorMessageUsers = await editUserService.updateAccountDetails({
                        userGuid: userDetails.userGuid,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        emailAddress: values.emailAddress,
                        roleId: values.userType,
                        department: values.department,
                        jobTitle: values.jobTitle,
                        languageId: values.languageId
                    });

                    if (filteredProjects) {
                        var errorMessagePermissions = await userManagementService.setUserPermissions(id, filteredProjects);
                    }

                    if (!errorMessageUsers && errorMessagePermissions) {
                        notifySuccess(getSuccessNotification(t("UserManagement.AccountUpdateSucceededMessage")));
                    }
                    else if (!errorMessagePermissions) {
                        notifyError(getErrorNotification(t("UserPermissions.SubmitPermissionsFailure")));
                    }
                    else if (errorMessageUsers === 'ValidationError.DefaultCountryRiskErrorMessage') {
                        notifyError(getErrorNotification(t("ValidationError.DefaultCountryRiskErrorMessage")));
                    } else {
                        notifyError(getErrorNotification(t("UserManagement.AccountUpdateFailedMessage")));
                    }

                } catch (e) {

                    notifyError(getErrorNotification(t("UserManagement.AccountUpdateFailedMessage")));
                }

                setIsBusy(false);
                setIsBusyMessage(undefined);
            }}
        >

            {({ setFieldValue, values }) => (
                <Container>
                    <AreaLoader show={isBusy} message={isBusyMessage ?? t("UserManagement.LoadingUserLabel")} />

                    <Form>
                        <Row className="small-bottom-margin">
                            <Col xs="12" md="3" lg="2" className="profile-picture-area">
                                <div>
                                    <FaUserCircle size={96} />
                                    <p className="font-weight-bold text-center">{`${userDetails?.firstName} ${userDetails?.lastName}`}</p>
                                </div>
                            </Col>

                            <Col xs="12" md="9" lg="10">
                                <Row>

                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="firstName" className="font-weight-bold text-uppercase">{t("FirstNameLabel")}</Label>
                                            <Field id="firstName" name="firstName" component={InputWithValidations} />
                                        </FormGroup>
                                    </Col>

                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="lastName" className="font-weight-bold text-uppercase">{t("LastNameLabel")}</Label>
                                            <Field id="lastName" name="lastName" component={InputWithValidations} />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="emailAddress" className="font-weight-bold text-uppercase">{t("Login.EmailAddressLabel")}</Label>
                                            <Field id="emailAddress" name="emailAddress" component={InputWithValidations} />
                                        </FormGroup>
                                    </Col>

                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="userType" className="font-weight-bold text-uppercase">{t("UserTypeLabel")}</Label>
                                            <Field id="userType" name="userType" component={
                                                () =>
                                                    <Input
                                                        id="user-type-select"
                                                        type="select"
                                                        onChange={(e) => {
                                                            setFieldValue("userType", +e.target.value);
                                                            setUserType(+e.target.value);
                                                        }}
                                                        value={values.userType}
                                                    >
                                                        {
                                                            Array.from(UserTypeLabel.entries())
                                                                .filter(entry => [UserType.SelfServiceUser, UserType.SelfServiceSuperUser].includes(entry[0]))
                                                                .map((entry) => <option key={entry[0]} value={entry[0]}>{t(entry[1])}</option>)
                                                        }
                                                    </Input>
                                            } />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="department" className="font-weight-bold text-uppercase">{t("UserManagement.DepartmentLabel")}</Label>
                                            <Field id="department" name="department" component={InputWithValidations} />
                                        </FormGroup>
                                    </Col>

                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="jobTitle" className="font-weight-bold text-uppercase">{t("UserManagement.JobTitleLabel")}</Label>
                                            <Field id="jobTitle" name="jobTitle" component={InputWithValidations} />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <Label for="languageId" className="font-weight-bold text-uppercase">{t("UserManagement.LanguageLabel")}</Label>
                                            <Field id="languageId" name="languageId" component={
                                                () =>
                                                    <Input
                                                        id="user-language-select"
                                                        type="select"
                                                        onChange={(e) => {
                                                            setFieldValue("languageId", +e.target.value)
                                                        }}
                                                        value={values.languageId}
                                                    >
                                                        {
                                                            Array.from(LanguageLabel.entries())
                                                                .map((entry) => <option key={entry[0]} value={entry[0]}>{t(entry[1])}</option>)
                                                        }
                                                    </Input>
                                            } />

                                        </FormGroup>

                                    </Col>


                                    {tenantProjects.length > 1 &&
                                        <Col xs="12" md="6">
                                            <FormGroup>
                                                <Label for="inviteUserMultiselectCompanies" className="font-weight-bold text-uppercase">{t("Projects.CompanyLabel")}</Label>
                                                <MultiSelectDropdown
                                                    id="inviteUserMultiselectCompanies"
                                                    message={!selectedClientIds.length ? t("Projects.NoneLabel") : t("Projects.SelectedCompanyCount").replace("[[Count]]", selectedClientIds.length.toString())}
                                                    options={tenantProjects
                                                        .map(c => ({ label: c.tenantName, value: c.tenantId.toString() }))
                                                        .sort((a, b) => a.label.localeCompare(b.label))
                                                    }
                                                    chosenOptions={selectedClientIds
                                                        .map(clientId => ({ label: tenantProjects.find(c => c.tenantId.toString() === clientId)?.tenantName || "", value: clientId }))
                                                    }
                                                    onChange={values => {
                                                        setSelectedClientIds(values.map(v => v.value));
                                                        setShowErrorForMultiSelectTenants(!(values.map(v => v.value).length > 0));
                                                    }}
                                                />
                                                {showErrorForMultiSelectTenants &&
                                                    <div className="error-tenant-multiselect">{t("Projects.SelectAtleastOneCompany")}</div>
                                                }
                                            </FormGroup>

                                        </Col>
                                    }

                                </Row>


                                <Row>
                                    <Col className="mb-2 mt-3">
                                        <h2>{t("UserPermissions.ProjectPermissions")}</h2>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        {filteredProjects && filteredProjects.length > 0 && (
                                            <Nav tabs>
                                                {filteredProjects?.map((tenant) => (
                                                    <NavItem key={tenant.tenantId}>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === tenant.tenantId })}
                                                            onClick={() => setActiveTab(tenant.tenantId)}
                                                        >
                                                            {tenant.tenantName}
                                                        </NavLink>
                                                    </NavItem>
                                                ))}
                                            </Nav>
                                        )}

                                        <TabContent activeTab={activeTab}>
                                            {filteredProjects?.map((tenant) => (
                                                <TabPane key={tenant.tenantId} tabId={tenant.tenantId} className={filteredProjects.length === 1 ? "active" : ""}>
                                                    <Container className="px-0">
                                                        {userType == UserType.SelfServiceSuperUser ?
                                                            <div>                                                              
                                                                <h3 className="alert alert-info">{t("OrderingPortal.UserManagementSuperUserProjectAccess")}</h3>
                                                            </div>
                                                            :
                                                            tenant.projects?.length <= 0 ?
                                                                <div>
                                                                    <h3 className="alert alert-info">{t("Projects.CompanyHasNoProjects")}</h3>
                                                                </div>
                                                                :
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t("UserPermissions.JobNumber")}</th>
                                                                        <th>{t("UserPermissions.ProjectName")}</th>
                                                                        <th>{t("UserPermissions.ProjectType")}</th>
                                                                        <th>{t("UserPermissions.OrderPlacement")}</th>
                                                                        <th>{t("UserPermissions.CustomerService")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {tenant.projects?.map((item) =>
                                                                        <tr key={item.projectId}>
                                                                            <td>{item.jobNumber}</td>
                                                                            <td>{item.projectName}</td>
                                                                            <td>{t(ProjectTypeLabel.get(item.projectType) ?? "")}</td>
                                                                            <td>
                                                                                <input
                                                                                    style={{ cursor: "pointer" }}
                                                                                    name={"orderPlacement" + item.projectId.toString()}
                                                                                    id={"orderPlacement" + item.projectId.toString()}
                                                                                    onChange={() => {
                                                                                        setTenantProjects(permissions => (
                                                                                            permissions?.map((tenant) => ({
                                                                                                ...tenant,
                                                                                                projects: tenant.projects.map((project) =>
                                                                                                    project.projectId === item.projectId
                                                                                                        ? { ...project, hasOrderPlacementPermission: !project.hasOrderPlacementPermission }
                                                                                                        : project
                                                                                                )
                                                                                            })) ?? permissions
                                                                                        ));
                                                                                    }}
                                                                                    checked={item.hasOrderPlacementPermission }
                                                                                    type="checkbox"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    style={{ cursor: "pointer" }}
                                                                                    name={"customerService" + item.projectId.toString()}
                                                                                    id={"customerService" + item.projectId.toString()}
                                                                                    onChange={() => {
                                                                                        setTenantProjects(permissions => (
                                                                                            permissions?.map((tenant) => ({
                                                                                                ...tenant,
                                                                                                projects: tenant.projects.map((project) =>
                                                                                                    project.projectId === item.projectId
                                                                                                        ? { ...project, hasCustomerServicePermission: !project.hasCustomerServicePermission }
                                                                                                        : project
                                                                                                )
                                                                                            })) ?? permissions
                                                                                        ));
                                                                                    }}
                                                                                    checked={item.hasCustomerServicePermission}
                                                                                    type="checkbox"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        }
                                                    </Container>
                                                </TabPane>
                                            ))}
                                        </TabContent>
                                    </Col>
                                </Row>   

                                <Row>
                                    <Col>
                                        <Button color="primary" type="submit" disabled={filteredProjects.length <= 0} className="text-uppercase float-right">{t("SaveChangesLabel")}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            )}
        </Formik>
    </div>);
}

const ConnectedComponent = connect(null, mapDispatchToProps)(accountDetails);

export { ConnectedComponent as AccountDetails };