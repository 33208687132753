import React from 'react';
import { BatchStatus, BatchType, DownloadableBatchStatus, DownloadableBatchType, PaymentMethod } from '../Models';
import PaymentMethodImage_PaymentOnAccount from 'Assets/Images/Account-payment-X1.png';
import PaymentMethodImage_BacsChaps from 'Assets/Images/BACS-CHAPS-X1.png';
import PaymentMethodImage_DebitCreditCard from 'Assets/Images/Card-payment-X1.png';
import PlaceholderCircle from 'Assets/Images/placeholderCircle.png';


export const getBatchStatusLocalizationKey = (batchStatus: BatchStatus) => {

    if (batchStatus === BatchStatus.FailedRiskReview) {
        return "RedemptionCodeBatchStatus." + BatchStatus[BatchStatus.Cancelled];
    }
    return "RedemptionCodeBatchStatus." + BatchStatus[batchStatus];
}

export const getBatchTypeLocalizationKey = (batchType: BatchType) => {

    return "RedemptionCodeBatchType." + BatchType[batchType];
}

export const getDownloadableBatchTypeLocalizationKey = (batchType: DownloadableBatchType) => {

    return "DownloadableBatchType." + DownloadableBatchType[batchType];
}
export const getDownloadableBatchStatusLocalizationKey = (batchStatus: DownloadableBatchStatus) => {

    return "DownloadableBatchStatus." + DownloadableBatchStatus[batchStatus];
}
export const getPaymentMethodLocalizationKey = (paymentMethod: PaymentMethod) => {

    return "PaymentMethod." + PaymentMethod[paymentMethod];
}
export const getPaymentImage = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
        case PaymentMethod.BacsChaps:
            return PaymentMethodImage_BacsChaps;
        case PaymentMethod.DebitCreditCard:
            return PaymentMethodImage_DebitCreditCard;
        case PaymentMethod.PaymentOnAccount:
            return PaymentMethodImage_PaymentOnAccount;
        default:
            return PlaceholderCircle;
    }
}