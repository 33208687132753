import { ApplicationState } from "Store";
import { store } from "Store/StoreConfiguration";

export function getAuthHeader() {
    // return authorization header with jwt token
    const state = store.getState() as ApplicationState;

    if (state?.auth?.loggedIn &&
        state?.auth?.accessToken) {
        return {
            'Authorization': `Bearer ${state.auth.accessToken}`,
        };
    } else {
        return undefined;
    }
}
