import { ClaimTypes } from "../Auth";
import { UserType } from "../Models";
import { ApplicationState } from "../Store";
import { store } from "../Store/StoreConfiguration";
import { getClaimValue } from "../Utilities";

const loggedInUser = () => {
    const state = store.getState() as ApplicationState;
    return state.user?.user;
};

const isLoggedInUserSuperUser = () => {
    const claimValue = getClaimValue(loggedInUser(), ClaimTypes.UserType);
    return claimValue != undefined ? (+claimValue as UserType) == UserType.SelfServiceSuperUser : false;
};

const hasUserLoggedInThroughSSO = () => {
    const claimValue = getClaimValue(loggedInUser(), ClaimTypes.IsSSO);
    return claimValue != undefined ? (claimValue === "True") : false;
}

export const serviceUtils = {
    loggedInUser,
    isLoggedInUserSuperUser,
    hasUserLoggedInThroughSSO
};