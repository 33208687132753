import React from "react";
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bemNames, getErrorNotification, getSuccessNotification } from 'Utilities';
import { ProjectTypeLabel, UserPermissionItem, UserType } from 'Models';
import { useTranslation } from "react-multi-lang";
import { AreaLoader } from "../Components";
import { editUserService, userManagementService } from "../Services";
import Notifications from 'react-notification-system-redux';
import { useParams } from "react-router-dom";
import { Col, Container, Row, Table } from "reactstrap";

interface StoreProps {
    notifyError: Notifications.NotificationShow;
    notifySuccess: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
    notifySuccess: Notifications.success
}

const UserPermissions = ({
    notifyError,
    notifySuccess
}: StoreProps) => {
    
    const t = useTranslation();
    const bem = bemNames.create("edit-user");

    const [isSuperUser, setIsSuperUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userPermissions, setUserPermissions] = useState<UserPermissionItem[]>();

    // url params
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        tryLoadingUserPermissions();
    }, [id]);

    const tryLoadingUserPermissions = async () => {
        setLoading(true);
        const fetchedAccountDetails = await editUserService.getAccountDetails(id);
        if (fetchedAccountDetails.roleId == UserType.SelfServiceSuperUser) {
            setIsSuperUser(true);
        }
        try {
            if (!isSuperUser) {
                const data = await userManagementService.getUserPermissions(id);
                //setUserPermissions(data);
            }
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("UserPermissions.LoadPermissionsFailure")));
        }
        finally {
            setLoading(false);
        }
    }

    const submit = async () => {
        setLoading(true);

        try {
            if (userPermissions != undefined) {
                await userManagementService.setUserPermissions(id, userPermissions);
                notifySuccess(getSuccessNotification(t("UserPermissions.SubmitPermissionsSucceded")));
            }            
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("UserPermissions.SubmitPermissionsFailure")));
        }

        setLoading(false);
    }

    const toggleOrderPlacementPermission = (index: number, item: UserPermissionItem) => {
        setUserPermissions(permissions => permissions?.map((p, i) => i === index ?
            { ...p, hasOrderPlacementPermission: !item.hasOrderPlacementPermission } : p))
    }

    const toggleCustomerServicePermission = (index: number, item: UserPermissionItem) => {
        setUserPermissions(permissions => permissions?.map((p, i) => i == index ?
            { ...p, hasCustomerServicePermission: !item.hasCustomerServicePermission } : p));
    }

    return (
        <>
            <div className={bem.e("section-title", "font-weight-bold p-3")} style={{ lineHeight: "1rem" }}>
                <Container>
                    <Row>
                        <Col>
                            <h2>{t("UserPermissions.ProjectPermissions")}</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={bem.e("section-content")}>
                <AreaLoader show={loading} message={t("UserPermissions.LoadPermissionsMessage")} />
                <Container>
                    {isSuperUser
                        ? <h3 className="alert alert-info">{t("OrderingPortal.UserManagementSuperUserProjectAccess")}</h3>
                        : <>
                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>{t("UserPermissions.JobNumber")}</th>
                                                <th>{t("UserPermissions.ProjectName")}</th>
                                                <th>{t("UserPermissions.Currency")}</th>
                                                <th>{t("UserPermissions.ProjectType")}</th>
                                                <th>{t("UserPermissions.OrderPlacement")}</th>
                                                <th>{t("UserPermissions.CustomerService")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userPermissions?.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{item.jobNumber}</td>
                                                    <td>{item.projectName}</td>
                                                    <td>{item.currency}</td>
                                                    <td>{t(ProjectTypeLabel.get(item.projectType) ?? "")}</td>
                                                    <td>
                                                        <input
                                                            style={{ cursor: "pointer" }}
                                                            name={"orderPlacement" + index.toString()}
                                                            id={"orderPlacement" + index.toString()}
                                                            defaultChecked={item.hasOrderPlacementPermission}
                                                            onClick={() => toggleOrderPlacementPermission(index, item)}
                                                            type="checkbox"
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            style={{ cursor: "pointer" }}
                                                            name={"customerService" + index.toString()}
                                                            id={"customerService" + index.toString()}
                                                            defaultChecked={item.hasCustomerServicePermission}
                                                            onClick={() => toggleCustomerServicePermission(index, item)}
                                                            type="checkbox"
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button className="btn btn-primary" onClick={submit}>
                                        {t("UserPermissions.Submit")}
                                    </button>
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </div>
        </>
    );
}

const ConnectedComponent = connect(null, mapDispatchToProps)(UserPermissions);

export {
    ConnectedComponent as UserPermissionsPage
}

//remove this page and referance