import React, { useMemo, useRef, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, InputProps } from 'reactstrap';
import { ErrorMessage, FieldProps, getIn, useFormikContext } from 'formik';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { bemNames } from 'Utilities/bemNames';
import PhoneInput from 'react-phone-input-2';

export function InputWithValidations({
    field,
    form: { touched, errors },
    showPasswordVisibilityToggle,
    inputPrefix,
    countryCodes,
    ...restProps
}: {
    showPasswordVisibilityToggle?: boolean,
    inputPrefix?: string,
    countryCodes?: string[],
} & FieldProps & InputProps) {

    const bem = bemNames.create("input-with-validations");

    const isTouched = !!getIn(touched, field.name);
    const error = getIn(errors, field.name);

    const initialInputTypeRef = useRef(restProps.type);
    const [inputType, setInputType] = useState(restProps.type);

    const { isSubmitting } = useFormikContext();

    const togglePasswordVisibility = () => {

        if (initialInputTypeRef.current !== "password") {

            return;
        }

        setInputType(v => v === "password" ? "text" : "password");
    }

    const showPasswordToggle = useMemo(() =>
        !!(initialInputTypeRef.current === "password" && showPasswordVisibilityToggle),
        [initialInputTypeRef.current, showPasswordVisibilityToggle]);

    const RenderCustomInput = () => {
        const inputProps: InputProps = {
            invalid: !!(isTouched && error),
            valid: !!(isTouched && !error),
            disabled: restProps.disabled || isSubmitting,
        }

        if (inputType === "tel") {
            const formattedCountryCodes = (countryCodes || [])
                .map(c => c.toLowerCase())
                .sort((a, b) => a.localeCompare(b));

            return (
                <PhoneInput
                    {...field}
                    country={formattedCountryCodes[0] || undefined}
                    onlyCountries={formattedCountryCodes}
                    countryCodeEditable={false}
                    onChange={(val, country, e, formattedValue) => {
                        if (!e || e.target?.name !== field.name) return;
                        e.target.value = formattedValue.replace(/\(|\)|\s/g, ''); // library injects brackets & spaces when formatting that we don't want
                        field.onChange(e);
                    }}
                    inputClass={bem.e("form-control",
                        (!!(isTouched && !error) ? "is-valid" : ""),
                        (!!(isTouched && error) ? "is-invalid" : ""))}
                    inputStyle={{ width: "100%" }}
                    inputProps={{
                        name: field.name,
                        autoComplete: inputProps.autoComplete,
                    }}
                    disabled={isSubmitting}
                    disableDropdown={isSubmitting}
                />
            );
        }

        return (
            <Input
                {...field}
                {...restProps}
                {...inputProps}
                type={inputType}
                style={showPasswordToggle ? {
                    backgroundPosition: "right calc(0.375em + 1.6rem) center"
                } : undefined}
            />
        );
    }

    return (
        <div className={bem.b()}>

            {
                //<pre>
                //    {JSON.stringify(isTouched, null, 4)}
                //    {JSON.stringify(hasError, null, 4)}
                //</pre>
            }

            <div className={bem.e("input-container",
                (!!(isTouched && !error) ? "is-valid" : ""),
                (!!(isTouched && error) ? "is-invalid" : ""))} >

                {inputPrefix
                    ? <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                {inputPrefix}
                            </InputGroupText>
                        </InputGroupAddon>
                        {RenderCustomInput()}

                    </InputGroup>
                    : RenderCustomInput()
                }

                {showPasswordToggle &&
                    <Button
                        className={bem.e("icon", "p-0 shadow-none text-secondary")}
                        color="link"
                        aria-label={inputType === "text" ? "Hide Password" : "Show Password"}
                        onClick={togglePasswordVisibility}
                        disabled={restProps.disabled || isSubmitting}
                    >
                        {inputType === "text"
                            ? <FaRegEyeSlash size={18} />
                            : <FaRegEye size={18} />
                        }
                    </Button>
                }

            </div>

            <ErrorMessage
                className="invalid-feedback"
                name={field.name}
                component="div"
            />

        </div>
    );
}