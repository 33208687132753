import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps } from "formik";
import { Redirect } from "react-router";
import * as Yup from "yup";
import { useTranslation } from 'react-multi-lang'
import { AxiosError } from "axios";
import { Link } from "react-router-dom";
import { ApplicationState } from "Store";
import * as UserReducer from 'Store/Reducers/UserReducer';
import * as AuthenticationReducer from 'Store/Reducers/AuthenticationReducer';
import { LoginAction } from "Store/Reducers/AuthenticationReducer";
import { AreaLoader, InputWithValidations } from "Components";
import { getHomepage } from "../Utilities/claimsHelper";
import { serviceUtils } from "Services/ServiceUtils";
import { AppSetting, Arkose } from "../Models";
import { SelectedLanguageDetails } from "Models";
import { getArkosePublicKey, isArkoseEnabled } from "../Utilities";


interface StoreProps {
    login: LoginAction;
    loadUser: typeof UserReducer.actions.loadUser;
    loggedIn?: boolean;
    appSettings: AppSetting[];
    loggingOut?: boolean;
    selectedLanguage?: SelectedLanguageDetails;
}

const mapStateToProps = ({ auth, user: userState, app }: ApplicationState) => {

    return {
        loggedIn: auth?.loggedIn ?? {},
        user: userState?.user ?? {},
        appSettings: app?.appSettings ?? [],
        loggingOut: userState?.loggingOut ?? undefined,
        selectedLanguage: app?.selectedLanguage
    };
}

const mapDispatchToProps = {
    login: AuthenticationReducer.actions.login,
    loadUser: UserReducer.actions.loadUser,
    setLoggingOut: UserReducer.actions.setLoggingOut
}

const LoginPage = ({
    login,
    loggedIn,
    appSettings,
    loggingOut,
    selectedLanguage
}: StoreProps) => {

    interface FormValues {
        emailAddress: string;
        password: string;
    }

    if (loggedIn) {
        var path = getHomepage(serviceUtils.loggedInUser());
        return <Redirect to={{ pathname: path }} />;
    }

    const [currentArkose, setCurrentArkose] = useState<Arkose>();
    const [submitting, setSubmitting] = useState(false);

    async function setupEnforcement(enforcement: Arkose) {
        setCurrentArkose(enforcement);
    }

    const ARKOSE_LABS_API = 'arkose-labs-api'
    const ARKOSE_LABS_DATA_CALLBACK = 'setupEnforcement';
    const ARKOSE_LABS_HOSTNAME = 'bhn-api.arkoselabs.com';
    const ARKOSE_LABS_API_SCRIPT_NAME = 'api.js';
    const ARKOSE_LABS_API_VERSION = 'v2';

    function generateArkoseLabsScriptSrc(publicKey: string, version: string) {
        return `https://${ARKOSE_LABS_HOSTNAME}/${version}/${publicKey}/${ARKOSE_LABS_API_SCRIPT_NAME}`;
    }

    function loadArkoseScript(publicKey: string) {

        var scriptElement = document.querySelector(`#${ARKOSE_LABS_API}`);
        if (scriptElement) {
            scriptElement.remove()
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = generateArkoseLabsScriptSrc(publicKey, ARKOSE_LABS_API_VERSION);
        script.setAttribute('data-callback', ARKOSE_LABS_DATA_CALLBACK);
        script.async = true;
        script.defer = true;
        script.id = ARKOSE_LABS_API;

        document.head.append(script);
    }

    useEffect(() => {
        if (appSettings && isArkoseEnabled(appSettings)) {
            (window as any).setupEnforcement = setupEnforcement;
            loadArkoseScript(getArkosePublicKey(appSettings));            
        }

    }, []);

    useEffect(() => {
        if (loggingOut) {
            location.reload();           
        }
    }, [loggingOut])

    let arkoseRunning = false;
    async function setupAndRunArkose() {
        if (currentArkose && isArkoseEnabled(appSettings)) {
            currentArkose?.setConfig({
                onHide: function () {
                    setSubmitting(false);
                },
                onCompleted:
                    async (t: any) => {
                        if (!arkoseRunning) {
                            arkoseRunning = true;
                            await arkoseCompletedCallback(t?.token);
                        }
                    },
                onReady: function () {
                    currentArkose?.run();
                }
            });
        }
    }

    const formRef = useRef<FormikProps<FormValues> | null>(null);


    const t = useTranslation();

    const LoginDetailsValidationSchema = Yup.object().shape({
        emailAddress: Yup.string()
            .email(t("ValidationError.InvalidEmailAddressMessage"))
            .required(t("ValidationError.EmailAddressRequiredMessage")),
        password: Yup.string()
            .min(6, t("ValidationError.PasswordTooShortMessage"))
            .required(t("ValidationError.PasswordRequiredMessage"))
    });

    async function arkoseCompletedCallback(token: string) {

        if (token && token.length > 0 && formRef.current) {

            const values = formRef.current.values;
            try {

                if (values.emailAddress && values.password && token && selectedLanguage && selectedLanguage.languageCode) {
                    await login(values.emailAddress, values.password, token, selectedLanguage.languageCode);
                }

            } catch (e) {
                if (currentArkose) {
                    currentArkose?.reset();
                }
                setSubmitting(false);

                formRef.current.setFieldError("emailAddress", " ");

                try {

                    const axiosError = e as AxiosError;
                    if (axiosError) {
                        switch (axiosError.response?.status) {
                            case 403:
                                formRef.current.setFieldError("password", t("NotificationMessage.UserLockedOutMessage"));
                                return;
                            case 401:
                                if (axiosError.response?.data === "IPAddressNotWhitelisted") {
                                    formRef.current.setFieldError("password", t("NotificationMessage.IPAddressNotWhitelistedMessage"));
                                } else {
                                    formRef.current.setFieldError("password", t("NotificationMessage.IncorrectLoginCredentialsMessage"));
                                }
                                return;
                        }
                    }
                }
                catch (e) {
                    console.error(e);
                }
                formRef.current.setFieldError("password", t("UserManagement.FailedToLoadAccountDetailsMessage"));
                return;
            }
        }

        arkoseRunning = false;
    }

    return (
        <div>
            <Container>

                <Row>

                    <Col xs="0" sm="2" md="3"></Col>
                    <Col xs="12" sm="8" md="6">

                        <h1>{t("Login.LoginLabel")}</h1>

                        <div
                            className="mt-4"
                        >
                            <Formik<FormValues>
                                innerRef={formRef}
                                initialValues={{
                                    emailAddress: "",
                                    password: ""
                                }}
                                validationSchema={LoginDetailsValidationSchema}
                                onSubmit={async (values, actions) => {

                                    setSubmitting(true);
                                    if (isArkoseEnabled(appSettings)) {
                                        await setupAndRunArkose();
                                    } else {
                                        await arkoseCompletedCallback("PlaceholderToken");
                                    }
                                }}
                            >

                                {() => (
                                    <Form translate="yes" className="d-flex flex-column">

                                        <AreaLoader show={submitting} message={t("Login.LoggingInMessage")} />

                                        <FormGroup>
                                            <Label
                                                className="text-uppercase"
                                                for="emailAddress"
                                            >{t("Login.EmailAddressLabel")}</Label>

                                            <Field
                                                name="emailAddress"
                                                disabled={submitting}
                                                component={InputWithValidations} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label
                                                className="text-uppercase"
                                                for="password"
                                            >{t("Login.PasswordLabel")}</Label>

                                            <Field
                                                name="password"
                                                disabled={submitting}
                                                type="password"
                                                showPasswordVisibilityToggle={true}
                                                component={InputWithValidations}
                                            />

                                            <Link
                                                to="/forgot-password"
                                                tabIndex={5}
                                            >
                                                <Button
                                                    className="float-right"
                                                    color="link"
                                                    tabIndex={5}
                                                    type="button"

                                                >
                                                    {t("ForgotPassword.ForgotPasswordLabel")}
                                                </Button>
                                            </Link>
                                        </FormGroup>

                                        <Button
                                            color="primary"
                                            className="align-self-center"
                                            type="submit"
                                            style={{ maxWidth: "150px" }}
                                            disabled={submitting}
                                        >{t("Login.SubmitButton")}</Button>

                                    </Form>
                                )}

                            </Formik>

                        </div>
                    </Col>
                    <Col xs="0" sm="2" md="3"></Col>
                </Row>
            </Container>
            {loggingOut && <AreaLoader show={true} message={t("Login.LoggingOutLabel")} />}
        </div>
    );
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(LoginPage);

export {
    ConnectedComponent as LoginPage
}
