import { ClaimTypes, UserDetails } from 'Auth';
import { RolePermission } from '../Models/RolePermission';
import { ProjectRolePermissionDto } from 'Models/ProjectRolePermissionDto';
import { UserType } from '../Models';


export const getClaimValue = (user: UserDetails | undefined, claimType: ClaimTypes): string | undefined => {

    return user?.claims?.find(c => c.type === claimType)?.value;
}

export const hasProjectPermission = (user: UserDetails | undefined, permission: RolePermission, projectId?: number): boolean | undefined => {

    const userType = (getClaimValue(user, ClaimTypes.UserType) ?? 0) as UserType;

    if (userType == UserType.SelfServiceSuperUser) {
        return true;
    }

    const allProjectPermissions = getProjectPermissions(user);

    if (projectId && projectId > 0) {

        const projectPermissions = (allProjectPermissions?.find(d => d.projectId === projectId)?.permissions) as RolePermission[] ?? [];

        return projectPermissions?.length <= 0 ? false : !!projectPermissions.find(d => d === permission);
    }

    // if no projectid is supplied look at every project

    return !!allProjectPermissions?.find(d => d.permissions.find(p => p === permission));
}

export const getHomepage = (user: UserDetails | undefined): string => {

    const userType = (getClaimValue(user, ClaimTypes.UserType) ?? 0) as UserType;
    let hasProjects = false;
    if (userType == UserType.SelfServiceSuperUser) {
        hasProjects = true;
    } else {
        var permissions = getProjectPermissions(user);
        hasProjects = permissions.some(x => x.permissions.includes(RolePermission.OrderPlacement))
    }

    return (hasProjects ? "/projects" : "/downloads");
}

const getProjectPermissions = (user: UserDetails | undefined): ProjectRolePermissionDto[] => {

    const allProjectPermissionsValue = user?.claims?.find(c => c.type === ClaimTypes.ProjectPermissions)?.value ?? "[]";

    return JSON.parse(allProjectPermissionsValue) as ProjectRolePermissionDto[];
}