import React, { useEffect, useRef, useState, Ref } from "react";
import { UncontrolledPopover, PopoverBody, Button } from 'reactstrap';
import { StepWizardChildProps } from "react-step-wizard";
import { connect } from 'react-redux';
import { bemNames, getPaymentMethodLocalizationKey, getPaymentImage } from 'Utilities';
import { ApplicationState } from "Store";
import * as Yup from 'yup';
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import { DigitalOrderDetails, InvoiceAddress, PaymentMethod, BaseVoucherType } from "../Models";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputWithValidations } from ".";
import { HiCheckCircle } from "react-icons/hi";
import { BsFillExclamationCircleFill, BsInfoCircle } from 'react-icons/bs';
import { useTranslation } from "react-multi-lang";

import { orderingService } from "../Services";

interface StoreProps {
    setCurrentStep: typeof OrderReducer.actions.setCurrentStep;
    setOrder: typeof OrderReducer.actions.setOrder;
    attemptGoToStepNumber?: number;
    order: DigitalOrderDetails;
    setReferenceOrPONumber: typeof OrderReducer.actions.setReferenceOrPONumber;
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep;
    setOtherReference: typeof OrderReducer.actions.setOtherReference;
}

interface Props extends StoreProps, Partial<StepWizardChildProps> {
    defaultInvoiceAddress?: InvoiceAddress;
    codeType?: BaseVoucherType;
    isPayOnAccount?: boolean;
    paymentMethodItems?: any[];
    otherReferenceMandatory: boolean;
    totalOrderAmount: number;
    isOrderWithinCreditLimit: boolean;
}

const mapStateToProps = ({ digitalOrder, user: userState }: ApplicationState) => (
    {
        attemptGoToStepNumber: digitalOrder.attemptGoToStepNumber,
        order: digitalOrder.order,
        user: userState.user,
    })

const mapDispatchToProps = {
    setCurrentStep: OrderReducer.actions.setCurrentStep,
    setOrder: OrderReducer.actions.setOrder,
    attemptGoToStep: OrderReducer.actions.attemptGoToStep,
    setReferenceOrPONumber: OrderReducer.actions.setReferenceOrPONumber,
    setOtherReference: OrderReducer.actions.setOtherReference
}

const PaymentPageStep = ({
    setCurrentStep,
    isActive,
    attemptGoToStepNumber,
    goToStep,
    order,
    setOrder,
    attemptGoToStep,
    setReferenceOrPONumber,
    defaultInvoiceAddress,
    codeType,
    isPayOnAccount,
    setOtherReference,
    paymentMethodItems,
    otherReferenceMandatory,
    totalOrderAmount,
    isOrderWithinCreditLimit
}: Props) => {

    const t = useTranslation();
    const bem = bemNames.create("digital-order-wizard");

    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const popoverRef = useRef(null);
    const [isPaymentMethodLoading, setisPaymentMethodLoading] = useState(false);
    const [selectedPaymentItems, setSelectedPaymentItems] = useState<any[]>();
    const togglePopover = () => {
        setPopoverOpen(popoverOpen => !popoverOpen);
    }


    //function hidePopoverClick(ref: React.RefObject<any>) {
    //    useEffect(() => {
    //        function handleClickOutside(event: Event) {
    //            if (ref.current && !ref.current.contains(event.target)) {
    //                setPopoverOpen(false);
    //            }
    //        }

    //        document.addEventListener("mousedown", handleClickOutside);
    //        return () => {                
    //            document.removeEventListener("mousedown", handleClickOutside);
    //        };
    //    }, [ref])
    //}


    useEffect(() => {
        async function goToReviewStepIfValid() {

            if (attemptGoToStepNumber === 6) {

                if (order.paymentMethod === PaymentMethod.BacsChaps) {

                    await bacsFormikRef.current?.submitForm();

                    if (bacsFormikRef.current?.isValid) {
                        goToStep?.(6);
                    } else {
                        attemptGoToStep(5);
                    }

                } else if (order.paymentMethod === PaymentMethod.PaymentOnAccount || order.paymentMethod === PaymentMethod.DebitCreditCard) {
                    goToStep?.(6);
                }
            } else if (attemptGoToStepNumber === 5) {
                goToStep?.(5);
            }
        }

        goToReviewStepIfValid();
    }, [attemptGoToStepNumber])

    useEffect(() => {
        // reset save address whenever payment method changes
        if (order.paymentMethod === PaymentMethod.PaymentOnAccount) {
            setOrder({ ...order, saveInvoiceAddress: false });
        }    
        
    }, [order.paymentMethod])
    useEffect(() => {
        var arr = [];
        if (paymentMethodItems)
        {
            for (var i = 0; i < paymentMethodItems?.length; i++) {
                if (order.paymentMethod && order.paymentMethod === paymentMethodItems[i]) {
                    arr.push(true);
                } else {
                    arr.push(false);
                }
            }
            setSelectedPaymentItems(arr);
        }
    }, [order.paymentMethod])

    const bacsFormikRef = useRef<FormikProps<any> | null>();

    if (isActive) {
        setCurrentStep(5);
    }

    const BacsChapsValidationSchema = Yup.object().shape({
        addressLine1: Yup.string()
            .required(t("ValidationError.AddressLineRequiredMessage")),
        cityTown: Yup.string()
            .required(t("ValidationError.CityTownRequiredMessage")),
    });

    const handlePaymentMethodClick = (payment: PaymentMethod, index: number) => {
        setOrder({ ...order, paymentMethod: payment });
    };
    return (
        <div className={bem.e("payment")}>
            <div className="card w-100">
                <div className="card-body">
                    <div className="d-block w-100">

                        <h2>{t("OrderDetails.PaymentInformationLabel")}</h2>

                        <p className="mt-3 st text-uppercase"><strong>{t("OrderDetails.PaymentMethodLabel")} *</strong></p>

                        <div data-automation-id="paymenMethods"  className="buttons w-100 d-inline-flex flex-column flex-lg-row">
                            {paymentMethodItems?.map((payment, i) => (
                                <button
                                    key={"PaymentMethodbtn_" + payment}
                                    tabIndex={isActive ? 0 : -1}
                                    onClick={() => handlePaymentMethodClick(payment, i)}
                                    style={{ minWidth: "200px" }}
                                    type="button"
                                    className={"btn btn-light large mb-3 mb-lg-0 mr-lg-3 py-3 border rounded border-solid d-flex flex-column justify-content-start align-items-center"
                                        + ((selectedPaymentItems && selectedPaymentItems[i]) ? " active" : "")}
                                    disabled={!isActive || (!isOrderWithinCreditLimit && payment == PaymentMethod.PaymentOnAccount ? true : false)}
                                    
                                ><HiCheckCircle size={28} color="limegreen" className="check" /><img className="mb-2" src={getPaymentImage(payment)} alt="placeholder" />{t(getPaymentMethodLocalizationKey(payment))}</button>
                            ))}
                        </div>
                    </div>
                    <label className="mt-3 text-uppercase">{t("OrderDetails.ReferencePoNumberLabel")} *</label>
                    <input
                        tabIndex={isActive ? 0 : -1}
                        maxLength={20}
                        style={{ maxWidth: "344px" }}
                        defaultValue={order.referenceOrPONumber}
                        onChange={e => setReferenceOrPONumber(e.target?.value ?? "")}
                        type="text"
                        className="form-control"
                        name="ReferenceOrPoNumber "
                        disabled={!isActive}
                    />
                    <label className="mt-3 text-uppercase">{t("OrderDetails.OtherReferenceLabel")}{otherReferenceMandatory ? " *" : ""}</label>
                    <div style={{ position: "relative", maxWidth: "344px" }}>
                        <input
                            tabIndex={isActive ? 0 : -1}
                            maxLength={60}
                            defaultValue={order.otherReference}
                            onChange={e => setOtherReference(e.target?.value ?? "")}
                            type="text"
                            className="form-control mb-2 w-100"
                            name="OtherReference"
                            disabled={!isActive}
                        />
                        <Button tabIndex={isActive ? 0 : -1} className="btn-unstyled" style={{ position: "absolute", right: "10px", top: "5px", cursor: "pointer", padding: "0px" }} id="PopoverFocus" disabled={!isActive}>
                            <BsInfoCircle />
                        </Button>

                    </div>
                    <UncontrolledPopover placement="bottom" target="PopoverFocus" trigger="focus" >
                        <PopoverBody>{t("OrderDetails.OtherReferenceCopy")}</PopoverBody>
                    </UncontrolledPopover>

                </div>
            </div>

            {order.paymentMethod &&
                <div className="card w-100 mt-3">
                    <div className="card-body">
                        <h2>
                        {order.paymentMethod === PaymentMethod.BacsChaps && t("PaymentMethod.BacsChaps")}
                        {order.paymentMethod === PaymentMethod.PaymentOnAccount && t("PaymentMethod.PaymentOnAccount")}
                        {order.paymentMethod === PaymentMethod.DebitCreditCard && t("PaymentMethod.DebitCredit")}
                        </h2>
                    {order.paymentMethod === PaymentMethod.BacsChaps && 
                            <div>

                                <p>{t("OrderingProcess.AddressRequiredForProformaMessage")}</p>

                                <Formik
                                    initialValues={{
                                        addressLine1: defaultInvoiceAddress?.addressLine1 ?? "",
                                        addressLine2: defaultInvoiceAddress?.addressLine2 ?? "",
                                        addressLine3: defaultInvoiceAddress?.addressLine3 ?? "",
                                        cityTown: defaultInvoiceAddress?.cityTown ?? "",
                                        postCode: defaultInvoiceAddress?.postCode ?? "",
                                        saveInvoiceAddress: false
                                    }}
                                    validateOnMount={true}
                                    enableReinitialize
                                    innerRef={ref => bacsFormikRef.current = ref}
                                    validationSchema={BacsChapsValidationSchema}
                                    onSubmit={async (values) => {

                                        await setOrder({
                                            ...order,
                                            invoiceAddress: {
                                                addressLine1: values.addressLine1,
                                                addressLine2: values.addressLine2,
                                                addressLine3: values.addressLine3,
                                                cityTown: values.cityTown,
                                                postCode: values.postCode
                                            },
                                            saveInvoiceAddress: values.saveInvoiceAddress
                                        });
                                    }}
                                >

                                    {({ isSubmitting, values, setFieldValue }) => (
                                    <Form translate="yes">
                                            <div className="mt-3">
                                                <label className="text-uppercase">{t("OrderingProcess.AddressLine1Label")} *</label>
                                                <Field
                                                    name="addressLine1"
                                                    disabled={!isActive || isSubmitting}
                                                    component={InputWithValidations}
                                                    className="form-control"
                                                    tabIndex={isActive ? 0 : -1}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className="text-uppercase">{t("OrderingProcess.AddressLine2Label")}</label>
                                                <Field
                                                    name="addressLine2"
                                                    disabled={!isActive || isSubmitting}
                                                    component={InputWithValidations}
                                                    className="form-control"
                                                    tabIndex={isActive ? 0 : -1}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className="text-uppercase">{t("OrderingProcess.AddressLine3Label")}</label>
                                                <Field
                                                    name="addressLine3"
                                                    disabled={!isActive || isSubmitting}
                                                    component={InputWithValidations}
                                                    className="form-control"
                                                    tabIndex={isActive ? 0 : -1}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className="text-uppercase">{t("OrderingProcess.CityTownLabel")} *</label>
                                                <Field
                                                    name="cityTown"
                                                    disabled={!isActive || isSubmitting}
                                                    component={InputWithValidations}
                                                    className="form-control"
                                                    tabIndex={isActive ? 0 : -1}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className="text-uppercase">{t("OrderingProcess.PostcodeLabel")}</label>
                                                <Field
                                                    name="postCode"
                                                    disabled={!isActive || isSubmitting}
                                                    component={InputWithValidations}
                                                    className="form-control"
                                                    tabIndex={isActive ? 0 : -1}
                                                />
                                            </div>


                                            <div className="mt-4">
                                                <input
                                                    style={{ cursor: "pointer" }}
                                                    name="saveInvoiceAddress"
                                                    disabled={!isActive}
                                                    id="saveInvoiceAddress"
                                                    checked={values.saveInvoiceAddress}
                                                    onChange={e => setFieldValue("saveInvoiceAddress", e.target.checked)}
                                                    type="checkbox"
                                                    tabIndex={isActive ? 0 : -1}
                                                /><label style={{ cursor: "pointer" }} htmlFor="saveInvoiceAddress" className="pl-2">{t("OrderingProcess.SaveAddressForFutureLabel")}</label>
                                            </div>

                                            <div className="alert alert-info d-flex align-items-start mt-2" role="alert">
                                                <div>
                                                    <BsFillExclamationCircleFill fontSize="20" width="20px" className="mb-1" />
                                                </div>
                                                <p className="pl-2 mb-0">{t("OrderingProcess.UniqueReferenceNotificationMessage")}</p>
                                            </div>


                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        }
                        {order.paymentMethod === PaymentMethod.PaymentOnAccount &&
                            <p className="mt-3">{t("OrderingProcess.ReferToPaymentTermsLabel")}</p>                       
                    }
                    {order.paymentMethod === PaymentMethod.DebitCreditCard &&
                        <p className="mt-3">{t("OrderingProcess.DebitCreditCardDescription")}</p>
                    }
                    </div>
                </div>
            }

        </div>
    );
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(PaymentPageStep);

export {
    ConnectedComponent as PaymentPageStep
}