import React from "react";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ProfileBlock } from './ProfileBlock';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { ReactComponent as ContactIcon } from 'Assets/Images/icons/contact.svg';
import { ReactComponent as CustomerServiceIcon } from 'Assets/Images/icons/cust-service.svg';
import { ReactComponent as DownloadsIcon } from 'Assets/Images/icons/downloads.svg';
import { ReactComponent as HistoryIcon } from 'Assets/Images/icons/history.svg';
import { ReactComponent as ProjectsIcon } from 'Assets/Images/icons/projects.svg';
import { ReactComponent as UsersIcon } from 'Assets/Images/icons/users.svg';
import SelectLogo from 'Assets/Images/select-logo-blue.svg';
import { CgInfo } from "react-icons/cg";
import { ApplicationState } from "Store";
import { connect } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { LocalizationSelector } from ".";
import { UserType } from "../Models";
import { getClaimValue, hasProjectPermission } from "../Utilities";
import { ClaimTypes, UserDetails } from "Auth";
import { RolePermission } from "../Models/RolePermission";

interface StoreProps {
    loggedIn?: boolean;
    user?: UserDetails;
    isSSO: boolean;
}


function NavMenu({
    loggedIn,
    user,
    isSSO
}: StoreProps) {

    const t = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavBar = () => {
        setIsOpen(v => !v);
    }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm" light>

                {!isSSO &&
                    <Container>
                        <div className="d-block justify-content-between w-100">
                            <div className="d-block d-md-flex justify-content-between w-100">
                                <NavbarBrand tag={Link} to="/"><img alt="Select" src={SelectLogo} /></NavbarBrand>
                                <div className="d-flex flex-row flex-md-column justify-content-between">
                                    <div className="profile-wrap d-flex">
                                        <ProfileBlock />
                                        <LocalizationSelector />
                                    </div>
                                    <NavbarToggler onClick={toggleNavBar} className="my-1" />
                                </div>
                            </div>
                            <Collapse className="d-md-inline-flex" isOpen={isOpen} navbar>
                                <ul className="navbar-nav flex-grow">

                                    {/* Public routes */}
                                    {!loggedIn &&

                                        <>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark text-uppercase" to="/login"><ProjectsIcon fill="#343a40" />{t("Login.LoginLabel")}</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark text-uppercase" to="/guides"><CgInfo fill="#343a40" />{t("UserGuide.UserGuideLabel")}</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark text-uppercase" to="/contact"><ContactIcon fill="#343a40" />{t("ContactUs.ContactUsLabel")}</NavLink>
                                            </NavItem>
                                        </>
                                    }

                                    {/* Private routes */}
                                    {loggedIn &&

                                        <>
                                            {(Number(getClaimValue(user, ClaimTypes.UserType)) == UserType.SelfServiceSuperUser ||
                                                hasProjectPermission(user, RolePermission.OrderPlacement)) &&
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark text-uppercase" to="/projects"><ProjectsIcon fill="#343a40" />{t("Projects.ProjectsLabel")}</NavLink>
                                                </NavItem>
                                            }

                                            {(Number(getClaimValue(user, ClaimTypes.UserType)) == UserType.SelfServiceSuperUser ||
                                                hasProjectPermission(user, RolePermission.OrderPlacement)) &&
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark text-uppercase" style={{ paddingTop: "5px" }} to="/order-history"><HistoryIcon fill="#343a40" />{t("OrderHistory.OrderHistoryLabel")}</NavLink>
                                                </NavItem>
                                            }


                                            {Number(getClaimValue(user, ClaimTypes.UserType)) == UserType.SelfServiceSuperUser &&
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark text-uppercase" to="/manage-users"><UsersIcon fill="#343a40" />{t("UserManagement.ManageUsersTitleLabel")}</NavLink>
                                                </NavItem>
                                            }

                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark text-uppercase" to="/downloads"><DownloadsIcon fill="#343a40" />{t("YourDownloads.YourDownloadsLabel")}</NavLink>
                                            </NavItem>

                                            {(Number(getClaimValue(user, ClaimTypes.UserType)) == UserType.SelfServiceSuperUser ||
                                                hasProjectPermission(user, RolePermission.CustomerService)) &&
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark text-uppercase" to="/customer-service"><CustomerServiceIcon fill="#343a40" style={{ marginTop: "-3px" }} />{t("CustomerService.CustomerServiceLabel")}</NavLink>
                                                </NavItem>
                                            }

                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark text-uppercase" to="/guides"><CgInfo fill="#343a40" />{t("UserGuide.UserGuideLabel")}</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark text-uppercase" to="/contact"><ContactIcon fill="#343a40" />{t("ContactUs.ContactUsLabel")}</NavLink>
                                            </NavItem>
                                        </>
                                    }

                                </ul>
                            </Collapse>
                        </div>
                    </Container>
                }
            </Navbar>
        </header>
    );
}

function mapStateToProps({ auth, user: userState }: ApplicationState) {
    const {
        loggedIn
    } = auth || {};

    return {
        loggedIn,
        user: userState.user
    };
}


const ConnectedComponent = connect(mapStateToProps)(NavMenu);

export {
    ConnectedComponent as NavMenu
}