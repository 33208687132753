export enum BaseVoucherType {
    Undefined = 0,
    Physical = 1,
    ECode = 2,
    PrintReady = 3
}

export const ProjectTypeLabel = new Map<number, string>([
    [BaseVoucherType.Undefined, "ProjectManagement.ProjectTypeUndefined"],
    [BaseVoucherType.Physical, "ProjectManagement.ProjectTypePhysical"],
    [BaseVoucherType.ECode, "ProjectManagement.ProjectTypeECode"],
    [BaseVoucherType.PrintReady, "ProjectManagement.ProjectTypePrintReady"],
]);
