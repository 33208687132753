import React from 'react';
import { ProjectSetupType } from '../Models';


export class BatchFeesCalculator {
    private orderAmount: number;
    private vatRatePercentage: number;
    private _discountPercentage: number;
    private handlingFeePercentage: number;
    private costPerCode: number;
    private costPerSms: number;
    private noItemsCount: number;
    private noSmsItemsCount: number;
    private projectSetupType: ProjectSetupType;

    constructor(
        orderAmount: number,
        vatRatePercentage: number,
        discountPercentage: number,
        handlingFeePercentage: number,
        costPerCode: number,
        costPerSms: number,
        noItemsCount: number,
        noSmsItemsCount: number,
        projectSetupType: ProjectSetupType
    ) {
        this.orderAmount = orderAmount;
        this.vatRatePercentage = vatRatePercentage;
        this._discountPercentage = discountPercentage;
        this.handlingFeePercentage = handlingFeePercentage;
        this.costPerCode = costPerCode;
        this.costPerSms = costPerSms;
        this.noItemsCount = noItemsCount;
        this.noSmsItemsCount = noSmsItemsCount;
        this.projectSetupType = projectSetupType;
    }

    set setNumberOfSmsCodes(count: number) {
        this.noSmsItemsCount = count;
    }

    get numberOfSmsCodes() {
        return this.noSmsItemsCount;
    }

    get discountPercentage() {
        return this.projectSetupType === ProjectSetupType.UkBillOnRedemption
            ? 100
            : this._discountPercentage;
    }

    get discountAmount() {
        return this.projectSetupType === ProjectSetupType.UkBillOnRedemption
            ? this.orderAmount
            : this._discountPercentage > 0
                ? this.orderAmount * (this._discountPercentage / 100)
                : 0;
    }

    get vatAmount() {
        return this.projectSetupType === ProjectSetupType.UkBillOnRedemption || this.projectSetupType === ProjectSetupType.BillOnIssuance
            ? this.handlingFeeVatAmount + this.costPerCodeVatAmount + this.costPerSmsVatAmount
            : this.costPerCodeVatAmount + this.costPerSmsVatAmount;
    }

    get handlingFeeAmount() {
        return this.projectSetupType === ProjectSetupType.BillOnIssuance
            || this.projectSetupType === ProjectSetupType.UkBillOnRedemption
            ? this.orderAmount * (this.handlingFeePercentage / 100)
            : 0;
    }

    get handlingFeeVatAmount() {
        return this.handlingFeeAmount > 0
            ? this.handlingFeeAmount * (this.vatRatePercentage / 100)
            : 0;
    }

    get costPerCodeAmount() {
        return this.projectSetupType === ProjectSetupType.BillOnIssuance
            || this.projectSetupType === ProjectSetupType.UkBillOnRedemption
            ? this.noItemsCount * this.costPerCode
            : 0;
    }

    get costPerCodeVatAmount() {
        return (this.projectSetupType === ProjectSetupType.BillOnIssuance
            || this.projectSetupType === ProjectSetupType.UkBillOnRedemption)
            && this.costPerCodeAmount > 0
            ? this.costPerCodeAmount * (this.vatRatePercentage / 100)
            : 0; 
    }

    get costPerSmsAmount() {
        return this.projectSetupType === ProjectSetupType.BillOnIssuance
            || this.projectSetupType === ProjectSetupType.UkBillOnRedemption
            ? this.noSmsItemsCount * this.costPerSms
            : 0;
    }

    get costPerSmsVatAmount() {
        return (this.projectSetupType === ProjectSetupType.BillOnIssuance
            || this.projectSetupType === ProjectSetupType.UkBillOnRedemption)
            && this.costPerSmsAmount > 0
            ? this.costPerSmsAmount * (this.vatRatePercentage / 100)
            : 0;
    }

    get totalOrderAmount() {
        return this.projectSetupType === ProjectSetupType.UkBillOnRedemption
            ? this.handlingFeeAmount + this.costPerCodeAmount + this.costPerSmsAmount + this.vatAmount
            : (this.orderAmount + this.handlingFeeAmount + this.handlingFeeVatAmount + this.costPerCodeAmount + this.costPerCodeVatAmount + this.costPerSmsAmount + this.costPerSmsVatAmount) - this.discountAmount;
    }

}
