import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router";
import * as UserReducer from 'Store/Reducers/UserReducer';
import { GetJwtTokenAction } from "Store/Reducers/AuthenticationReducer";
import * as AuthenticationReducer from 'Store/Reducers/AuthenticationReducer';
import { ApplicationState } from "Store";
import { AreaLoader } from "../Components";
import { userService } from "../Services/UserService";
import axios from "axios";
import { resolve } from "dns";

interface StoreProps {
    getJwtToken: GetJwtTokenAction,
    loadUser: typeof UserReducer.actions.loadUser;
    loginSuccess: (accessToken: string, refreshToken: string) => void;
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
}

type MiddlewareResponse = {
    AccessToken: string,
    RefreshToken: string,
    calculatedItemId: number
}

export const ssoService = {
    SSOMiddleware
};

async function SSOMiddleware(actionId: string, productNumber: string, suborgid: string, jwttoken: string) {
    const response = await axios.get<string>(`sso-middlewareLogin`,
        {
            params: {
                actionId: actionId,
                itemId: productNumber,
                suborgid: suborgid,
                jwttoken: jwttoken
            }
        }
    )

    const queryParams = new URLSearchParams(response.data)

    const responseitemId = new URLSearchParams(queryParams).get('i') ?? "";
    // Get the token, which we had set in controller
    const JWTauthToken = new URLSearchParams(queryParams).get('t') ?? "";
    const JWTRefreshToken = new URLSearchParams(queryParams).get('rt') ?? "";

    return { itemId: responseitemId, JWTauthToken: JWTauthToken, JWTRefreshToken: JWTRefreshToken };
}

const mapStateToProps = ({ user: userState }: ApplicationState) => {
    return {
        user: userState?.user ?? {}
    };
}

const mapDispatchToProps = {
    getJwtToken: AuthenticationReducer.actions.getJwtToken,
    loadUser: UserReducer.actions.loadUser,
    loginSuccess: AuthenticationReducer.actions.loginSuccess,
    refreshTokens: AuthenticationReducer.actions.refreshTokens
}

const SsoMiddlewarePage = ({ loginSuccess, loadUser, refreshTokens }: StoreProps) => {

    let [actionId, setactionId] = useState("");
    let [itemId, setitemId] = useState("");
    let [JWTauthToken, setJWTauthToken] = useState("");
    let [JWTRefreshToken, setJWTRefreshToken] = useState("");

    // Get the token, which we had set in controller

    window.addEventListener('message', (event) => {
        actionId = event.data.actionId;
        setactionId(event.data.actionId);
        const productNumber = event.data.productNumber;
        const suborgid = event.data.subOrgId;
        const jwttoken = event.data.token;
        if (productNumber != "" && suborgid != "" && actionId != "" && jwttoken != "") {
            setIsBusy(true);
            ssoService.SSOMiddleware(actionId, productNumber, suborgid, jwttoken).then((response) => {
                itemId = response.itemId;
                JWTauthToken = response.JWTauthToken;
                JWTRefreshToken = response.JWTRefreshToken;
                setitemId(response.itemId);
                setJWTRefreshToken(response.JWTRefreshToken);
                setJWTauthToken(response.JWTauthToken);
            })
        }
    });

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isBusy, setIsBusy] = useState(true);
    const [redirectPath, setRedirectPath] = useState("");


    useEffect(() => {
        if (JWTauthToken == "" || JWTRefreshToken == "" || actionId == "" || actionId == "0" || itemId == "") {
            setIsBusy(false);
            setShowErrorMessage(true);
        } else {
            const refreshToken = async () => {
                const userDetails = await userService.refreshingToken(JWTauthToken, JWTRefreshToken, true);
                if (userDetails) {

                    await refreshTokens(userDetails.accessToken, userDetails.refreshToken);
                    await loadUser(userDetails);
                    loginSuccess(userDetails.accessToken, userDetails.refreshToken);

                    return;
                }
            }

            refreshToken()
                .then(() => {
                    var url = "";
                    switch (actionId.toString()) {
                        // order codes
                        case "1": url = `/projects/order-codes/${itemId}`;
                            break;
                        // view order
                        case "2": url = `/order-history/order-details/${itemId}`;
                            break;
                        // customer service
                        case "3": url = `/customer-service`;
                            break;
                    }
                    setIsBusy(false);
                    setRedirectPath(url);
                })
                .catch(() => {
                    setIsBusy(false);
                    setShowErrorMessage(true);
                    return;
                });
        }
    }, [JWTauthToken]);

    return (
        <div className="d-flex w-100 flex-column flex-md-row">
            <AreaLoader show={isBusy} />
            {showErrorMessage &&
                <div>
                    We could not log you on.
                </div>
            }
            {redirectPath !== "" &&
                <Redirect to={{ pathname: redirectPath }} />
            }
        </div>
    );
}


const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(SsoMiddlewarePage);

export {
    ConnectedComponent as SsoMiddlewarePage
}