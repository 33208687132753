
export class ContactUsDetails {
    name?: string = "";
    emailAddress?: string = "";
    companyName?: string = "";
    projects?: Unit[] = [];
    orderId?: number = 0;
    topics?: Unit[] = [];
    comments?: string = "";
    arkoseToken?: string = "";
    isArkoseEnabled: boolean = false
};

export interface Unit {
    id: number;
    description: string;
};