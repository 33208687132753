import React from "react";
import { useTranslation } from "react-multi-lang";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

import { bemNames } from "Utilities";
import { IClientDto } from "Models";

type Props = {
    clients: IClientDto[]
    selected: string[]
    setSelected: (selected: React.SetStateAction<string[]>) => void
    isOpen: boolean
    setIsOpen: (isOpen: React.SetStateAction<boolean>) => void
    alignDropdownLeft?: boolean
}

export const ClientFilter = ({ clients = [], selected, setSelected, isOpen, setIsOpen, alignDropdownLeft = false }: Props) => {

    const t = useTranslation();
    const bem = bemNames.create("client-filter");

    if (clients.length <= 1) return <></>;

    const toggleSelected = (id: string) => {
        if (selected.find(tenantId => tenantId === id)) {
            setSelected(selected.filter(tenantId => tenantId !== id));
        } else {
            setSelected([...selected, id]);
        }
    }

    return (
        <div className={bem.b("client-filter")}>
            <label className="text-uppercase">{t("Projects.CompanyLabel")}</label>
            <Dropdown
                className={bem.e(`dropdown ${alignDropdownLeft ? "align-left" : "align-right"}`)}
                isOpen={isOpen}
                toggle={() => setIsOpen(isOpen => !isOpen)}
            >
                <DropdownToggle id="client-filter" caret>
                    {t("Projects.SelectedCompanyCount").replace("[[Count]]", selected.length.toString())}
                </DropdownToggle>
                <DropdownMenu>
                    {clients.map(client => {
                        const isSelected = selected.find(tenantId => tenantId === client.tenantId);

                        return (
                            <DropdownItem
                                key={client.tenantId}
                                onClick={() => {
                                    toggleSelected(client.tenantId);
                                    setTimeout(() => setIsOpen(true));
                                }}
                            >
                                <span>{client.tenantName}</span>
                                <span className="ml-4">{isSelected ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}</span>
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}