import React, { useState } from "react";
import { ReactstrapDataTable } from "Components";
import { ECodeOrderItem, BaseVoucherType, PrintReadyOrderItem, DigitalOrderDetails, FileUploadBulkOrderItem, CodeDeliveryMethod } from "../Models";
import { Column, Cell } from "react-table";
import { MdEmail, MdSms } from "react-icons/md";
import { nameOf } from 'Utilities';
import { Modal, ModalBody, ModalHeader, Button, Card, CardBody, Col } from "reactstrap";
import { useTranslation } from "react-multi-lang";

import { userService } from 'Services';
import { AreaLoader } from "Components";
import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";
import { ApplicationState } from "../Store";
import { connect } from 'react-redux';

interface Props extends StoreProps, UserStoreProps, AuthStoreProps {
    codeType?: BaseVoucherType;
    order: DigitalOrderDetails;
    codeDeliveryMethod?: CodeDeliveryMethod;
}

interface StoreProps {
    refreshToken?: string,
    accessToken?: string
}

interface AuthStoreProps {
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
}

interface UserStoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
}

const mapStateToProps = ({ user, auth }: ApplicationState) => ({
    refreshToken: auth.refreshToken,
    accessToken: auth.accessToken
})

const mapDispatchToProps = {
    refreshTokens: AuthenticationReducer.actions.refreshTokens,
    loadUser: UserReducer.actions.loadUser
}


const FileUploadRowsTable = ({
    codeType,
    order,
    refreshTokens,
    loadUser,
    refreshToken,
    accessToken,
    codeDeliveryMethod
}: Props) => {
    const t = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isCodeSummaryModalOpen, setIsCodeSummaryModalOpen] = useState<boolean>(false); 
    const [selectedDistributorEmail, setselectedDistributorEmail] = useState<string>("");

    const [isBusy, setIsBusy] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("");

    const toggleModal = (distributorEmail: string) => {
        setselectedDistributorEmail(distributorEmail);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setselectedDistributorEmail("");
        setIsModalOpen(false);
    };

    const toggleCodeSummaryModal = (distributorEmail: string) => {
        setselectedDistributorEmail(distributorEmail);
        setIsCodeSummaryModalOpen(true);
    };

    const closeCodeSummaryModal = () => {
        setselectedDistributorEmail("");
        setIsCodeSummaryModalOpen(false);
    };

    const refreshingToken = async () => {
        setIsBusy(true);

        try {
            var userDetails = await userService.refreshingToken(accessToken, refreshToken);

            if (userDetails) {

                await refreshTokens(userDetails.accessToken, userDetails.refreshToken);
                await loadUser(userDetails);

                return;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsBusy(false);
        }
    }

    const formatDate = (dateString: string) => {

        if (typeof dateString === 'number') {
            dateString = String(dateString);
        }
        if (!dateString || dateString.length < 8) {
            return '';
        }

        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        const date = new Date(`${year}-${month}-${day}`);
        return date.toLocaleDateString();
    };

    const printReadyFileUploadItemsFiltered = order.fileUploadPrintReadyItems.filter((v, i, a) => a.findIndex(t => (t.distributorEmailAddress === v.distributorEmailAddress)) === i);

    const EcodeBulkFileUploadItemsFiltered = order.fileUploadBulkOrderItems.filter((v, i, a) => a.findIndex(t => (t.distributorEmailAddress === v.distributorEmailAddress)) === i);
        
    const ECodeFileUploadColumnDefinitions: Column<ECodeOrderItem>[] = [
        {
            id: "CodeDeliverMethod",
            Header: t("OrderDetails.DeliveryMethodHeading"),
            accessor: (model) => model.emailAddress,
            Cell: (cell: Cell<ECodeOrderItem>) => {
                const item = cell.row.original;

                return <>
                    <div className="d-flex">
                        {IsEmailDelivery(item.emailAddress) && <div className="mr-1">{getEmailElement()}</div>}
                        {IsSmsDelivery(item.mobileNumber) && <div>{getSmsElement()}</div>}
                    </div>
                </>
            }
        },
        {
            id: nameOf<ECodeOrderItem>("firstName"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => model.firstName,
            Cell: (cell: Cell<ECodeOrderItem>) => {
                const item = cell.row.original;

                return <>{item.firstName} {item.lastName}</>
            }
        },
        {
            id: nameOf<ECodeOrderItem>("emailAddress"),
            Header: t("OrderDetails.EmailAddressLabel"),
            accessor: (model) => model.emailAddress
        },
        {
            id: nameOf<ECodeOrderItem>("mobileNumber"),
            Header: t("OrderSummary.PhoneNumberLabel"),
            accessor: (model) => model.mobileNumber
        },
        {
            id: nameOf<ECodeOrderItem>("company"),
            Header: t("OrderSummary.CompanyLabel"),
            accessor: (model) => model.company
        },
        {
            id: nameOf<ECodeOrderItem>("staffNumber"),
            Header: t("OrderDetails.StaffNumberLabel"),
            accessor: (model) => model.staffNumber
        },
        {
            id: nameOf<ECodeOrderItem>("reasonForReward"),
            Header: t("OrderDetails.ReasonForAwardLabel"),
            accessor: (model) => model.reasonForReward
        },
        {
            id: nameOf<ECodeOrderItem>("awardType"),
            Header: t("OrderDetails.AwardTypeLabel"),
            accessor: (model) => model.awardType
        },
        {
            id: nameOf<ECodeOrderItem>("awardValue"),
            Header: t("OrderDetails.ValueLabel"),
            accessor: (model) => model.awardValue
        },
        {
            id: nameOf<ECodeOrderItem>("awardOriginator"),
            Header: t("OrderDetails.OriginatorLabel"),
            accessor: (model) => model.awardOriginator
        }
    ];

    const hasSendDate = order.fileUploadECodeItems?.some(item => item.scheduledSendDate);
    if (hasSendDate) {
        ECodeFileUploadColumnDefinitions.push({
            id: "scheduledSendDate",
            Header: t("OrderDetails.ScheduledSendDateLabel"),
            accessor: (model) => formatDate(model.scheduledSendDate) || t("OrderDetails.ASAPLabel")
        });
    }

    const DistributorColumnDefinitions: Column<PrintReadyOrderItem>[] = [
        {
            id: nameOf<PrintReadyOrderItem>("firstName"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => model.firstName,
            Cell: (cell: Cell<PrintReadyOrderItem>) => {
                const item = cell.row.original;

                return <>{item.firstName} {item.lastName}</>
            }
        },
        {
            id: nameOf<PrintReadyOrderItem>("recipientEmailAddress"),
            Header: t("OrderDetails.EmailAddressLabel"),
            accessor: (model) => model.recipientEmailAddress
        },
        {
            id: nameOf<PrintReadyOrderItem>("company"),
            Header: t("OrderSummary.CompanyLabel"),
            accessor: (model) => model.company
        },
        {
            id: nameOf<PrintReadyOrderItem>("staffNumber"),
            Header: t("OrderDetails.StaffNumberLabel"),
            accessor: (model) => model.staffNumber
        },
        {
            id: nameOf<PrintReadyOrderItem>("reasonForReward"),
            Header: t("OrderDetails.ReasonForAwardLabel"),
            accessor: (model) => model.reasonForReward
        },
        {
            id: nameOf<PrintReadyOrderItem>("awardType"),
            Header: t("OrderDetails.AwardTypeLabel"),
            accessor: (model) => model.awardType
        },
        {
            id: nameOf<PrintReadyOrderItem>("awardValue"),
            Header: t("OrderDetails.ValueLabel"),
            accessor: (model) => model.awardValue
        },
        {
            id: nameOf<PrintReadyOrderItem>("awardOriginator"),
            Header: t("OrderDetails.OriginatorLabel"),
            accessor: (model) => model.awardOriginator
        },

    ]

    const CodeSummaryColumnDefinitions: Column<FileUploadBulkOrderItem>[] =[
        {
            id: nameOf<FileUploadBulkOrderItem>("codeValue"),
            Header: t("OrderDetails.ValueLabel"),
            accessor: (model) => model.codeValue
        },
        {
            id: nameOf<FileUploadBulkOrderItem>("quantity"),
            Header: t("YourDownloads.NumberOfCodes"),
            accessor: (model) => model.quantity
        }
    ]

    const printReadyFileUploadDefinitions: Column<PrintReadyOrderItem>[] = [
        {
            id: nameOf<PrintReadyOrderItem>("distributorFirstName"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => model.distributorFirstName,
            Cell: (cell: Cell<PrintReadyOrderItem>) => {
                const item = cell.row.original;

                return <>{item.distributorFirstName} {item.distributorLastName}</>
            }
        },
        {
            id: nameOf<PrintReadyOrderItem>("distributorEmailAddress"),
            Header: t("OrderDetails.EmailAddressLabel"),
            accessor: (model) => model.distributorEmailAddress
        },
        {
            id: nameOf<PrintReadyOrderItem>("distributorPhoneNumber"),
            Header: t("OrderDetails.DistributorPhoneNumberLabel"),
            accessor: (model) => model.distributorPhoneNumber
        },
        {
            id: "numberOfCodes",
            Header: t("YourDownloads.NumberOfCodes"),
            accessor: (model) => model.distributorEmailAddress,
            Cell: (cell: Cell<PrintReadyOrderItem>) => {
                const item = cell.row.original;

                return <>{order.fileUploadPrintReadyItems.filter(i => i.distributorEmailAddress === item.distributorEmailAddress).length}</>

            }
        },
        {
            id: nameOf<PrintReadyOrderItem>("awardValue"),
            Header: t("OrderDetails.TotalValueLabel"),
            accessor: (model) => model.awardValue,
            Cell: (cell: Cell<PrintReadyOrderItem>) => {
                const item = cell.row.original;

                return <>{`${order.fileUploadPrintReadyItems.filter(i => i.distributorEmailAddress === item.distributorEmailAddress).reduce((acc, item) => acc + +item.awardValue, 0)}`}</>

            }
        },
        {
            id: nameOf<PrintReadyOrderItem>("recipientEmailAddress"),
            Header: '',
            accessor: (model) => model.distributorEmailAddress,
            Cell: (cell: Cell<PrintReadyOrderItem>) => {
                const item = cell.row.original;

                return <div className="text-right">
                    <Button
                        onClick={async () => {
                            refreshingToken();
                            toggleModal(item.distributorEmailAddress);
                        }}>{t("OrderingProcess.ViewRecipientsModalButton")}
                    </Button>
                </div>
            }
        }
    ]

    const distributorRows = order.fileUploadPrintReadyItems.filter(obj => { return obj.distributorEmailAddress === selectedDistributorEmail });

    const EcodeBulkFileUploadColumnDefinition: Column<FileUploadBulkOrderItem>[] =
    [
        {
            id: nameOf<FileUploadBulkOrderItem>("distributorFirstName"),
            Header: t("OrderDetails.NameLabel"),
            accessor: (model) => model.distributorFirstName,
            Cell: (cell: Cell<FileUploadBulkOrderItem>) => {
                const item = cell.row.original;

                return <>{item.distributorFirstName} {item.distributorLastName}</>
            }
        },
        {
            id: nameOf<FileUploadBulkOrderItem>("distributorEmailAddress"),
            Header: t("OrderDetails.EmailAddressLabel"),
            accessor: (model) => model.distributorEmailAddress
        },
        {
            id: nameOf<FileUploadBulkOrderItem>("codeValue"),
            Header: t("OrderDetails.TotalValueLabel"),
            accessor: (model) => model.codeValue,
            Cell: (cell: Cell<FileUploadBulkOrderItem>) => {
                const item = cell.row.original;

                return <>{`${order.fileUploadBulkOrderItems.filter(i => i.distributorEmailAddress === item.distributorEmailAddress).reduce((acc, item) => acc + (+item.quantity * +item.codeValue), 0)}`}</>
            }
        },
        {
            id: nameOf<FileUploadBulkOrderItem>("quantity"),
            Header: t("YourDownloads.NumberOfCodes"),
            accessor: (model) => model.quantity,
            Cell: (cell: Cell<FileUploadBulkOrderItem>) => {
                const item = cell.row.original;

                return <>{`${order.fileUploadBulkOrderItems.filter(i => i.distributorEmailAddress === item.distributorEmailAddress).reduce((acc, item) => acc + +item.quantity, 0)}`}</>
            }
        },
            {
                id: nameOf<FileUploadBulkOrderItem>("distributorLastName"),
            Header: '',
            accessor: (model) => model.distributorEmailAddress,
            Cell: (cell: Cell<FileUploadBulkOrderItem>) => {
                const item = cell.row.original;

                return <div className="text-right">
                    <Button
                        onClick={async () => {
                            refreshingToken();
                            toggleCodeSummaryModal(item.distributorEmailAddress);
                        }}>{t("OrderingProcess.ViewCodesModalButton")}
                    </Button>
                </div>
            }
        }
    ]

    const codeSummaryRows = order.fileUploadBulkOrderItems.filter(obj => { return obj.distributorEmailAddress === selectedDistributorEmail });

    const IsEmailDelivery = (emailAddress: string) => { return (codeDeliveryMethod === CodeDeliveryMethod.Email || codeDeliveryMethod === CodeDeliveryMethod.EmailAndSms) && !!emailAddress && emailAddress.toString().trim() !== "" };

    const IsSmsDelivery = (mobileNumber: string) => { return (codeDeliveryMethod === CodeDeliveryMethod.Sms || codeDeliveryMethod === CodeDeliveryMethod.EmailAndSms) && !!mobileNumber && mobileNumber.toString().trim() !== "" };

    const numberOfEmailOnlyCodes = order.fileUploadECodeItems.filter((value) => IsEmailDelivery(value.emailAddress) && !IsSmsDelivery(value.mobileNumber)).length;

    const numberOfSmsOnlyCodes = order.fileUploadECodeItems.filter((value) => !IsEmailDelivery(value.emailAddress) && IsSmsDelivery(value.mobileNumber)).length;

    const numberOfEmailAndSmsCodes = order.fileUploadECodeItems.filter((value) => IsEmailDelivery(value.emailAddress) && IsSmsDelivery(value.mobileNumber)).length;

    const getEmailElement = () => {
        return <span style={{ display: "inline-block" }} className="c-order-status-badge bg-info">Email</span>;
    }

    const getSmsElement = () => {
        return <span style={{ display: "inline-block" }} className="c-order-status-badge bg-warning">Sms</span>;
    }

    return (
        <Card>
            <AreaLoader show={isBusy} message={loadingMessage} />

            <CardBody className="w-100 mt-3">

                {codeType == BaseVoucherType.ECode && order.fileUploadListValid &&
                    <div>
                        <h2>{t("OrderingProcess.CardOrderReviewTitle")}</h2>
                        <div className="d-flex align-content-center">
                            <div style={{ marginTop: "11px" }}>{t("OrderDetails.DeliveryMethodHeading")}</div>
                            {numberOfEmailOnlyCodes > 0 && <div className="ml-3"> x{numberOfEmailOnlyCodes}{" "}{getEmailElement()}{numberOfSmsOnlyCodes + numberOfEmailAndSmsCodes > 0 ? "," : ""}</div>}
                            {numberOfSmsOnlyCodes > 0 && <div className="ml-3"> x{numberOfSmsOnlyCodes}{" "}{getSmsElement()}{numberOfEmailAndSmsCodes > 0 ? "," : ""}</div>}
                            {numberOfEmailAndSmsCodes > 0 && <div className="ml-3"> x{numberOfEmailAndSmsCodes}{" "}{getEmailElement()}{" & "}{getSmsElement()} </div>}
                        </div>
                        <div className="mt-4 file-upload-table">
                            <ReactstrapDataTable
                                columnDefinitions={ECodeFileUploadColumnDefinitions}
                                items={order.fileUploadECodeItems! ?? []}
                                totalItemCount={order.fileUploadECodeItems!.length || 0}
                                hideSearch={true}
                            />
                        </div>
                    </div>
                }

                {
                    codeType == BaseVoucherType.ECode && order.ecodeBulkFileUploadListValid &&
                    <div>
                        <h2>{t("OrderingProcess.CardOrderReviewTitle")}</h2>
                        <div className="mt-4 file-upload-table">
                            <ReactstrapDataTable
                                columnDefinitions={EcodeBulkFileUploadColumnDefinition}
                                items={EcodeBulkFileUploadItemsFiltered! ?? []}
                                totalItemCount={EcodeBulkFileUploadItemsFiltered!.length || 0}
                                hideSearch={true}
                            />
                        </div>
                    </div>
                }

                {codeType == BaseVoucherType.PrintReady &&
                    <div>
                        <h2>{t("OrderingProcess.DistributorModalTitle")}</h2>
                        <div className="mt-4 file-upload-table">
                            <ReactstrapDataTable
                                columnDefinitions={printReadyFileUploadDefinitions}
                                items={printReadyFileUploadItemsFiltered ?? []}
                                totalItemCount={printReadyFileUploadItemsFiltered.length || 0}
                                hideSearch={true}
                            />
                        </div>
                    </div>
                }

            </CardBody>

            <Modal className="preview-modal" style={{ maxWidth: "max-content" }} isOpen={isModalOpen} toggle={closeModal}>
                <ModalHeader className="border-0" toggle={closeModal}><span style={{ fontSize: "1.5rem" }}>{t("OrderingProcess.DistributorModalTitle")}</span></ModalHeader>
                <ModalBody>
                    <ReactstrapDataTable
                        columnDefinitions={DistributorColumnDefinitions}
                        items={distributorRows! ?? []}
                        totalItemCount={distributorRows!.length || 0}
                        hideSearch={true}
                    />
                </ModalBody>
            </Modal>

            <Modal className="preview-modal" style={{ maxWidth: "max-content" }} isOpen={isCodeSummaryModalOpen} toggle={closeCodeSummaryModal}>
                <ModalHeader className="border-0" toggle={closeCodeSummaryModal}><span style={{ fontSize: "1.5rem" }}>{t("OrderingProcess.CodeSummaryTitle")}</span></ModalHeader>
                <ModalBody>
                    <ReactstrapDataTable
                        columnDefinitions={CodeSummaryColumnDefinitions}
                        items={codeSummaryRows! ?? []}
                        totalItemCount={codeSummaryRows!.length || 0}
                        hideSearch={true}
                    />
                </ModalBody>
            </Modal>

        </Card>)
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(FileUploadRowsTable);

export {
    ConnectedComponent as FileUploadRowsTable
}