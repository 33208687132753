import React, { useState } from "react";
import { connect } from 'react-redux';
import { bemNames, getErrorNotification, getHomepage, getSuccessNotification } from 'Utilities';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, Label } from "reactstrap";
import { InputWithValidations } from "../Components";
import { useTranslation } from "react-multi-lang";
import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";
import Notifications from 'react-notification-system-redux';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import { userService } from "../Services";
import { Redirect } from "react-router";
import { UserDetails } from "../Auth";
import { ApplicationState } from "../Store";

interface StoreProps {
    user?: UserDetails;
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
    loadUser: typeof UserReducer.actions.loadUser;
    notifySuccess: Notifications.NotificationShow;
    notifyError: Notifications.NotificationShow;
    showUserNotification: typeof UserReducer.actions.showUserNotification;
}

const mapStateToProps = ({ user }: ApplicationState) => ({
    user: user.user
});

const mapDispatchToProps = {
    refreshTokens: AuthenticationReducer.actions.refreshTokens,
    loadUser: UserReducer.actions.loadUser,
    notifySuccess: Notifications.success,
    notifyError: Notifications.error,
    showUserNotification: UserReducer.actions.showUserNotification,
}

const UpdatePasswordPage = ({
    refreshTokens,
    loadUser,
    user,
    notifyError,
    showUserNotification
}: StoreProps) => {

    const bem = bemNames.create("update-password");

    const t = useTranslation();

    const [isMinValid, setIsMinValid] = useState(false);
    const [isUppercaseValid, setIsUppercaseValid] = useState(false);
    const [isLowercaseValid, setIsLowercaseValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isSpecialValid, setIsSpecialValid] = useState(false);
    const [isPrevUsedValid, setIsPrevUsedValid] = useState(true);
    const [redirectToHomepage, setRedirectToHomepage] = useState(false);

    const UpdatePasswordValidationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .test({
                name: "custom",
                test: value => {

                    const minValid = !!(value && value.length >= 12)
                    const uppercaseValid = !!(value && value.match(/[A-Z]/));
                    const lowercaseValid = !!(value && value.match(/[a-z]/));
                    const numberValid = !!(value && value.match(/[0-9]/));
                    const specialCharValid = !!(value && value.match(/[!@#$£%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/));

                    setIsMinValid(minValid);
                    setIsUppercaseValid(uppercaseValid);
                    setIsLowercaseValid(lowercaseValid);
                    setIsNumberValid(numberValid);
                    setIsSpecialValid(specialCharValid);

                    return minValid && uppercaseValid && lowercaseValid && numberValid && specialCharValid;
                },
                message: t("ValidationError.PasswordRequirementsNotMetMessage")
            })
            .test({
                name: "custom",
                test: value => {
                    return isPrevUsedValid
                },
                message: t("ValidationError.PasswordCantMatchPrevious")
            }),
        reEnterPassword: Yup.string()
            .equals([Yup.ref('newPassword'), null], t("ValidationError.PasswordsMustMatch"))
            .required(t("ValidationError.ReEnterPasswordIsRequired"))
    });

    if (redirectToHomepage) {
        var path = getHomepage(user);
        return <Redirect to={{ pathname: path }} />;
    }

    return (
        <div className={bem.b()}>
            <div className="d-flex flex-column flex-md-row justify-content-center mt-3 mt-md-5">
                <div className={bem.e("requirements", "mr-md-5 pt-2 pt-md-4")}>
                    <strong>{t("UpdatePassword.PasswordRequirements")}</strong>
                    <p className="mt-2">{t("UpdatePassword.PasswordRequirementsMessage")}</p>
                    <div className="d-flex flex-column mt-3">
                        <p>{isMinValid
                            ? <IoIosCheckmarkCircle color={"#28a745"} size={20} className="mb-1 mr-2" />
                            : <IoIosCloseCircle color={"#878ea0"} size={20} className="mb-1 mr-2" />}{t("UpdatePassword.PasswordMinLengthDescription")}
                        </p>
                        <p>{isUppercaseValid
                            ? <IoIosCheckmarkCircle color={"#28a745"} size={20} className="mb-1 mr-2" />
                            : <IoIosCloseCircle color={"#878ea0"} size={20} className="mb-1 mr-2" />}{t("UpdatePassword.PasswordUppercaseDescription")}
                        </p>
                        <p>{isLowercaseValid
                            ? <IoIosCheckmarkCircle color={"#28a745"} size={20} className="mb-1 mr-2" />
                            : <IoIosCloseCircle color={"#878ea0"} size={20} className="mb-1 mr-2" />}{t("UpdatePassword.PasswordLowercaseDescription")}</p>
                        <p>{isNumberValid
                            ? <IoIosCheckmarkCircle color={"#28a745"} size={20} className="mb-1 mr-2" />
                            : <IoIosCloseCircle color={"#878ea0"} size={20} className="mb-1 mr-2" />}{t("UpdatePassword.PasswordNumberDescription")}</p>
                        <p>{isSpecialValid
                            ? <IoIosCheckmarkCircle color={"#28a745"} size={20} className="mb-1 mr-2" />
                            : <IoIosCloseCircle color={"#878ea0"} size={20} className="mb-1 mr-2" />}{t("UpdatePassword.PasswordSpecialCharacterDescription")}</p>
                    </div>
                </div>
                <div className={bem.e("small-panel", "card border-0 ml-md-5 py-4 px-5 mt-4 mt-md-0")} style={{ boxShadow: "0px 4px 6px #00000029" }}>
                    <h2 className="mb-5">{t("UpdatePassword.UpdatePassword")}</h2>
                    <Formik
                        initialValues={{
                            newPassword: "",
                            reEnterPassword: ""
                        }}
                        validationSchema={UpdatePasswordValidationSchema}
                        onSubmit={async (values, actions) => {

                            setIsPrevUsedValid(true);

                            try {

                                const newUserDetails = await userService.changePassword(values.newPassword, values.reEnterPassword);

                                if (newUserDetails) {

                                    await refreshTokens(newUserDetails.accessToken, newUserDetails.refreshToken);
                                    await loadUser(newUserDetails);

                                    setRedirectToHomepage(true);

                                    showUserNotification({ title: t("NotificationMessage.ChangePasswordSuccessTitle"), message: t("NotificationMessage.ChangePasswordSuccessMessage") });
                                } else {
                                    notifyError(getErrorNotification(t("NotificationMessage.ChangePasswordFailure")));
                                }

                            } catch (ex) {
                                console.error(ex);

                                if (ex?.response?.data === 'NotificationMessage.ChangePasswordFailure') {
                                    setIsPrevUsedValid(false);
                                } else {
                                    notifyError(getErrorNotification(t("NotificationMessage.ChangePasswordFailure")));
                                }

                            }

                            actions.validateField("newPassword");

                        }}
                    >
                        {({ isSubmitting, handleChange }) => (
                            <Form className="d-flex flex-column">
                                <FormGroup>
                                    <Label
                                        className="text-uppercase"
                                        for="newPassword"
                                    >{t("UpdatePassword.NewPasswordLabel")}</Label>

                                    <Field
                                        name="newPassword"
                                        disabled={isSubmitting}
                                        component={InputWithValidations}
                                        type="password"
                                        showPasswordVisibilityToggle={true}
                                        onChange={(e: any) => {
                                            setIsPrevUsedValid(true)
                                            handleChange(e)
                                        }}
                                        onKeyUp={(e: any) => {
                                            setIsPrevUsedValid(true)
                                            handleChange(e)
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label
                                        className="text-uppercase"
                                        for="reEnterPassword"
                                    >{t("UpdatePassword.ReEnterPasswordLabel")}</Label>

                                    <Field
                                        name="reEnterPassword"
                                        disabled={isSubmitting}
                                        component={InputWithValidations}
                                        type="password"
                                        showPasswordVisibilityToggle={true}
                                    />
                                </FormGroup>

                                <Button
                                    color="primary"
                                    className="align-self-center mt-5 text-uppercase"
                                    type="submit"
                                    disabled={isSubmitting}
                                >{t("UpdatePassword.ChangePasswordButton")}</Button>

                            </Form>
                        )}

                    </Formik>
                </div>
            </div>
        </div>
    )
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordPage);

export { ConnectedComponent as UpdatePasswordPage };