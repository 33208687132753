
export enum BatchType {
    Undefined = 0,
    Stock = 1,
    Physical = 2,
    PhysicalBatchParent = 3,
    PhysicalBatchSubOrder = 4,
    ElectronicBatch = 5,
    PrintReadyBulk = 6,
    PrintReadyBatch = 7,
    ElectronicBulk = 8
}
