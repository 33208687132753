import { ActionType, createAction, createReducer } from "typesafe-actions";
import { AppThunkResult } from "..";
import { UserDetails } from "Auth";
import { userService } from "Services";
import * as UserReducer from "./UserReducer";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthState {
    loggingIn: boolean;
    loggedIn: boolean;
    accessToken?: string;
    refreshToken?: string;
}

export const initialState: AuthState = {
    loggingIn: false,
    loggedIn: false
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    typeof actionCreators.loginRequest |
    typeof actionCreators.loginSuccess |
    typeof actionCreators.loginFailed |
    typeof actionCreators.logoutSuccess |
    typeof actionCreators.refreshTokens;

type ActionsType = ActionType<
    typeof actionCreators.loginRequest |
    typeof actionCreators.loginSuccess |
    typeof actionCreators.loginFailed |
    typeof actionCreators.logoutSuccess |
    typeof actionCreators.refreshTokens
>;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// Action definitions

const actionCreators = {
    loginRequest: createAction("LOGIN_REQUEST")(),
    loginSuccess: createAction("LOGIN_SUCCESS")<string, string>(),
    refreshTokens: createAction("REFRESH_TOKENS")<string, string>(),
    loginFailed: createAction("LOGIN_FAILURE")<string>(),
    logoutSuccess: createAction("LOGOUT_SUCCESS")(),
};

// Thunk actions

const login = (emailAddress: string, password: string, arkoseToken: string, languageCode: string): AppThunkResult<ActionsType | UserReducer.ActionsType, Promise<UserDetails>> =>
    async (dispatch) => {

        try {

            await dispatch(actionCreators.loginRequest());

            const user = await userService.login(emailAddress, password, arkoseToken, languageCode);

            // Update tokens in store
            await dispatch(UserReducer.actions.loadUser(user));
            await dispatch(actionCreators.loginSuccess(user.accessToken, user.refreshToken));

            return user;

        } catch (error) {

            //console.log(`There was an error trying to log in user '${error}'.`);
            await dispatch(actionCreators.loginFailed(error));

            throw error;
        }
    }

const logout = (refreshToken: string): AppThunkResult<ActionsType, Promise<boolean>> =>
    async (dispatch) => {

        try {

            await userService.logout(refreshToken);
            // if the server side logout succeeded update the react state to log the user out

            await dispatch(actionCreators.logoutSuccess());

            return true;
        } catch (error) {

            console.log(`There was an error trying to logout user '${error}'.`);
            await dispatch(actionCreators.logoutSuccess());
            return false;
        }
    }


const getJwtToken = (): AppThunkResult<ActionsType | UserReducer.ActionsType, Promise<UserDetails>> =>
    async (dispatch) => {

        try {

            await dispatch(actionCreators.loginRequest());

            const user = await userService.getJwtToken();
            // Update tokens in store
            await dispatch(UserReducer.actions.loadUser(user));
            await dispatch(actionCreators.loginSuccess(user.accessToken, user.refreshToken));

            return user;

        } catch (error) {

            //console.log(`There was an error trying to log in user '${error}'.`);
            await dispatch(actionCreators.loginFailed(error));

            throw error;
        }
    }


export const actions = {
    ...actionCreators,
    login,
    logout,
    getJwtToken
}

export type LogoutAction = (refreshToken: string) => Promise<boolean>
export type LoginAction = (username: string, password: string, arkoseToken: string, languageCode: string) => Promise<UserDetails>
export type GetJwtTokenAction = () => Promise<UserDetails>


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer = createReducer<AuthState, ActionsType>(initialState)
    .handleAction(actionCreators.loginRequest, (state: AuthState, action) => {
        return {
            ...state,
            loggingIn: true,
            loggedIn: false,
            accessToken: undefined,
            refreshToken: undefined
        }
    })
    .handleAction(actionCreators.loginSuccess, (state: AuthState, action) => {
        return {
            ...state,
            loggingIn: false,
            loggedIn: true,
            accessToken: action.payload,
            refreshToken: action.meta
        }
    })
    .handleAction(actionCreators.refreshTokens, (state: AuthState, action) => {
        return {
            ...state,
            accessToken: action.payload,
            refreshToken: action.meta
        }
    })
    .handleAction(actionCreators.loginFailed, (state: AuthState, action) => {
        return {
            ...state,
            loggingIn: false,
            loggedIn: false,
            accessToken: undefined,
            refreshToken: undefined
        }
    })
    .handleAction(actionCreators.logoutSuccess, (state: AuthState, action) => {
        return {
            ...state,
            loggingIn: false,
            loggedIn: false,
            accessToken: undefined,
            refreshToken: undefined
        }
    });