import React, { useRef, useEffect } from "react";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps } from "formik";
import { Redirect } from "react-router";
import * as Yup from "yup";
import { useTranslation } from 'react-multi-lang'
import { ApplicationState } from "Store";
import * as UserReducer from 'Store/Reducers/UserReducer';
import { AreaLoader, InputWithValidations } from "Components";
import { getHomepage } from "../Utilities/claimsHelper";
import { Link } from "react-router-dom";
import { useState } from "react";
import { UserDetails } from "../Auth";
import { userService } from "../Services";
import { AppSetting, Arkose } from "../Models";
import { SelectedLanguageDetails } from "Models";
import { getArkosePublicKey, isArkoseEnabled } from "../Utilities";


interface StoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
    showUserNotification: typeof UserReducer.actions.showUserNotification;
    loggedIn?: boolean;
    user?: UserDetails;
    appSettings: AppSetting[];
    selectedLanguage?: SelectedLanguageDetails;
}

const mapStateToProps = ({ auth, user: userState, app }: ApplicationState) => ({
    loggedIn: auth.loggedIn,
    user: userState.user,
    appSettings: app?.appSettings ?? [],
    selectedLanguage: app?.selectedLanguage
});

const mapDispatchToProps = {
    loadUser: UserReducer.actions.loadUser,
    showUserNotification: UserReducer.actions.showUserNotification
}

const ForgotPasswordPage = ({
    loggedIn,
    user,
    showUserNotification,
    appSettings,
    selectedLanguage
}: StoreProps) => {

    if (loggedIn) {
        var path = getHomepage(user);
        return <Redirect to={{ pathname: path }} />;
    }

    let Arkose: Arkose;

    function setupEnforcement(enforcement: Arkose) {
        Arkose = enforcement;
    }

    const ARKOSE_LABS_API = 'arkose-labs-api'
    const ARKOSE_LABS_DATA_CALLBACK = 'setupEnforcement';
    const ARKOSE_LABS_HOSTNAME = 'bhn-api.arkoselabs.com';
    const ARKOSE_LABS_API_SCRIPT_NAME = 'api.js';
    const ARKOSE_LABS_API_VERSION = 'v2';

    function generateArkoseLabsScriptSrc(publicKey: string, version: string) {
        return `https://${ARKOSE_LABS_HOSTNAME}/${version}/${publicKey}/${ARKOSE_LABS_API_SCRIPT_NAME}`;
    }

    function loadArkoseScript(publicKey: string) {

        var scriptElement = document.querySelector(`#${ARKOSE_LABS_API}`);
        if (scriptElement) {
            scriptElement.remove()
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = generateArkoseLabsScriptSrc(publicKey, ARKOSE_LABS_API_VERSION);
        script.setAttribute('data-callback', ARKOSE_LABS_DATA_CALLBACK);
        script.async = true;
        script.defer = true;
        script.id = ARKOSE_LABS_API;

        document.head.append(script);
    }

    useEffect(() => {
        if (appSettings && isArkoseEnabled(appSettings)) {
            (window as any).setupEnforcement = setupEnforcement;
            loadArkoseScript(getArkosePublicKey(appSettings));
        }
    }, []);    

    let arkoseRunning = false;
    async function setupAndRunArkose() {
        if (isArkoseEnabled(appSettings)) {

            Arkose.setConfig({
                onHide: function () {
                    setSubmitting(false);
                },
                onCompleted:
                    async (t: any) => {
                        if (!arkoseRunning) {
                            arkoseRunning = true;
                            await arkoseCompletedCallback(t.token);
                            setSubmitting(false);
                        }
                    },
                onReady: function () {
                    Arkose.run();
                }
            });
        }
    }

    const t = useTranslation();

    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const formRef = useRef<FormikProps<{
        emailAddress: string
    }> | null>(null);

    async function arkoseCompletedCallback(token: string) {

        if (token && token.length > 0 && formRef.current) {

            const values = formRef.current.values;
            formRef.current.setFieldError("emailAddress", " ");

            try {
                if (values) {

                    if (values.emailAddress && token && selectedLanguage && selectedLanguage.languageCode) {
                        await userService.requestResetPassword(values.emailAddress, token, selectedLanguage.languageCode);
                    }

                    showUserNotification({ title: t("NotificationMessage.ForgotPasswordRequestedTitle"), message: t("NotificationMessage.ForgotPasswordRequestedMessage") })

                    await formRef.current?.resetForm();

                    setRedirectToLogin(true);
                }
            }
            catch (e) {
                formRef.current.setFieldError("emailAddress", t("UserManagement.FailedToLoadAccountDetailsMessage"));
                console.error(e);
            }
        }

        arkoseRunning = false;
    }


    const ForgotPasswordValidationSchema = Yup.object().shape({
        emailAddress: Yup.string()
            .email(t("ValidationError.InvalidEmailAddressMessage"))
            .required(t("ValidationError.EmailAddressRequiredMessage"))
    });

    if (redirectToLogin) {
        return <Redirect to="/login" />;
    }

    return (
        <div>
            <Container>
                <Row>

                    <Col xs="0" sm="2" md="3"></Col>
                    <Col xs="12" sm="8" md="6">

                        <h1>{t("ForgotPassword.ForgotPasswordLabel")}</h1>

                        <div
                            className="mt-4"
                        >
                            <Formik
                                innerRef={formRef}
                                initialValues={{
                                    emailAddress: ""
                                }}
                                validationSchema={ForgotPasswordValidationSchema}
                                onSubmit={async (values, actions) => {
                                    setSubmitting(true);
                                    if (isArkoseEnabled(appSettings)) {
                                        await setupAndRunArkose();
                                    } else {
                                        await arkoseCompletedCallback("PlaceholderToken");
                                    }
                                    setSubmitting(false);
                                }}
                            >

                                {() => (
                                    <Form className="d-flex flex-column">

                                        <AreaLoader show={submitting} message={t("ForgotPassword.RequestingPasswordResetMessage")} />

                                        <FormGroup>
                                            <Label
                                                className="text-uppercase"
                                                for="emailAddress"
                                            >{t("ForgotPassword.EmailAddressLabel")}</Label>

                                            <Field
                                                name="emailAddress"
                                                disabled={submitting}
                                                autoComplete="off"
                                                component={InputWithValidations} />
                                        </FormGroup>

                                        <Button
                                            color="primary"
                                            className="align-self-center"
                                            type="submit"
                                            style={{ maxWidth: "150px" }}
                                            disabled={submitting}
                                        >{t("Login.SubmitButton")}</Button>

                                        <Link className="d-flex align-self-center" to="/login">
                                            <Button color="link" >
                                                {t("CancelButton")}
                                            </Button>
                                        </Link>

                                    </Form>
                                )}

                            </Formik>

                        </div>
                    </Col>
                    <Col xs="0" sm="2" md="3"></Col>
                </Row>
            </Container>

        </div>
    );
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);

export {
    ConnectedComponent as ForgotPasswordPage
}
