import React from "react";

import { ReactComponent as DigitalProjectIcon } from 'Assets/Images/icons/Digital-project.svg';
import { ReactComponent as PhysicalProjectIcon } from 'Assets/Images/icons/Physical-project.svg';
import { ReactComponent as PrintReadyProjectIcon } from 'Assets/Images/icons/Print-Ready-project.svg';
import { bemNames } from 'Utilities';
import { BatchType, BatchTypeIconSize, BaseVoucherType } from 'Models';


interface Props {
    batchType?: BatchType;
    size?: BatchTypeIconSize;
    codeType?: BaseVoucherType;
}


export const BatchTypeIconImage = ({ batchType, size, codeType }: Props) => {
    const bem = bemNames.create("icon-image");
    return (
        <div className={bem.b()}>
            {(batchType == BatchType.ElectronicBatch || batchType == BatchType.ElectronicBulk || codeType == BaseVoucherType.ECode) && <DigitalProjectIcon className={size} />}
            {(batchType == BatchType.Physical || batchType == BatchType.PhysicalBatchParent || batchType == BatchType.PhysicalBatchSubOrder || codeType == BaseVoucherType.Physical) && <PhysicalProjectIcon className={size} />}
            {(batchType == BatchType.PrintReadyBatch || batchType == BatchType.PrintReadyBulk || codeType == BaseVoucherType.PrintReady) && <PrintReadyProjectIcon className={`print-icon ${size}`} />}            
        </div>
    )
}