import axios from "axios";
import { getAuthHeader } from "./getAuthHeader";
import { IClientDto } from "Models";

export const clientService = {
    getClients
};

async function getClients() {

    const response = await axios.get<IClientDto[]>(`api/Clients/GetClients`, {
        headers: getAuthHeader()
    });

    return response.data;
}