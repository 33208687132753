import { BatchType, RedemptionStatus } from ".";
import { CodeDeliveryMethod } from "./CodeDeliveryMethod";

export interface AddressDetailsDto {
    firstName: string,
    lastName: string,
    staffNumber: string,
    organisationName: string,
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
    cityTown: string,
    county: string,
    country: string,
    state: string,
    postCode: string,
    mobileNumber: string,
    emailAddress: string
}

export interface ScmOrderSearchDetailsDto {
    scmOrderId: number;
    redemptionDate: Date;
    scmConfigurationTypeId?: ScmConfigurationType;
    balanceProviderTypeId?: BalanceProviderType;
    addressDetails: AddressDetailsDto;
    clientName: string;
    clientId: number;
    projectName: string;
    projectId: number;
    paymentSources: PaymentSourceDto[];
    scmOrderItems: ScmOrderItemSummaryDto[];
    canOrderBeEdited: boolean;
}

export interface AssociatedEmail {
    id: number;
    isReminder: boolean;
    recipient: string;
    generated: Date;
    generatedBy: string;
    sentTime: Date;
    logStatus: string;
    latestEmailStatus: string;
    type: string;
    hasError: boolean;
    errorMessage: string;
    codeDeliveryMethod: CodeDeliveryMethod;
    mobileNumberPrefix: string;
    mobileNumberWithoutPrefix: string;
}

export interface IssuanceDetailsDto {
    code: string;
    value: string;
    issuanceDate: Date;
    expiryDate: Date;
    orderStatus: string;
    redemptionStatusId: RedemptionStatus;
    batchTypeId: BatchType;
    firstName: string;
    lastName: string;
    staffNumber: string;
    emailAddress: string;
    mobileNumber: string;
    associatedEmails: AssociatedEmail[];
    clientName: string;
    clientId: number;
    projectName: string;
    projectId: number;
    scmOrderId: number;
    onHoldDate?: Date;
    defaultErrorMessage: string;
    isEcode: boolean;
    batchType: string;
    batchId: number;
    redemptionCodeId: number;
    redemptionCodeECodeId: number;
    codeDeliveryMethod: CodeDeliveryMethod;
    mobileNumberPrefix: string;
}

export interface EditOrderDetailsDto {
    scmOrderId: number;
    scmConfigurationId: number;
    projectId?: number;
    balance: number;
    currency: string;
    scmProcessingReference: string;
    addressDetails: AddressDetailsDto;
    items: EditOrderItemDto[];
}

export interface EditOrderItemDto {

    id?: number;
    productId: string;
    productName: string;
    processIndividually: boolean;
    productBaseType: ProductBaseTypeEnum;
    lastExternalOrderStatusId?: ExternalOrderStatus;
    value: number;
}

export interface PaymentSourceDto {
    redemptionCodeId?: number;
    cardCodeNumber: string;
    employeeNumber: string;
    value: number;
}


export interface ScmOrderItemSummaryDto {
    id: number;
    productName: string;
    externalOrderReference?: string;
    orderStatus?: string;
    isGrouped: boolean;
    currency: string;
    value?: number;
    productBaseType: ProductBaseTypeEnum;
    canBeReprocessed: boolean;
}



export enum ScmConfigurationType {
    Go2 = 0,
    Bhn = 1,
    Marketplace = 2
}


export enum BalanceProviderType {
    KeystoneBalanceProvider = 1,
    EmlBalanceProvider = 2,
    HawkSelect = 3,
    PointsBalanceProvider = 4,
    FisBalanceProvider = 5,
}

export enum ProductBaseTypeEnum {
    Unknown = 0,
    ECode = 1,
    Physical = 2,
    Merchandise = 3,
    Virtual = 4
}

export enum ExternalOrderStatus {
    Pending = 0,
    ReadyForProcessing = 1,
    Requested = 2,
    Retrieved = 3,
    Despatched = 4,
    ReversalNeeded = 5,
    ReversalCompleted = 6,
    InFulfillment = 7,
    CompletedWithFailure = 8,
    Declined = 9,
    CreditAdjustmentRequired = 10,
    CreditAdjustmentReversalNeeded = 11,
    CreditAdjustmentNotificationRequired = 12,
    CreditAdjustmentNotified = 13,
    Timeout = 14,
    OrderFailedRetryRequired = 15,
    OrderFailureNotified = 16,
    FulfillmentError = 17,
    InProcessing = 18,
    AlertNotified = 19,
    ReadyForFulfillment = 20,
    SentToFulfillment = 21,
    Packaging = 22,
    New = 23,
    Draft = 24,
    Enriched = 25,
    Approved = 26,
    Configured = 27,
    Rejected = 28,
    Cancelled = 29,
    Created = 30,
    Submitted = 31
}

export interface ItemStatusLogDto {
    statusDate: Date,
    status: string,
    requestId: string,
    details: string
}

export interface GroupItems {
    status: string,
    quantity: number,
    productTitle: string,
    productId: string,
    itemId: string,
    cancelQuantity: number
}

export interface ItemGroupStatusLogDto {
    orderDate: Date,
    go2OrderId: string,
    externalOrderId: string,
    orderStatus: string,
    comments: string,
    items: GroupItems[]
}

