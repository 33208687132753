import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useTranslation } from "react-multi-lang";
import Notifications from 'react-notification-system-redux';
import { Link } from "react-router-dom";
import classNames from "classnames";
import { OrderStatusBadge, BatchTypeIconImage } from "../Components";
import { bemNames, getBatchStatusLocalizationKey, getBatchTypeLocalizationKey, getErrorNotification } from 'Utilities';
import { CgFileDocument } from "react-icons/cg";

import { OrderSummaryDto, BatchStatus, BatchTypeIconSize } from 'Models';
import { orderHistoryService } from "../Services";
import { LineLoader } from ".";
import moment from "moment";

interface StoreProps {
    notifyError: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
}


const OrderSummaryComponent = ({
    orderId,
    notifyError
}: { orderId: number } & StoreProps) => {

    const bem = bemNames.create("order-summary");

    const t = useTranslation();
    const [loading, setLoading] = useState(true);
    const [orderSummary, setOrderSummary] = useState<OrderSummaryDto>();

    useEffect(() => {
        var f = async () => {
            if (orderId) {
                await loadOrderSummary();
            }
        }
        f();
    }, [orderId]);
   
    const loadOrderSummary = async () => {

        try {
            setLoading(true);

            const data = await orderHistoryService.getOrderSummary(orderId);

            setOrderSummary(data);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderSummaryFailure")));
        }
        setLoading(false);
    }

    return <div className="d-flex flex-column flex-md-row justify-content-between flex-wrap">

        <div className="col">
            <div className={bem.e("icon-wrapper")}>
                <BatchTypeIconImage batchType={orderSummary?.batchTypeId} size={BatchTypeIconSize.small} />
            </div>
        </div>

        <div className="col">

            <div className="d-flex flex-column">
                <strong className="text-uppercase">{t("OrderSummary.ProjectDetailsLabel")}</strong>
                {loading
                    ? <LineLoader lines={3} />
                    :
                    <>
                        <div className="d-block">{orderSummary?.projectName}</div>
                        <div className="d-block"><strong>ID: </strong>{orderSummary?.projectId}</div>
                        <Link to={`/projects/project/${orderSummary?.projectId}`} className="mt-3" style={{ fontSize: "0.875rem" }}><CgFileDocument className="mr-1" size={22} />{t("OrderSummary.ViewProjectButton")}</Link>
                    </>
                }
            </div>
        </div>

        <div className="col">

            <div className="d-flex flex-column">
                <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderIdLabel")}</strong>
                <span data-automation-id="OrderSummaryOrderId">{loading
                    ? <LineLoader />
                    : orderSummary?.orderId
                }
                </span>
            </div>

            <div className="d-flex flex-column mt-2">
                <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderTypeLabel")}</strong>
                {loading
                    ? <LineLoader />
                    : (orderSummary && t(getBatchTypeLocalizationKey(orderSummary?.batchTypeId)))
                }
            </div>

        </div>

        <div className="col">

            <div className="d-flex flex-column">
                <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderDateLabel")}</strong>
                {loading
                    ? <LineLoader />
                    : (orderSummary?.orderDate ? moment.utc(orderSummary.orderDate).format("ll HH:mm") : "")
                }
            </div>

            <div className="d-flex flex-column mt-2">
                <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderedByLabel")}</strong>
                {loading
                    ? <LineLoader />
                    : (orderSummary?.orderedByInternalAdmin
                        ? t("OrderHistory.InternalAdminUserLabel")
                        : `${orderSummary?.orderedByFirstName} ${orderSummary?.orderedByLastName}`)
                }
            </div>

        </div>

        <div className="col">

            <div className="d-flex flex-column">
                <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderValueLabel")}</strong>
                {loading
                    ? <LineLoader />
                    : (`${orderSummary?.currency} ${orderSummary?.orderValue?.toFixed(2)}`)
                }
            </div>

        </div>

        <div className="col">
            <strong className="text-uppercase">{t("OrderHistory.OrderTileStatusLabel")}</strong>
            {loading
                ? <LineLoader />
                :
                <OrderStatusBadge batchStatus={orderSummary?.batchStatusId ?? BatchStatus.Undefined} />
            }
        </div>
    </div>
}

const ConnectedComponent = connect(null, mapDispatchToProps)(OrderSummaryComponent);


export {
    ConnectedComponent as OrderSummary
}