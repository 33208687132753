import axios from "axios";
import { getAuthHeader } from "./getAuthHeader";
import { DownloadableBatchDetails, SearchResults } from "Models";
import { FilterByOptions, SortByOptions } from "../Components";

export const downloadsService = {
    getDownloadDetails,
    getDownloadItemDetails,
    getDownloadAllCsv,
    getDownloadAllPdf,
    getDownloadBulkCsv,
    getDownloadBulkPdf,
    getDownloadPdf,
    getPreviewTemplateHtml,
    getDownloadAllPrintReadyCsv,
    getDownloadPrintReadyCsv
};

async function getDownloadDetails(batchId: number) {
    try {
        const response = await axios.get<DownloadableBatchDetails>(`api/Downloads/GetDownloadDetails/${batchId}`, {
            headers: getAuthHeader()
        });
        return response.data;
    }
    catch (error) {
        console.log(error);
    }
}

async function getDownloadItemDetails(batchId: number, pageIndex: number, pageSize: number, searchTerm: string) {
    try {
        const response = await axios.get<DownloadableBatchDetails>(`api/Downloads/GetDownloadItemDetails/${batchId}`, {
            headers: getAuthHeader(),
            params: {
                pageIndex,
                pageSize,
                searchTerm
            }
        });
        return response.data;
    }
    catch (error) {
        console.log(error);
    }
}

async function getDownloadAllCsv(batchId: number) {

    const response = await axios.get(`api/Downloads/${batchId}/DownloadAllCsv`, {
        headers: getAuthHeader(),
        responseType: "blob"
    });

    return response.data;
}
async function getDownloadAllPrintReadyCsv(batchId: number) {

    const response = await axios.get(`api/Downloads/${batchId}/DownloadAllPrintreadyCSV`, {
        headers: getAuthHeader(),
        responseType: "blob"
    });

    return response.data;
}

async function getDownloadPrintReadyCsv(batchId: number, redemptionCodeBatchEcodeId: number) {

    const response = await axios.get(`api/Downloads/${batchId}/DownloadPrintReadyCSV/${redemptionCodeBatchEcodeId}`, {
        headers: getAuthHeader(),
        responseType: "blob"
    });

    return response.data;
}

async function getDownloadAllPdf(batchId: number) {

    const response = await axios.get(`api/Downloads/${batchId}/DownloadAllPdf`, {
        headers: getAuthHeader(),
        responseType: "blob"
    });

    return response.data;
}

async function getDownloadBulkCsv(batchId: number, redemptionCodeBatchEcodeId: number, value: number) {

    const response = await axios.get(`api/Downloads/${batchId}/DownloadBulkCsv/${redemptionCodeBatchEcodeId}/${value}`, {
        headers: getAuthHeader(),
        responseType: "blob"
    });

    return response.data;
}

async function getDownloadBulkPdf(batchId: number, redemptionCodeBatchEcodeId: number, value: number) {

    const response = await axios.get(`api/Downloads/${batchId}/DownloadBulkPdf/${redemptionCodeBatchEcodeId}/${value}`, {
        headers: getAuthHeader(),
        responseType: "blob"
    });

    return response.data;
}


async function getDownloadPdf(batchId: number, redemptionCodeBatchEcodeId: number) {

    const response = await axios.get(`api/Downloads/${batchId}/DownloadPdf/${redemptionCodeBatchEcodeId}`, {
        headers: getAuthHeader(),
        responseType: "blob"
    });

    return response.data;
}

async function getPreviewTemplateHtml(batchId: number) {

    const response = await axios.get(`api/Downloads/${batchId}/PreviewTemplate`, {
        headers: getAuthHeader()
    });

    return response.data;
}
