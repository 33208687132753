import React from 'react';
import Moment from 'react-moment';
import { useTranslation } from 'react-multi-lang';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

// Import Models
import { ItemStatusLogDto } from '../Models';

interface Props {
    isModalOpen: boolean;
    closeModal: () => void;
    title: string;
    statuses: ItemStatusLogDto[];
}

export function ItemStatusLogDialog({
    isModalOpen,
    closeModal,
    title,
    statuses
}: Props) {

    const t = useTranslation();

    return (

        <Modal
            isOpen={isModalOpen}
            toggle={() => closeModal()}
            style={{ maxWidth: "max-content" }}
        >
            <ModalHeader toggle={() => closeModal()}>
                {title}
            </ModalHeader>

            <ModalBody>
                <Container>
                    <Row>
                        <Col>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{t("DateLabel")}</th>
                                        <th>{t("OrderDetails.StatusLabel")}</th>
                                        <th>{t("OrderDetails.RequestIdLabel")}</th>
                                        <th>{t("OrderDetails.DetailsLabel")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {statuses.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><Moment format="ll HH:mm">{item.statusDate}</Moment></td>
                                                <td>{item.status}</td>
                                                <td>{item.requestId}</td>
                                                <td>{item.details}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>

            <ModalFooter className="flex-row-reverse">
                <Button
                    outline
                    onClick={() => closeModal()}
                >{t("BackButton")}</Button>
            </ModalFooter>

        </Modal>
    );
}