import React, { useEffect, useRef, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { connect } from 'react-redux';
import { bemNames, getClaimValue } from 'Utilities';
import { ApplicationState } from "Store";
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import * as Yup from 'yup';
import { ClaimTypes, UserDetails } from "../Auth";
import { DigitalOrderDetails, DigitalOrderItemDto, DistributorDetails } from "../Models";
import { Field, Form, Formik, FormikProps } from "formik";
import { InputWithValidations } from ".";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { useTranslation } from "react-multi-lang";
import { Card, CardBody, Table } from "reactstrap";
import { array } from "yup/lib/locale";
import { userService } from 'Services';
import { AreaLoader } from "Components";
import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";


interface StoreProps {
    setCurrentStep: typeof OrderReducer.actions.setCurrentStep;
    setOrder: typeof OrderReducer.actions.setOrder;
    order: DigitalOrderDetails;
    setDistributorDetails: typeof OrderReducer.actions.setDistributorDetails;
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep;
    attemptGoToStepNumber?: number,
    user?: UserDetails;
    refreshToken?: string;
    accessToken?: string;
}

interface AuthStoreProps {
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
}

interface UserStoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
}

const mapStateToProps = ({ digitalOrder, user: userState, auth }: ApplicationState) => ({
    order: digitalOrder.order,
    attemptGoToStepNumber: digitalOrder.attemptGoToStepNumber,
    user: userState.user,
    refreshToken: auth.refreshToken,
    accessToken: auth.accessToken
})

const mapDispatchToProps = {
    setCurrentStep: OrderReducer.actions.setCurrentStep,
    setOrder: OrderReducer.actions.setOrder,
    setDistributorDetails: OrderReducer.actions.setDistributorDetails,
    attemptGoToStep: OrderReducer.actions.attemptGoToStep,
    refreshTokens: AuthenticationReducer.actions.refreshTokens,
    loadUser: UserReducer.actions.loadUser
}

interface Props extends StoreProps, Partial<StepWizardChildProps>, AuthStoreProps, UserStoreProps {
    projectID: string,
    deliveryMethod?: OrderReducer.DeliveryMethod,
    currencySymbol?: string;
}

const OrderForm = ({
    setCurrentStep,
    isActive,
    setOrder,
    setDistributorDetails,
    attemptGoToStepNumber,
    goToStep,
    attemptGoToStep,
    user,
    order,
    projectID,
    deliveryMethod,
    currencySymbol,
    refreshTokens,
    loadUser,
    refreshToken,
    accessToken
}: Props) => {

    const t = useTranslation();
    const bem = bemNames.create("digital-order-wizard");

    const [inputList, setInputList] = useState<DigitalOrderItemDto[]>(order.bulkItems ?? [{ value: "", quantity: "" }]);

    const [sendToAnother, setSendToAnother] = useState(false);
    const [containsDecimal, setContainsDecimal] = useState(false);

    const [isBusy, setIsBusy] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("");

    const formikRef = useRef<FormikProps<any> | null>();

    const valueRefs = useRef<Array<HTMLInputElement | null>>([]);
    useEffect(() => {

        // Keep track of value inputs
        valueRefs.current = valueRefs.current.slice(0, inputList.length);

        // Autofocus on latest value input after add/removal
        if (inputList.length <= 1) return
        valueRefs.current[valueRefs.current.length - 1]?.focus();

    }, [inputList.length]);

    // state resets
    useEffect(() => {
    
        setInputList([{ value: "", quantity: "" }]);
    }, [projectID])

    useEffect(() => {
        setSendToAnother(false);
    }, [deliveryMethod])

    useEffect(() => {

        async function goToNextStepIfValid() {
            if (attemptGoToStepNumber === 5) {
                if (sendToAnother) {
                    await formikRef.current?.submitForm();
                    if (formikRef.current?.isValid) {
                        goToStep?.(5)
                    } else {
                        attemptGoToStep(2);
                    }
                } else if (!sendToAnother) {
                    goToStep?.(5)
                    setDistributorDetails({
                        firstName: getClaimValue(user, ClaimTypes.Name) ?? "",
                        lastName: getClaimValue(user, ClaimTypes.Surname) ?? "",
                        emailAddress: getClaimValue(user, ClaimTypes.Email) ?? ""
                    })
                }
            } else if (attemptGoToStepNumber === 1) {
                goToStep?.(1)
            }

            if (attemptGoToStepNumber === 4) {
                if (sendToAnother) {
                    await formikRef.current?.submitForm();
                    if (formikRef.current?.isValid) {
                        goToStep?.(4)
                    } else {
                        attemptGoToStep(2);
                    }
                } else if (!sendToAnother) {
                    goToStep?.(4)
                    setDistributorDetails({
                        firstName: getClaimValue(user, ClaimTypes.Name) ?? "",
                        lastName: getClaimValue(user, ClaimTypes.Surname) ?? "",
                        emailAddress: getClaimValue(user, ClaimTypes.Email) ?? ""
                    })
                }
            } else if (attemptGoToStepNumber === 1) {
                goToStep?.(1)
            }
        }

        goToNextStepIfValid()


    }, [attemptGoToStepNumber])

    if (isActive) {
        setCurrentStep(2);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        const list: any = [...inputList];
        list[index][name] = value;
        setInputList(list);
        checkForDecimal();
    };


    const refreshingToken = async () => {
        setIsBusy(true);

        try {
            var userDetails = await userService.refreshingToken(accessToken, refreshToken);

            if (userDetails) {

                await refreshTokens(userDetails.accessToken, userDetails.refreshToken);
                await loadUser(userDetails);

                return;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsBusy(false);
        }
    }

    const handleRemoveClick = (index: number) => {

        refreshingToken();

        const current = [...inputList];
        current.splice(index, 1);
        setInputList(current);
        let sum: number = current.map(a => +a.quantity * +a.value).reduce(function (a, b) {
            return a + b;
        });

        setOrder({ ...order, bulkItems: current, itemsTotalValue: sum });
    };

    const handleAddClick = () => {

        refreshingToken();

        let sum: number = inputList.map(a => +a.quantity * +a.value).reduce(function (a, b) {
            return a + b;
        });

        setInputList([...inputList, { value: "", quantity: "" }]);
        setOrder({ ...order, bulkItems: inputList, itemsTotalValue: sum });
    };

   

    const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const characterCode = e.key;
        switch (characterCode) {
            case ".":
            case "Decimal":
                // Reject input
                e.preventDefault();
                return;
            case "Backspace":
            case "Tab":
            case "ArrowUp":
            case "ArrowDown":
                // Respect input's default interaction
                return;
            default:
                const characterNumber = Number(characterCode);
                if (isNaN(characterNumber) || characterNumber < 0 || characterNumber > 9) {
                    // Reject number input
                    e.preventDefault();
                }
        }
    }

    const checkForDecimal = () => {
        const value: Array<string> = inputList.map(i => i.value);
        const quantity: Array<string> = inputList.map(i => i.quantity);
        const merge = [...value, ...quantity];

        setContainsDecimal(false);
      
        merge.forEach(i => {
            let num = Number(i);

            if (num % 1 != 0) {
                setContainsDecimal(true);
            }
            
        })

    }

    const SendToAnotherValidationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(t("ValidationError.FirstNameRequired")),
        lastName: Yup.string()
            .required(t("ValidationError.LastNameRequired")),
        emailAddress: Yup.string()
            .email(t("ValidationError.InvalidEmailAddressMessage"))
            .matches(new RegExp('^(".+"|[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+(\\.[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+)*)@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$'), t("ValidationError.InvalidEmailAddressMessage"))
            .required(t("ValidationError.EmailAddressRequiredMessage")),
    });

    return (<>
        <Card className="w-100">
            <AreaLoader show={isBusy} message={loadingMessage} />
            <CardBody>
                <div className={bem.e("form-entry d-block w-100")}>
                    <div className="clearfix">
                        <h2 className="float-left">{t("OrderingProcess.AddCodesLabel")}</h2>
                        <p className="float-right"><sup>*</sup> {t("RequiredLabel")}</p>
                    </div>
                    <div className="code-lines">                        
                        <table>
                            <thead>
                                <tr>
                                    <th className="pb-1 text-uppercase">{t("OrderDetails.CodeValueLabel")}<sup>*</sup></th>
                                    <th className="pb-1 text-uppercase">{t("OrderDetails.QuantityLabel")}<sup>*</sup></th>
                                    <th className="pb-1 text-uppercase">{t("OrderDetails.TotalLabel")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {inputList.map((x, i) => {
                                    const canBeAdded = +x.quantity * +x.value > 0

                                    return (
                                        <tr key={i} className={"field-group " + (inputList.length - 1 !== i ? "line-set" : "")}>
                                            <td>
                                                <div className="input-group pr-2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">{currencySymbol}</div>
                                                    </div>
                                                    <input
                                                        tabIndex={isActive ? 0 : -1}
                                                        type="number"
                                                        min="0"                                                        
                                                        disabled={!isActive || inputList.length - 1 !== i}
                                                        name="value"
                                                        value={x.value}
                                                        onChange={e => handleInputChange(e, i)}
                                                        onKeyDown={e => handleKeypress(e)}
                                                        className="form-control"
                                                        ref={el => valueRefs.current[i] = el}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="input-group pr-2">
                                                    <input
                                                        tabIndex={isActive ? 0 : -1}
                                                        type="number"
                                                        step="1"
                                                        min="0"
                                                        disabled={!isActive || inputList.length - 1 !== i}
                                                        name="quantity"
                                                        value={x.quantity}
                                                        onKeyDown={e => handleKeypress(e)}
                                                        onChange={e => handleInputChange(e, i)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="input-group pr-2">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">{currencySymbol}</div>
                                                    </div>
                                                    <input
                                                        tabIndex={isActive ? 0 : -1}
                                                        type="text"
                                                        name="lineTotal"
                                                        disabled={!isActive || inputList.length - 1 !== i}
                                                        value={+x.quantity * +x.value}
                                                        onChange={e => handleInputChange(e, i)}
                                                        className="form-control read-only"
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="btn-box">
                                                    {inputList.length - 1 !== i &&
                                                        <button
                                                            tabIndex={isActive ? 0 : -1}
                                                            type="button"
                                                            className="btn btn-link px-0"
                                                            onClick={() => handleRemoveClick(i)}
                                                            disabled={!isActive}
                                                        ><RiDeleteBin6Line className="mr-2" size={24} />
                                                        </button>
                                                    }
                                                    {inputList.length - 1 === i &&
                                                        <button
                                                        data-automation-id="addToOrderButton"
                                                        tabIndex={isActive ? 0 : -1}
                                                        type="button"
                                                        style={{ color: "#0366d6" }}
                                                        className={"btn btn-link px-0 mt-1" + (canBeAdded ? "" : " disabled")}
                                                        onClick={handleAddClick}
                                                        disabled={!isActive || containsDecimal || !canBeAdded}
                                                        ><AiOutlinePlus className="mr-1 mb-1" size={24} />{t("OrderingProcess.AddToOrderButton")}</button>}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {containsDecimal &&
                            <div className="alert mt-2 alert-warning" role="alert">
                                {t("OrderingProcess.ValueContainsDecimal")}
                            </div>
                        }
                    </div>
                </div>

            </CardBody>
        </Card>

        <Card className="w-100 mt-3">
            <AreaLoader show={isBusy} message={loadingMessage} />
            <CardBody>
                <div className={bem.e("delivery d-block w-100")}>

                    <h2>{t("OrderingProcess.DistributorDetailsLabel")}</h2>

                    <div className="d-flex mt-2">
                        <div className="pr-4">
                            <input
                                tabIndex={isActive ? 0 : -1}
                                onClick={() => setSendToAnother(false)}
                                disabled={!isActive}
                                style={{ cursor: "pointer" }}
                                id="sendToMe"
                                className="mr-2"
                                type="radio"
                                defaultChecked
                                value="Me"
                                name="sendTo"
                            />
                            <label
                                className="text-uppercase"
                                style={{ cursor: "pointer" }}
                                htmlFor="sendToMe"
                            >{t("OrderingProcess.SendToMeLabel")}</label>
                        </div>

                        <div className="pl-4" style={{ cursor: "pointer" }}>
                            <input
                                tabIndex={isActive ? 0 : -1}
                                onClick={() => setSendToAnother(true)}
                                disabled={!isActive}
                                style={{ cursor: "pointer" }}
                                className="mr-2"
                                id="sendToAnotherRadio"
                                type="radio"
                                value="Another"
                                name="sendTo"
                            />
                            <label
                                className="text-uppercase"
                                style={{ cursor: "pointer" }}
                                htmlFor="sendToAnotherRadio"
                            >{t("OrderingProcess.SendToAnotherLabel")}</label>
                        </div>
                    </div>

                    {sendToAnother &&
                        <Formik
                            initialValues={{
                                firstName: "",
                                lastName: "",
                                emailAddress: ""
                            }}
                            validateOnMount={true}
                            innerRef={ref => formikRef.current = ref}
                            validationSchema={SendToAnotherValidationSchema}
                            onSubmit={async (values: DistributorDetails) => {
                                setDistributorDetails(values);
                            }}
                        >

                            {({ isSubmitting }) => (
                                <Form translate="yes">
                                    <div className="d-flex justify-content-between mt-2 ">

                                        <div className="mr-2 w-50">
                                            <label><span className="text-uppercase">{t("FirstNameLabel")}</span> *</label>
                                            <Field
                                                name="firstName"
                                                disabled={!isActive || isSubmitting}
                                                component={InputWithValidations}
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="ml-2 w-50">
                                            <label><span className="text-uppercase">{t("LastNameLabel")}</span> *</label>
                                            <Field
                                                name="lastName"
                                                disabled={!isActive || isSubmitting}
                                                component={InputWithValidations}
                                                className="form-control"
                                            />
                                        </div>

                                    </div>

                                    <div className="mt-3">
                                        <label><span className="text-uppercase">{t("OrderDetails.EmailAddressLabel")}</span> *</label>
                                        <Field
                                            name="emailAddress"
                                            disabled={!isActive || isSubmitting}
                                            component={InputWithValidations}
                                            className="form-control"
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>}

                </div>

            </CardBody>
        </Card>
    </>);
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(OrderForm);

export {
    ConnectedComponent as OrderFormStep
}