import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from "Store";
import Notifications from 'react-notification-system-redux';
import { getSuccessNotification, getErrorNotification } from 'Utilities';
import { userService } from "../Services";
import { useTranslation } from "react-multi-lang";
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AreaLoader } from './AreaLoader';

import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";

interface Props extends StoreProps, AuthStoreProps, UserStoreProps {
};

interface StoreProps {
    loggedIn?: boolean;
    accessToken?: string;
    refreshToken?: string;
    notifyError: Notifications.NotificationShow;
    notifySuccess: Notifications.NotificationShow;
    logout: AuthenticationReducer.LogoutAction;
}

interface AuthStoreProps {
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
}

interface UserStoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
    clearUser: typeof UserReducer.actions.clearUser;
    setLoggingOut: typeof UserReducer.actions.setLoggingOut;
}

const mapStateToProps = ({ auth }: ApplicationState) => ({
    loggedIn: auth.loggedIn,
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken
});

const mapDispatchToProps = {
    notifyError: Notifications.error,
    notifySuccess: Notifications.success,
    logout: AuthenticationReducer.actions.logout,
    refreshTokens: AuthenticationReducer.actions.refreshTokens,
    loadUser: UserReducer.actions.loadUser,
    clearUser: UserReducer.actions.clearUser,
    setLoggingOut: UserReducer.actions.setLoggingOut
}

const UserLogOutCheck = ({ accessToken, notifySuccess, notifyError, logout, refreshToken, refreshTokens, loadUser, clearUser, setLoggingOut }: Props) => {

    const t = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [originalTitle, setOriginalTitle] = useState("");
    const [counter, setCounter] = useState(120);
    const [isBusy, setIsBusy] = useState(false);
    const timeIntRef = useRef<NodeJS.Timeout>();

    if (counter <= 0) {
        logoutUserDueToInactivity();
    }

    useEffect(() => {
        timeIntRef.current = setInterval(async () => {
            await checkTokenExpiration();
        }, 30000);


        return () => {
            if (timeIntRef.current) {
                clearInterval(timeIntRef.current);
            }
        }

    }, [accessToken]);

    useEffect(() => {
        const checkVisibility = async () => {
            if (document.visibilityState == "visible") {
                await checkTokenExpiration();
            }
        };
        document.addEventListener("visibilitychange", checkVisibility, true);
        return () => {
            document.removeEventListener("visibilitychange", checkVisibility, true);
        };
    }, [accessToken]);

    useEffect(() => {
        let timer: ReturnType<typeof setInterval> | null = null;
        if (isModalOpen && !timer) {
            setOriginalTitle(document.title);
            timer = setInterval(() => {
                setCounter(counter => {
                    const newCounter = counter - 1;
                    document.title = t("Logout.LogoutWarningMessage").replace("[[counter]]", newCounter.toString());
                    return newCounter;
                });
            }, 1000);
        }
        else {
            if (timer) {
                clearInterval(timer);
            }
        }

        return () => {
            if (timer) {
                clearInterval(timer)
            }
        };
    }, [isModalOpen]);

    const checkTokenExpiration = async () => {
        const accessTokenDetails = await userService.checkIfUserHasTimedOut(accessToken!);

        const now = new Date();
        const validTo = new Date(accessTokenDetails.accessTokenValidTo);
        const timeLeft = Math.ceil((validTo.getTime() - now.getTime()) / 1000);

        if (accessTokenDetails.hasAccessTokenExpired) {
            logoutUserDueToInactivity();
        }
        else if (timeLeft <= 120 && !isModalOpen) {
            setCounter(timeLeft);
            requestOpenModal();
        }
    }

    const onLogout = async () => {
        document.title = originalTitle;
        requestCloseModal();
        const result = await logout(refreshToken ?? "");
        if (result) {
            notifySuccess(getSuccessNotification(t("NotificationMessage.LogoutSuccess")));
            setLoggingOut(true);            
            clearUser();
        } else {
            notifyError(getErrorNotification(t("NotificationMessage.LogoutFailure")))
        }
    }

    function logoutUserDueToInactivity() {
        logout(refreshToken ?? "");
        notifyError(getErrorNotification(t("NotificationMessage.InactivityMessage")));
    }

    function requestOpenModal() {
        setIsModalOpen(true);
    }

    function requestCloseModal() {
        setIsModalOpen(false);
    }

    function resumeSession() {
        document.title = originalTitle;
        requestCloseModal();
        refreshingToken();
    }

    const refreshingToken = async () => {
        setIsBusy(true);
        try {
            const userDetails = await userService.refreshingToken(accessToken, refreshToken);
            if (userDetails) {
                await refreshTokens(userDetails.accessToken, userDetails.refreshToken);
                await loadUser(userDetails);
                return;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsBusy(false);
        }
    }

    return (
        <>
            <AreaLoader hasDelay={false} show={isBusy} message={t("Login.ResumeSessionMessage")} />

            <Modal isOpen={isModalOpen && counter >= 0} style={{ maxWidth: "34vw" }} keyboard={false} backdrop="static">
                <ModalHeader>
                    {t("Logout.WarningTitle")}
                </ModalHeader>

                <ModalBody>
                    <Container>
                        <Row>
                            {t("Logout.UserInactivityMessage")
                                .replace("[[counter]]", counter.toString())
                            }
                        </Row>
                    </Container>
                </ModalBody>

                <ModalFooter className="justify-content-between">
                    <Button                       
                        onClick={onLogout}
                    >
                        {t("LogoutButton")}
                    </Button>


                    <Button
                        color="primary"
                        onClick={() => resumeSession()}
                    >
                        {t("Login.ResumeSessionButton")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}


const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(UserLogOutCheck);

export {
    ConnectedComponent as UserLogOutCheck
}