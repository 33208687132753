import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { ApplicationState } from 'Store';
import { MfaAuthType } from '../Models';
import { getClaimValue } from '../Utilities';
import { ClaimTypes } from './ClaimTypes';
import { UserDetails } from './UserDetails';


interface Props extends RouteProps {
    loggedIn?: boolean;
    user?: UserDetails;
}

const AuthenticatedRoute = ({ component: Component, loggedIn, user, ...rest }: Props) => {

    if (!loggedIn) {
        return <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />;
    }

    const mfaAuthTypeClaim = getClaimValue(user, ClaimTypes.MfaAuthType);
    const mfaAuthType = mfaAuthTypeClaim ? +mfaAuthTypeClaim as MfaAuthType : undefined;
    const mfaSetupCompleted = !!mfaAuthTypeClaim;
    const mfaAuthPassedClaim = getClaimValue(user, ClaimTypes.MfaAuthPassed) === "True";
    const passwordChangeRequired = getClaimValue(user, ClaimTypes.PasswordChangeRequired) === "True";

    const getMfaHash = () => mfaAuthType === undefined
        ? "download-app"
        : mfaAuthType === MfaAuthType.SmsOtp
            ? "sms-authenticate"
            : "app-authenticate";

    if ((!mfaSetupCompleted || !mfaAuthPassedClaim) &&
        (rest.location?.pathname !== "/mfa-setup")) {

        return <Redirect to={{
            pathname: '/mfa-setup',
            hash: getMfaHash(),
            state: { from: rest.location }
        }} />;
    }

    if (mfaSetupCompleted &&
        !mfaAuthPassedClaim &&
        (rest.location?.pathname !== "/mfa-setup" ||
            rest.location?.hash.substring(1) !== getMfaHash())) {

        return <Redirect to={{
            pathname: '/mfa-setup',
            hash: getMfaHash(),
            state: { from: rest.location }
        }} />;
    }

    if (mfaSetupCompleted &&
        mfaAuthPassedClaim &&
        passwordChangeRequired &&
        rest.location?.pathname !== "/update-password") {

        return <Redirect to={{
            pathname: '/update-password',
            state: { from: rest.location }
        }} />;
    }

    return <Route {...rest} render={props => (Component && <Component {...props} />)} />
}

const mapStateToProps = ({ auth, user }: ApplicationState) => ({

    loggedIn: auth.loggedIn,
    user: user.user
})

const ConnectedComponent = connect(mapStateToProps)(AuthenticatedRoute);

export {
    ConnectedComponent as AuthenticatedRoute
}
