import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { Alert, Container, Row, Card, CardHeader, CardBody } from "reactstrap";

import { bemNames } from "Utilities";
import { IClientDto, IProjectDto } from "Models";
import { AreaLoader, IconFilter, ProjectTile, ClientFilter } from "Components";
import { clientService } from "Services";
import { ApplicationState } from "Store";
import { UserDetails } from "Auth";

interface StoreProps {
    user?: UserDetails;
}

function mapStateToProps({ user: userState }: ApplicationState) {
    const { user } = userState ?? {};
    return { user };
}

const ProjectsPage = ({ user }: StoreProps) => {

    const t = useTranslation();
    const bem = bemNames.create("projects-page");

    const [loading, setLoading] = useState(true);
    const [isInitialised, setIsInitialised] = useState(false);

    const [clients, setClients] = useState<IClientDto[]>([]);
    const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
    const [selectedClients, setSelectedClients] = useState<string[]>([]);
    const [selectedFilter, setSelectedFilter] = useState<String>("0");

    useEffect(() => {
        if (!user) return;

        setLoading(true);
        Promise.all([
            loadClients()
        ]).then(() => {
            setLoading(false);
            setIsInitialised(true);
        });
    }, [user]);

    useEffect(() => {
        setSelectedClients(clients.map(c => c.tenantId));
    }, [clients]);

    const loadClients = async (): Promise<void> => {
        try {
            setClients(await clientService.getClients());
        } catch (error) {
            return error;
        }
    }

    const projects = clients.reduce((projects: IProjectDto[], client) => [...projects, ...client.projects], []);
    if (isInitialised && !projects.length) {
        return (
            <div className="d-flex justify-content-md-center align-items-center">
                <Alert color="danger" className="mt-3 w-100">
                    <div className="client-blocked-warning-msg">
                        <strong>{t("Projects.NoProjectsFound")}</strong>
                    </div>
                </Alert>
            </div>
        );
    }

    const availableProjects = projects.filter(p => {
        const client = clients.find(c => c.tenantId === p.tenantId);
        return !client?.isClientBlocked;
    });

    const typeFilters = availableProjects
        .map((i) => (i.projectType).toString())
        .filter((x, i, a) => a.indexOf(x) == i);

    const filteredProjects = availableProjects.filter(i => +selectedFilter === 0 || +i.projectType === +selectedFilter);

    const isClientBlocked = projects.length > 0 && availableProjects.length === 0;
    if (isInitialised && isClientBlocked) {
        return (
            <div className="d-flex justify-content-md-center align-items-center">
                <Alert color="danger" className="mt-3 w-100">
                    <div className="client-blocked-warning-msg">
                        <strong>{t("Projects.ClientBlockedWrng")}</strong>
                    </div>
                </Alert>
            </div>
        );
    }

    const hasFilters = clients.length > 1 || typeFilters.length > 1;
    const filteredClients = clients.filter(({ tenantId }) => selectedClients.includes(tenantId));

    return (
        <div className={bem.b()}>

            <div className={bem.e("top")}>
                <h1>{t("Projects.ProjectsLabel")}</h1>
                {hasFilters && (
                    <div className={bem.e("filters")}>
                        <ClientFilter
                            clients={clients}
                            selected={selectedClients}
                            setSelected={setSelectedClients}
                            isOpen={isClientDropdownOpen}
                            setIsOpen={setIsClientDropdownOpen}
                        />

                        {typeFilters.length > 1 &&
                            <div>
                                <div className={bem.b("icon-filter")}>
                                    <IconFilter appliedFilters={(data: string) => setSelectedFilter(data)} filters={typeFilters} />
                                </div>
                            </div>
                        }
                    </div>
                )}
            </div>

            <div data-automation-id="projectTitleContainer" className={bem.e("projects-container")}>
                <AreaLoader show={loading} message={t("Projects.LoadingProjectsMessage")} />

                {isInitialised && (
                    <Container>
                        {filteredClients.map(({ tenantId, tenantName, isClientBlocked }) => {
                            const clientProjects = filteredProjects.filter(project => tenantId === project.tenantId);

                            let body;
                            if (isClientBlocked) {
                                body = (
                                    <div className="d-flex justify-content-md-center align-items-center">
                                        <Alert color="danger" className="mt-3 w-100">
                                            <div className="client-blocked-warning-msg">
                                                <strong>{t("Projects.ClientBlockedWrng")}</strong>
                                            </div>
                                        </Alert>
                                    </div>
                                );
                            } else if (clientProjects.length > 0) {
                                body = (
                                    <div className={bem.e("tiles-container")}>
                                        {clientProjects.map((item) => <ProjectTile project={item} key={item.projectId} />)}
                                    </div>
                                );
                            } else {
                                body = (
                                    <div className="d-flex justify-content-md-center align-items-center">
                                        <Alert color="warning" className="mt-3 w-100">
                                            <div className="client-blocked-warning-msg">
                                                <strong>{t("Projects.NoProjectsFound")}</strong>
                                            </div>
                                        </Alert>
                                    </div>
                                );
                            }

                            return (
                                <Row key={tenantId}>
                                    <Card className="w-100 mt-3">
                                        {clients.length > 1 && (
                                            <CardHeader>
                                                <h2>{tenantName}</h2>
                                            </CardHeader>
                                        )}
                                        <CardBody>
                                            {body}
                                        </CardBody>
                                    </Card>
                                </Row>
                            );
                        })}
                    </Container>
                )}
            </div>
        </div>
    );
}

const ConnectedComponent = connect(mapStateToProps)(ProjectsPage);

export {
    ConnectedComponent as ProjectsPage
}
