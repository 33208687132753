import axios from "axios";
import { getAuthHeader } from "./getAuthHeader";
import { DigitalOrderDetails, DistributorDetails, ProjectOrderingDetails, EmailTemplateDto, PaymentMethod, PaymentPlusOrderDetails } from "Models";
import { DeliveryMethod } from "../Store/Reducers/DigitalOrderReducer";

export const orderingService = {
    placeBulkOrder,
    placeBatchOrder,
    getProjectOrderingDetails,
    getProjectTemplates,
    getSmsTemplates,
    getAllPaymentMethods,
    getPaymentPlusCheckoutId,
    updatePaymentPlusOrderDetails,
    cancelOrder,
    validateMobileNumber
}

async function placeBulkOrder(projectId: number, orderDetails: DigitalOrderDetails, distributorDetails: DistributorDetails) {

    const resp = await axios.post<string>(`api/Ordering/PlaceBulkOrder/${projectId}`, {
        projectId,
        orderDetails,
        distributorDetails
    }, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function placeBatchOrder(projectId: number, orderDetails: DigitalOrderDetails, distributorDetails: DistributorDetails) {
    const resp = await axios.post<string>(`api/Ordering/PlaceBatchOrder/${projectId}`, {
        projectId,
        orderDetails,
        distributorDetails
    }, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function getProjectOrderingDetails(projectId: number) {

    const resp = await axios.get<ProjectOrderingDetails>(`api/Ordering/GetProjectOrderingDetails/${projectId}`, {
        headers: getAuthHeader()
    });
    
    return resp.data;
}
async function getAllPaymentMethods() {

    const resp = await axios.get<PaymentMethod[]>(`api/Ordering/GetAllPaymentMethods`, {
        headers: getAuthHeader()
    });

    return resp.data;
}
async function getProjectTemplates(projectId: number, deliveryMethod: DeliveryMethod) {

    const resp = await axios.get<Array<EmailTemplateDto>>(`api/Ordering/GetEmailTemplates/${projectId}/${deliveryMethod}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function getSmsTemplates(projectId: number) {

    const resp = await axios.get<Array<EmailTemplateDto>>(`api/Ordering/GetSmsTemplates/${projectId}`, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function validateMobileNumber(mobileNumber: string, countryCode: string) {
    try
    {
        const response = await axios.post('/api/Ordering/ValidateMobileNumber', {
            mobileNumber,
            countryCode
        }, {
            headers: getAuthHeader()
        });
        return response.data.isValid;
    }
    catch (error)
    {
        console.error("Error validating mobile number", error);
        return false;
    }
}

async function getPaymentPlusCheckoutId(orderId: number, previousAttempts: number, totalAmount: number, currencyCode: string, merchantId: string) {
    
    const resp = await axios.post<string>(`api/Payment/Checkout/${orderId}`, {
        orderTotalAmount: totalAmount.toFixed(2),
        currencyCode: currencyCode,
        merchantId: merchantId,
        attemptCount: previousAttempts
    }, {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function updatePaymentPlusOrderDetails(orderId: number, orderDetails: PaymentPlusOrderDetails) {    
    
    const resp = await axios.post<number>(`api/Ordering/SetPaymentPlusOrderDetails/${orderId}`, {
        orderDetails: orderDetails
    }, {
        headers: getAuthHeader()
    })

    return resp.data;

}

async function cancelOrder(projectId: string | undefined, orderId: number) {
    const response = await axios.post<string>(`api/Ordering/rejectOrder/${projectId}/${orderId}`, {
        projectId,
        orderId
    }, {
        headers: getAuthHeader()
    });

    return response;
}
