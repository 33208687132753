import axios from "axios";

import { AppSetting, LanguageDto } from "../Models";


export const appService = {
    getLocalizedContent,
    getAppSettings,
    getDataDomeSettings
}

async function getLocalizedContent() {

    const resp = await axios.get<LanguageDto[]>("api/App/GetLanguages");

    return resp.data;
}
async function getAppSettings() {

    const resp = await axios.get<AppSetting[]>("api/App/GetAppSettings");

    return resp.data;
}
async function getDataDomeSettings() {
    const resp = await axios.get<AppSetting[]>("api/App/GetDataDomeSettings");

    return resp.data;
}