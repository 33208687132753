import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { UserType, UserTypeLabel, UserPermissionItem, ProjectTypeLabel, LanguageDto, LanguageLabel, TenantGroup, IProjectDto, IClientDto } from "../Models"
import { AreaLoader, InputWithValidations, MultiSelectDropdown } from "../Components";;
import { bemNames, getErrorNotification, getSuccessNotification } from "Utilities";
import { Formik, Form, Field } from 'formik';
import { Container, Col, Row, FormGroup, Input, Label, Button, Table, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-multi-lang";
import Notifications from 'react-notification-system-redux';
import { userManagementService } from "../Services";
import * as Yup from "yup";
import Select from "react-select/src/Select";
import classnames from 'classnames';

enum Tab {
    SearchUsers,
    InviteUser
}

interface StoreProps {
    notifySuccess: Notifications.NotificationShow;
    notifyError: Notifications.NotificationShow;
};

const mapDispatchToProps = {
    notifyError: Notifications.error,
    notifySuccess: Notifications.success,
};

interface Props extends StoreProps {
    parentActiveTab: (tab: Tab) => void;
}


const InviteUser = ({
    notifyError,
    notifySuccess,
    parentActiveTab
}: Props) => {

    const [isBusy, setIsBusy] = useState(false);
    const [isBusyMessage, setIsBusyMessage] = useState<string>();   
    const [projectList, setProjectList] = useState<UserPermissionItem[]>();
    const [languageOptions, setLanguageOptions] = useState<LanguageDto[]>();
    const [userType, setUserType] = useState<UserType>(UserType.SelfServiceUser);
    const [activeTab, setActiveTab] = useState<Tab>(Tab.SearchUsers);
    const [tenantProjects, setTenantProjects] = useState<Record<number, UserPermissionItem[]>>({});
    const [tenantNoProjects, setTenantsNoProjects] = useState<IClientDto[]>([]);
    const [tenantAdminPermission, setTenantAdminPermission] = useState<{ [key: string]: boolean }>({});
    const [projects, setProjects] = useState<IProjectDto[]>([]);
    const [clients, setClients] = useState<IClientDto[]>([]);
    const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);
    const [showErrorForMultiSelectTenants, setShowErrorForMultiSelectTenants] = useState<boolean>(false);

    const bem = bemNames.create("invite-users");
    const t = useTranslation();

    const UserDetailsValidationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(t("ValidationError.FirstNameRequired"))
            .max(100, t("ValidationError.FirstNameMaxChars")),

        lastName: Yup.string()
            .required(t("ValidationError.LastNameRequired"))
            .max(100, t("ValidationError.LastNameMaxChars")),

        emailAddress: Yup.string()
            .email(t("ValidationError.InvalidEmailAddressMessage"))
            .required(t("ValidationError.EmailAddressRequiredMessage")),

        department: Yup.string()
            .max(100, t("ValidationError.DepartmentMaxChars")),

        jobTitle: Yup.string()
            .max(100, t("ValidationError.DepartmentMaxChars")),

        languageId: Yup.number()
            .required(t("ValidationError.LanguageRequired"))
    });

    useEffect(() => {
        (async () => {
            await GetProjectsList();
            await GetLanguages();
        })();
    }, [])

    const GetProjectsList = async () => {
        try {
            setIsBusy(true);
            setIsBusyMessage(t("Projects.LoadingProjectsMessage"))

            const projectList = await userManagementService.getProjectDetails();
            setProjectList(projectList); 

            // Group projects by tenantId
            const tenantIds = new Set(projectList.map(project => project.tenantId));
            const groupedProjects: Record<number, UserPermissionItem[]> = {};
            tenantIds.forEach(tenantId => {
                groupedProjects[tenantId] = projectList.filter(project => project.tenantId === tenantId);
            });

            const tenantsWithNoProjects = await userManagementService.getTenantsWithNoProjects();

            setTenantsNoProjects(tenantsWithNoProjects);


            tenantsWithNoProjects?.forEach(tenant => {
                groupedProjects[Number(tenant.tenantId)] = [];
            });

            setTenantProjects(groupedProjects);

            const initialTenantTabsState: { [key: number]: boolean } = {};
            projectList.forEach(project => {
                initialTenantTabsState[project.tenantId] = false;
            });
            setTenantAdminPermission(initialTenantTabsState);



            // Map UserPermissionItems to IProjectDto
            const mappedProjects: IProjectDto[] = projectList.map<any>((project) => {
                return {
                    projectId: project.projectId.toString(),
                    projectName: project.projectName,
                    projectType: project.projectType,
                    jobNumber: project.jobNumber,
                    tenantId: project.tenantId,
                    tenantName: project.tenantName
                }
            });

            // Group projects by tenantId
            const projectsByTenant: { [key: string]: IProjectDto[] } = mappedProjects.reduce((acc, project) => {
                if (!acc[project.tenantId]) {
                    acc[project.tenantId] = [];
                }
                acc[project.tenantId].push(project);
                return acc;
            }, {} as { [key: string]: IProjectDto[] });

            // Set projects
            setProjects(mappedProjects);

            // Extract unique tenants
            const uniqueTenants: IClientDto[] = Object.values(projectsByTenant).map(projects => ({
                tenantId: projects[0].tenantId,
                tenantName: projects[0].tenantName,
                isClientBlocked: false,
                projects: projects
            }));

            const tenants = [...uniqueTenants, ...tenantsWithNoProjects];
            // Set clients
            setClients(tenants);

            // If there is only a single client, preselect it during page load
            if (tenants.length == 1) {
                setSelectedClientIds([tenants[0].tenantId]);
                setShowErrorForMultiSelectTenants(false);
            }
        }
        catch (e) {
            console.error(e);
        }
        setIsBusy(false);
        setIsBusyMessage(undefined);
    }

    const GetLanguages = async () => {
        try {
            setIsBusy(true);

            const languages = await userManagementService.getLanguages();
            setLanguageOptions(languages);
        }
        catch (e) {
            console.error(e);
        }

        setIsBusy(false);
        setIsBusyMessage(undefined);
    }

    const scrollToErrors = (errors: {}) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            window.scrollTo(0, 0);
        }
    }

    function getTenantName(tenantId: string) : string {
        var tenantName = tenantNoProjects?.find(x => x.tenantId == tenantId)?.tenantName;
        return tenantName ?? '';
    }

    const filteredProjects: Record<number, UserPermissionItem[]> = {};
    selectedClientIds.forEach(tenantId => {

        var projects = projectList!.filter(project => project.tenantId === Number(tenantId));
        if (projects == null || projects == undefined) {
            filteredProjects[Number(tenantId)] = [];
        }
        filteredProjects[Number(tenantId)] = projects;
    });

    return (
        <div className="card">
            <div className="card-body">
                <div className="card-title mb-0">
                    <h2>{t("UserManagement.InviteUsers")}</h2>
                </div>
                <div className={bem.e("section-content")}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            emailAddress: "",
                            department: "",
                            jobTitle: "",
                            userType: UserType.SelfServiceUser,
                            languageId: 1
                        }}                       
                        validationSchema={UserDetailsValidationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            if (((values.userType == UserType.SelfServiceSuperUser) && selectedClientIds.length > 0) ||
                                ((values.userType == UserType.SelfServiceUser) && (projectList?.some((i => i.hasCustomerServicePermission == true || i.hasOrderPlacementPermission == true))))) {
                                try {

                                    setIsBusy(true);
                                    setIsBusyMessage(t("UserManagement.SubmttingInviteRequest"));

                                    let filteredProjectList: UserPermissionItem[] = [];

                                    if (values.userType == UserType.SelfServiceSuperUser) {
                                        selectedClientIds.forEach(tenantId => {

                                            var projects = projectList!.filter(project => project.tenantId === Number(tenantId));
                                            filteredProjectList = [...filteredProjectList, ...projects];
                                        });
                                    }
                                    else {
                                        filteredProjectList = projectList || [];
                                    } 


                                    const tenantGroupsMap: { [key: number]: TenantGroup } = filteredProjectList.reduce((acc, item) => {
                                        if (!acc[item.tenantId]) {
                                            acc[item.tenantId] = {
                                                tenantId: item.tenantId,
                                                tenantName: item.tenantName,
                                                hasAdminPermission: (values.userType == UserType.SelfServiceSuperUser),
                                                projects: []
                                            };
                                        }
                                        acc[item.tenantId].projects.push(item);
                                        return acc;
                                    }, {} as { [key: number]: TenantGroup });

                                    if (values.userType == UserType.SelfServiceSuperUser) {
                                        tenantNoProjects.forEach(tenant => {
                                            tenantGroupsMap[Number(tenant.tenantId)] = {
                                                tenantId: Number(tenant.tenantId),
                                                tenantName: tenant.tenantName,
                                                hasAdminPermission: (values.userType == UserType.SelfServiceSuperUser),
                                                projects: []
                                            };
                                        })
                                    }

                                    let errorMessage = await userManagementService.inviteUser({
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        emailAddress: values.emailAddress,
                                        department: values.department,
                                        jobTitle: values.jobTitle,
                                        userRole: values.userType,
                                        tenantGroups: Object.values(tenantGroupsMap),
                                        languageId: values.languageId
                                    });

                                    if (errorMessage) {
                                        notifyError(getErrorNotification(t(errorMessage)));
                                    } else {
                                        notifySuccess(getSuccessNotification(t("UserManagement.InviteUsersSucceededMessage")));
                                        parentActiveTab(Tab.SearchUsers);
                                    }

                                } catch (e) {
                                    console.error(e);
                                    notifyError(getErrorNotification(t("UserManagement.InviteUsersFailedMessage")));
                                }

                                setIsBusy(false);
                                setIsBusyMessage(undefined);

                            } else {
                                notifyError(getErrorNotification(t("UserManagement.UniqueUserError")));
                            }
                        }}
                    >

                        {({ setFieldValue, values }) => (
                            <Container className="px-0">
                                <AreaLoader show={isBusy} message={isBusyMessage ?? t("UserManagement.LoadingUserLabel")} />

                                <Form>
                                    <Row className="small-bottom-margin">                                   
                                        <Col xs="12">
                                            <Row>

                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <Label for="firstName" className="font-weight-bold text-uppercase">{t("FirstNameLabel")}</Label>
                                                        <Field id="firstName" name="firstName" component={InputWithValidations} />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <Label for="lastName" className="font-weight-bold text-uppercase">{t("LastNameLabel")}</Label>
                                                        <Field id="lastName" name="lastName" component={InputWithValidations} />
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                            <Row>

                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <Label for="emailAddress" className="font-weight-bold text-uppercase">{t("Login.EmailAddressLabel")}</Label>
                                                        <Field id="emailAddress" name="emailAddress" component={InputWithValidations} />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <Label for="userType" className="font-weight-bold text-uppercase">{t("UserTypeLabel")}</Label>
                                                        <Field id="userType" name="userType" component={
                                                            () =>
                                                                <Input
                                                                    id="user-type-select"
                                                                    type="select"
                                                                    onChange={(e) => {
                                                                        setFieldValue("userType", +e.target.value);
                                                                        setUserType(+e.target.value);
                                                                        setProjectList(prev => prev?.map(obj => ({ ...obj, hasCustomerServicePermission: false, hasOrderPlacementPermission: false })));                                                                                                                                                
                                                                    }}
                                                                    value={values.userType}
                                                                >
                                                                    {
                                                                        Array.from(UserTypeLabel.entries())
                                                                            .filter(entry => [UserType.SelfServiceUser, UserType.SelfServiceSuperUser].includes(entry[0]))
                                                                            .map((entry) => <option key={entry[0]} value={entry[0]}>{t(entry[1])}</option>)
                                                                    }
                                                                </Input>
                                                        } />

                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                            <Row>

                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <Label for="department" className="font-weight-bold text-uppercase">{t("UserManagement.DepartmentLabel")}</Label>
                                                        <Field id="department" name="department" component={InputWithValidations} />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <Label for="jobTitle" className="font-weight-bold text-uppercase">{t("UserManagement.JobTitleLabel")}</Label>
                                                        <Field id="jobTitle" name="jobTitle" component={InputWithValidations} />
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col xs="12" md="6">
                                                    <FormGroup>
                                                        <Label for="languageId" className="font-weight-bold text-uppercase">{t("UserManagement.LanguageLabel")}</Label>
                                                        <Field id="languageId" name="languageId" component={
                                                            () =>
                                                                <Input
                                                                    id="user-language-select"
                                                                    type="select"
                                                                    onChange={(e) => {
                                                                        setFieldValue("languageId", +e.target.value)
                                                                    }}
                                                                    value={values.languageId}
                                                                >
                                                                    {
                                                                        Array.from(LanguageLabel.entries())
                                                                            .map((entry) => <option key={entry[0]} value={entry[0]}>{t(entry[1])}</option>)
                                                                    }
                                                                </Input>
                                                        } />
                                                       
                                                    </FormGroup>

                                                </Col>

                                                {clients.length > 1 &&
                                                    <Col xs="12" md="6">
                                                        <FormGroup>
                                                            <Label for="inviteUserMultiselectCompanies" className="font-weight-bold text-uppercase">{t("Projects.CompanyLabel")}</Label>
                                                            <MultiSelectDropdown
                                                                id="inviteUserMultiselectCompanies"
                                                                message={!selectedClientIds.length ? t("Projects.NoneLabel") : t("Projects.SelectedCompanyCount").replace("[[Count]]", selectedClientIds.length.toString())}
                                                                options={clients
                                                                    .map(c => ({ label: c.tenantName, value: c.tenantId }))
                                                                    .sort((a, b) => a.label.localeCompare(b.label))
                                                                }
                                                                chosenOptions={selectedClientIds
                                                                    .map(clientId => ({ label: clients.find(c => c.tenantId === clientId)?.tenantName || "", value: clientId }))
                                                                }
                                                                onChange={values => {
                                                                    setSelectedClientIds(values.map(v => v.value));
                                                                    setShowErrorForMultiSelectTenants(!(values.map(v => v.value).length > 0));
                                                                }}

                                                            />
                                                            {showErrorForMultiSelectTenants &&  
                                                                <div className="error-tenant-multiselect">{t("Projects.SelectAtleastOneCompany")}</div>
                                                            }
                                                        </FormGroup>

                                                    </Col>
                                                }
                                            </Row>

                                            <Row>
                                                <Col className="mb-2 mt-3">
                                                    <h2>{t("UserPermissions.ProjectPermissions")}</h2>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col>
                                                    {filteredProjects && Object.keys(filteredProjects).length > 0 && (
                                                        <Nav tabs>
                                                            {Object.entries(filteredProjects).map(([tenantId, projects], index) => (
                                                                <NavItem key={index}>
                                                                    <NavLink
                                                                        className={classnames({ active: activeTab === parseInt(tenantId, 10) })}
                                                                        onClick={() => setActiveTab(parseInt(tenantId, 10))}
                                                                    >
                                                                        {projects.length > 0 ? projects[0].tenantName : getTenantName(tenantId)}
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))}
                                                        </Nav>
                                                    )}
                                                    <TabContent activeTab={activeTab}>
                                                        {Object.entries(filteredProjects).map(([tenantId, projects], index) => (
                                                            <TabPane key={index} tabId={parseInt(tenantId, 10)} className={Object.keys(filteredProjects).length === 1 ? "active" : ""}>
                                                                <Container className="px-0">
                                                                    {userType == UserType.SelfServiceSuperUser ?
                                                                        <div>
                                                                            <h3 className="alert alert-info">{t("OrderingPortal.UserManagementSuperUserProjectAccess")}</h3>
                                                                        </div>
                                                                        :
                                                                        projects.length <= 0 ?
                                                                    <div>
                                                                                <h3 className="alert alert-info">{t("Projects.CompanyHasNoProjects")}</h3>
                                                                    </div>
                                                                        :
                                                                        <Table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>{t("UserPermissions.JobNumber")}</th>
                                                                                    <th>{t("UserPermissions.ProjectName")}</th>
                                                                                    <th>{t("UserPermissions.ProjectType")}</th>
                                                                                    <th>{t("UserPermissions.OrderPlacement")}</th>
                                                                                    <th>{t("UserPermissions.CustomerService")}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {projects?.map((item) =>
                                                                                    <tr key={item.projectId}>
                                                                                        <td>{item.jobNumber}</td>
                                                                                        <td>{item.projectName}</td>
                                                                                        <td>{t(ProjectTypeLabel.get(item.projectType) ?? "")}</td>
                                                                                        <td>
                                                                                            <input
                                                                                                style={{ cursor: "pointer" }}
                                                                                                name={"orderPlacement" + item.projectId.toString()}
                                                                                                id={"orderPlacement" + item.projectId.toString()}
                                                                                                onChange={() => setProjectList(permissions => permissions?.map((p, i) => p.projectId == item.projectId ? { ...p, hasOrderPlacementPermission: !item.hasOrderPlacementPermission } : p))}
                                                                                                type="checkbox"
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <input
                                                                                                style={{ cursor: "pointer" }}
                                                                                                name={"customerService" + item.projectId.toString()}
                                                                                                id={"customerService" + item.projectId.toString()}
                                                                                                onChange={() => setProjectList(permissions => permissions?.map((p) => p.projectId == item.projectId ? { ...p, hasCustomerServicePermission: !item.hasCustomerServicePermission } : p))}
                                                                                                type="checkbox"
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </Table>
                                                                    }
                                                                </Container>
                                                            </TabPane>
                                                        ))}
                                                    </TabContent>
                                                </Col>
                                            </Row>                                                  

                                            <Row>
                                                <Col>
                                                    <Button onClick={(errors) => scrollToErrors(errors)} color="primary" type="submit" className="text-uppercase float-right">{t("UserManagement.AddUserButton")}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Container>
                        )}
                    </Formik>           
                </div> 
                
            </div>
        </div>
    )
}

const ConnectedComponent = connect(null, mapDispatchToProps)(InviteUser);

export { ConnectedComponent as InviteUser };