import axios from "axios";

import { getAuthHeader } from "./";
import { UserDetails } from "Auth";
import { MfaSetupDetails } from "Models";
import { AccessTokenDetails } from "../Models/AccessTokenDetails";
export const userService = {
    login,
    getJwtToken,
    getMfaSetupDetails,
    validateAppMfaCode,
    sendSmsCode,
    validateSmsCode,
    logout,
    getUserDetails,
    changePassword,
    requestResetPassword,
    validateResetPassword,
    checkIfUserHasTimedOut,
    refreshingToken,
    updateUserLanguage
};

async function login(emailAddress: string, password: string, arkoseToken: string, languageCode: string) {

    const resp = await axios.post<UserDetails>("api/account/login", {
        emailAddress,
        password,
        arkoseToken,
        languageCode
    });

    return resp.data;
}

async function getJwtToken() {

    const resp = await axios.get<UserDetails>("sso/getJwtToken");

    return resp.data;
}

async function getMfaSetupDetails() {

    const resp = await axios.get<MfaSetupDetails>("api/account/getMfaSetupCode", {
        headers: getAuthHeader()
    });
    return resp.data;
}

async function validateAppMfaCode(code: string) {

    const resp = await axios.post<UserDetails>("api/account/validateAppMfaCode",
        {
            inputCode: code
        },
        {
            headers: getAuthHeader()
        });
    return resp.data;
}

async function validateSmsCode(code: string, phoneNumber: string) {

    var result = await axios.post<UserDetails>("api/Account/ValidateSmsCode",
        {
            inputCode: code,
            phoneNumber: phoneNumber
        },
        {
            headers: getAuthHeader()
        });

    return result.data;
}

async function sendSmsCode(countryIso2: string, phoneNumber: string) {

    await axios.post("api/Account/SendSmsCode",
        {
            countryIso2: countryIso2,
            phoneNumber: phoneNumber
        },
        {
            headers: getAuthHeader()
        });
    return true;
}

async function logout(refreshToken: string) {

    await axios.post("api/account/logout",
        {
            refreshToken: refreshToken
        },
        {
            headers: getAuthHeader()
        });
}

async function getUserDetails() {

    const resp = await axios.get<UserDetails>("api/account/user", {
        headers: getAuthHeader()
    });

    return resp.data;
}

async function changePassword(password: string, passwordConfirmation: string) {

    const resp = await axios.post<UserDetails>("api/Account/ChangePassword",
        {
            password: password,
            passwordConfirmation: passwordConfirmation
        },
        {
            headers: getAuthHeader()
        });

    return resp.data;
}

async function requestResetPassword(emailAddress: string, arkoseToken: string, languageCode: string) {

    await axios.post("api/Account/ResetPassword",
        {
            emailAddress: emailAddress,
            arkoseToken: arkoseToken,
            languageCode: languageCode
        });
}

async function validateResetPassword(token: string) {

    const resp = await axios.get<UserDetails>("api/Account/ResetPassword",
        {
            params: {

                token: token
            }
        });

    return resp.data;
}

async function checkIfUserHasTimedOut(accessToken: string) {
    const resp = await axios.get<AccessTokenDetails>("api/Account/HasUserBeenTimedOut",
        {
            params: {
                accessToken: accessToken
            }
        });

    return resp.data;
}

async function refreshingToken(accessToken?: string, refreshToken?: string, isSso?: boolean) {
    var resp = await axios.post<UserDetails>("api/account/refresh-token", {
        accessToken: accessToken,
        refreshToken: refreshToken,
        isSso: isSso ?? false
    },
        {
            headers: getAuthHeader()
        });

    return resp.data;
}

async function updateUserLanguage(languageCode: string) {
    const resp = await axios.post("api/Account/UpdateUserLanguage", {
        languageCode: languageCode
    },
        {
            headers: getAuthHeader()
        });


    return resp.data;
}

