import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from "Store";
import { Redirect, useParams, Link } from 'react-router-dom';
import { useTranslation } from "react-multi-lang";
import { getErrorNotification, scrollToTopOfThePage, bemNames } from 'Utilities';
import { PaymentPlusOrderDetails, ProjectOrderingDetails, OrderDetailsDto, BatchTypeIconSize, ProjectSetupType } from "../Models";
import Notifications from 'react-notification-system-redux';
import PaymentWidget from '@bhn/oc-payments-js/dist/core/payment';
import { AreaLoader, BatchTypeIconImage } from '../Components';
import axios from "axios";
import { orderingService, orderHistoryService } from 'Services';
import { Alert, Button, Modal, ModalBody, ModalHeader, ModalFooter, Card, CardBody, CardHeader } from 'reactstrap';
import { UserDetails } from '../Auth/UserDetails';
import { ClaimTypes } from '../Auth/ClaimTypes';
import { CgFileDocument } from "react-icons/cg";

interface StoreProps {
    notifyError: Notifications.NotificationShow;
}

interface Props extends StoreProps {
    userDetails: UserDetails
}

const mapStateToProps = ({ user }: ApplicationState) => (
{
    userDetails: user.user!
})

const mapDispatchToProps = {
    notifyError: Notifications.error
}

const PaymentPlusWidget = ({
    notifyError,
    userDetails,
}: Props) => {

    const bem = bemNames.create("payment-plus");

    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [redirectToOrderDetails, setRedirectToOrderDetails] = useState(false);
    const [cancelModal, setCancelModal] = useState<boolean>(false);
    const [redirectToOrderHistory, setRedirectToOrderHistory] = useState(false);
    const toggleCancelModal = () => setCancelModal(!cancelModal);
    const [projectOrderingDetails, setProjectOrderingDetails] = useState<ProjectOrderingDetails>();
    const [orderDetails, setOrderDetails] = useState<OrderDetailsDto>();
    const [pPConfiguration, setPPConfiguration] = useState<any>();
    const [isReady, setIsReady] = useState(false);
    const [checkoutId, setCheckoutId] = useState<string>();
    const [isTherePaymentError, setisTherePaymentError] = useState(false);
    const [boldMessage, setBoldMessage] = useState<string>();
    const [normalMessage, setNormalMessage] = useState<string>();
    // url params
    const routeParams = useParams<{
        projectId: string,
        orderId: string
    }>();

    const orderId = parseInt(routeParams.orderId, 10);
    const projectId = routeParams.projectId;

    const t = useTranslation();

    useEffect(() => {
        return () => {
            axios.defaults.baseURL = "/";
        }
    }, [])

    useEffect(() => {
        if (projectId == "0" || orderId == 0) {
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderDetailsFailure")));
            setRedirectToOrderHistory(true);
        }
        async function a() {
            loadOrderDetails();
            loadProjectDetails();
        }

        if (orderId) {
            a();
        }
    }, [routeParams.projectId, routeParams.orderId]);

    const loadOrderDetails = async () => {
        try {
            axios.defaults.baseURL = "/";
            const data = await orderHistoryService.getOrderDetails(orderId);
            if (data.batchStatusId != 11) {
                notifyError(getErrorNotification(t("NotificationMessage.LoadOrderDetailsFailure")));
                setRedirectToOrderDetails(true);
            }
            setOrderDetails(data);
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderDetailsFailure")));
        }
    }

    const loadProjectDetails = async () => {
        try {
            axios.defaults.baseURL = "/";
            const data = await orderingService.getProjectOrderingDetails(parseInt(projectId, 10));
            setProjectOrderingDetails(data);
        } catch (e) {
            throw e;
        }
    }

    const removePaymentErrorMessage = () => {
        setisTherePaymentError(false);
    }

    const showPaymentErrorMessage = () => {
        setisTherePaymentError(true);
        scrollToTopOfThePage();
    }

    const onCancelOrder = async () => {

        setLoading(true);
        setLoadingMessage(t("NotificationMessage.CancelOrderInProcess"));
        setBoldMessage("OrderDetails.CancelledDebitCreditMessage");
        try {
            axios.defaults.baseURL = "/";
            await orderingService.cancelOrder(projectId, orderId);
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("ErrorPages.CancelOrderFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
        setRedirectToOrderDetails(true);
    }

    const onPayLater = async () => {
        setBoldMessage("OrderDetails.OrderSavedMessage");
        setNormalMessage("OrderDetails.SaveAndPayLaterMessage");
        setRedirectToOrderDetails(true);
    }



    useEffect(() => {
        if (orderDetails?.checkoutId) {
            setCheckoutId(orderDetails?.checkoutId);
        }
        else if (checkoutId == undefined && projectOrderingDetails && orderDetails) {
            try {
                // need to handle the retry count properly (currently 0)
                orderingService.getPaymentPlusCheckoutId(
                    orderId,
                    0,
                    orderDetails?.totalOrderAmount,
                    projectOrderingDetails?.currency ?? "",
                    projectOrderingDetails?.paymentPlusMerchantId ?? ""
                )
                    .then((cId) => { setCheckoutId(cId); });
            } catch (e) {
                console.error(e);
            }
        }
    }, [orderDetails, projectOrderingDetails]);

    useEffect(() => {
        if (checkoutId && projectOrderingDetails && orderDetails) {
            const updateTransactionDetail = async (data: any) => {
                let paymentDetails = {
                    decision: data['decision'],
                    checkoutId: checkoutId,
                    transactionId: data['transactionId'],
                    authorizationId: data['authorizationId'],
                    approvedAmount: data['approvedAmount'],
                    currencyCode: data['currencyCode'],
                    lastFourDigits: data['lastFourDigits'],
                    processorAuthorizationId: data['processorAuthorizationId'],
                    hmac: data['hmac']
                } as PaymentPlusOrderDetails;

                try {
                    axios.defaults.baseURL = "/";
                    setLoading(true);
                    setLoadingMessage(t("RedemptionCodeBatchStatus.ProcessingCardPayment"));
                    setBoldMessage("OrderDetails.ThankyouForOrderMessage");
                    setNormalMessage("OrderDetails.SuccessfulPaymentMessage");
                    await orderingService.updatePaymentPlusOrderDetails(orderId, paymentDetails);
                } catch (e) {
                    console.error(e);
                }
                setRedirectToOrderDetails(true);

            };

            var BillingInfoHeader = {
                name: 'BillingInfoHeader'
            };

            var adyenWidget = {
                name: 'PaymentComponent'
            };

            // Custom Data to be passed to the adyen web drop-in component
            var paymentWidgetConfiguration = {
                paymentPlusData: { merchantId: projectOrderingDetails?.paymentPlusMerchantId },
                checkoutId: checkoutId,
                caseId: orderId,
                environment: projectOrderingDetails?.paymentPlusEnvironment,
                components: [
                    BillingInfoHeader,
                    adyenWidget
                ],
                cart: {
                    countryCode: projectOrderingDetails?.countryCode,
                    languageCode: projectOrderingDetails?.languageCode,
                    cartAmount: orderDetails?.totalOrderAmount.toFixed(2),
                    currencyCode: projectOrderingDetails?.currency
                },
                billingAddress: { countryCode: projectOrderingDetails?.countryCode },
                authCallback: async (data: any) => {
                    if (data['status'] === 201 &&
                        (data['decision'] === 'APPROVED' || data['decision'] === 'REVIEW')) {
                        await updateTransactionDetail(data);
                    }
                    else {
                        showPaymentErrorMessage();
                    }
                }
            };
            setPPConfiguration(paymentWidgetConfiguration);
            setIsReady(true);
            setLoading(false);
        }
    }, [checkoutId, orderDetails, projectOrderingDetails]);

    if (isReady) {
        var pp = new PaymentWidget(pPConfiguration);
    }

    if (redirectToOrderDetails && orderId) {
       // return <Redirect to={`/order-history/order-details/${orderId}`} />
        return <Redirect to={{
            pathname: `/order-history/order-details/${orderId}`,
            state: {
                customMessage: {
                    boldpart: boldMessage,
                    normalpart: normalMessage
                }
            }
        }} />
    }

    if (redirectToOrderHistory) {
        return <Redirect to={`/order-history/`} />;
    }


    return (
        <div className={bem.b()}>
            <AreaLoader
                show={loading}
                message={loadingMessage ?? t("NotificationMessage.LoadOrderDetailsMessage")}
            />
            <div className="d-flex flex-column flex-lg-row">

                <div className="c-order-details__row mt-4 d-flex flex-column mr-lg-3">
                    <Card className="w-100">
                        <CardHeader>
                            <strong className="text-uppercase">Pay by Debit/Credit Cards</strong>
                        </CardHeader>
                        {isReady &&
                            <CardBody>
                                <Alert color="danger" className="mt-3" isOpen={isTherePaymentError} toggle={removePaymentErrorMessage}>
                                    <strong>{t("OrderingProcess.PaymentErrorOne")}</strong><>&nbsp;</>-<>&nbsp;</><span>{t("OrderingProcess.PaymentErrorTwo")}</span>
                                </Alert>
                                {pp.render()}
                                <div className="payment-plus_options d-flex flex-column flex-lg-row justify-content-between">
                                    <Button className={bem.e("payment-options", "bg-danger w-100 mb-3 mb-lg-0 mr-lg-1 px-4 px-lg-5 py-2 border rounded border-solid d-flex align-items-center justify-content-center")} onClick={toggleCancelModal}>
                                        Cancel order
                                    </Button>
                                    <Button className={bem.e("payment-options", "bg-warning w-100 ml-lg-1 px-4 px-lg-5 py-2 border rounded border-solid d-flex align-items-center justify-content-center")} onClick={onPayLater}>
                                        Save and Pay later
                                    </Button>
                                </div>

                                <Modal isOpen={cancelModal} toggle={toggleCancelModal} >
                                    <ModalHeader toggle={toggleCancelModal}>Cancel order</ModalHeader>
                                    <ModalBody>
                                        <div className="cancel-confirm-box">
                                            Are you sure you want to cancel this order?
                                        </div>
                                    </ModalBody>
                                    <ModalFooter className="flex-row justify-content-between">
                                        <Button outline onClick={toggleCancelModal}>
                                            No
                                        </Button>
                                        <Button color="primary" onClick={onCancelOrder}>
                                            Yes
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </CardBody>
                        }
                    </Card>
                </div>
                <div className="c-order-details__row c-order-digital__info-panel order-costs mt-4 d-flex flex-column ml-lg-3" style={{ height: "100%" }}>
                    <div className="card" style={{ borderRadius: "0.25rem 0.25rem 0 0" }}>
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="mr-3 icon-wrap">
                                    <BatchTypeIconImage codeType={projectOrderingDetails?.codeType} size={BatchTypeIconSize.medium} />
                                </div>
                                <div>
                                    <h3 className="text-uppercase">{t("OrderSummary.ProjectDetailsLabel")}</h3>
                                    <p style={{ lineHeight: "1.2rem" }}>
                                        {projectOrderingDetails && projectOrderingDetails.projectName}
                                        <br />
                                        Id: {projectOrderingDetails && projectId}
                                    </p>
                                    <Link to={`/projects/project/${projectId}`} style={{ fontSize: "0.875rem" }}><CgFileDocument className="mr-1" size={22} />{t("OrderSummary.ViewProjectButton")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={bem.e("row order-costs", "mt-4 d-flex flex-column pb-5")} style={{ position: "relative" }}>
                        <div className={bem.e("row-body", "w-100 pt-1")}>
                            <div className="p-3">

                                <div className="d-flex justify-content-between w-100">
                                    <div>{t("OrderDetails.TotalValueLabel")}</div>
                                    <div>{orderDetails?.currency}{orderDetails?.totalItemAmount?.toFixed(2) || 0.00}</div>
                                </div>

                                {orderDetails && (orderDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                    || orderDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption) &&
                                    (orderDetails.handlingFeePercentage ?? 0) > 0 &&

                                    <div className="d-flex justify-content-between w-100 mt-2">
                                        <div>{t("OrderDetails.HandlingFeeLabel")} ({orderDetails?.handlingFeePercentage?.toFixed(1)}%)</div>
                                        <div>{orderDetails?.currency}{orderDetails?.handlingFeeAmount?.toFixed(2) || 0.00}</div>
                                    </div>
                                }

                                {orderDetails && (orderDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                    || orderDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption)
                                    && (orderDetails?.totalCostPerCodeAmount ?? 0) > 0 &&

                                    <div className="d-flex justify-content-between w-100 mt-2">
                                        <div>{t("OrderDetails.TotalCostPerCodeLabel")}</div>
                                        <div>{orderDetails?.currency}{orderDetails?.totalCostPerCodeAmount?.toFixed(2) || 0.00}</div>
                                    </div>
                                }

                                {orderDetails && (orderDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                    || orderDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption)
                                    && (orderDetails?.costPerSmsAmount ?? 0) > 0 &&

                                    <div className="d-flex justify-content-between w-100 mt-2">
                                        <div>{t("OrderDetails.TotalCostPerSmsLabel")}</div>
                                        <div>{orderDetails?.currency}{orderDetails?.totalSMSAmount?.toFixed(2) || 0.00}</div>
                                    </div>
                                }

                                {orderDetails && orderDetails.discountPercentage && orderDetails.discountPercentage > 0 &&

                                    <div className="d-flex justify-content-between w-100 mt-2">
                                        <div>{t("OrderDetails.DiscountLabel")} ({orderDetails?.discountPercentage?.toFixed(1)}%)</div>
                                        <div>{orderDetails?.currency}{orderDetails?.discountAmount && `-${orderDetails?.discountAmount?.toFixed(2) || 0.00}`}</div>
                                    </div>
                                }

                                {orderDetails && orderDetails.vatPercentage && orderDetails.vatPercentage > 0 && orderDetails.vatAmount > 0 &&

                                    <div className="d-flex justify-content-between w-100 mt-2">
                                        <div>{t("OrderDetails.VatRateLabel")} ({orderDetails?.vatPercentage?.toFixed(1)}%)</div>
                                        <div>{orderDetails?.currency}{orderDetails?.vatAmount.toFixed(2) || 0.00}</div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card border-top-0 w-100" style={{ position: "absolute", bottom: "0px", left: "0px", borderRadius: "0 0 0.25rem 0.25rem", fontSize: "1.5rem", backgroundColor: "#CBCFDB" }}>
                            <div className="card-body py-2">
                                <div className="d-flex large justify-content-between">
                                    <div>{t("OrderDetails.TotalLabel")}</div>
                                    <div>{orderDetails?.currency}{orderDetails && orderDetails.totalOrderAmount?.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(PaymentPlusWidget);

export {
    ConnectedComponent as PaymentPlusPage
}