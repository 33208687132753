import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from 'react-router';
import { connect } from "react-redux";
import { setTranslations, setDefaultLanguage } from 'react-multi-lang'

import { ApplicationState } from "Store";
import * as UserReducer from "Store/Reducers/UserReducer";
import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as AppReducer from "Store/Reducers/AppReducer";
import { ProjectsPage, LoginPage, NotFoundPage, OrderHistoryPage, OrderCodesPage, DownloadDetailsPage, ManageUsersPage, MfaSetupPage, YourDownloadsPage, ProjectDetailsPage, OrderDetailsPage, UnauthorizedPage, EditUserPage, UpdatePasswordPage, ForgotPasswordPage, ResetPasswordPage, CustomerServicePage, SearchOrderDetailsPage, ContactUsPage, IssuanceDetailsPage, SsoMiddlewarePage, UserGuidePage, PaymentPlusPage } from 'Pages';
import { appService, userService } from 'Services';
import { AuthenticatedRoute, UserDetails } from 'Auth';
import { Layout, PageLoader } from 'Components';
import { LanguageDto, SelectedLanguageDetails } from "./Models";
import { groupBy } from "./Utilities";

import TagManager from 'react-gtm-module';

interface StoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
    setLocalization: typeof AppReducer.actions.setLocalization;
    logout: AuthenticationReducer.LogoutAction;
    loadLocalizedContent: typeof AppReducer.actions.loadLocalizedContent;
    setAppSettings: typeof AppReducer.actions.setAppSettings;
    user?: UserDetails;
    language: LanguageDto[];
    selectedLanguage?: SelectedLanguageDetails;
    loggedIn: boolean;
    accessToken?: string;
    refreshToken?: string;
}

const mapStateToProps = ({ auth, user: userState, app }: ApplicationState) => {

    return {
        loggedIn: auth.loggedIn,
        accessToken: auth.accessToken,
        refreshToken: auth.refreshToken,
        user: userState.user,
        language: app.language,
        selectedLanguage: app.selectedLanguage
    };
}

const mapDispatchToProps = {
    loadUser: UserReducer.actions.loadUser,
    logout: AuthenticationReducer.actions.logout,
    loadLocalizedContent: AppReducer.actions.loadLocalizedContent,
    setLocalization: AppReducer.actions.setLocalization,
    setAppSettings: AppReducer.actions.setAppSettings
}

const tagManagerArgs = {
    gtmId: 'GTM-K23FXS2'
}

TagManager.initialize(tagManagerArgs)

const App = ({
    loggedIn,
    accessToken,
    refreshToken,
    user,
    loadUser,
    setAppSettings,
    logout,
    language,
    loadLocalizedContent,
    setLocalization,
    selectedLanguage
}: StoreProps) => {

    const [initializing, setInitializing] = useState(true);
    const [dataDomeClientkey, setDataDomeClientkey] = useState<string>();

    useEffect(() => {
        // Create an scoped async function in the hook
        async function af() {

            await logInExistingUser();
            await initializeLocalizedContent();
            await loadAppSettings();
            await loadDataDomeScript();

            setInitializing(false);
        }
        // Execute the created function directly
        af();
    }, []);


    const initializeLocalizedContent = async () => {

        // if the client already has loaded localization content, use that instead and load the newest one in the background
        if (language && language.length > 0) {

            getLocalizedContent();

            if (selectedLanguage && language.find(d => d.languageCode === selectedLanguage.languageCode)) {

                const localizations = mapLocalizationContentsToObject(language);

                setTranslations(localizations);

                // try to get the previously selected localization
                loadSelectedLocalization(language, selectedLanguage.languageCode);
            }
        } else {

            await getLocalizedContent();
        }
    }

    const logInExistingUser = async () => {

        if (loggedIn && accessToken && refreshToken && !user) {

            try {
                const userDetails = await userService.getUserDetails();

                await loadUser(userDetails);
            } catch (e) {
                await logout(refreshToken);
            }
        }
    }

    async function loadAppSettings() {

        var settings = await appService.getAppSettings();

        await setAppSettings(settings);
    }

    async function loadDataDomeScript() {
        var dataDomeSettings = await appService.getDataDomeSettings();
        var isDataDomeEnabledKey = 'IsDataDomeEnabled';
        if ((dataDomeSettings.find(s => s.key === isDataDomeEnabledKey)?.value?.toLowerCase?.()) === 'true') {
            var clientKey = 'DataDomeClientKey';
            setDataDomeClientkey(dataDomeSettings.find(s => s.key === clientKey)?.value);
        }
    }

    function getBrowserLanguage() {
        var a = (navigator.languages && navigator.languages[0]) || navigator.language;
        return a;
    }

    const getLocalizedContent = async () => {

        const localizedContent = await appService.getLocalizedContent();

        await loadLocalizedContent(localizedContent);

        const localizations = mapLocalizationContentsToObject(localizedContent);

        setTranslations(localizations);

        // Default is always EN for now
        setDefaultLanguage("en-UK");
        var lang = localizedContent?.find(obj => obj.languageCode == getBrowserLanguage());

        if (selectedLanguage && localizedContent.find(d => d.languageCode === selectedLanguage.languageCode)) {

            loadSelectedLocalization(localizedContent, selectedLanguage.languageCode);
        }
        else if (lang) {
            // Set Browser Language if it is present in Select
            setLocalization({
                name: lang.name,
                languageCode: lang.languageCode,
                imageUrl: lang.imageUrl
            });
        }
        else {
            // otherwise select the first localization

            const localization = localizedContent[0];

            setLocalization({
                name: localization.name,
                languageCode: localization.languageCode,
                imageUrl: localization.imageUrl
            });
        }
    }

    const loadSelectedLocalization = (localizedContent: LanguageDto[], LanguageCode: string) => {
        // try to get the previously selected localization
        const localization = localizedContent.find(d => d.languageCode === LanguageCode);

        if (localization) {

            setLocalization({
                name: localization.name,
                languageCode: localization.languageCode,
                imageUrl: localization.imageUrl
            });
        }
    }

    const mapLocalizationContentsToObject = (localizedContent: LanguageDto[]) => {

        const localizations: Record<string, any> = {}

        localizedContent.forEach(c => {

            const valuesObj: Record<string, any> = {};

            const valuesWithoutCategory = c.values.filter(c => !c.category);

            valuesWithoutCategory.forEach(v => valuesObj[v.key] = v.value);

            const valuesWithCategory = c.values.filter(c => c.category);

            groupBy(valuesWithCategory, d => d.category)
                .forEach((items, grp) => {

                    const itemsObj: Record<string, any> = {};

                    items.forEach(i => itemsObj[i.key] = i.value)

                    valuesObj[grp] = itemsObj;
                });

            localizations[c.languageCode] = valuesObj;

        });

        return localizations;
    }

    if (initializing) {
        return <PageLoader />;
    } 

    return (
        <Layout>
            <Switch>

                <Route exact path="/">
                    <Redirect to="/login" />
                </Route>

                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/forgot-password' component={ForgotPasswordPage} />
                <Route exact path='/ResetPassword' component={ResetPasswordPage} />
                <Route exact path='/sso-middleware' component={SsoMiddlewarePage} />

                <AuthenticatedRoute exact path='/mfa-setup' component={MfaSetupPage} />

                <AuthenticatedRoute exact path='/projects' component={ProjectsPage} />
                <AuthenticatedRoute exact path='/projects/project/:projectId' component={ProjectDetailsPage} />

                <AuthenticatedRoute exact path='/projects/order-codes/:id' component={OrderCodesPage} />
                <AuthenticatedRoute exact path='/order-history' component={OrderHistoryPage} />
                <AuthenticatedRoute exact path='/order-history/order-details/:orderId' component={OrderDetailsPage} />
                <AuthenticatedRoute exact path='/downloads' component={YourDownloadsPage} />
                <AuthenticatedRoute exact path='/downloads/download-details/:batchId' component={DownloadDetailsPage} />
                {/* TODO: make sure that this page can be accessed only by super user as its "parent" page (user list) */}
                <AuthenticatedRoute exact path='/manage-users/edit-user/:id' component={EditUserPage} />
                <AuthenticatedRoute exact path='/manage-users' component={ManageUsersPage} />
                <AuthenticatedRoute exact path='/update-password' component={UpdatePasswordPage} />
                <AuthenticatedRoute exact path='/customer-service' component={CustomerServicePage} />
                <AuthenticatedRoute exact path='/customer-service/redemption-order-details/:scmOrderId' component={SearchOrderDetailsPage} />
                <AuthenticatedRoute exact path='/customer-service/issuance-details/:id' component={IssuanceDetailsPage} />
                <AuthenticatedRoute exact path='/PaymentPlusPage/project/:projectId/order/:orderId' component={PaymentPlusPage} />
                <Route exact path='/contact' component={ContactUsPage} />
                <Route exact path='/guides' component={UserGuidePage} />

                <Route path="/unauthorized">
                    <UnauthorizedPage />
                </Route>
                {/* Didn't match any of the routes */}
                <Route path="*">
                    <NotFoundPage />
                </Route>
            </Switch>
            {dataDomeClientkey &&
                <script dangerouslySetInnerHTML={{
                __html: `!function(a,b,c,d,e,f){a.ddjskey = e;a.ddoptions=f||null;var m=b.createElement(c),n=b.getElementsByTagName(c)[0];m.async=1,m.src=d,n.parentNode.insertBefore(m,n)}(window,document,"script","https://js.datadome.co/tags.js","${dataDomeClientkey}", {ajaxListenerPath: true });`}} />

            }
        </Layout>
    );
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(App);

export {
    ConnectedComponent as App
}
