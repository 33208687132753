import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getSuccessNotification, getErrorNotification } from 'Utilities';
import { ApplicationState } from "Store";
import { ECodeOrderItem, PrintReadyOrderItem, DigitalOrderDetails, BaseVoucherType, FileUploadBulkOrderItem, CodeDeliveryMethod } from "../Models";
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import * as yup from 'yup';
import Notifications from 'react-notification-system-redux';
import { useTranslation } from "react-multi-lang";
import { AreaLoader } from './AreaLoader';
import { userService } from 'Services';
import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";
import * as XLSX from 'xlsx';
import { orderingService } from "../Services";

interface Props extends StoreProps, AuthStoreProps, UserStoreProps {
    isActive: boolean,
    codeType?:
    BaseVoucherType,
    requireStaffNumberOnUpload?: boolean,
    deliveryMethod?: OrderReducer.DeliveryMethod,
    codeDeliveryMethod?: CodeDeliveryMethod,
    countryIso2: string,
};

interface StoreProps {
    notifySuccess: Notifications.NotificationShow;
    notifyError: Notifications.NotificationShow;
    setOrder: typeof OrderReducer.actions.setOrder;
    order?: DigitalOrderDetails;
    codeType?: BaseVoucherType;
    refreshToken?: string;
    accessToken?: string;
}

interface AuthStoreProps {
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
}

interface UserStoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
}

const mapStateToProps = ({ digitalOrder, user, auth }: ApplicationState) => ({
    order: digitalOrder.order,
    refreshToken: auth.refreshToken,
    accessToken: auth.accessToken
})

const mapDispatchToProps = {
    notifySuccess: Notifications.success,
    notifyError: Notifications.error,
    setOrder: OrderReducer.actions.setOrder,
    refreshTokens: AuthenticationReducer.actions.refreshTokens,
    loadUser: UserReducer.actions.loadUser
}

function CSVUploader({
    notifySuccess,
    notifyError,
    order,
    setOrder,
    isActive,
    codeType,
    requireStaffNumberOnUpload,
    refreshTokens,
    loadUser,
    refreshToken,
    accessToken,
    deliveryMethod,
    codeDeliveryMethod,
    countryIso2
}: Props) {

    const fileNameContainer = useRef<HTMLDivElement>(null);
    const t = useTranslation();

    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [errorList, setErrorList] = useState<Array<string>>([]);
    const [isBusy, setIsBusy] = useState(false);
    const toggleErrorModal = () => setErrorModal(!errorModal);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string>();

    const refreshingToken = async () => {
        setIsBusy(true);

        try {
            const userDetails = await userService.refreshingToken(accessToken, refreshToken);

            if (userDetails) {

                await refreshTokens(userDetails.accessToken, userDetails.refreshToken);
                await loadUser(userDetails);

                return;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsBusy(false);
        }
    }

    const handleOpenDialog = (e: React.MouseEvent<HTMLButtonElement>) => {

        refreshingToken();
        // Note that the ref is set async, so it might be null at some point
        fileInputRef?.current?.click();
    }

    useEffect(() => {

        if (order) {
            const sum: number = order?.fileUploadECodeItems.map(a => +a.awardValue).reduce((p, a) => p + a, 0) || 0;
            setOrder({ ...order, itemsTotalValue: sum });
        };

    }, [order?.fileUploadECodeItems])

    useEffect(() => {

        if (order) {
            const sum: number = order?.fileUploadPrintReadyItems.map(a => +a.awardValue).reduce((p, a) => p + a, 0) || 0;
            setOrder({ ...order, itemsTotalValue: sum });
        };

    }, [order?.fileUploadPrintReadyItems])

    useEffect(() => {

        if (order) {
            const sum: number = (order?.fileUploadBulkOrderItems || []).reduce((p, a) => p + (+a.codeValue * +a.quantity), 0);
            setOrder({ ...order, itemsTotalValue: sum });
        };

    }, [order?.fileUploadBulkOrderItems])

    let validationSchemaShape: any = {};
    validationSchemaShape["awardValue"] = yup.string().trim().test('not zero value', t("ValidationError.AwardValueAnyNumeric"), (value) => value?.replaceAll('0', '').trim().length != 0).matches(/^[0-9]+$/, { message: t("ValidationError.AwardValueAnyNumeric") }).required(t("ValidationError.AwardValueRequired"));
    validationSchemaShape["staffNumber"] = yup.string().trim().max(20, t("ValidationError.StaffNumberMaxChars")).required(t("ValidationError.StaffNumberRequired"));

    //For universal email address - if email exist
    validationSchemaShape["emailAddress"] = yup.string().trim()
        .max(100, t("ValidationError.EmailMaxChars"))
        .matches(new RegExp('^(".+"|[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+(\\.[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+)*)@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$'),
            { message: t("ValidationError.InvalidEmailAddressMessage"), excludeEmptyString: true });

    const smsValidation = (codeDeliveryMethod === CodeDeliveryMethod.Sms || codeDeliveryMethod === CodeDeliveryMethod.EmailAndSms);

    validationSchemaShape["firstName"] = yup.string().trim().required(t("ValidationError.FirstNameRequired"))
        .test("maxFNFieldlengths", "check field lengths", (value, validationContext) => {
            const { createError, parent: { mobileNumber } } = validationContext;
            if (smsValidation && mobileNumber && mobileNumber.toString().trim() !== "" && value && value!.length > 30) {
                return createError({ message: t("ValidationError.FirstNameMaxCharsForSMS") });
            }
            if (value && value.length > 100) {
                return createError({ message: t("ValidationError.FirstNameMaxChars") });
            }
            return true;
        });
    validationSchemaShape["lastName"] = yup.string().trim().required(t("ValidationError.LastNameRequired"))
        .test("maxLNFieldlengths", "check field lengths", (value, validationContext) => {
            const { createError, parent: { mobileNumber } } = validationContext;
            if (smsValidation && mobileNumber && mobileNumber.toString().trim() !== "" && value && value!.length > 30) {
                return createError({ message: t("ValidationError.LastNameMaxCharsForSMS") });
            }
            if (value && value.length > 100) {
                return createError({ message: t("ValidationError.LastNameMaxChars") });
            }
            return true;
        });
    validationSchemaShape["company"] = yup.string().trim().required(t("ValidationError.CompanyRequired"))
        .test("maxCoFieldlengths", "check field lengths", (value, validationContext) => {
            const { createError, parent: { mobileNumber } } = validationContext;
            if (smsValidation && mobileNumber && mobileNumber.toString().trim() !== "" && value && value!.length > 50) {
                return createError({ message: t("ValidationError.CompanyMaxCharsForSMS") });
            }
            if (value && value.length > 100) {
                return createError({ message: t("ValidationError.CompanyMaxChars") });
            }
            return true;
        });
    validationSchemaShape["reasonForReward"] = yup.string().trim().required(t("ValidationError.AwardReasonRequired"))
        .test("maxARFieldlengths", "check field lengths", (value, validationContext) => {
            const { createError, parent: { mobileNumber } } = validationContext;
            if (smsValidation && mobileNumber && mobileNumber.toString().trim() !== "" && value && value!.length > 120) {
                return createError({ message: t("ValidationError.AwardReasonMaxCharsForSMS") });
            }
            if (value && value.length > 1000) {
                return createError({ message: t("ValidationError.AwardReasonMaxChars") });
            }
            return true;
        });
    validationSchemaShape["awardType"] = yup.string().trim().required(t("ValidationError.AwardTypeRequired"))
        .test("maxATFieldlengths", "check field lengths", (value, validationContext) => {
            const { createError, parent: { mobileNumber } } = validationContext;
            if (smsValidation && mobileNumber && mobileNumber.toString().trim() !== "" && value && value!.length > 50) {
                return createError({ message: t("ValidationError.AwardTypeMaxCharsForSMS") });
            }
            if (value && value.length > 100) {
                return createError({ message: t("ValidationError.AwardTypeMaxChars") });
            }
            return true;
        });
    validationSchemaShape["awardOriginator"] = yup.string().trim().required(t("ValidationError.AwardOriginatorRequired"))
        .test("maxAOFieldlengths", "check field lengths", (value, validationContext) => {
            const { createError, parent: { mobileNumber } } = validationContext;
            if (smsValidation && mobileNumber && mobileNumber.toString().trim() !== "" && value && value!.length > 50) {
                return createError({ message: t("ValidationError.AwardOriginatorMaxCharsForSMS") });
            }
            if (value && value.length > 150) {
                return createError({ message: t("ValidationError.AwardOriginatorMaxChars") });
            }
            return true;
        });

    if (codeDeliveryMethod == CodeDeliveryMethod.Sms) {
        validationSchemaShape["mobileNumber"] = yup.string().trim()
            .required(t("ValidationError.InvalidPhoneNumberMessage"))
            .matches(/^([\+]?\d{1,3}[- ]?)?\d{10}$/, { message: t("ValidationError.InvalidPhoneNumberMessage"), excludeEmptyString: true });
    }
    if (codeDeliveryMethod == CodeDeliveryMethod.Email) {
        validationSchemaShape["emailAddress"] = yup.string().trim()
            .required(t("ValidationError.InvalidEmailAddressMessage"))
            .max(100, t("ValidationError.EmailMaxChars"))
            .matches(new RegExp('^(".+"|[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+(\\.[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+)*)@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$'),
                { message: t("ValidationError.InvalidEmailAddressMessage"), excludeEmptyString: true });
    }
    if (codeDeliveryMethod == CodeDeliveryMethod.EmailAndSms) {
        validationSchemaShape["emailOrSms"] = yup.string()
            .when(['mobileNumber', 'emailAddress'], {
                is: (mobileNumber: string, emailAddress: string) => (!mobileNumber || !mobileNumber.toString().trim()) && (!emailAddress || !emailAddress.toString().trim()),
                then: yup.string().required(t("ValidationError.EmailOrSMS"))
            });
    }

    validationSchemaShape["scheduledSendDate"] = yup.string().trim()
        .matches(/^\d{8}$/, { message: t("ValidationError.ScheduledSendDateFormatError"), excludeEmptyString: true })
        .test('not zero value', t("ValidationError.ScheduledSendDateInvalidError"), (value) => {
            if (!value) return true;
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const selectedDate = new Date(parseInt(value.substr(0, 4)), parseInt(value.substr(4, 2)) - 1, parseInt(value.substr(6, 2)));
            return selectedDate! >= today;
        });

    const eCodeSchema = yup.array().of(yup.object().shape(validationSchemaShape));

    const printReadySchema = yup.array().of(yup.object().shape({
        firstName: yup.string().required(t("ValidationError.FirstNameRequired")).max(100, t("ValidationError.FirstNameMaxChars")),
        lastName: yup.string().required(t("ValidationError.LastNameRequired")).max(100, t("ValidationError.LastNameMaxChars")),
        recipientEmailAddress: yup.string().email(t("ValidationError.RecipientEmailFormat")).matches(new RegExp('^(".+"|[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+(\\.[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+)*)@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$'), t("ValidationError.InvalidEmailAddressMessage")).max(100, t("ValidationError.EmailMaxChars")),
        mobileNumber: yup.string().matches(/^([\+]?\d{1,3}[- ]?)?\d{10}$/, { message: t("ValidationError.PhoneNumberFormat"), excludeEmptyString: true }),
        company: yup.string().max(100, t("ValidationError.CompanyMaxChars")),
        requireStaffNumber: yup.boolean().default(requireStaffNumberOnUpload),
        staffNumber: yup.string().max(20, t("ValidationError.StaffNumberMaxChars")).when("requireStaffNumber", { is: true, then: yup.string().required(t("ValidationError.StaffNumberRequired")) }),
        reasonForReward: yup.string().max(1000, t("ValidationError.AwardReasonMaxChars")),
        awardType: yup.string().max(100, t("ValidationError.AwardTypeMaxChars")),
        awardValue: yup.string().test('not zero value', t("ValidationError.AwardValueAnyNumeric"), (value) => value?.replaceAll('0', '').trim().length != 0).matches(/^[0-9]+$/, { message: t("ValidationError.AwardValueAnyNumeric") }).required(t("ValidationError.AwardValueRequired")),
        awardOriginator: yup.string().max(150, t("ValidationError.AwardOriginatorMaxChars")),
        distributorFirstName: yup.string().required(t("ValidationError.DistributorFirstNameRequired")).max(100, t("ValidationError.FirstNameMaxChars")),
        distributorLastName: yup.string().required(t("ValidationError.DistributorLastNameRequired")).max(100, t("ValidationError.LastNameMaxChars")),
        distributorEmailAddress: yup.string().email(t("ValidationError.DistributorEmailFormat")).matches(new RegExp('^(".+"|[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+(\\.[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+)*)@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$'), t("ValidationError.InvalidEmailAddressMessage")).max(100, t("ValidationError.EmailMaxChars")).required(t("ValidationError.DistributorEmailAddressRequiredMessage")),
        distributorPhoneNumber: yup.string().matches(/^\d+$/, { message: t("ValidationError.PhoneNumberFormat"), excludeEmptyString: true })
    }));

    const eCodeBulkSchema = yup.array().of(yup.object().shape({
        codeValue: yup.string().test('not zero value', t("ValidationError.AwardValueAnyNumeric"), (value) => value?.replaceAll('0', '').trim().length != 0).matches(/^[0-9]+$/, { message: t("ValidationError.AwardValueAnyNumeric") }).required(t("ValidationError.AwardValueRequired")),
        quantity: yup.string().test('not zero value', t("ValidationError.AwardValueAnyNumeric"), (value) => value?.replaceAll('0', '').trim().length != 0).matches(/^[0-9]+$/, { message: t("ValidationError.AwardValueAnyNumeric") }).required(t("ValidationError.AwardValueRequired")),
        distributorFirstName: yup.string().required(t("ValidationError.DistributorFirstNameRequired")).max(100, t("ValidationError.FirstNameMaxChars")),
        distributorLastName: yup.string().required(t("ValidationError.DistributorLastNameRequired")).max(100, t("ValidationError.LastNameMaxChars")),
        distributorEmailAddress: yup.string().email(t("ValidationError.DistributorEmailFormat")).matches(new RegExp('^(".+"|[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+(\\.[a-zA-Z0-9!#$%&’\'*+\\/=?^_`{|}~-]+)*)@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$'), t("ValidationError.InvalidEmailAddressMessage")).max(100, t("ValidationError.EmailMaxChars")).required(t("ValidationError.DistributorEmailAddressRequiredMessage"))
    }));

    const handleOnRemoveFile = () => {
        setOrder({ ...order, fileUploadECodeItems: [], fileUploadPrintReadyItems: [], fileUploadBulkOrderItems: [], fileUploadListValid: false, ecodeBulkFileUploadListValid: false, fileName: "" } as DigitalOrderDetails);
        setFileName("");
    }

    const validateMobileNumbers = async (eCodeRows: ECodeOrderItem[]) => {
        const invalidNumbers = [];
        const countryCode = countryIso2;

        for (const row of eCodeRows) {
            let isValidMobile = true;
            if (row.mobileNumber && row.mobileNumber.toString().trim() !== "") {
                isValidMobile = await orderingService.validateMobileNumber(row.mobileNumber.toString().trim(), countryCode);
            }
            if (!isValidMobile || (codeDeliveryMethod === CodeDeliveryMethod.Sms && !row.mobileNumber)) {
                invalidNumbers.push(`On line ${(row.rowId + 1)}: Invalid mobile number ${row.mobileNumber}`);
            }
        }
        return invalidNumbers;
    }

    const handleOnFileChanged = async (e: any) => {
        refreshingToken();
        setIsBusy(true);
        const file = e.target.files[0];
        const fileData = await file.arrayBuffer();
        setFileName(file.name);

        const parsedData = await parseExcel(fileData);
        setIsBusy(false);

        if (parsedData === null) {
            toggleErrorModal();
            setErrorList([t("ValidationError.InvalidCsvColumnCount")]);
            return;
        }

        if (codeType == BaseVoucherType.ECode) {

            if (deliveryMethod === OrderReducer.DeliveryMethod.Email) {
                try {
                    let validationErrors: string[] = [];
                    try {
                        await eCodeSchema.validate(parsedData, { abortEarly: false });
                    } catch (e) {
                        const errorMap: string[] = e.inner.map((e: any) => {

                            const line = e.path.split(".")[0]
                                .replace("[", "")
                                .replace("]", "");

                            return t("ValidationError.CsvValidationMessageTemplate")
                                .replace("[[Line]]", `${(+line + 2)}`)
                                .replace("[[Message]]", `${e.message}`);
                        })
                        validationErrors = errorMap;
                    }

                    const mobileNumberErrors = await validateMobileNumbers(parsedData as ECodeOrderItem[]);

                    if (mobileNumberErrors.length > 0) {
                        validationErrors.push(...mobileNumberErrors);
                    }
                    if (validationErrors && validationErrors.length > 0) {
                        setOrder({
                            ...order,
                            fileUploadListValid: false,
                            fileUploadECodeItems: [],
                            itemsTotalValue: 0
                        } as DigitalOrderDetails);
                        validationErrors = validationErrors.sort();
                        setErrorList(validationErrors);
                        toggleErrorModal();
                        return;
                    } else {
                        setOrder({
                            ...order,
                            fileUploadECodeItems: parsedData,
                            fileUploadListValid: true,
                            fileName: fileNameContainer.current?.innerText
                        } as DigitalOrderDetails);

                        notifySuccess(getSuccessNotification(t("NotificationMessage.FileUploadSuccess")));
                    }
                } catch (e) {
                    setOrder({
                        ...order,
                        fileUploadListValid: false,
                        printReadyFileUploadListValid: false,
                        ecodeBulkFileUploadListValid: false,
                        fileUploadBulkOrderItems: [],
                        fileUploadECodeItems: [],
                        fileUploadPrintReadyItems: [],
                        itemsTotalValue: 0
                    } as DigitalOrderDetails);
                }
            } else if (deliveryMethod === OrderReducer.DeliveryMethod.Form) {
                try {
                    const result: any = await eCodeBulkSchema.validate(parsedData, { abortEarly: false });

                    if (result) {
                        setOrder({
                            ...order,
                            fileUploadBulkOrderItems: parsedData,
                            ecodeBulkFileUploadListValid: true,
                            fileName: fileNameContainer.current?.innerText
                        } as DigitalOrderDetails);

                        notifySuccess(getSuccessNotification(t("NotificationMessage.FileUploadSuccess")));
                    } else {
                        setOrder({
                            ...order,
                            ecodeBulkFileUploadListValid: false,
                            fileUploadBulkOrderItems: [],
                            itemsTotalValue: 0
                        } as DigitalOrderDetails);
                    }
                } catch (e) {
                    setOrder({
                        ...order,
                        fileUploadListValid: false,
                        printReadyFileUploadListValid: false,
                        ecodeBulkFileUploadListValid: false,
                        fileUploadBulkOrderItems: [],
                        fileUploadECodeItems: [],
                        fileUploadPrintReadyItems: [],
                        itemsTotalValue: 0
                    } as DigitalOrderDetails);

                    const errorMap = e.inner.map((e: any) => {

                        const line = e.path.split(".")[0]
                            .replace("[", "")
                            .replace("]", "");

                        return t("ValidationError.CsvValidationMessageTemplate")
                            .replace("[[Line]]", `${(+line + 2)}`)
                            .replace("[[Message]]", `${e.message}`);
                    })

                    setErrorList(errorMap);
                    toggleErrorModal();
                }
            } else {
                notifyError(getErrorNotification(t("ValidationError.EmptyCsv")))
            }
        }

        if (codeType == BaseVoucherType.PrintReady) {
            try {
                const result: any = await printReadySchema.validate(parsedData, { abortEarly: false });

                if (result) {
                    if (parsedData.length) {

                        setOrder({
                            ...order,
                            fileUploadPrintReadyItems: parsedData,
                            printReadyFileUploadListValid: true,
                            fileName: fileNameContainer.current?.innerText
                        } as DigitalOrderDetails);

                        notifySuccess(getSuccessNotification(t("NotificationMessage.FileUploadSuccess")));

                    } else {

                        notifyError(getErrorNotification(t("ValidationError.EmptyCsv")))
                    }

                } else {
                    setOrder({ ...order, printReadyFileUploadListValid: false, fileUploadPrintReadyItems: [], itemsTotalValue: 0 } as DigitalOrderDetails);
                }
            } catch (e) {
                setOrder({
                    ...order, fileUploadListValid: false, printReadyFileUploadListValid: false, ecodeBulkFileUploadListValid: false, fileUploadBulkOrderItems: [], fileUploadECodeItems: [], fileUploadPrintReadyItems: [], itemsTotalValue: 0
                } as DigitalOrderDetails);

                const errorMap = e.inner.map((e: any) => {

                    const line = e.path.split(".")[0]
                        .replace("[", "")
                        .replace("]", "");

                    return t("ValidationError.CsvValidationMessageTemplate").replace("[[Line]]", `${(+line + 2)}`).replace("[[Message]]", `${e.message}`);
                })

                setErrorList(errorMap);
                toggleErrorModal();
            }
        }
    }

    const parseExcel = async (fileData: any): Promise<ECodeOrderItem[] | PrintReadyOrderItem[] | FileUploadBulkOrderItem[] | null> => {
        const workbook = XLSX.read(fileData);
        const data: any = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1, blankrows: false });

        const excelcolumns = data[0].map((r: any) => ({ key: r, name: r }));
        const excelrows = data.slice(1).map((r: any) => r.reduce((acc: any, x: any, i: any) => {
            acc[data[0][i]] = x;
            return acc;
        }, {}));

        let count = 1;
        if (excelcolumns && (excelcolumns?.length == 10 || excelcolumns?.length == 11)) {
            return excelrows.map((r: any) => ({
                firstName: r[excelcolumns[0].key],
                lastName: r[excelcolumns[1].key],
                emailAddress: r[excelcolumns[2].key],

                mobileNumber: r[excelcolumns[3].key],
                company: r[excelcolumns[4].key],
                staffNumber: r[excelcolumns[5].key],

                reasonForReward: r[excelcolumns[6].key],
                awardType: r[excelcolumns[7].key],
                awardValue: r[excelcolumns[8].key],
                awardOriginator: r[excelcolumns[9].key],
                scheduledSendDate: r[excelcolumns[10]?.key],
                rowId: count++
            } as ECodeOrderItem));
        } else if (excelcolumns && excelcolumns?.length == 13) {
            return excelrows.map((r: any) => ({
                firstName: r[excelcolumns[0].key],
                lastName: r[excelcolumns[1].key],
                recipientEmailAddress: r[excelcolumns[2].key],
                company: r[excelcolumns[3].key],
                staffNumber: r[excelcolumns[4].key],
                reasonForReward: r[excelcolumns[5].key],
                awardType: r[excelcolumns[6].key],
                awardValue: r[excelcolumns[7].key],
                awardOriginator: r[excelcolumns[8].key],
                distributorFirstName: r[excelcolumns[9].key],
                distributorLastName: r[excelcolumns[10].key],
                distributorEmailAddress: r[excelcolumns[11].key],
                distributorPhoneNumber: r[excelcolumns[12].key],
                rowId: count++
            } as PrintReadyOrderItem));
        } else if (excelcolumns && excelcolumns?.length == 5) {
            return excelrows.map((r: any) => ({
                codeValue: r[excelcolumns[0].key],
                quantity: r[excelcolumns[1].key],
                distributorFirstName: r[excelcolumns[2].key],
                distributorLastName: r[excelcolumns[3].key],
                distributorEmailAddress: r[excelcolumns[4].key],
                rowId: count++
            } as FileUploadBulkOrderItem));
        } else {
            return null;
        }

    }

    return (
        <aside
            className="d-flex flex-column flex-md-row mb-3 align-items-center">
            <Button
                data-automation-id="fileUploadButton"
                type='button'
                onClick={handleOpenDialog}
                className="text-uppercase mr-3 mb-3 mb-md-0"
                tabIndex={isActive ? 0 : -1}
            >
                {t("OrderingProcess.LoadFile")}
            </Button>
            <input
                className="form-control"
                type="file"
                name="file"
                ref={fileInputRef}
                accept=".xlsx,.csv"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleOnFileChanged(e);
                    e.target.value = "";
                }}
                hidden />

            <div
                className="file-box"
                style={{
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#ccc',
                    height: 45,
                    lineHeight: 2.5,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingLeft: 13,
                    paddingRight: 40,
                    paddingTop: 3,
                    position: 'relative',
                }}
            >
                {fileName && <div ref={fileNameContainer}>{fileName}</div>}

                {fileName && <Button tabIndex={isActive ? 0 : -1} style={{ position: "absolute", right: "10px", top: "2px" }} onClick={handleOnRemoveFile} color="link">X</Button>}

                <AreaLoader hasDelay={false} show={(fileName && isBusy) || isBusy} message={t("NotificationMessage.LoadOrderRowsMessage")} />

            </div>
            <Modal isOpen={errorModal} toggle={toggleErrorModal} >
                <ModalHeader toggle={toggleErrorModal}>{t("OrderingProcess.CsvErrorModalHeader")}</ModalHeader>
                <ModalBody>
                    <ul className="pl-1" style={{ listStyle: "none" }}>
                        {errorList.map((e, i) =>
                            <li key={i}>{e}</li>
                        )}
                    </ul>
                </ModalBody>
            </Modal>

        </aside>
    )
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(CSVUploader);

export {
    ConnectedComponent as CSVUploader
}
