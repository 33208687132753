import React from "react";
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bemNames, getBatchStatusLocalizationKey, getBatchTypeLocalizationKey, getErrorNotification, nameOf } from 'Utilities';
import { BatchStatus, OrderHistoryItemDto, BatchTypeIconSize, IClientDto, IProjectDto } from 'Models';
import { IoPrintOutline } from "react-icons/io5";
import { useTranslation } from "react-multi-lang";
import { AreaLoader, DataTable, FilterByOptions, SortByOptions, BatchTypeIconImage, OrderStatusBadge } from "../Components";
import { Cell, Column } from "react-table";

import { orderHistoryService, clientService } from "../Services";
import classNames from "classnames";
import Notifications from 'react-notification-system-redux';
import { Link } from "react-router-dom";
import Moment from "react-moment";


interface StoreProps {
    notifyError: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
}

const OrderHistoryPage = ({ notifyError }: StoreProps) => {

    const t = useTranslation();
    const bem = bemNames.create("order-history-page");

    const [isClientsLoading, setIsClientsLoading] = useState(true);
    const [clients, setClients] = useState<IClientDto[]>([]);
    const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);

    const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);

    const [orderHistoryItems, setOrderHistoryItems] = useState<OrderHistoryItemDto[]>([]);
    const [orderHistoryItemCount, setOrderHistoryItemCount] = useState<number>(0);
    const [isResultsLoading, setIsResultsLoading] = useState(true);
    const [orderhistoryIsInProgress, setOrderhistoryIsInProgress] = useState(true);

    useEffect(() => {
        loadClients();
    }, []);

    const loadClients = async () => {

        setIsClientsLoading(true);

        try {

            const data = await clientService.getClients();

            setClients(data);

        } catch (e) {
            console.error(e);

            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderHistoryFailure")));
        }

        setIsClientsLoading(false);
    }

    const loadOrderHistory = async (pageIndex: number, pageSize: number, sortBy: SortByOptions[], searchTerm: string, filterBy: FilterByOptions[]) => {

        // Reset selected project if not associated to the selected client
        let validatedFilterBy = [...filterBy];

        const clientIds = filterBy.find(f => f.id === "tenantId")?.value.split(",") || [];
        const projectIds = filterBy.find(f => f.id === "projectId")?.value.split(",") || [];
        if (clientIds.length > 0 && projectIds.length > 0) {
            const possibleProjectIds = projectIds
                .map(projectId => projects.find(p => p.projectId.toString() === projectId))
                .filter(project => project && clientIds.map(c => c.toString()).includes(project.tenantId.toString()))
                .map(project => project?.projectId.toString() || "")
                .filter(Boolean);

            if (projectIds.length !== possibleProjectIds.length) {
                validatedFilterBy = validatedFilterBy.map(f => ({
                    ...f,
                    value: f.id === "projectId" ? possibleProjectIds.join(",") : f.value
                }));
                setSelectedProjectIds(possibleProjectIds);
            }

            if (!possibleProjectIds.length) {
                validatedFilterBy = validatedFilterBy.filter(f => f.id !== "projectId");
                setSelectedProjectIds([]);
            }

        }

        setIsResultsLoading(true);

        try {
            setOrderhistoryIsInProgress(true);
            const data = await orderHistoryService.getOrderHistory(
                pageIndex,
                pageSize,
                sortBy,
                searchTerm,
                validatedFilterBy
            );
            setOrderhistoryIsInProgress(false);
            setOrderHistoryItems(data.items);
            setOrderHistoryItemCount(data.itemCount);

        } catch (e) {
            console.error(e);

            notifyError(getErrorNotification(t("NotificationMessage.LoadOrderHistoryFailure")));
        }

        setIsResultsLoading(false);
    }

    const columnDefinitions: Column<OrderHistoryItemDto>[] = [
        {
            id: "icon",
            Header: '',
            accessor: (model) => model.projectName,
            Cell: (cell: Cell<OrderHistoryItemDto>) => {

                const order = cell.row.original;

                return (
                    <div
                        className={bem.e("order-tile")}
                    >
                        <div className="icon-wrap">
                            <BatchTypeIconImage batchType={order.batchTypeId} size={BatchTypeIconSize.small} />                            
                        </div>
                    </div>
                );
            }
        },
        {
            id: nameOf<OrderHistoryItemDto>("orderId"),
            Header: '',
            accessor: (model) => model.orderId,
            Cell: (cell: Cell<OrderHistoryItemDto>) => {

                const order = cell.row.original;

                return <>
                    <div className={bem.e("order-tile")}>
                        <div className="top">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderIdLabel")}</strong>
                            {order.orderId}
                        </div>
                        <div className="bottom">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderDateLabel")}</strong>
                            {order?.orderDate && <Moment format="ll HH:mm">{order.orderDate}</Moment>}
                        </div>
                    </div>
                </>;
            }
        },
        {
            id: nameOf<OrderHistoryItemDto>("batchTypeId"),
            Header: '',
            accessor: (model) => t(getBatchTypeLocalizationKey(model.batchTypeId)),
            Cell: (cell: Cell<OrderHistoryItemDto>) => {

                const order = cell.row.original;

                return <>
                    <div className={bem.e("order-tile")}>
                        <div className="top">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderTypeLabel")}</strong>
                            {t(getBatchTypeLocalizationKey(order.batchTypeId))}
                        </div>
                        <div className="bottom">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderedByLabel")}</strong>
                            {order.orderedByInternalAdmin
                                ? t("OrderHistory.InternalAdminUserLabel")
                                : `${order.orderedByFirstName} ${order.orderedByLastName}`}
                        </div>
                    </div>
                </>;
            }
        },
        {
            id: nameOf<OrderHistoryItemDto>("projectName"),
            Header: '',
            accessor: (model) => model.projectName,
            Cell: (cell: Cell<OrderHistoryItemDto>) => {

                const order = cell.row.original;

                return <>
                    <div className={bem.e("order-tile")}>
                        <div className="top">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileProjectNameLabel")}</strong>
                            {order.projectName}
                        </div>
                        <div className="bottom">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileProjectIdLabel")}</strong>
                            {order.projectId}
                        </div>
                    </div>
                </>;
            }
        },
        {
            id: nameOf<OrderHistoryItemDto>("orderValue"),
            Header: '',
            accessor: (model) => model.currency + model.orderValue,
            Cell: (cell: Cell<OrderHistoryItemDto>) => {

                const order = cell.row.original;

                return <>
                    <div className={bem.e("order-tile")}>
                        <div className="top">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderValueLabel")}</strong>
                            {order.currency}{order.orderValue}
                        </div>
                        <div className="bottom">
                            <strong className="text-uppercase">{t("Company")}</strong>
                            {order.tenantName}
                        </div>
                    </div>

                </>;
            }
        },
        {
            id: nameOf<OrderHistoryItemDto>("batchStatusId"),
            Header: '',
            accessor: (model) => model.batchStatusId,
            Cell: (cell: Cell<OrderHistoryItemDto>) => {

                const order = cell.row.original;

                return <>
                    <div className={bem.e("order-tile")}>
                        <strong className="text-uppercase">{t("OrderHistory.OrderTileStatusLabel")}</strong>
                        <OrderStatusBadge batchStatus={order.batchStatusId} />
                    </div>
                </>;
            }
        },
        {
            id: "actions",
            Header: '',
            accessor: (model) => model.projectName,
            Cell: (cell: Cell<OrderHistoryItemDto>) => {

                const order = cell.row.original;

                return <>
                    <div className="float-right d-print-none">
                        <Link to={`/order-history/order-details/${order.orderId}`} aria-label={order.projectName + "" + order.orderValue + " view order"} className="btn btn-primary text-uppercase">{t("OrderHistory.OrderTileViewDetailsButton")}</Link>
                    </div>
                </>;
            }
        },
        // Hidden columns for search only
        {
            id: nameOf<OrderHistoryItemDto>("projectId"),
            Header: '',
            accessor: (model) => model.projectId
        },
        {
            id: nameOf<OrderHistoryItemDto>("orderDate"),
            Header: '',
            accessor: (model) => model.currency + model.orderDate
        },
        {
            id: nameOf<OrderHistoryItemDto>("orderedByFirstName"),
            Header: '',
            accessor: (model) => model.orderedByInternalAdmin ? "Internal" : `${model.orderedByFirstName} ${model.orderedByLastName}`
        },
        {
            id: nameOf<OrderHistoryItemDto>("tenantId"),
            Header: '',
            accessor: (model) => model.tenantId
        },
        {
            id: nameOf<OrderHistoryItemDto>("tenantName"),
            Header: '',
            accessor: (model) => model.tenantName
        },
    ]

    const projects = clients
        .reduce((projects: IProjectDto[], client) => ([...projects, ...client.projects]), []);

    const filteredProjects = projects
        .filter(client => selectedClientIds.length > 0 ? selectedClientIds.map(c => c.toString()).includes(client.tenantId.toString()) : true);

    return (
        <div className={bem.b()}>

            <div className="top d-flex flex-row justify-content-start align-items-center">
                <h1>{t("OrderHistory.OrderHistoryLabel")}</h1>
                <button
                    type="button"
                    aria-label={t("OrderHistory.PrintButton")}
                    onClick={() => window.print()}
                    className={bem.e("download", "text-uppercase")}
                ><IoPrintOutline fontSize="20" />{t("OrderHistory.PrintButton")}</button>
            </div>

            <div className={bem.e("orders-container")}>

                <AreaLoader show={isClientsLoading || isResultsLoading || orderhistoryIsInProgress} message={t("OrderHistory.LoadingOrderHistoryMessage")} />

                <DataTable<OrderHistoryItemDto>
                    resultsElId="orderHistoryResults"
                    columnDefinitions={columnDefinitions}
                    fetchData={loadOrderHistory}
                    items={orderHistoryItems}
                    totalItemCount={orderHistoryItemCount}
                    sortByOptions={[
                        {
                            sortByColumnId: "orderDate",
                            sortByAscText: t("OrderHistory.FilterSortByDateAscLabel"),
                            sortByDescText: t("OrderHistory.FilterSortByDateDescLabel")
                        }
                    ]}
                    sortByDefaultOption={{ sortByColumnId: "orderDate", isAsc: false, sortByText: t("OrderHistory.FilterSortByDateDescLabel") }}
                    columnFilters={{
                        tenantId: {
                            label: t("Projects.CompanyLabel"),
                            selectedMessage: !selectedClientIds.length ? t("Projects.AllSelectedCompanies") : t("Projects.SelectedCompanyCount").replace("[[Count]]", selectedClientIds.length.toString()),
                            options: clients
                                .map(c => ({ label: c.tenantName, value: c.tenantId }))
                                .sort((a, b) => a.label.localeCompare(b.label)),
                            value: selectedClientIds,
                            onChange: values => setSelectedClientIds(values),
                            isMultiple: true
                        },
                        projectId: {
                            label: t("Projects.ProjectsLabel"),
                            selectedMessage: !selectedProjectIds.length ? t("Projects.AllSelectedProjects") : t("Projects.SelectedProjectCount").replace("[[Count]]", selectedProjectIds.length.toString()),
                            options: filteredProjects
                                .map(p => ({ label: p.projectName, value: p.projectId }))
                                .sort((a, b) => a.label.localeCompare(b.label)),
                            value: selectedProjectIds,
                            onChange: values => setSelectedProjectIds(values),
                            isMultiple: true
                        }
                    }}
                    hiddenColumns={["projectId", "orderDate", "orderedByFirstName", "tenantId", "tenantName"]}
                />

            </div>

        </div>
    )
}

const ConnectedComponent = connect(null, mapDispatchToProps)(OrderHistoryPage);

export {
    ConnectedComponent as OrderHistoryPage
}