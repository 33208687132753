import React, { useEffect } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { connect } from 'react-redux';
import { bemNames, getOrderItemCounts } from 'Utilities';
import { ApplicationState } from "Store";
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import { DigitalOrderDetails, DistributorDetails, PaymentMethod, FileUploadObject, BaseVoucherType, CodeDeliveryMethod } from "../Models";
import { FileUploadECodeRowItem } from 'Models/FileUploadECodeRowItem';
import { FileUploadPrintReadyRowItem } from 'Models/FileUploadPrintReadyRowItem';
import { FileUploadPhysicalRowItem } from 'Models/FileUploadPhysicalRowItem';
import { FiEdit } from "react-icons/fi";
import { useTranslation } from "react-multi-lang";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { useHTMLPreview } from "Hooks";
import { MdEmail, MdSms } from "react-icons/md";

interface StoreProps {
    distributorDetails: DistributorDetails,
    setCurrentStep: typeof OrderReducer.actions.setCurrentStep;
    attemptGoToStepNumber?: number;
    order: DigitalOrderDetails,
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep;
    deliveryMethod?: OrderReducer.DeliveryMethod;
    fileUploadList?: FileUploadObject<FileUploadECodeRowItem | FileUploadPrintReadyRowItem | FileUploadPhysicalRowItem>;
}

const mapStateToProps = ({ digitalOrder }: ApplicationState) => ({
    deliveryMethod: digitalOrder.deliveryMethod,
    distributorDetails: digitalOrder.distributorDetails,
    attemptGoToStepNumber: digitalOrder.attemptGoToStepNumber,
    order: digitalOrder.order
})

const mapDispatchToProps = {
    setCurrentStep: OrderReducer.actions.setCurrentStep,
    attemptGoToStep: OrderReducer.actions.attemptGoToStep
}

interface Props extends StoreProps, Partial<StepWizardChildProps> {
    codeType?: BaseVoucherType;
    currencySymbol?: string;
    codeDeliveryMethod?: CodeDeliveryMethod;
}

const OrderReviewStep = ({
    goToStep,
    isActive,
    deliveryMethod,
    distributorDetails,
    setCurrentStep,
    attemptGoToStepNumber,
    attemptGoToStep,
    order,
    codeType,
    currencySymbol,
    codeDeliveryMethod
}: Props) => {

    const bem = bemNames.create("digital-order-wizard");
    const t = useTranslation();

    useEffect(() => {
        if (attemptGoToStepNumber === 5) {
            goToStep?.(5);
        }
        if (attemptGoToStepNumber === 7) {
            goToStep?.(7);
        }
    }, [attemptGoToStepNumber]);

    if (isActive) {
        setCurrentStep(6);
    }

    const {
        emailOnly: numberOfEmailOnlyCodes,
        smsOnly: numberOfSmsOnlyCodes,
        emailAndSmsOnly: numberOfEmailAndSmsCodes,
        emailTotal,
        smsTotal
    } = getOrderItemCounts(order, codeType, deliveryMethod, codeDeliveryMethod);

    const getEmailElement = () => {
        return <span style={{ display: "inline-block" }} className="c-order-status-badge bg-info">Email</span>;
    }

    const getSmsElement = () => {
        return <span style={{ display: "inline-block" }} className="c-order-status-badge bg-warning">Sms</span>;
    }

    const selectedTemplateNames = {
        email: !!order.emailTemplateId && emailTotal > 0 && codeType !== BaseVoucherType.PrintReady ? order.emailTemplateName : null,
        sms: !!order.smsTemplateId && smsTotal > 0 ? order.smsTemplateName : null,
        printReady: !!order.emailTemplateId && codeType === BaseVoucherType.PrintReady ? order.emailTemplateName : null
    }

    let distributorCount: number | undefined;
    let singleDistributorEmailAddress: string | undefined;
    switch (codeType) {
        case BaseVoucherType.PrintReady:
            switch (deliveryMethod) {
                case OrderReducer.DeliveryMethod.Email: // batch & file upload
                    distributorCount = order.fileUploadPrintReadyItems
                        .filter((v, i, a) => a.findIndex(t => (t.distributorEmailAddress === v.distributorEmailAddress)) === i)
                        .length;
                    break;
                case OrderReducer.DeliveryMethod.Form: // bulk & form
                    singleDistributorEmailAddress = distributorDetails.emailAddress;
                    break;
            }
            break;
        case BaseVoucherType.ECode:
            switch (deliveryMethod) {
                case OrderReducer.DeliveryMethod.Form: // bulk
                    if (order.fileUploadBulkOrderItems?.length > 0) { // file upload
                        distributorCount = order.fileUploadBulkOrderItems
                            .map(item => item.distributorEmailAddress)
                            .filter((value, index, self) => self.indexOf(value) === index)
                            .length;
                    } else { // form
                        singleDistributorEmailAddress = distributorDetails.emailAddress;
                    }
                    break;
            }
            break;
    }

    return (
        <>
            <Card className="w-100" style={{ maxWidth: "51.563rem" }}>
                <CardHeader>
                    <strong className="text-uppercase">{t("OrderDetails.InvoiceSummaryLabel")}</strong>
                </CardHeader>

                <CardBody>
                    <div className={bem.e("review d-block w-100")}>
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="col payment-info">

                                <div className="clearfix">
                                    <h2 className="float-left">{t("OrderDetails.PaymentInformationLabel")}</h2>
                                    <button
                                        tabIndex={isActive ? 0 : -1}
                                        onClick={() => attemptGoToStep(5)}
                                        disabled={!isActive}
                                        className="float-right btn btn-link d-flex ml-2 align-items-center"
                                        style={{ fontSize: "0.938rem" }}>
                                        <FiEdit className="mr-1" size={18} />{t("EditLabel")}</button>
                                </div>

                                <div className="pb-3">
                                    <label className="mb-0 font-weight-bold text-uppercase">{t("OrderDetails.PaymentMethodLabel")}</label>
                                    <div className="d-block">
                                        {order.paymentMethod === PaymentMethod.BacsChaps && t("PaymentMethod.BacsChaps")}
                                        {order.paymentMethod === PaymentMethod.PaymentOnAccount && t("PaymentMethod.PaymentOnAccount")}
                                        {order.paymentMethod === PaymentMethod.DebitCreditCard && t("PaymentMethod.DebitCreditCard")}
                                    </div>
                                </div>

                                <div className="pb-3">
                                    <label className="mb-0 font-weight-bold text-uppercase">{t("OrderDetails.ReferencePoNumberLabel")}</label>
                                    <div className="d-block">{order.referenceOrPONumber}</div>
                                </div>

                                {order.otherReference != "" &&
                                    <div className="pb-3">
                                        <label className="mb-0 font-weight-bold text-uppercase">{t("OrderDetails.OtherReferenceLabel")}</label>
                                        <div className="d-block">{order.otherReference}</div>
                                    </div>
                                }

                                {order.paymentMethod === PaymentMethod.BacsChaps &&
                                    <div className="pb-3">
                                        <label className="mb-0 font-weight-bold text-uppercase">{t("OrderDetails.BillingDetailsLabel")}</label>
                                        <div className="d-block">
                                            {order.invoiceAddress.addressLine1}<br />
                                            {order.invoiceAddress.addressLine2}{order.invoiceAddress.addressLine2 !== "" ? <br /> : null}
                                            {order.invoiceAddress.addressLine3}{order.invoiceAddress.addressLine3 !== "" ? <br /> : null}
                                            {order.invoiceAddress.cityTown}<br />
                                            {order.invoiceAddress.postCode}
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className="col delivery-info">

                                <div className="clearfix">
                                    <h2 className="float-left">{t("OrderDetails.DeliveryInformationLabel")}</h2>
                                    <button
                                        tabIndex={isActive ? 0 : -1}
                                        onClick={() => attemptGoToStep(1)}
                                        disabled={!isActive}
                                        className="float-right btn btn-link d-flex ml-2 align-items-center"
                                        style={{ fontSize: "0.938rem" }}
                                    ><FiEdit className="mr-1" size={18} />{t("EditLabel")}</button>
                                </div>

                                {deliveryMethod === OrderReducer.DeliveryMethod.Email && codeType !== BaseVoucherType.PrintReady &&
                                    <div>
                                        <p>{t("OrderDetails.DeliveryMethodHeading")}</p>
                                        <div className="d-flex pb-3">
                                            {numberOfEmailOnlyCodes > 0 && <div className="ml-2"> x{numberOfEmailOnlyCodes}{" "}{getEmailElement()}{numberOfSmsOnlyCodes + numberOfEmailAndSmsCodes > 0 ? "," : ""}</div>}
                                            {numberOfSmsOnlyCodes > 0 && <div className="ml-2"> x{numberOfSmsOnlyCodes}{" "}{getSmsElement()}{numberOfEmailAndSmsCodes > 0 ? "," : ""}</div>}
                                            {numberOfEmailAndSmsCodes > 0 && <div className="ml-2"> x{numberOfEmailAndSmsCodes}{" "}{getEmailElement()}{" & "}{getSmsElement()} </div>}
                                        </div>
                                    </div>
                                }

                                {singleDistributorEmailAddress && <div className="pb-3">{t("OrderDetails.DeliveryDestinationLabel")} {singleDistributorEmailAddress}</div>}

                                {distributorCount != undefined && <div className="pb-3">{t("OrderDetails.DeliveryToLabel").replace("[[Count]]", distributorCount.toString())}</div>}

                                {Object.values(selectedTemplateNames).filter(Boolean).length > 0 &&
                                    <>
                                        <div className="clearfix">
                                            <h2 className="float-left">{t("OrderDetails.TemplateSelectionLabel")}</h2>
                                            <button
                                                tabIndex={isActive ? 0 : -1}
                                                onClick={() => attemptGoToStep(4)}
                                                disabled={!isActive}
                                                className="float-right btn btn-link d-flex ml-2 align-items-center"
                                                style={{ fontSize: "0.938rem" }}
                                            ><FiEdit className="mr-1" size={18} />{t("EditLabel")}</button>
                                        </div>
                                        {selectedTemplateNames.email && <p>{t("OrderDetails.EmailTemplateNamePrefix")} {selectedTemplateNames.email}</p>}
                                        {selectedTemplateNames.sms && <p>{t("OrderDetails.SmsTemplateNamePrefix")} {selectedTemplateNames.sms}</p>}
                                        {selectedTemplateNames.printReady && <p>{t("OrderDetails.PrintReadyTemplateNamePrefix")} {selectedTemplateNames.printReady}</p>}
                                    </>
                                }
                            </div>
                        </div>

                        {order.paymentMethod === PaymentMethod.BacsChaps &&
                            <div className="alert alert-info d-flex align-items-start mt-2" role="alert">
                                <div>
                                    <BsFillExclamationCircleFill fontSize="20" width="20px" className="mb-1" />
                                </div>
                                <p className="pl-2 mb-0">{t("OrderingProcess.UniqueReferenceNotificationMessage")}</p>
                            </div>
                        }

                    </div>
                </CardBody>
            </Card>
            <Card className="w-100 mt-3">
                <CardHeader>
                    <strong className="text-uppercase">{t("OrderDetails.RewardSummaryLabel")}</strong>
                </CardHeader>

                <CardBody>

                    {deliveryMethod === OrderReducer.DeliveryMethod.Form && !order.fileUploadBulkOrderItems.length &&
                        <Table>
                            <thead>
                                <tr>
                                    <th>{t("OrderDetails.DenominationLabel")}</th>
                                    <th>{t("OrderDetails.QuantityLabel")}</th>
                                    <th>{t("OrderDetails.ValueLabel")}</th>
                                </tr>
                                {order?.bulkItems?.map((item, index) =>
                                    <tr key={index}>
                                        <td>{currencySymbol}{(+item.value)?.toFixed(2)}</td>
                                        <td>{item.quantity}</td>
                                        <td>{currencySymbol}{(+item.value * +item.quantity)?.toFixed(2)}</td>
                                    </tr>
                                )
                                }
                                <tr>
                                    <td className="font-weight-bold">{t("OrderDetails.TotalLabel")}</td>
                                    <td className="font-weight-bold">{order?.bulkItems?.reduce((acc, item) => acc + +item.quantity, 0)}</td>
                                    <td className="font-weight-bold">{currencySymbol}{order?.itemsTotalValue?.toFixed(2)}</td>
                                </tr>
                            </thead>
                        </Table>
                    }

                    {deliveryMethod === OrderReducer.DeliveryMethod.Form && ((order.fileUploadBulkOrderItems?.length ?? 0) > 0) &&
                        <Table>
                            <thead>
                                <tr>
                                    <th>{t("YourDownloads.NumberOfCodes")}</th>
                                    <th>{t("OrderDetails.CodeValueLabel")}</th>
                                    <th>{t("OrderDetails.FileNameLabel")}</th>
                                    <th></th>
                                </tr>

                                <tr>
                                    <td>
                                        {codeType == BaseVoucherType.ECode && order.fileUploadListValid &&
                                            order.fileUploadECodeItems.length
                                        }
                                        {codeType == BaseVoucherType.ECode && order.ecodeBulkFileUploadListValid &&
                                            order.fileUploadBulkOrderItems.reduce((acc, item) => acc + +item.quantity, 0)
                                        }
                                        {codeType == BaseVoucherType.PrintReady &&
                                            order.fileUploadPrintReadyItems.length
                                        }
                                    </td>
                                    <td>
                                        {codeType == BaseVoucherType.ECode && order.fileUploadListValid &&
                                            `${currencySymbol}${order.fileUploadECodeItems.reduce((acc, item) => acc + +item.awardValue, 0)}`
                                        }
                                        {codeType == BaseVoucherType.ECode && order.ecodeBulkFileUploadListValid &&
                                            `${currencySymbol}${(order?.fileUploadBulkOrderItems || []).reduce((p, a) => p + (+a.codeValue * +a.quantity), 0)}`
                                        }
                                        {codeType == BaseVoucherType.PrintReady &&
                                            `${currencySymbol}${order.fileUploadPrintReadyItems.reduce((acc, item) => acc + +item.awardValue, 0)}`
                                        }
                                    </td>
                                    <td>{order.fileName}</td>
                                    <td>
                                        <button
                                            tabIndex={isActive ? 0 : -1}
                                            onClick={() => attemptGoToStep(3)}
                                            disabled={!isActive}
                                            className="float-right btn btn-link d-flex ml-2 align-items-center"
                                            style={{ fontSize: "0.938rem" }}>
                                            <FiEdit className="mr-1" size={18} />{t("EditLabel")}</button>
                                    </td>
                                </tr>

                            </thead>
                        </Table>
                    }

                    {deliveryMethod === OrderReducer.DeliveryMethod.Email &&
                        <Table>
                            <thead>
                                <tr>
                                    <th>{t("YourDownloads.NumberOfCodes")}</th>
                                    <th>{t("OrderDetails.CodeValueLabel")}</th>
                                    <th>{t("OrderDetails.FileNameLabel")}</th>
                                    <th></th>
                                </tr>

                                <tr>
                                    <td>
                                        {codeType == BaseVoucherType.ECode && order.fileUploadListValid &&
                                            order.fileUploadECodeItems.length
                                        }
                                        {codeType == BaseVoucherType.ECode && order.ecodeBulkFileUploadListValid &&
                                            order.fileUploadBulkOrderItems.reduce((acc, item) => acc + +item.quantity, 0)
                                        }
                                        {codeType == BaseVoucherType.PrintReady &&
                                            order.fileUploadPrintReadyItems.length
                                        }
                                    </td>
                                    <td>
                                        {codeType == BaseVoucherType.ECode && order.fileUploadListValid &&
                                            `${currencySymbol}${order.fileUploadECodeItems.reduce((acc, item) => acc + +item.awardValue, 0)}`
                                        }
                                        {codeType == BaseVoucherType.ECode && order.ecodeBulkFileUploadListValid &&
                                            `${currencySymbol}${(order?.fileUploadBulkOrderItems || []).reduce((p, a) => p + (+a.codeValue * +a.quantity), 0)}`
                                        }
                                        {codeType == BaseVoucherType.PrintReady &&
                                            `${currencySymbol}${order.fileUploadPrintReadyItems.reduce((acc, item) => acc + +item.awardValue, 0)}`
                                        }
                                    </td>
                                    <td>{order.fileName}</td>
                                    <td>
                                        <button
                                            tabIndex={isActive ? 0 : -1}
                                            onClick={() => attemptGoToStep(3)}
                                            disabled={!isActive}
                                            className="float-right btn btn-link d-flex ml-2 align-items-center"
                                            style={{ fontSize: "0.938rem" }}>
                                            <FiEdit className="mr-1" size={18} />{t("EditLabel")}</button>
                                    </td>
                                </tr>

                            </thead>
                        </Table>
                    }
                </CardBody>
            </Card>
        </>
    );
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(OrderReviewStep);

export {
    ConnectedComponent as OrderReviewStep
}