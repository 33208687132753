import React, { useEffect, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import { AreaLoader } from "Components";
import { connect } from 'react-redux';
import { bemNames, getOrderItemCounts } from 'Utilities';
import { EmailTemplateDto, DigitalOrderDetails, CodeDeliveryMethod, BaseVoucherType } from 'Models';
import { ApplicationState } from "Store";
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import { orderingService } from "../Services";
import { useParams } from "react-router";
import { AiOutlineSearch } from "react-icons/ai";
import { useTranslation } from "react-multi-lang";
import { useHTMLPreview } from "Hooks";


interface StoreProps {
    setCurrentStep: typeof OrderReducer.actions.setCurrentStep;
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep;
    attemptGoToStepNumber?: number;
    setOrder: typeof OrderReducer.actions.setOrder;
    order?: DigitalOrderDetails;
    deliveryMethod?: OrderReducer.DeliveryMethod;
}

const mapStateToProps = ({ digitalOrder, user: userState }: ApplicationState) => ({
    order: digitalOrder.order,
    deliveryMethod: digitalOrder.deliveryMethod,
    attemptGoToStepNumber: digitalOrder.attemptGoToStepNumber,
    user: userState.user
})

const mapDispatchToProps = {
    setCurrentStep: OrderReducer.actions.setCurrentStep,
    setOrder: OrderReducer.actions.setOrder,
    attemptGoToStep: OrderReducer.actions.attemptGoToStep
}

interface Props extends StoreProps, Partial<StepWizardChildProps> {
    codeDeliveryMethod?: CodeDeliveryMethod
    codeType?: BaseVoucherType,
}


const TemplateSelection = ({
    setCurrentStep,
    isActive,
    attemptGoToStepNumber,
    goToStep,
    currentStep,
    setOrder,
    order,
    deliveryMethod,
    codeDeliveryMethod,
    codeType
}: Props) => {

    const bem = bemNames.create("digital-order-wizard");
    const [loading, setLoading] = useState(true);
    const [projectTemplates, setProjectTemplates] = useState<EmailTemplateDto[]>([]);
    const [smsTemplates, setSmsTemplates] = useState<EmailTemplateDto[]>([]);
    const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<number>();
    const [selectedEmailTemplateName, setSelectedEmailTemplateName] = useState<string>("");
    const [selectedSmsTemplateId, setSelectedSmsTemplateId] = useState<number>();
    const [selectedSmsTemplateName, setSelectedSmsTemplateName] = useState<string>("");
    const [previewModal, setPreviewModal] = useState(false);
    const [previewSmsModal, setPreviewSmsModal] = useState(false);
    const chosenTemplateHTML = projectTemplates.filter(i => i.id === selectedEmailTemplateId).map(i => i.html).toString();
    const [templateHTML, templateRef, templateHeight] = useHTMLPreview(chosenTemplateHTML, ".preview-html");
    const chosenSmsTemplateHTML = smsTemplates.filter(i => i.id === selectedSmsTemplateId).map(i => i.html).toString();
    const [smsTemplateHTML, smsTemplateRef, smsTemplateHeight] = useHTMLPreview(chosenSmsTemplateHTML, ".smspreview-html");

    const togglePreview = () => setPreviewModal(!previewModal);
    const toggleSMSPreview = () => setPreviewSmsModal(!previewSmsModal);

    const { id: projectId } = useParams<{ id: string }>();

    const t = useTranslation();

    useEffect(() => {
        if (attemptGoToStepNumber === 5) {
            goToStep?.(5);
        }
    }, [attemptGoToStepNumber])

    useEffect(() => {

        if (currentStep === 4) {
            loadTemplates(+projectId, deliveryMethod ?? OrderReducer.DeliveryMethod.Form);
        }

    }, [currentStep === 4])

    useEffect(() => {
        setOrder({
            ...order,
            templateId: selectedEmailTemplateId,
            templateName: selectedEmailTemplateName,
            emailTemplateId: selectedEmailTemplateId,
            emailTemplateName: selectedEmailTemplateName,
            templateHtml: chosenTemplateHTML
        } as DigitalOrderDetails);
    }, [selectedEmailTemplateId, selectedEmailTemplateName, chosenTemplateHTML])

    useEffect(() => {
        setOrder({
            ...order,
            smsTemplateId: selectedSmsTemplateId,
            smsTemplateName: selectedSmsTemplateName
        } as DigitalOrderDetails);
    }, [selectedSmsTemplateId, selectedSmsTemplateName])

    if (isActive) {
        setCurrentStep(4);
    }

    const loadTemplates = async (projectId: number, deliveryMethod: OrderReducer.DeliveryMethod): Promise<void> => {
        setLoading(true);
        try {
            let currentOrder = order;
            if (codeDeliveryMethod === CodeDeliveryMethod.Email || codeDeliveryMethod === CodeDeliveryMethod.EmailAndSms) {
                const templateList = await orderingService.getProjectTemplates(+projectId, deliveryMethod);
                setProjectTemplates(templateList);
                //setOrder({ ...order, templateId: templateList[0].id, templateName: templateList[0].name, templateHtml: templateList[0].html, emailTemplateId: templateList[0].id, emailTemplateName: templateList[0].name } as DigitalOrderDetails);
                currentOrder = { ...currentOrder, templateId: templateList[0].id, templateName: templateList[0].name, templateHtml: templateList[0].html, emailTemplateId: templateList[0].id, emailTemplateName: templateList[0].name } as DigitalOrderDetails;
                setSelectedEmailTemplateId(templateList[0].id);
                setSelectedEmailTemplateName(templateList[0].name);
            }

            if (codeDeliveryMethod === CodeDeliveryMethod.Sms || codeDeliveryMethod === CodeDeliveryMethod.EmailAndSms) {
                const smsTemplateList = await orderingService.getSmsTemplates(+projectId);
                setSmsTemplates(smsTemplateList);
                //setOrder({ ...order, smsTemplateId: smsTemplateList[0].id, smsTemplateName: smsTemplateList[0].name } as DigitalOrderDetails);
                currentOrder = { ...currentOrder, smsTemplateId: smsTemplateList[0].id, smsTemplateName: smsTemplateList[0].name } as DigitalOrderDetails;
                setSelectedSmsTemplateId(smsTemplateList[0].id);
                setSelectedSmsTemplateName(smsTemplateList[0].name);
            }
            setOrder(currentOrder!);
        } catch (error) {
            return error;
        } finally {
            setLoading(false);
        }
    }

    const onEmailTemplateChange = (event: any) => {
        setSelectedEmailTemplateId(+event.target.value);
        setSelectedEmailTemplateName(event.target.alt);
    }

    const onSmsTemplateChange = (event: any) => {
        setSelectedSmsTemplateId(+event.target.value);
        setSelectedSmsTemplateName(event.target.alt);
    }

    const codeCounts = getOrderItemCounts(order, codeType, deliveryMethod, codeDeliveryMethod);

    const shouldShowEmailTemplates = ((): boolean => {
        if (codeType == BaseVoucherType.PrintReady) return true;

        const deliveryMethodIsEmail = codeDeliveryMethod === CodeDeliveryMethod.Email || codeDeliveryMethod === CodeDeliveryMethod.EmailAndSms;
        const validBulkItems = order?.bulkItems?.filter(code => code.value && code.quantity);

        if (deliveryMethodIsEmail && validBulkItems && validBulkItems.length > 0) return true;

        return codeCounts.emailTotal > 0 && deliveryMethodIsEmail;
    })();

    const shouldShowSmsTemplates = ((): boolean => {
        const deliveryMethodIsSms = codeDeliveryMethod === CodeDeliveryMethod.Sms || codeDeliveryMethod === CodeDeliveryMethod.EmailAndSms;
        const validBulkItems = order?.bulkItems?.filter(code => code.value && code.quantity);

        if (deliveryMethodIsSms && validBulkItems && validBulkItems.length > 0) return true;

        return codeCounts.smsTotal > 0 && deliveryMethodIsSms;
    })();

    return (
        <>
            <AreaLoader show={loading} message={t("OrderProcess.LoadingTemplatesMessage")} />
            {shouldShowSmsTemplates &&
                <div className="card w-100">
                    <div className="card-body">
                        <div className={bem.e("template-selection d-block w-100")}>
                            <h2>{t("OrderDetails.SmsTemplateNamePrefix")}</h2>
                            <div className="mt-4 d-flex flex-column flex-xl-row justify-content-between">
                                <div className="p-0 selection-area">
                                    <p>{t("OrderingProcess.SelectTemplateText")}</p>
                                    <FormGroup tag="fieldset" onChange={onSmsTemplateChange}>
                                        {smsTemplates.map((i, index) => {
                                            return (
                                                <FormGroup key={i.id} check className="pb-2">
                                                    <Label check style={{ cursor: "pointer" }}>
                                                        <Input alt={i.name} defaultChecked={index === 0} id={i.name} value={i.id} style={{ width: "25px", height: "25px" }} type="radio" name="radio0" disabled={!isActive} />
                                                        <span>{i.name}</span>
                                                    </Label>
                                                </FormGroup>
                                            )
                                        })}
                                    </FormGroup>
                                </div>
                                <div className="p-0 preview-area" style={{ flexShrink:0 }}>
                                    <div className="scale-wrap overflow-hidden" style={{ visibility: isActive ? "visible" : "hidden" }}>
                                        <div className="d-flex justify-content-center preview-html">
                                            {currentStep == 4 && <div ref={smsTemplateRef} style={{ transform: "scale(0.8)", transformOrigin: "center top", pointerEvents: "none", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: smsTemplateHTML }}>{ }</div>}
                                        </div>
                                    </div>
                                    <div className="d-flex w-100 justify-content-center">
                                        <button className="btn btn-link mt-2" onClick={toggleSMSPreview} disabled={!isActive}><AiOutlineSearch size={24} className="mr-2" />{t("OrderingProcess.FullSizePreview")}</button>
                                    </div>
                                    <Modal className="preview-modal" isOpen={previewSmsModal} toggle={toggleSMSPreview}>
                                        <ModalHeader toggle={toggleSMSPreview}>{t("OrderingProcess.TemplatePreview")}</ModalHeader>
                                        <ModalBody>
                                            {currentStep == 4 && <div className="preview-html" style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: smsTemplateHTML }} />}
                                        </ModalBody>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {shouldShowEmailTemplates &&
                <div className="card w-100">
                    <div className="card-body">
                        <div className={bem.e("template-selection d-block w-100")}>
                            <h2>{t("OrderDetails.EmailTemplateNamePrefix")}</h2>
                            <div className="mt-4 d-flex flex-column flex-xl-row justify-content-between">
                                <div className="p-0 selection-area">
                                    <p>{t("OrderingProcess.SelectTemplateText")}</p>
                                    <FormGroup tag="fieldset" onChange={onEmailTemplateChange}>
                                        {projectTemplates.map((i, index) => {
                                            return (
                                                <FormGroup key={i.id} check className="pb-2">
                                                    <Label check style={{ cursor: "pointer" }}>
                                                        <Input alt={i.name} defaultChecked={index === 0} id={i.name} value={i.id} style={{ width: "25px", height: "25px" }} type="radio" name="radio1" disabled={!isActive} />
                                                        <span>{i.name}</span>
                                                    </Label>
                                                </FormGroup>
                                            )
                                        }
                                        )}
                                    </FormGroup>

                                </div>
                                <div className="p-0 preview-area" style={{ flexShrink: 0 }}>
                                    <div className="scale-wrap overflow-hidden" style={{ height: templateHeight || "0", visibility: isActive ? "visible" : "hidden" }}>
                                        <div className="d-flex justify-content-center preview-html">
                                            {currentStep == 4 && <div ref={templateRef} style={{ transform: "scale(0.4)", transformOrigin: "center top", pointerEvents: "none" }} dangerouslySetInnerHTML={{ __html: templateHTML }}></div>}
                                        </div>
                                    </div>
                                    <div className="d-flex w-100 justify-content-center">
                                        <button className="btn btn-link mt-2" onClick={togglePreview} disabled={!isActive}><AiOutlineSearch size={24} className="mr-2" />{t("OrderingProcess.FullSizePreview")}</button>
                                    </div>
                                    <Modal className="preview-modal" isOpen={previewModal} toggle={togglePreview}>
                                        <ModalHeader toggle={togglePreview}>{t("OrderingProcess.TemplatePreview")}</ModalHeader>
                                        <ModalBody>
                                            {currentStep == 4 && <div className="preview-html" dangerouslySetInnerHTML={{ __html: templateHTML }} />}
                                        </ModalBody>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }            
        </>

    );
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(TemplateSelection);

export {
    ConnectedComponent as TemplateSelectionStep
}