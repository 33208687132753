import React, { useRef, useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import StepWizard from "react-step-wizard";
import { CgFileDocument } from "react-icons/cg";
import Notifications from 'react-notification-system-redux';
import { useTranslation } from "react-multi-lang";
import { Alert } from 'reactstrap';
import { OrderWizardBarNav, DeliveryMethodStep, OrderFormStep, PaymentPageStep, OrderReviewStep, AreaLoader, FileUploadStep, TemplateSelectionStep, FileUploadRowsTable, BatchTypeIconImage, AccountRestrictions } from "Components";

import { BatchFeesCalculator, bemNames, getErrorNotification, scrollToTopOfThePage } from 'Utilities';
import { ApplicationState } from "Store";
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import { DigitalOrderDetails, DistributorDetails, ProjectOrderingDetails, BaseVoucherType, ProjectSetupType, BatchTypeIconSize, PaymentMethod, CodeDeliveryMethod } from "../Models";
import { orderingService, userService } from 'Services';
import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";


interface StoreProps {
    setOrder: typeof OrderReducer.actions.setOrder;
    setDistributorDetails: typeof OrderReducer.actions.setDistributorDetails;
    setDeliveryMethod: typeof OrderReducer.actions.setDeliveryMethod;
    deliveryMethod?: OrderReducer.DeliveryMethod;
    setCurrentStep: typeof OrderReducer.actions.setCurrentStep;
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep;
    currentWizardStep?: number;
    order: DigitalOrderDetails;
    distributorDetails: DistributorDetails;
    notifyError: Notifications.NotificationShow;
    refreshToken?: string;
    accessToken?: string;
}

interface AuthStoreProps {
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
}

interface UserStoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
}


const mapStateToProps = ({ digitalOrder, auth }: ApplicationState) =>
({
    currentWizardStep: digitalOrder.currentWizardStep,
    order: digitalOrder.order,
    deliveryMethod: digitalOrder.deliveryMethod,
    distributorDetails: digitalOrder.distributorDetails,
    refreshToken: auth.refreshToken,
    accessToken: auth.accessToken
})


const mapDispatchToProps = {
    notifyError: Notifications.error,
    attemptGoToStep: OrderReducer.actions.attemptGoToStep,
    setOrder: OrderReducer.actions.setOrder,
    setDistributorDetails: OrderReducer.actions.setDistributorDetails,
    setDeliveryMethod: OrderReducer.actions.setDeliveryMethod,
    setCurrentStep: OrderReducer.actions.setCurrentStep,
    refreshTokens: AuthenticationReducer.actions.refreshTokens,
    loadUser: UserReducer.actions.loadUser
}

const OrderCodesPage = ({
    currentWizardStep,
    setOrder,
    setDeliveryMethod,
    setDistributorDetails,
    order,
    deliveryMethod,
    distributorDetails,
    attemptGoToStep,
    notifyError,
    refreshTokens,
    loadUser,
    refreshToken,
    accessToken,
    setCurrentStep
}: StoreProps & AuthStoreProps & UserStoreProps) => {

    const t = useTranslation();

    const bem = bemNames.create("order-digital");

    const wizardRef = useRef<any>(null);

    const [isBusy, setIsBusy] = useState(false);
    const [couldLoad, setCouldLoad] = useState(false);
    const [redirectToOrderDetails, setRedirectToOrderDetails] = useState(false);
    const [redirectToPayments, setRedirectToPayments] = useState(false);
    const [placedOrderId, setPlacedOrderId] = useState<number>();
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [projectOrderingDetails, setProjectOrderingDetails] = useState<ProjectOrderingDetails>();
    const [orderWithinLimit, setOrderWithinLimit] = useState(false);
    const [orderLimitWarningVisible, setorderLimitWarningVisible] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("");
    const [creditLimitWarningVisible, setCreditLimitWarningVisible] = useState(false);
    const [numberOfSmsCodes, setNumberOfSmsCodes] = useState(0);

    const { id: projectId } = useParams<{ id: string }>();
    const [paymentMethodItems, setpaymentMethodItems] = useState<PaymentMethod[]>();
    const [isOrderWithinCreditLimit, setIsOrderWithinCreditLimit] = useState(false);
    const [boldMessage, setBoldMessage] = useState<string>();
    const [normalMessage, setNormalMessage] = useState<string>();

    useEffect(() => {
        feesCalculator = new BatchFeesCalculator(
            order.itemsTotalValue,
            projectOrderingDetails?.vatPercentage ?? 0,
            projectOrderingDetails?.discountPercentage ?? 0,
            projectOrderingDetails?.handlingFeePercentage ?? 0,
            costPerCode,
            costPerSms,
            noItems,
            numberOfSmsCodes,
            projectSetupType
        );
    }, [numberOfSmsCodes])

    useEffect(() => {
        (async () => {
            if (projectId && +projectId > 0) {
                try {

                    await loadDetails(+projectId);
                    setOrder({
                        ...OrderReducer.initialState.order,
                        bulkItems: [{ value: "", quantity: "" }],
                        fileUploadECodeItems: [],
                        fileUploadPrintReadyItems: [],
                        fileUploadPhysicalItems: [],
                        fileUploadBulkOrderItems: [],
                        itemsTotalValue: 0,
                        referenceOrPONumber: "",
                    });
                    attemptGoToStep(1);
                    setCurrentStep(1);
                    setDeliveryMethod(undefined);

                } catch {

                    notifyError(getErrorNotification(t("NotificationMessage.LoadOrderDetailsFailure")));
                }
            }
        })();
    }, [projectId])

    useEffect(() => {
        return () => {
            setOrder({
                ...OrderReducer.initialState.order,
                bulkItems: [{ value: "", quantity: "" }],
                fileUploadECodeItems: [],
                fileUploadPrintReadyItems: [],
                fileUploadPhysicalItems: [],
                fileUploadBulkOrderItems: [],
                itemsTotalValue: 0,
            });
            attemptGoToStep(1);
            setCurrentStep(1);
            setDeliveryMethod(undefined);
        }

    }, []);

    useEffect(() => {
        if (projectOrderingDetails?.orderingLimit.orderingLimit || projectOrderingDetails?.orderingLimit.orderingLimit == 0) {
            const remainingAllowance = projectOrderingDetails?.orderingLimit.orderingLimit - projectOrderingDetails?.orderingLimit.orderingLimitRunningTotal;

            if ((+feesCalculator.totalOrderAmount.toFixed(2) > remainingAllowance) || (+feesCalculator.totalOrderAmount.toFixed(2) > projectOrderingDetails.orderingLimit.orderingLimit)) {
                setOrderWithinLimit(false);
                setorderLimitWarningVisible(true);
                scrollToTopOfThePage();
            } else {
                setOrderWithinLimit(true);
                setorderLimitWarningVisible(false);
            }
        } else {
            setOrderWithinLimit(true);
        }

        setNumberOfSmsCodes(projectOrderingDetails?.codeDeliveryMethod == CodeDeliveryMethod.Sms || projectOrderingDetails?.codeDeliveryMethod == CodeDeliveryMethod.EmailAndSms
            ? order.fileUploadECodeItems.filter(c => !!c.mobileNumber && c.mobileNumber.toString().trim() !== "").length
            : feesCalculator.numberOfSmsCodes);

        setCreditLimitWarningVisible(false);
        setIsOrderWithinCreditLimit(true);

        if (projectOrderingDetails?.isPayOnAccount && projectOrderingDetails.isClientCreditLimitEnabled) {
            const remainingcredit = projectOrderingDetails?.clientCreditBalance - projectOrderingDetails?.clientRunningTotal;
            const payOnAccountMethod = paymentMethodItems?.find((pm) => {
                return pm === PaymentMethod.PaymentOnAccount;
            });
            if (feesCalculator && ((+feesCalculator.totalOrderAmount.toFixed(2)) > remainingcredit)) {
                setCreditLimitWarningVisible(true);
                scrollToTopOfThePage();
                setIsOrderWithinCreditLimit(false);
                if (order.paymentMethod === PaymentMethod.PaymentOnAccount && paymentMethodItems && (paymentMethodItems?.length === 1 || paymentMethodItems?.length > 2)) {
                    order.paymentMethod = undefined;
                    setOrder(order);
                }
                if (paymentMethodItems && paymentMethodItems?.length === 2) {
                    if (payOnAccountMethod !== undefined) {
                        order.paymentMethod = paymentMethodItems.find((pm) => {
                            return pm !== PaymentMethod.PaymentOnAccount;
                        });
                        setOrder(order);
                    }
                }
            } else {
                if (payOnAccountMethod && paymentMethodItems && paymentMethodItems?.length === 1) {
                    order.paymentMethod = payOnAccountMethod;
                    setOrder(order);
                }
            }
        } else {
            if (paymentMethodItems?.length === 1) {
                order.paymentMethod = paymentMethodItems[0];
                setOrder(order);
            }
        }
    }, [order.itemsTotalValue, projectOrderingDetails?.orderingLimit.orderingLimitRunningTotal, projectOrderingDetails?.clientCreditBalance, projectOrderingDetails?.clientRunningTotal])

    useEffect(() => {
        LoadPaymentMethods();
    }, [projectId]);

    useEffect(() => {
        if (order.paymentMethod === PaymentMethod.DebitCreditCard) {

            setRedirectToPayments(true);

        }
    }, [placedOrderId])


    const loadDetails = async (projectId: number) => {
        setIsBusy(true);
        setLoadingMessage(t("NotificationMessage.LoadOrderDetailsMessage"));

        try {

            const data = await orderingService.getProjectOrderingDetails(+projectId);

            setProjectOrderingDetails(data);
            setCouldLoad(true);
        } catch (e) {

            throw e;

        } finally {
            setIsBusy(false);
            setLoadingMessage("");
        }
    }

    const navButtonsCallback = (step: number) => {
        wizardRef.current.goToStep(step);
    }

    const toggleAcceptTerms = () => {
        setAcceptTerms(!acceptTerms);
    }

    const refreshingToken = async () => {
        setIsBusy(true);

        try {
            var userDetails = await userService.refreshingToken(accessToken, refreshToken);

            if (userDetails) {

                await refreshTokens(userDetails.accessToken, userDetails.refreshToken);
                await loadUser(userDetails);

                return;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsBusy(false);
        }
    }

    const DigitalOrderWizardButtonNav = () => <div className="w-100 mr-3">
        <div className="nav">
            {currentWizardStep === 1 && deliveryMethod === OrderReducer.DeliveryMethod.Form &&
                <div className="w-100 d-flex justify-content-end pt-5">
                    <button
                        data-automation-id="orderNextButton"
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(2);
                        }}
                        disabled={true}
                    >{t("NextButton")}</button>
                </div>
            }
            {currentWizardStep === 1 && deliveryMethod === OrderReducer.DeliveryMethod.Email &&
                <div className="w-100 d-flex justify-content-end pt-5">
                    <button
                        data-automation-id="orderNextButton"
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(3);
                        }}
                    >{t("NextButton")}</button>
                </div>
            }
            {currentWizardStep == 2 && projectOrderingDetails?.codeType == BaseVoucherType.ECode &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(1);
                        }}
                    >{t("BackButton")}</button>
                    <button
                        data-automation-id="orderNextButton"
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(5);
                        }}
                        disabled={order.itemsTotalValue && order.itemsTotalValue > 0 && order.bulkItems?.length && orderWithinLimit ? false : true}
                    >{t("NextButton")}</button>
                </div>
            }
            {currentWizardStep == 2 && projectOrderingDetails?.codeType == BaseVoucherType.PrintReady &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(1);
                        }}
                    >{t("BackButton")}</button>
                    <button
                        data-automation-id="orderNextButton"
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(4);
                        }}
                        disabled={order.itemsTotalValue && order.itemsTotalValue > 0 && order.bulkItems?.length && orderWithinLimit ? false : true}
                    >{t("NextButton")}</button>
                </div>
            }
            {currentWizardStep == 3 && BaseVoucherType.ECode && order.ecodeBulkFileUploadListValid &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(1);
                        }}
                    >{t("BackButton")}</button>
                    <button
                        data-automation-id="orderNextButton"
                        disabled={(order.fileUploadListValid || order.printReadyFileUploadListValid || order.ecodeBulkFileUploadListValid) && orderWithinLimit ? false : true}
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(5);
                        }}
                    >{t("NextButton")}</button>
                </div>
            }
            {currentWizardStep == 3 && !order.ecodeBulkFileUploadListValid &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(1);
                        }}
                    >{t("BackButton")}</button>
                    <button
                        data-automation-id="orderNextButton"
                        disabled={(order.fileUploadListValid || order.printReadyFileUploadListValid || order.ecodeBulkFileUploadListValid) && orderWithinLimit ? false : true}
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(4);
                        }}
                    >{t("NextButton")}</button>
                </div>
            }
            {currentWizardStep == 4 && deliveryMethod === OrderReducer.DeliveryMethod.Form && 
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(2);
                        }}
                    >{t("BackButton")}</button>
                    <button
                        data-automation-id="orderNextButton"
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(5);
                        }}
                    >{t("NextButton")}</button>
                </div>
            }
            {currentWizardStep == 4 && deliveryMethod === OrderReducer.DeliveryMethod.Email &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(3);
                        }}
                    >{t("BackButton")}</button>
                    <button
                        data-automation-id="orderNextButton"
                        disabled={order.fileUploadListValid || order.ecodeBulkFileUploadListValid || order.printReadyFileUploadListValid ? false : true}
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(5);
                        }}
                    >{t("NextButton")}</button>
                </div>
            }

            {currentWizardStep == 5 && deliveryMethod === OrderReducer.DeliveryMethod.Form && projectOrderingDetails?.codeType == BaseVoucherType.ECode &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(2);
                        }}
                    >{t("BackButton")}</button>
                    {projectOrderingDetails.otherReferenceMandatory ?
                        <button
                            data-automation-id="orderNextButton"
                            className="btn btn-primary"
                            onClick={async () => {
                                refreshingToken();
                                attemptGoToStep(6);
                            }}
                            disabled={order.referenceOrPONumber === undefined || order.referenceOrPONumber?.trim() === "" || order.otherReference === undefined || order.otherReference.trim() === "" || order.paymentMethod === undefined}
                        >{t("NextButton")}</button>
                        :
                        <button
                            data-automation-id="orderNextButton"
                            className="btn btn-primary"
                            onClick={async () => {
                                refreshingToken();
                                attemptGoToStep(6);
                            }}
                        disabled={order.referenceOrPONumber === undefined || order.referenceOrPONumber?.trim() === "" || order.paymentMethod === undefined}
                        >{t("NextButton")}</button>
                    }
                </div>
            }

            {currentWizardStep == 5 && deliveryMethod === OrderReducer.DeliveryMethod.Email && projectOrderingDetails?.codeType == BaseVoucherType.ECode && order.ecodeBulkFileUploadListValid &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(3);
                        }}
                    >{t("BackButton")}</button>
                    {projectOrderingDetails.otherReferenceMandatory ?
                        <button
                            data-automation-id="orderNextButton"
                            className="btn btn-primary"
                            onClick={async () => {
                                refreshingToken();
                                attemptGoToStep(6);
                            }}
                            disabled={order.referenceOrPONumber === undefined || order.referenceOrPONumber?.trim() === "" || order.otherReference === undefined || order.otherReference.trim() === "" || order.paymentMethod === undefined}
                        >{t("NextButton")}</button>
                        :
                        <button
                            data-automation-id="orderNextButton"
                            className="btn btn-primary"
                            onClick={async () => {
                                refreshingToken();
                                attemptGoToStep(6);
                            }}
                            disabled={order.referenceOrPONumber === undefined || order.referenceOrPONumber?.trim() === "" || order.paymentMethod === undefined}
                        >{t("NextButton")}</button>
                    }

                </div>
            }

            {currentWizardStep == 5 && deliveryMethod === OrderReducer.DeliveryMethod.Email && projectOrderingDetails?.codeType == BaseVoucherType.ECode && !order.ecodeBulkFileUploadListValid &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(4);
                        }}
                    >{t("BackButton")}</button>
                    {projectOrderingDetails.otherReferenceMandatory ?
                        <button
                            data-automation-id="orderNextButton"
                            className="btn btn-primary"
                            onClick={async () => {
                                refreshingToken();
                                attemptGoToStep(6);
                        }}
                        disabled={order.referenceOrPONumber === undefined || order.referenceOrPONumber?.trim() === "" || order.otherReference === undefined || order.otherReference.trim() === "" || order.paymentMethod === undefined}
                        >{t("NextButton")}</button>
                        :
                        <button
                            data-automation-id="orderNextButton"
                            className="btn btn-primary"
                            onClick={async () => {
                                refreshingToken();
                                attemptGoToStep(6);
                            }}
                            disabled={order.referenceOrPONumber === undefined || order.referenceOrPONumber?.trim() === "" || order.paymentMethod === undefined}
                        >{t("NextButton")}</button>
                    }

                </div>
            }

            {currentWizardStep == 5 && projectOrderingDetails?.codeType == BaseVoucherType.PrintReady &&
                <div className="w-100 d-flex justify-content-between pt-5">
                    <button
                        className="btn btn-secondary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(4);
                        }}
                    >{t("BackButton")}</button>
                    <button
                        data-automation-id="orderNextButton"
                        className="btn btn-primary"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(6);
                        }}
                    disabled={order.referenceOrPONumber === undefined || order.referenceOrPONumber?.trim() === "" || order.paymentMethod === undefined}
                    >{t("NextButton")}</button>
                </div>
            }


            {currentWizardStep == 6 &&
                <>
                    <div className="clearfix w-100 mt-3">
                        <div className="float-right d-flex align-items-start" style={{ maxWidth: "23.75rem" }}>
                            <input
                                className="mt-2"
                                name="acceptTerms"
                                id="acceptTerms"
                                checked={acceptTerms}
                                onChange={toggleAcceptTerms}
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                            />
                            <label
                                style={{ cursor: "pointer" }}
                                htmlFor="acceptTerms"
                                className="pl-2"
                            >{t("OrderingProcess.ConfirmTermsDescription")} <a target="_blank" href={t("OrderingProcess.TermsAndConditionsLink")}>{t("OrderingProcess.TermsAndConditionsLabel")}</a> {t("OrderingProcess.TermsViewedDescription")} <a target="_blank" href={t("FooterLinks.PrivacyPolicyLink")}>{t("FooterLinks.PrivacyPolicyLabel")}</a>
                            </label>
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-between pt-3">
                        <button
                            className="btn btn-secondary"
                            onClick={async () => {
                                refreshingToken();
                                attemptGoToStep(5);
                            }}
                        >{t("BackButton")}</button>
                        {order.paymentMethod === PaymentMethod.DebitCreditCard ?
                            <button
                                data-automation-id="proceedToPaymentButton"
                                className="btn btn-primary"
                                disabled={acceptTerms && orderWithinLimit && !isBusy ? false : true}
                                onClick={onPlaceOrder}
                            >{t("OrderingProcess.ProceedToPayment")}</button>
                            :
                            <button
                                data-automation-id="proceedToPaymentButton"
                                className="btn btn-primary"
                                disabled={acceptTerms && orderWithinLimit && !isBusy ? false : true}
                                onClick={onPlaceOrder}
                            >{t("OrderingProcess.PlaceOrderButton")}</button>
                        }
                    </div>
                </>
            }


        </div>
    </div >;

    const onPlaceOrder = async () => {
        await loadDetails(+projectId);

        setIsBusy(true);
        setLoadingMessage(t("OrderingProcess.PlacingOrderLabel"));
        let isOk = true;

        try {
            let result = "";
            if (deliveryMethod === OrderReducer.DeliveryMethod.Email && order.fileUploadBulkOrderItems!.length > 0) {
                order.bulkItems = [];
                result = await orderingService.placeBulkOrder(+projectId, order, distributorDetails);
            } else if (deliveryMethod === OrderReducer.DeliveryMethod.Email) {
                order.bulkItems = [];
                result = await orderingService.placeBatchOrder(+projectId, order, distributorDetails);
            } else if (deliveryMethod === OrderReducer.DeliveryMethod.Form && order.fileUploadBulkOrderItems!.length > 0) {
                order.bulkItems = [];
                result = await orderingService.placeBulkOrder(+projectId, order, distributorDetails);
            } else {
                result = await orderingService.placeBulkOrder(+projectId, order, distributorDetails);
            }

            let orderId = parseInt(result, 10);
            if (isNaN(orderId)) {
                notifyError(getErrorNotification(t(result)));
                isOk = false;
            } else {
                setPlacedOrderId(orderId);
            }

            if (isOk && order.paymentMethod != PaymentMethod.DebitCreditCard) {

                if (order.paymentMethod == PaymentMethod.BacsChaps)
                {
                    setBoldMessage("OrderDetails.ThankyouForOrderMessage");
                    setNormalMessage("OrderDetails.SuccessfulBacsChapsMessage");
                }
                else if (order.paymentMethod == PaymentMethod.PaymentOnAccount)
                {
                    setBoldMessage("OrderDetails.ThankyouForOrderMessage");
                    setNormalMessage("OrderDetails.SuccessfulPaymentMessage");
                }
                setRedirectToOrderDetails(true);

                // reset state
                setOrder({
                    ...OrderReducer.initialState.order,
                    bulkItems: [{ value: "", quantity: "" }],
                    fileUploadListValid: false,
                    fileUploadECodeItems: [],
                    fileUploadPrintReadyItems: [],
                    fileUploadPhysicalItems: [],
                    fileUploadBulkOrderItems: [],
                    saveInvoiceAddress: false
                });
                setDeliveryMethod(OrderReducer.initialState.deliveryMethod);
                setDistributorDetails(OrderReducer.initialState.distributorDetails);
                attemptGoToStep(1);
            }

        } catch (e) {
            console.error(e);

            notifyError(getErrorNotification(t("NotificationMessage.OrderPlacementFailure")));
        }
        finally {
            setIsBusy(false);
            setLoadingMessage("");
        }
    }

    if (redirectToOrderDetails && placedOrderId) {
        
        return <Redirect to={{
            pathname: `/order-history/order-details/${placedOrderId}`,
            state: {
                customMessage: {
                    boldpart: boldMessage,
                    normalpart: normalMessage
                }
            }
        }} />
    }

    if (projectOrderingDetails && projectOrderingDetails.isClosed) {
        notifyError(getErrorNotification(t("NotificationMessage.ProjectClosedNotification")));
        return <Redirect to={`/projects`} />
    }

    if (redirectToPayments) {
        return <Redirect to={`/PaymentPlusPage/project/${projectId}/order/${placedOrderId}`} />
    }

    const noItems =
        order.fileUploadECodeItems.length > 0
            ? order.fileUploadECodeItems.length
            : order.fileUploadPrintReadyItems.length > 0
                ? order.fileUploadPrintReadyItems.length
                : order.fileUploadBulkOrderItems.length > 0
                    ? order.fileUploadBulkOrderItems.reduce((acc, item) => acc + +item.quantity, 0)
                    :order.bulkItems.reduce((acc, item) => acc + +item.quantity, 0);

    const costPerCode = projectOrderingDetails?.perCodeFee ?? 0;
    const costPerSms = projectOrderingDetails?.smsFee ?? 0;

    const projectSetupType = (projectOrderingDetails?.projectSetupType ?? ProjectSetupType.BillOnIssuance);

    const hasFees = projectSetupType === ProjectSetupType.UkBillOnRedemption || projectSetupType === ProjectSetupType.BillOnIssuance
                        ? ((projectOrderingDetails?.handlingFeePercentage ?? 0) + costPerCode + costPerSms) > 0
                        : costPerCode > 0;

    var feesCalculator = new BatchFeesCalculator(
        order.itemsTotalValue,
        projectOrderingDetails?.vatPercentage ?? 0,
        projectOrderingDetails?.discountPercentage ?? 0,
        projectOrderingDetails?.handlingFeePercentage ?? 0,
        costPerCode,
        costPerSms,
        noItems,
        numberOfSmsCodes,
        projectSetupType
    );

    const onDismiss = () => {
        setorderLimitWarningVisible(false);
    }
    const onCreditWarningDismiss = () => {
        setCreditLimitWarningVisible(false);
    }
    const LoadPaymentMethods = async () => {

        try {
            const data = await orderingService.getAllPaymentMethods();
            if (data && data.length == 1) {
                order.paymentMethod == data[0];
            }
            else {
                order.paymentMethod = undefined;
            }
            setOrder(order);
            setpaymentMethodItems(data);
        } catch (e) {
            notifyError(getErrorNotification(t("PaymentMethodStatus.ErrorMsg")));
        }
    }

    return (
        <div className={bem.b()} style={{ overflow: "hidden" }}>
            <AreaLoader show={isBusy} message={loadingMessage} />
            {couldLoad &&
                <div>
                    {<OrderWizardBarNav codeType={projectOrderingDetails?.codeType} returnWizardStep={navButtonsCallback} ecodeBulkFileUpload={order.ecodeBulkFileUploadListValid} />}
                    <h1>
                        {currentWizardStep === 1 && projectOrderingDetails?.codeType == BaseVoucherType.ECode && t("OrderingProcess.DigitalOrderMethodLabel")}
                        {currentWizardStep === 1 && projectOrderingDetails?.codeType == BaseVoucherType.PrintReady && t("OrderingProcess.PrintReadyOrderMethodLabel")}
                        {currentWizardStep === 2 && t("OrderingProcess.RewardDetailsLabel")}
                        {currentWizardStep === 3 && t("OrderingProcess.RewardDetailsLabel")}
                        {currentWizardStep === 4 && t("OrderDetails.TemplateSelectionLabel")}
                        {currentWizardStep === 5 && t("OrderingProcess.PaymentLabel")}
                        {currentWizardStep === 6 && t("OrderingProcess.ReviewLabel")}
                    </h1>
                    <Alert color="warning" className="mt-3" isOpen={creditLimitWarningVisible && (currentWizardStep === 2 || currentWizardStep === 3)} toggle={onCreditWarningDismiss}>
                        <strong>{t("OrderingProcess.creditLimitWarningBold")}</strong> {t("OrderingProcess.creditLimitWarningCopy")}
                    </Alert>

                    <Alert color="danger" className="mt-3" isOpen={((currentWizardStep === 2 || currentWizardStep === 3) && orderLimitWarningVisible) || ((currentWizardStep === 5) && !isOrderWithinCreditLimit)} toggle={onDismiss}>
                        <strong>{t("OrderingProcess.orderLimitWarningBold")}</strong> {t("OrderingProcess.orderLimitWarningCopy")}
                    </Alert>
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-4 align-items-start">

                        <div className="w-100 mr-3">
                            <StepWizard
                                isHashEnabled={true}
                                instance={(W) => wizardRef.current = W}
                            >
                                <DeliveryMethodStep
                                    hashKey="delivery-method"
                                    codeType={projectOrderingDetails?.codeType}
                                />
                                <OrderFormStep
                                    hashKey="order-form"
                                    projectID={projectId}
                                    deliveryMethod={deliveryMethod}
                                    currencySymbol={projectOrderingDetails?.currencySymbol}
                                />
                                <FileUploadStep
                                    hashKey="file-upload"
                                    codeType={projectOrderingDetails?.codeType}
                                    requireStaffNumberOnUpload={projectOrderingDetails?.requireStaffNumberOnUpload}
                                    deliveryMethod={deliveryMethod}
                                    codeDeliveryMethod={projectOrderingDetails?.codeDeliveryMethod}
                                    countryIso2={projectOrderingDetails?.countryCode}
                                />
                                <TemplateSelectionStep
                                    hashKey="template-selection"
                                    codeType={projectOrderingDetails?.codeType}
                                    codeDeliveryMethod={projectOrderingDetails?.codeDeliveryMethod}
                                />
                                <PaymentPageStep
                                    hashKey="payment"
                                    isPayOnAccount={projectOrderingDetails?.isPayOnAccount}
                                    defaultInvoiceAddress={projectOrderingDetails?.defaultInvoiceAddress}
                                    paymentMethodItems={paymentMethodItems}
                                    otherReferenceMandatory={projectOrderingDetails?.otherReferenceMandatory!}
                                    totalOrderAmount={feesCalculator.totalOrderAmount!}
                                    isOrderWithinCreditLimit={isOrderWithinCreditLimit}
                                />
                                <OrderReviewStep
                                    hashKey="order-review"
                                    codeType={projectOrderingDetails?.codeType}
                                    currencySymbol={projectOrderingDetails?.currencySymbol}
                                    codeDeliveryMethod={projectOrderingDetails?.codeDeliveryMethod}
                                />
                            </StepWizard>
                        </div>

                        <div className={bem.e("info-panel w-100 ml-md-3 mt-4 mt-md-0")}>
                            {projectOrderingDetails?.isPayOnAccount &&
                                <AccountRestrictions project={projectOrderingDetails} />
                            }
                            <div className="card" style={{ borderRadius: "0.25rem 0.25rem 0 0" }}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="mr-3 icon-wrap">
                                            <BatchTypeIconImage codeType={projectOrderingDetails?.codeType} size={BatchTypeIconSize.medium} />
                                        </div>
                                        <div>
                                            <h3 className="text-uppercase">{t("OrderSummary.ProjectDetailsLabel")}</h3>
                                            <p style={{ lineHeight: "1.2rem" }}>
                                                {projectOrderingDetails && projectOrderingDetails.projectName}
                                                <br />
                                                Id: {projectOrderingDetails && projectId}
                                            </p>
                                            <Link to={`/projects/project/${projectId}`} style={{ fontSize: "0.875rem" }}><CgFileDocument className="mr-1" size={22} />{t("OrderSummary.ViewProjectButton")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card border-top-0 rounded-0" style={{ boxShadow: "inset 0px 18px 12px -13px rgb(0 0 0 / 15%)", WebkitBoxShadow: "inset 0px 18px 12px -13px rgb(0 0 0 / 15%)" }}>
                                <div className="card-body">
                                    <div className="px-3">

                                        <div className="d-flex justify-content-between w-100">
                                            <div>{t("OrderDetails.TotalValueLabel")}</div>
                                            <div>{projectOrderingDetails?.currencySymbol}{order.itemsTotalValue?.toFixed(2) || 0.00}</div>
                                        </div>

                                        {projectOrderingDetails && (projectOrderingDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                            || projectOrderingDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption) &&
                                            (projectOrderingDetails.handlingFeePercentage ?? 0) > 0 &&

                                            <div className="d-flex justify-content-between w-100 mt-2">
                                                <div>{t("OrderDetails.HandlingFeeLabel")} ({projectOrderingDetails?.handlingFeePercentage?.toFixed(1)}%)</div>
                                                <div>{projectOrderingDetails?.currencySymbol}{feesCalculator.handlingFeeAmount?.toFixed(2) || 0.00}</div>
                                            </div>
                                        }

                                        {projectOrderingDetails && (projectOrderingDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                            || projectOrderingDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption) &&
                                            costPerCode > 0 &&

                                            <div className="d-flex justify-content-between w-100 mt-2">
                                                <div>{t("OrderDetails.TotalCostPerCodeLabel")}</div>
                                                <div>{projectOrderingDetails?.currencySymbol}{feesCalculator.costPerCodeAmount?.toFixed(2) || 0.00}</div>
                                            </div>
                                        }

                                        {projectOrderingDetails && (projectOrderingDetails.projectSetupType === ProjectSetupType.BillOnIssuance
                                            || projectOrderingDetails.projectSetupType === ProjectSetupType.UkBillOnRedemption) &&
                                            (feesCalculator.costPerSmsAmount ?? 0) > 0 &&

                                            <div className="d-flex justify-content-between w-100 mt-2">
                                                <div>{t("OrderDetails.TotalCostPerSmsLabel")}</div>
                                                <div>{projectOrderingDetails?.currencySymbol}{feesCalculator.costPerSmsAmount?.toFixed(2) || 0.00}</div>
                                            </div>
                                        }

                                        {(projectOrderingDetails && feesCalculator && feesCalculator.discountPercentage > 0) &&
                                            <div className="d-flex justify-content-between w-100 mt-2">
                                                <div>{t("OrderDetails.DiscountLabel")} ({feesCalculator?.discountPercentage?.toFixed(2)}%)</div>
                                                <div>{projectOrderingDetails?.currencySymbol}{`-${feesCalculator?.discountAmount?.toFixed(2) || 0.00}`}</div>
                                            </div>
                                        }

                                        {(projectOrderingDetails && projectOrderingDetails.vatPercentage > 0 && hasFees
                                            && (feesCalculator.vatAmount ?? 0) > 0) &&
                                            <div className="d-flex justify-content-between w-100 mt-2">
                                                <div>{t("OrderDetails.VatRateLabel")} ({projectOrderingDetails?.vatPercentage?.toFixed(1)}%)</div>
                                                <div>{projectOrderingDetails?.currencySymbol}{feesCalculator.vatAmount.toFixed(2) || 0.00}</div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="card border-top-0" style={{ borderRadius: "0 0 0.25rem 0.25rem", fontSize: "1.5rem", backgroundColor: "#CBCFDB" }}>
                                <div className="card-body py-2">
                                    <div className="d-flex large justify-content-between">
                                        <div>{t("OrderDetails.OrderTotalLabel")}</div>
                                        <div>{projectOrderingDetails?.currencySymbol}{feesCalculator.totalOrderAmount.toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {currentWizardStep === 3 &&
                        <FileUploadRowsTable order={order} codeType={projectOrderingDetails?.codeType} codeDeliveryMethod={projectOrderingDetails?.codeDeliveryMethod} />
                    }
                    <DigitalOrderWizardButtonNav />
                </div>
            }
        </div >
    )
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(OrderCodesPage);

export {
    ConnectedComponent as OrderCodesPage
}

