
export enum BatchStatus {
    Undefined = 0,
    Stock = 1,
    Incomplete = 2,
    ReadyForApproval = 3,
    Approved = 4,
    SentToMvb = 5,
    Allocated = 6,
    Cancelled = 7,
    Expired = 8,
    Closed = 9,
    WaitingForPaymentOption = 10,
    WaitingForPaymentConfirmation = 11,
    ReadyForProcessing = 12,
    FileUploaded = 13,
    ReadyForFileProcessing = 14,
    ActivatedFromOnHold = 15,
    ProcessingCardPayment = 16,
    FailedRiskReview = 17
}
