import React, { useState } from "react";
import { useSelect, UseSelectProps } from "downshift";
import { IoCloseCircle, IoClose } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";

type ISelectOption = {
    label: string,
    value: string
}

export interface MultiSelectProps {
    id: string,
    message?: string,
    options: ISelectOption[],
    chosenOptions: ISelectOption[],
    onChange: (chosenOptions: ISelectOption[]) => void
}

const stateReducer: UseSelectProps<ISelectOption>["stateReducer"] = ({ highlightedIndex }, { changes, type }) => {
    switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
            if (changes.selectedItem === null) return changes;

            return {
                ...changes,
                isOpen: true,
                highlightedIndex,
            }
        default:
            return changes;
    }
}

export const MultiSelectDropdown = ({ id, message = "", options = [], chosenOptions = [], onChange }: MultiSelectProps) => {
    const [openerHasFocus, setOpenerHasFocus] = useState(false);

    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items: options,
        itemToString: (item: ISelectOption | null) => item ? item.label : "",
        stateReducer,
        selectedItem: undefined,
        onSelectedItemChange: ({ selectedItem }) => {
            if (!selectedItem) return;

            const selectedOptionIndex = chosenOptions
                .map(({ value }) => value)
                .indexOf(selectedItem.value);

            // Add selected item in order
            if (selectedOptionIndex > 0) {
                onChange([
                    ...chosenOptions.slice(0, selectedOptionIndex),
                    ...chosenOptions.slice(selectedOptionIndex + 1),
                ]);
            } else if (selectedOptionIndex === 0) {
                onChange([...chosenOptions.slice(1)]);
            } else {
                onChange([...chosenOptions, selectedItem]);
            }
        },
    })

    const hasSelection = chosenOptions.length > 0;

    return (
        <div className={`dropdown is-multi-select ${isOpen ? "is-open" : ""}`}>
            <input type="hidden" id={id} name={id} value={chosenOptions.map(o => o.value).join(",")} />
            <div className={`opener ${hasSelection ? "has-selection" : ""} ${openerHasFocus ? "has-focus" : ""}`}>
                <button
                    className="opener-button"
                    onFocus={() => setOpenerHasFocus(true)}
                    onBlur={() => setOpenerHasFocus(false)}
                    type="button"
                    {...getToggleButtonProps()}
                >
                    <div className="selected-summary">{message}</div>
                    <div className="icon-container">
                        <FaChevronDown />
                    </div>
                </button>
                <button
                    onClick={() => onChange([])}
                    className="clear-all-button"
                    aria-hidden={!hasSelection}
                    tabIndex={hasSelection ? 0 : -1}
                    type="button"
                >
                    <IoCloseCircle />
                </button>
            </div>
            <div className={`choices-container ${isOpen ? "is-open" : ""}`}>
                <ul className="choices" {...getMenuProps()}>
                    {options.map((option, index) => {
                        const isSelected = chosenOptions.map(_option => _option.value).includes(option.value);
                        const isHighlighted = highlightedIndex === index;

                        return (
                            <li
                                key={option.value}
                                {...getItemProps({ item: option, index })}
                                className={`choice ${isSelected ? "is-selected" : ""} ${isHighlighted ? "is-highlighted" : ""}`}
                                aria-selected={isSelected}
                            >
                                <div className="choice-label">{option.label}</div>
                                <div className="choice-label-deselect"><IoClose /></div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}