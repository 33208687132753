import React from "react";
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bemNames, getBatchTypeLocalizationKey, getErrorNotification, groupBy, nameOf } from 'Utilities';
import { BatchType, DownloadableBatchDetails, DownloadableBatchStatus, DownloadableItem, RedemptionStatus, BatchTypeIconSize } from 'Models';
import { useTranslation } from "react-multi-lang";
import { AreaLoader, DataTable, ReactstrapDataTable, SortByOptions, BatchTypeIconImage } from "../Components";
import { Cell, Column } from "react-table";
import { FaRegEye, FaFileCsv, FaFilePdf } from "react-icons/fa";

import { downloadsService } from "../Services";
import Notifications from 'react-notification-system-redux';
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { BsDownload} from "react-icons/bs";
import fileDownload from "js-file-download";
import Moment from "react-moment";

interface StoreProps {
    notifyError: Notifications.NotificationShow;
}

const mapDispatchToProps = {
    notifyError: Notifications.error,
}

const DownloadDetailsPage = ({
    notifyError
}: StoreProps) => {

    const t = useTranslation();
    const bem = bemNames.create("download-details");

    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [downloadableBatchDetails, setDownloadableBatchDetails] = useState<DownloadableBatchDetails>();
    const [tempDownloadableBatchDetails, setTempDownloadableBatchDetails] = useState<DownloadableBatchDetails>();

    // url params
    const { batchId } = useParams<{ batchId: string }>();

    useEffect(() => {
        loadDownloadableBatchDetails();
    }, [batchId]);

    const loadDownloadableBatchDetails = async () => {
        setLoading(true);
        try {
            const data = await downloadsService.getDownloadDetails(+batchId);
            if (data?.batchType != BatchType.PrintReadyBatch) {
                loadDownloadableBatchItemDetails(1, 10, [], "");
            }
            setTempDownloadableBatchDetails(data);
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadDownloadDetailsFailure")));
        }
        setLoading(false);
    }

    const loadDownloadableBatchItemDetails = async (pageNumber: number, pageSize: number, sortBy: SortByOptions[],searchTerm: string) => {
        setLoading(true);
        try {
            const data = await downloadsService.getDownloadItemDetails(
                +batchId,
                pageNumber,
                pageSize,
                searchTerm
            );
            setDownloadableBatchDetails(data);

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.LoadDownloadDetailsFailure")));
        }
        setLoading(false);
    }

    const onDownloadAll = async () => {

        setLoading(true);
        setLoadingMessage(t("NotificationMessage.DownloadingCodes"));

        try {

            if (downloadableBatchDetails?.batchType === BatchType.ElectronicBulk) {
                // csv
                var data = await downloadsService.getDownloadAllCsv(+batchId);
                fileDownload(data, `Order_${batchId}_All.csv`);

            } else {
                //pdf
                var data = await downloadsService.getDownloadAllPdf(+batchId);
                fileDownload(data, `Order_${batchId}_All.pdf`);
            }

            if (downloadableBatchDetails) {

                setDownloadableBatchDetails({
                    ...downloadableBatchDetails,
                    items: downloadableBatchDetails.items.map(i => ({ ...i, downloadTime: new Date() }))
                })
            }
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.FileDownloadFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    const onDownloadAllCSV = async () => {

        setLoading(true);
        setLoadingMessage(t("NotificationMessage.DownloadingCodes"));

        try {

            var data = await downloadsService.getDownloadAllPrintReadyCsv(+batchId);
            fileDownload(data, `Order_${batchId}_All.csv`);
            if (downloadableBatchDetails) {

                setDownloadableBatchDetails({
                    ...downloadableBatchDetails,
                    items: downloadableBatchDetails.items.map(i => ({ ...i, downloadTime: new Date() }))
                })
            }
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.FileDownloadFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }


    const onDownloadItem = async (redemptionCodeBatchEcodeId: number, value: number, recipientName: string) => {

        setLoading(true);
        setLoadingMessage(t("NotificationMessage.DownloadingCodes"));

        try {

            if (downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch) {
                //pdf
                var data = await downloadsService.getDownloadPdf(+batchId, redemptionCodeBatchEcodeId);
                fileDownload(data, `Order_${batchId}_${recipientName}.pdf`);

                if (downloadableBatchDetails) {

                    setDownloadableBatchDetails({
                        ...downloadableBatchDetails,
                        items: downloadableBatchDetails.items.map(i => i.redemptionCodeBatchEcodeId === redemptionCodeBatchEcodeId
                            ? ({ ...i, downloadTime: new Date() })
                            : i)
                    })
                }
            }
            else if (downloadableBatchDetails?.batchType === BatchType.ElectronicBulk) {
                // csv
                var data = await downloadsService.getDownloadBulkCsv(+batchId, redemptionCodeBatchEcodeId, value);
                fileDownload(data, `Order_${batchId}_${redemptionCodeBatchEcodeId}.csv`);

                if (downloadableBatchDetails) {

                    setDownloadableBatchDetails({
                        ...downloadableBatchDetails,
                        items: downloadableBatchDetails.items.map(i => i.redemptionCodeBatchEcodeId === redemptionCodeBatchEcodeId
                            && i.value === value
                            ? ({ ...i, downloadTime: new Date() })
                            : i)
                    })
                }
            } else {
                //pdf
                var data = await downloadsService.getDownloadBulkPdf(+batchId, redemptionCodeBatchEcodeId, value);
                fileDownload(data, `Order_${batchId}_${redemptionCodeBatchEcodeId}.pdf`);

                if (downloadableBatchDetails) {

                    setDownloadableBatchDetails({
                        ...downloadableBatchDetails,
                        items: downloadableBatchDetails.items.map(i => i.redemptionCodeBatchEcodeId === redemptionCodeBatchEcodeId
                            && i.value === value
                            ? ({ ...i, downloadTime: new Date() })
                            : i)
                    })
                }
            }

        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.FileDownloadFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    const onDownloadPrintReadyItemCSV = async (redemptionCodeBatchEcodeId: number, recipientName: string) => {

        setLoading(true);
        setLoadingMessage(t("NotificationMessage.DownloadingCodes"));

        try {

            var data = await downloadsService.getDownloadPrintReadyCsv(+batchId, redemptionCodeBatchEcodeId);
            fileDownload(data, `Order_${batchId}_${recipientName}.csv`);

            if (downloadableBatchDetails) {

                setDownloadableBatchDetails({
                    ...downloadableBatchDetails,
                    items: downloadableBatchDetails.items.map(i => i.redemptionCodeBatchEcodeId === redemptionCodeBatchEcodeId
                        ? ({ ...i, downloadTime: new Date() })
                        : i)
                })
            }
        } catch (e) {
            console.error(e);
            notifyError(getErrorNotification(t("NotificationMessage.FileDownloadFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    const getColumnDefinitions = (batchType: BatchType): Column<DownloadableItem>[] => {

        if (batchType === BatchType.ElectronicBulk || batchType === BatchType.PrintReadyBulk) {

            // Bulk columns
            return [
                {
                    id: nameOf<DownloadableItem>("name"),
                    Header: t("Product"),
                    accessor: (model) => model.name
                },
                {
                    id: nameOf<DownloadableItem>("quantity"),
                    Header: t("OrderDetails.QuantityLabel"),
                    accessor: (model) => model.quantity
                },
                {
                    id: nameOf<DownloadableItem>("value"),
                    Header: t("OrderDetails.ValueLabel"),
                    accessor: (model) => model.value,
                    Cell: (cell: Cell<DownloadableItem>) => {

                        const item = cell.row.original;

                        return <>{downloadableBatchDetails?.currencySymbol?.trim()}{item.value?.toFixed(2)}</>;
                    }
                },
                {
                    id: nameOf<DownloadableItem>("downloadTime"),
                    Header: t("YourDownloads.MostRecentDownloadTime"),
                    accessor: (model) => model.downloadTime,
                    Cell: (cell: Cell<DownloadableItem>) => {

                        const item = cell.row.original;

                        return item.downloadTime
                            ? <Moment format="ll HH:mm">{item.downloadTime}</Moment>
                            : "-";
                    }
                },
                {
                    id: "actions",
                    Header: '',
                    accessor: (item) => {
                        if (item.redemptionStatus === RedemptionStatus.Redeemed) {
                            return t("DownloadableBatchStatus.Redeemed")
                        }
                        if (item.redemptionStatus === RedemptionStatus.VoucherCancelled) {
                            return t("DownloadableBatchStatus.Cancelled")
                        }
                        if (item.redemptionStatus === RedemptionStatus.Expired) {
                            return t("DownloadableBatchStatus.Expired")
                        }

                        return t("Download")
                    },
                    Cell: (cell: Cell<DownloadableItem>) => {

                        const item = cell.row.original;

                        return <div style={{ textAlign: "right" }}>
                            {item.redemptionStatus === RedemptionStatus.Redeemed &&
                                <Button color="link" disabled>{t("RedemptionCodeBatchType.Redeemed")}</Button>
                            }
                            {item.redemptionStatus === RedemptionStatus.VoucherCancelled &&
                                <Button color="link" disabled>{t("RedemptionCodeBatchStatus.Cancelled")}</Button>
                            }
                            {item.redemptionStatus === RedemptionStatus.Expired &&
                                <Button color="link" disabled>{t("RedemptionCodeBatchStatus.Expired")}</Button>
                            }
                            {downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Expired
                                && downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Redeemed
                                && downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Cancelled
                                && item.redemptionStatus !== RedemptionStatus.Redeemed
                                && item.redemptionStatus !== RedemptionStatus.VoucherCancelled
                                && item.redemptionStatus !== RedemptionStatus.Expired &&
                                <Button
                                    color="link"
                                    className="text-uppercase"
                                    onClick={() => onDownloadItem(item.redemptionCodeBatchEcodeId, (item.value/item.quantity!), item.name)}
                                >
                                    <BsDownload className="mr-2" size="24px" />{t("Download")}</Button>
                                   
                            }
                        </div>;
                    }
                }
            ];
        }

        // Batch columns
        return [
            {
                id: nameOf<DownloadableItem>("name"),
                Header: t("OrderDetails.NameLabel"),
                accessor: (model) => model.name
            },
            {
                id: nameOf<DownloadableItem>("value"),
                Header: t("OrderDetails.ValueLabel"),
                accessor: (model) => model.value,
                Cell: (cell: Cell<DownloadableItem>) => {

                    const item = cell.row.original;

                    return <>{downloadableBatchDetails?.currencySymbol?.trim()}{item.value?.toFixed(2)}</>;
                }
            },
            {
                id: nameOf<DownloadableItem>("downloadTime"),
                Header: t("YourDownloads.MostRecentDownloadTime"),
                accessor: (model) => model.downloadTime,
                Cell: (cell: Cell<DownloadableItem>) => {

                    const item = cell.row.original;

                    return item.downloadTime
                        ? <Moment format="ll HH:mm">{item.downloadTime}</Moment>
                        : "-";
                }
            },
            {
                id: "actions",
                Header: '',
                accessor: (item) => {
                    if (item.redemptionStatus === RedemptionStatus.Redeemed) {
                        return t("DownloadableBatchStatus.Redeemed")
                    }
                    if (item.redemptionStatus === RedemptionStatus.VoucherCancelled) {
                        return t("DownloadableBatchStatus.Cancelled")
                    }
                    if (item.redemptionStatus === RedemptionStatus.Expired) {
                        return t("DownloadableBatchStatus.Expired")
                    }

                    return t("Download")
                },
                Cell: (cell: Cell<DownloadableItem>) => {

                    const item = cell.row.original;

                    return <div style={{ textAlign: "right" }}>
                        {item.redemptionStatus === RedemptionStatus.Redeemed &&
                            <Button color="link" disabled>{t("RedemptionCodeBatchType.Redeemed")}</Button>
                        }
                        {item.redemptionStatus === RedemptionStatus.VoucherCancelled &&
                            <Button color="link" disabled>{t("RedemptionCodeBatchStatus.Cancelled")}</Button>
                        }
                        {item.redemptionStatus === RedemptionStatus.Expired &&
                            <Button color="link" disabled>{t("RedemptionCodeBatchStatus.Expired")}</Button>
                        }
                        {item.redemptionStatus !== RedemptionStatus.Redeemed
                            && item.redemptionStatus !== RedemptionStatus.VoucherCancelled
                            && item.redemptionStatus !== RedemptionStatus.Expired
                            && downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch &&
                            <Button
                                color="link"
                                className="text-uppercase"
                                onClick={() => onDownloadPrintReadyItemCSV(item.redemptionCodeBatchEcodeId, item.name)}
                            >
                                <FaFileCsv className="mr-2" size="24px" />{t("YourDownloads.DownloadCSV")}</Button>
                        }
                        {item.redemptionStatus !== RedemptionStatus.Redeemed
                            && item.redemptionStatus !== RedemptionStatus.VoucherCancelled
                            && item.redemptionStatus !== RedemptionStatus.Expired &&
                            <Button
                                color="link"
                                className="text-uppercase"
                                onClick={() => onDownloadItem(item.redemptionCodeBatchEcodeId, (item.value/item.quantity!), item.name)}
                            >
                                {downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch 
                                    ? <><FaFilePdf className="mr-2 mb-1" size="24px" /> {t("YourDownloads.DownloadPDF")} </>
                                    : <><BsDownload className="mr-2 mb-1" size="24px" />{t("Download")}</>
                                }
                            </Button>
                        }
                        
                       
                    </div>;
                }
            }
        ];
    }

    const getDownloadableItems = () => {

        if (downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch) {

            return downloadableBatchDetails?.items ?? [];
        }

        if (downloadableBatchDetails?.items) {

            const items: DownloadableItem[] = [];
            groupBy(downloadableBatchDetails.items, d => d.value).forEach((group, value) => {

                items.push({
                    name: `${downloadableBatchDetails?.currencySymbol?.trim()}${value} Code`,
                    quantity: group.length,
                    value: group.reduce((sum, current) => sum + current.value, 0),
                    downloadTime: group[0].downloadTime,
                    redemptionCodeBatchEcodeId: group[0].redemptionCodeBatchEcodeId,
                    redemptionStatus: group.some(i => i.redemptionStatus === RedemptionStatus.Activated)
                        ? RedemptionStatus.Activated
                        : group.every(i => i.redemptionStatus === RedemptionStatus.Redeemed)
                            ? RedemptionStatus.Redeemed
                            : group.every(i => i.redemptionStatus === RedemptionStatus.VoucherCancelled)
                                ? RedemptionStatus.VoucherCancelled
                                : group.every(i => i.redemptionStatus === RedemptionStatus.Expired)
                                    ? RedemptionStatus.Expired
                                    : group.some(i => i.redemptionStatus === RedemptionStatus.Redeemed)
                                        && group.every(i => i.redemptionStatus !== RedemptionStatus.Activated)
                                        ? RedemptionStatus.Redeemed
                                        : group.some(i => i.redemptionStatus === RedemptionStatus.Expired)
                                            && group.every(i => i.redemptionStatus !== RedemptionStatus.Activated)
                                            ? RedemptionStatus.Expired
                                            : RedemptionStatus.Activated
                });
            });

            return items;
        }


        return downloadableBatchDetails?.items ?? [];
    }

    const onPreviewTemplate = async () => {

        setLoading(true);
        setLoadingMessage(t("NotificationMessage.LoadPreviewTemplateMessage"));

        try {

            const htmlContent = await downloadsService.getPreviewTemplateHtml(+batchId);

            var tab = window.open("", "_blank");

            if (tab) {
                tab.document.write(htmlContent);
                tab.document.close();
            }
        } catch (e) {
            console.error(e)

            notifyError(getErrorNotification(t("NotificationMessage.LoadPreviewTemplateFailure")));
        }

        setLoading(false);
        setLoadingMessage(undefined);
    }

    return (
        <div className={bem.b()}>

            <AreaLoader
                show={loading}
                message={loadingMessage ?? t("NotificationMessage.LoadDownloadDetails")}
            />

            <Breadcrumb>
                <BreadcrumbItem><Link to="/downloads/">{t("YourDownloads.YourDownloadsLabel")}</Link></BreadcrumbItem>
                <BreadcrumbItem active>{t("YourDownloads.DownloadDetails")}</BreadcrumbItem>
            </Breadcrumb>

            <div className="d-flex flex-column">
                <h1>{t("YourDownloads.DownloadDetails")}</h1>
            </div>

            <Card className="w-100 mt-3">
                <CardBody className="d-flex flex-column flex-md-row justify-content-between flex-wrap">

                    <Col>
                        <div className={bem.e("icon-wrapper")}>
                            <BatchTypeIconImage batchType={downloadableBatchDetails?.batchType} size={BatchTypeIconSize.small} />
                        </div>
                    </Col>

                    <Col>
                        <div className="d-flex flex-column">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileProjectNameLabel")}</strong>
                            {downloadableBatchDetails?.projectName}
                        </div>

                        <div className="d-flex flex-column mt-2">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileProjectIdLabel")}</strong>
                            {downloadableBatchDetails?.projectId}
                        </div>
                    </Col>

                    <Col>
                        <div className="d-flex flex-column">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderIdLabel")}</strong>
                            {downloadableBatchDetails?.batchId}
                        </div>

                        <div className="d-flex flex-column mt-2">
                            <strong className="text-uppercase">{t("YourDownloads.CodeType")}</strong>
                            {downloadableBatchDetails?.codeType}
                        </div>
                    </Col>

                    <Col>
                        <div className="d-flex flex-column">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderDateLabel")}</strong>
                            {downloadableBatchDetails?.processingDate && <Moment format="ll HH:mm">{downloadableBatchDetails.processingDate}</Moment>}
                        </div>

                        <div className="d-flex flex-column mt-2">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderTypeLabel")}</strong>
                            {downloadableBatchDetails?.batchType && t(getBatchTypeLocalizationKey(downloadableBatchDetails.batchType))}
                        </div>
                    </Col>

                    <Col>
                        <div className="d-flex flex-column">
                            <strong className="text-uppercase">{t("OrderHistory.OrderTileOrderValueLabel")}</strong>
                            {downloadableBatchDetails?.currencySymbol?.trim()}{downloadableBatchDetails?.orderValue.toFixed(2)}
                        </div>

                        <div className="d-flex flex-column mt-2">
                            <strong className="text-uppercase">{t("YourDownloads.NumberOfCodes")}</strong>
                            {downloadableBatchDetails?.numberOfCodes}
                        </div>
                    </Col>

                    <Col>
                        <strong className="text-uppercase">{t("OrderHistory.OrderTileStatusLabel")}</strong>
                        <div className={bem.e("status",
                            `${downloadableBatchDetails?.downloadableBatchStatus === DownloadableBatchStatus.Active
                                ? "bg-success"
                                : (downloadableBatchDetails?.downloadableBatchStatus === DownloadableBatchStatus.Expired || downloadableBatchDetails?.downloadableBatchStatus === DownloadableBatchStatus.Cancelled
                                    ? "bg-danger"
                                    : "bg-warning")}`)}
                        >{downloadableBatchDetails && DownloadableBatchStatus[downloadableBatchDetails.downloadableBatchStatus]}</div>
                    </Col>

                </CardBody>
            </Card>

            <Card className="w-100 mt-3">
                <CardHeader>
                    <h2>{t("DownloadDetails.ListOfCodes")}</h2>
                </CardHeader>

                <CardBody>
                    {tempDownloadableBatchDetails?.batchType === BatchType.PrintReadyBatch &&
                        <DataTable<DownloadableItem>
                        columnDefinitions={getColumnDefinitions(downloadableBatchDetails?.batchType ?? BatchType.Undefined)}
                        fetchData={loadDownloadableBatchItemDetails}
                        items={getDownloadableItems()}
                        totalItemCount={downloadableBatchDetails?.numberOfCodes ?? 0}
                        showHeaders={true}
                        hiddenColumns={[]}
                        resultsElId="downloadableItemResults"
                        disableStyling={true}
                        />
                    }
                    {tempDownloadableBatchDetails?.batchType != BatchType.PrintReadyBatch &&
                        <ReactstrapDataTable
                            columnDefinitions={getColumnDefinitions(downloadableBatchDetails?.batchType ?? BatchType.Undefined)}
                            items={getDownloadableItems()}
                            totalItemCount={getDownloadableItems().length}
                            hideSearch={downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch ? false : true}
                            
                        />
                    }

                    <div className="w-100 pt-3 pb-2">
                        <div className="d-flex flex-column d-md-block align-items-center">
                            {downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Expired
                                && downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Redeemed
                                && downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Cancelled
                                && (downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch || downloadableBatchDetails?.batchType === BatchType.PrintReadyBulk)
                                ?
                                <Button
                                    className="text-uppercase float-md-left"
                                    color="secondary"
                                    onClick={onPreviewTemplate}
                                >
                                    <FaRegEye className="mr-2 mb-1" size="24px" />
                                    {downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch
                                        ? t("YourDownloads.PreviewTemplatePDF")
                                        : t("PreviewTemplate")
                                    }
                                </Button>
                                : <></>

                            }
                            {downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Expired
                                && downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Redeemed
                                && downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Cancelled &&
                                <Button
                                    color="primary"
                                    className="text-uppercase float-md-right  mt-2 mt-md-0"
                                    onClick={onDownloadAll}
                                    style={{ marginLeft: "15px" }}
                                    
                                >
                                    {downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch
                                        ? <><FaFilePdf className="mr-2 mb-1" size="24px" /> {t("YourDownloads.DownloadAllPDF")} </>
                                        : <><BsDownload className="mr-2 mb-1" size="24px" />{t("YourDownloads.DownloadAll")}</>
                                    }
                                </Button>
                            }      
                            {downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Expired
                                && downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Redeemed
                                && downloadableBatchDetails?.downloadableBatchStatus !== DownloadableBatchStatus.Cancelled
                                && downloadableBatchDetails?.batchType === BatchType.PrintReadyBatch &&
                                <Button
                                    color="primary"
                                    className="text-uppercase float-md-right  mt-2 mt-md-0"
                                    onClick={onDownloadAllCSV}
                                >
                                    <FaFileCsv className="mr-2 mb-1" size="24px" />{t("YourDownloads.DownloadAllCSV")}
                                </Button>
                            }        

                                                 
                        </div>
                    </div>

                </CardBody>
            </Card>
        </div>
    )
}

const ConnectedComponent = connect(null, mapDispatchToProps)(DownloadDetailsPage);

export {
    ConnectedComponent as DownloadDetailsPage
}