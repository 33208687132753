export enum UserType {
        SystemAdministrator = 5,
        ClientApiUser = 6,
        SelfServiceUser = 9,
        SelfServiceSuperUser = 10,
        ProductManager = 19,
        AccountManager_LightTouch = 20,
        AccountManager_ClientServices = 21,
        FraudAndRiskTeam = 22,
        OperationsUser = 23,
        SupplyChainUser = 24,
        Finance_Accounting = 25,
        Finance_Receivables = 26,
        CustomerServiceAgent = 27,
        CustomerServiceManagement = 28
}

export const UserTypeLabel = new Map<number, string>([
    [UserType.SystemAdministrator, "UserManagement.SystemAdministrator"],
    [UserType.ClientApiUser, "UserManagement.ClientApiUser"],
    [UserType.SelfServiceUser, "UserManagement.SelfServiceUser"],
    [UserType.SelfServiceSuperUser, "UserManagement.SelfServiceSuperUser"],
    [UserType.ProductManager, "UserManagement.ProductManager"],
    [UserType.AccountManager_LightTouch, "UserManagement.AccountManager_LightTouch"],
    [UserType.AccountManager_ClientServices, "UserManagement.AccountManager_ClientServices"],
    [UserType.FraudAndRiskTeam, "UserManagement.FraudAndRiskTeam"],
    [UserType.OperationsUser, "UserManagement.OperationsUser"],
    [UserType.SupplyChainUser, "UserManagement.SupplyChainUser"],
    [UserType.Finance_Accounting, "UserManagement.Finance_Accounting"],
    [UserType.Finance_Receivables, "UserManagement.Finance_Receivables"],
    [UserType.CustomerServiceAgent, "UserManagement.CustomerServiceAgent"],
    [UserType.CustomerServiceManagement, "UserManagement.CustomerServiceManagement"]
]);