import React, { useEffect, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { useTranslation } from 'react-multi-lang';
import { Button, Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

// Import Models
import { EmailMessageLogDto } from '../Models';

interface Props {
    isModalOpen: boolean;
    closeModal: () => void;
    title: string;
    logItems: EmailMessageLogDto[];
    onSubmit: (scmOrderEGiftId: number, emailMessageId: number, newEmailAddress: string) => void;
}

export function EmailMessageLogDialog({
    isModalOpen,
    closeModal,
    title,
    logItems,
    onSubmit
}: Props) {

    const t = useTranslation();
    const [newEmailAddress, setNewEmailAddress] = useState<string>("");
    const [isResending, setIsResending] = useState(false);

    useEffect(() => {

        if (logItems && logItems.length > 0) {
            setNewEmailAddress(logItems[0].emailAddress);
        }

    }, [logItems]);

    function requestCloseModal() {

        setIsResending(false);

        closeModal();
    }

    function requestSubmit() {

        const item = logItems
            .filter(d => !d.isResend)
            .sort((a, b) => ((a.emailMessageId ?? 0) < (b.emailMessageId ?? 0)) ? 1 : -1)[0];

        if (item) {
            onSubmit(item.scmOrderEgiftId ?? 0, item.emailMessageId ?? 0, newEmailAddress);
        }

        requestCloseModal();
    }

    function emailStatusNotOk(emailStatus: string) {
        return emailStatus !== 'delivered'
            && emailStatus !== 'clicked'
            && emailStatus !== 'opened';
    }

    return (

        <Modal isOpen={isModalOpen} toggle={() => requestCloseModal()} style={{ maxWidth: "max-content" }}>
            <ModalHeader toggle={() => requestCloseModal()}>
                {title}
            </ModalHeader>

            <ModalBody>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{t("OrderDetails.EmailAddressLabel")}</th>
                                        <th>{t("OrderDetails.SentByLabel")}</th>
                                        <th>{t("OrderDetails.SentDateLabel")}</th>
                                        <th>{t("OrderDetails.StatusLabel")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {logItems.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.emailAddress}</td>
                                                <td>{item.senderMailAddress}</td>
                                                <td>{item.sendDate}</td>
                                                <td style={{ display: "flex", flexDirection: "row" }}>
                                                    <p data-toggle="tooltip" title={item.lastActionDate}>{item.emailStatus}</p>

                                                    {item.hasError && emailStatusNotOk(item.emailStatus) &&
                                                        <BsInfoCircle
                                                            style={{
                                                                fontSize: 18, marginLeft: "5px", marginTop: "2px"
                                                            }}
                                                            data-toggle="tooltip"
                                                            title={item.errorMessage}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>
                        </Col>

                        <Col md={12} hidden={!isResending}>
                            <div>
                                <h2><strong>{t("OrderDetails.EmailResendLabel")}</strong></h2>
                                {logItems.length > 0
                                    ? <div className="align-justify">{t("OrderDetails.EmailResendWarningMessage")}</div>
                                    : <div className="align-justify">{t("OrderDetails.EmailResendUnavailableMessage")}</div>
                                }
                            </div>

                            <br />

                            {logItems.length > 0 &&

                                <Col md={10}>
                                    <Label>{t("OrderDetails.EmailAddressLabel")}:</Label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        onChange={e => setNewEmailAddress(e.target.value)}
                                        defaultValue={logItems[0].emailAddress}
                                    />

                                </Col>
                            }
                        </Col>
                    </Row>
                </Container>
            </ModalBody>

            <ModalFooter className="justify-content-between">
                <Button
                    outline
                    onClick={() => requestCloseModal()}
                >{t("BackButton")}</Button>
                {logItems && logItems.length > 0 &&
                    (isResending
                        ? <Button
                            color="primary"
                            onClick={() => requestSubmit()}
                        >{t("OrderDetails.EmailResendButton")}</Button>

                        : <Button
                            color="primary"
                            onClick={() => setIsResending(true)}
                        >{t("OrderDetails.EmailResendButton")}</Button>
                    )
                }
            </ModalFooter>
        </Modal>
    );
}