import * as Notifications from 'react-notification-system-redux';
import * as AuthenticationReducer from './Reducers/AuthenticationReducer';
import * as UserReducer from './Reducers/UserReducer';
import * as AppReducer from './Reducers/AppReducer';
import * as DigitalOrderReducer from './Reducers/DigitalOrderReducer';

import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

// The top-level state object
export interface ApplicationState {
    app: AppReducer.AppState;
    auth: AuthenticationReducer.AuthState;
    user: UserReducer.UserState;
    notifications: Notifications.NotificationsState;
    digitalOrder: DigitalOrderReducer.OrderState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    app: AppReducer.reducer,
    auth: AuthenticationReducer.reducer,
    user: UserReducer.reducer,
    notifications: Notifications.reducer,
    digitalOrder: DigitalOrderReducer.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
//export interface AppThunkAction<TAction> {
//    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
//
//}

export type AppThunkResult<TActions extends Action, TReturn> = ThunkAction<TReturn, ApplicationState, undefined, TActions>
