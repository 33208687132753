import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from "Store";
import * as OrderReducer from 'Store/Reducers/DigitalOrderReducer';
import { bemNames } from 'Utilities';

import { FaRoute, FaRegCreditCard } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { IoDocumentsOutline } from "react-icons/io5";
import { BaseVoucherType } from 'Models';
import { useTranslation } from 'react-multi-lang';

import { userService } from 'Services';
import { AreaLoader } from "Components";
import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";
import { UserDetails } from "../Auth";

interface StoreProps {
    deliveryMethod?: OrderReducer.DeliveryMethod,
    currentWizardStep?: number,
    attemptGoToStep: typeof OrderReducer.actions.attemptGoToStep,
    attemptGoToStepNumber?: number,
    refreshToken?: string,
    accessToken?: string
}

interface AuthStoreProps {
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
}

interface UserStoreProps {
    loadUser: typeof UserReducer.actions.loadUser;
}

interface Props extends StoreProps {
    returnWizardStep: (step: number) => void;
    codeType?: BaseVoucherType;
    ecodeBulkFileUpload: boolean;
}


const mapStateToProps = ({ digitalOrder, auth }: ApplicationState) => ({
    deliveryMethod: digitalOrder.deliveryMethod,
    currentWizardStep: digitalOrder.currentWizardStep,
    attemptGoToStepNumber: digitalOrder.attemptGoToStepNumber,
    refreshToken: auth.refreshToken,
    accessToken: auth.accessToken
})

const mapDispatchToProps = {
    attemptGoToStep: OrderReducer.actions.attemptGoToStep,
    refreshTokens: AuthenticationReducer.actions.refreshTokens,
    loadUser: UserReducer.actions.loadUser
}

const OrderWizardBarNav = ({
    currentWizardStep,
    deliveryMethod,
    attemptGoToStep,
    codeType,
    refreshTokens,
    loadUser,
    refreshToken,
    accessToken,
    ecodeBulkFileUpload
}: Props & AuthStoreProps & UserStoreProps) => {

    const t = useTranslation();
    const bem = bemNames.create("order-digital");

    const [isBusy, setIsBusy] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("");

    const refreshingToken = async () => {
        setIsBusy(true);

        try {
            var userDetails = await userService.refreshingToken(accessToken, refreshToken);

            if (userDetails) {

                await refreshTokens(userDetails.accessToken, userDetails.refreshToken);
                await loadUser(userDetails);

                return;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsBusy(false);
        }
    }


    return (
        <div className={bem.e("digital-order-nav mb-4 btn-group d-none d-md-inline-flex")}>
            <AreaLoader show={isBusy} message={loadingMessage} />
            {deliveryMethod !== OrderReducer.DeliveryMethod.Email && codeType == BaseVoucherType.ECode &&
                <>
                    <button
                        type="button"
                        className="btn btn-light py-2 text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(1);
                        }}
                    >
                        <FaRoute className="mr-2" size={24} />
                        {t("OrderingProcess.OrderMethod")}
                    </button>
                    <button
                        disabled={currentWizardStep! === 1}
                        type="button"
                        className="btn btn-light text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(2);
                        }}
                    >
                        <CgFileDocument className="mr-2" size={24} />
                        {t("OrderingProcess.RewardDetailsLabel")}
                    </button>
                    <button
                        type="button"
                        disabled={currentWizardStep! <= 4}
                        className="btn btn-light text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(5);
                        }}
                    ><FaRegCreditCard className="mr-2" size={24} />
                        {t("OrderingProcess.PaymentLabel")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-light text-uppercase"
                        disabled={currentWizardStep! <= 5}
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(6);
                        }}
                    ><HiOutlineDocumentSearch className="mr-2" size={24} />
                        {t("OrderingProcess.ReviewLabel")}
                    </button>
                </>
            }

            {deliveryMethod === OrderReducer.DeliveryMethod.Email && codeType == BaseVoucherType.ECode &&
                <>
                    <button
                        type="button"
                        className="btn btn-light py-2 text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(1);
                        }}
                    >
                        <FaRoute className="mr-2" size={24} />
                        {t("OrderingProcess.OrderMethod")}
                    </button>
                    <button
                        disabled={currentWizardStep! === 1}
                        type="button"
                        className="btn btn-light text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(3);
                        }}
                    >
                        <CgFileDocument className="mr-2" size={24} />
                        {t("OrderingProcess.RewardDetailsLabel")}
                    </button>
                    {!ecodeBulkFileUpload &&<button
                            type="button"
                            className="btn btn-light text-uppercase"
                            onClick={async () => {
                                refreshingToken();
                                attemptGoToStep(4);
                            }}
                            disabled={currentWizardStep! <= 3}
                        >
                            <IoDocumentsOutline className="mr-2" size={24} />
                            {t("OrderDetails.TemplateSelectionLabel")}
                        </button>
                    }
                    <button
                        type="button"
                        disabled={currentWizardStep! <= 4}
                        className="btn btn-light text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(5);
                        }}
                    ><FaRegCreditCard className="mr-2" size={24} />
                        {t("OrderingProcess.PaymentLabel")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-light text-uppercase"
                        disabled={currentWizardStep! <= 5}
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(6);
                        }}
                    ><HiOutlineDocumentSearch className="mr-2" size={24} />
                        {t("OrderingProcess.ReviewLabel")}
                    </button>
                </>
            }

            {codeType == BaseVoucherType.PrintReady &&
                <>
                    <button
                        type="button"
                        className="btn btn-light py-2 text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(1);
                        }}
                    >
                        <FaRoute className="mr-2" size={24} />
                        {t("OrderingProcess.OrderMethod")}
                    </button>
                    <button
                        disabled={currentWizardStep! === 1}
                        type="button"
                        className="btn btn-light text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(3);
                        }}
                    >
                        <CgFileDocument className="mr-2" size={24} />
                        {t("OrderingProcess.RewardDetailsLabel")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-light text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(4);
                        }}
                        disabled={currentWizardStep! <= 3}
                    >
                        <IoDocumentsOutline className="mr-2" size={24} />
                        {t("OrderDetails.TemplateSelectionLabel")}
                    </button>
                    <button
                        type="button"
                        disabled={currentWizardStep! <= 4}
                        className="btn btn-light text-uppercase"
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(5);
                        }}
                    ><FaRegCreditCard className="mr-2" size={24} />
                        {t("OrderingProcess.PaymentLabel")}
                    </button>
                    <button
                        type="button"
                        className="btn btn-light text-uppercase"
                        disabled={currentWizardStep! <= 5}
                        onClick={async () => {
                            refreshingToken();
                            attemptGoToStep(6);
                        }}
                    ><HiOutlineDocumentSearch className="mr-2" size={24} />
                        {t("OrderingProcess.ReviewLabel")}
                    </button>
                </>
            }

        </div>
    )
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(OrderWizardBarNav);

export {
    ConnectedComponent as OrderWizardBarNav
}

