import axios from "axios";
import { getAuthHeader } from "./getAuthHeader";
import { IProjectDto, ProjectDetailsDto } from "Models";

export const projectService = {
    getProjects,
    getProjectDetails
};

async function getProjects() {

    const response = await axios.get<IProjectDto[]>(`api/Projects/GetProjects`, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getProjectDetails(projectId: string) {

    const response = await axios.get<ProjectDetailsDto>(`api/Projects/GetProjectDetails/${projectId}`, {
        headers: getAuthHeader()
    });

    return response.data;
}