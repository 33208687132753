import axios, { AxiosError } from "axios";

import { store } from "Store/StoreConfiguration";
import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import { ApplicationState } from "Store";
import { UserDetails } from "Auth";
import { userService } from "Services";

axios.interceptors.response.use((response) => {

    // Return a successful response back to the calling service
    return response;
}, (error: AxiosError) => {

    // Return any error which is not due to authentication back to the calling service
    if (error.response?.status !== 401) {

        return new Promise((resolve, reject) => {
            reject(error);
        });
    }

    const state = store.getState() as ApplicationState;

    if (error.config.url != null
        && error.config.url !== "api/account/refresh-token"
        && error.config.url !== "api/account/login"
        && error.config.url !== "api/account/logout") {
        // attempt to refresh token

        // Get new token and try request again with new token
        return axios.post<UserDetails>("api/account/refresh-token", {
            accessToken: state.auth?.accessToken,
            refreshToken: state.auth?.refreshToken
        })
            .then((tokenResp) => {

                // New request with new token
                const config = error.config;
                config.headers['Authorization'] = `Bearer ${tokenResp.data.accessToken}`;

                // Refresh tokens in state
                store.dispatch(AuthenticationReducer.actions.refreshTokens(tokenResp.data.accessToken, tokenResp.data.refreshToken));

                return new Promise((resolve, reject) => {
                    axios.request(config).then(response => {
                        resolve(response);
                    }).catch((error) => {
                        reject(error);
                    })
                });

            })
            .catch((error) => {
                Promise.reject(error);
            });
    }

    // Logout user if token refresh didn't work
    if (error.config.url != null
        && (error.config.url === "api/account/refresh-token"
            || error.config.url === "api/account/logout")) {

        if (error.config.url === 'api/account/logout'
            && error.response?.headers["Token-Expired"] == "true") {

            //logout
            userService.logout(state.auth?.refreshToken ?? "");
        }

        // if the server side logout succeeded update the react state to log the user out
        store.dispatch(AuthenticationReducer.actions.logoutSuccess());

        return new Promise((resolve, reject) => {
            reject(error);
        });
    }

    return new Promise((resolve, reject) => {
        reject(error);
    });
});
