import axios from "axios";
import { AccountDetails, ContactUsDetails } from "../Models";
import { HttpStatusCodes } from "../Utilities";
import { getAuthHeader } from "./getAuthHeader";

const getAccountDetails = async (userId: string) =>
    (await axios.get<AccountDetails>(
        `api/UserManagement/EditUser/${userId}`,
        { headers: getAuthHeader() }
    )).data;

const checkIfEmailAlreadyAssigned = async (userId: string, emailAddress: string) =>
    (await axios.post<boolean>(
        `api/UserManagement/EmailAddressAssigned/${userId}`, { emailAddress }, { headers: getAuthHeader() }
    )).data;

const updateAccountDetails = async (accountDetails: AccountDetails) => {
    try {
        return (await axios.post<string>(
            `api/UserManagement/EditUser/${accountDetails.userGuid}`, { userInfo: accountDetails }, { headers: getAuthHeader() }
        )).data;
    }
    catch (e) {
        return false;
    }
};

const getContactUsDetail = async (Iso2: string) =>
    (await axios.get<ContactUsDetails>(
        `api/ContactUs/GetContactUsDetail/${Iso2}`,        
        { headers: getAuthHeader() },
    )).data;

const submitContactFormSubmission = async (contactDetail: ContactUsDetails) => {
    return (await axios.post<string>(`api/ContactUs`, contactDetail,
        {
            headers: getAuthHeader()
        })).data;
};
export const editUserService = {
    getAccountDetails,
    updateAccountDetails,
    checkIfEmailAlreadyAssigned,
    getContactUsDetail,
    submitContactFormSubmission
};
