import axios from "axios";
import { getAuthHeader } from "./getAuthHeader";
import { YourDownloadsItemDto, SearchResults } from "Models";
import { FilterByOptions, SortByOptions } from "../Components";

export const yourDownloadsService = {
    getYourDownloadsProjectNames,
    getYourDownloads
};

async function getYourDownloadsProjectNames() {

    const response = await axios.get<string[]>(`api/DownloadManagement/GetProjectNames`, {
        headers: getAuthHeader()
    });

    return response.data;
}

async function getYourDownloads(pageIndex: number, pageSize: number, sortBy: SortByOptions[], searchTerm: string, filterBy: FilterByOptions[]) {

    const response = await axios.post<SearchResults<YourDownloadsItemDto>>(
        `api/DownloadManagement/GetBulkDownloadsForUser`,
        {
            pageIndex,
            pageSize,
            sortBy,
            searchTerm,
            filterBy
        },
        {
            headers: getAuthHeader()
        }
    );

    return response.data;
}