import { LocalizedContentValueDto } from "./LocalizedContentValueDto";

export interface LanguageDto {
    id: number;
    name: string;
    languageCode: string;
    imageUrl: string;
    dateModified: Date;
    isEnabled: boolean;
    values: LocalizedContentValueDto[];
}


enum Language {
    English_UK = 1,
    German = 2,
    English_US = 1002,
    Dutch = 1003,
    French = 1004,
    Spanish = 1005,
    Japanese = 1006,
    Portuguese = 1007
}


export const LanguageLabel = new Map<number, string>([
    [Language.English_UK, "English (UK)"],
    [Language.German, "German"],
]);
 

