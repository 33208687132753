import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-multi-lang";
import StepWizard, { StepWizardChildProps } from "react-step-wizard";
import { Button } from "reactstrap";
import * as Yup from "yup";
import Notifications from 'react-notification-system-redux';
import PhoneInput from 'react-phone-input-2'
import { Redirect } from "react-router";
import { connect } from "react-redux";

import * as AuthenticationReducer from "Store/Reducers/AuthenticationReducer";
import * as UserReducer from "Store/Reducers/UserReducer";
import { ApplicationState } from "../Store";
import { AreaLoader, InputWithValidations } from "../Components";
import { bemNames, getClaimValue, getErrorNotification } from "../Utilities";
import { MfaSetupDetails, SelectedLanguageDetails } from "../Models";
import { userService } from "../Services";

// Resources
import MicrosoftAuth from 'Assets/Images/microsoft-authenticator.svg';
import GoogleAuth from 'Assets/Images/google-authenticator-2.svg';
import TwilioAuth from 'Assets/Images/twilio-2.svg';
import AppStore from 'Assets/Images/Download_on_the_App_Store_Badge.svg';
import PlayStore from 'Assets/Images/Google_Play_Store_badge_EN.svg';
import { ClaimTypes, UserDetails } from "../Auth";
import { getHomepage } from "../Utilities/claimsHelper";
import { serviceUtils } from "Services/ServiceUtils";

interface NotificationStoreProps {
    notifyError: Notifications.NotificationShow;
}

interface AuthStoreProps {
    refreshTokens: typeof AuthenticationReducer.actions.refreshTokens;
}

interface UserStoreProps {
    user?: UserDetails;
    loadUser: typeof UserReducer.actions.loadUser;
    showUserNotification: typeof UserReducer.actions.showUserNotification;
}

interface LocalizationStoreProps {
    selectedLanguage?: SelectedLanguageDetails;
}

const mapStateToProps = ({ app, user }: ApplicationState) => ({
    user: user.user,
    selectedLanguage: app.selectedLanguage
});

const mapDispatchToProps = {
    notifyError: Notifications.error,
    refreshTokens: AuthenticationReducer.actions.refreshTokens,
    loadUser: UserReducer.actions.loadUser,
    showUserNotification: UserReducer.actions.showUserNotification,
}

const SetupStepComponent = ({
    goToStep,
    isActive,
    refreshTokens,
    loadUser,
    showUserNotification
}: Partial<StepWizardChildProps> & NotificationStoreProps & AuthStoreProps & UserStoreProps) => {

    const bem = bemNames.create("auth-wizard");
    const t = useTranslation();

    const [isBusy, setIsBusy] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [redirectToHomepage, setRedirectToHomepage] = useState<boolean>(false);

    // default the qr code to a transparent gif
    const [mfaSetupDetails, setMfaSetupDetails] = useState<MfaSetupDetails>({ setupKey: "", qrCode: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" });

    const initialFocusEl = useRef<HTMLHeadingElement>(null);
    useEffect(() => {
        if (!isActive) return
        initialFocusEl?.current?.focus();
    }, [isActive]);

    const copyToClipboard = () => {

        navigator.clipboard.writeText(mfaSetupDetails.setupKey);

        setCopySuccess(t("TwoFactorAuthentication.CopiedClipboardMessage"));

        setTimeout(() => {
            setCopySuccess('');
        }, 1500);
    }

    useEffect(() => {
        async function getSetupCode() {
            try {
                setIsBusy(true);

                const details = await userService.getMfaSetupDetails();
                setMfaSetupDetails(details);

                setIsBusy(false);
            } catch (error) {
                // TODO: Handle
            }
        }

        if (isActive) {

            getSetupCode();
        }
    }, [isActive]);

    const sixDigitCodeValidationSchema = Yup.object().shape({
        sixDigitCode: Yup.string()
            .matches(/^[0-9]+$/, t("TwoFactorAuthentication.OnlyDigitsError"))
            .min(6, t("TwoFactorAuthentication.SixDigitsOnlyError"))
            .max(6, t("TwoFactorAuthentication.SixDigitsOnlyError"))
            .required(t("TwoFactorAuthentication.SixDigitsRequiredError"))
    });

    if (redirectToHomepage) {
        var path = getHomepage(serviceUtils.loggedInUser());
        return <Redirect to={{ pathname: path }} />;
    }

    return (

        <div className="d-flex w-100 flex-column flex-md-row">
            <AreaLoader show={isBusy} />

            <div className={bem.e("app-panel-wrap d-flex justify-content-center")}>
                <div className={bem.e("app-panel py-4 px-3")}>
                    <h2 ref={initialFocusEl} className='text-left' tabIndex={0}>{t("TwoFactorAuthentication.SetupAppTitle")}</h2>
                    <p className="pt-4 ">{t("TwoFactorAuthentication.SetupAppMessage")}</p>
                    <div className="card">
                        <div className="px-2 py-3">
                            <p>{t("TwoFactorAuthentication.RecommendedAppsLabel")}</p>
                            <div className="d-flex justify-content-between">
                                <div className="text-center app">
                                    <img style={{ maxWidth: "2rem" }} alt={t("TwoFactorAuthentication.MicrosoftAuthenticatorLabel")} src={MicrosoftAuth} />
                                    <p className="text-center pt-2 text-uppercase">{t("TwoFactorAuthentication.MicrosoftAuthenticatorLabel")}</p>
                                </div>
                                <div className="text-center app">
                                    <img style={{ maxWidth: "2rem" }} alt={t("TwoFactorAuthentication.GoogleAuthenticatorLabel")} src={GoogleAuth} />
                                    <p className="text-center pt-2 text-uppercase">{t("TwoFactorAuthentication.GoogleAuthenticatorLabel")}</p>
                                </div>
                                <div className="text-center app">
                                    <img style={{ maxWidth: "4.625rem" }} alt={t("TwoFactorAuthentication.TwilioAuthenticatorLabel")} src={TwilioAuth} />
                                    <p className="text-center text-uppercase">{t("TwoFactorAuthentication.TwilioAuthenticatorLabel")}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <p className="pt-3">{t("TwoFactorAuthentication.AppsAvailableAtLabel")}</p>
                    <div className="d-flex pb-2">
                        <div className="pr-2 w-50"><img className="mw-100 w-100" alt="Apple App Store" src={AppStore} /></div>
                        <div className="pl-2 w-50"><img className="mw-100 w-100" alt="Google Play Store" src={PlayStore} /></div>
                    </div>
                </div>
            </div>
            <div className={bem.e("step-panel card-body p-4")}>
                <h3>{t("TwoFactorAuthentication.StepsTitle")}</h3>
                <div className="step d-flex">
                    <div className="step-number d-inline-block mr-2"><span>1</span></div>
                    <p className="d-inline-block">{t("TwoFactorAuthentication.StepOneMessage")}</p>
                </div>
                <div className="step d-flex flex-column">
                    <div className="d-flex">
                        <div className="step-number d-inline-block mr-2"><span>2</span></div>
                        <p className="d-inline-block">{t("TwoFactorAuthentication.StepTwoMessage")}</p>
                    </div>
                    <div className="d-flex flex-column flex-lg-row align-items-center mb-4 pl-lg-4">
                        <div className="d-block"><img alt="QR" src={mfaSetupDetails.qrCode} /></div>
                        <div className="form-group pl-4 w-100">
                            <h3>{t("TwoFactorAuthentication.AppKey")}</h3>
                            <div className="copy-input">
                                <input readOnly type='text' aria-label={t("TwoFactorAuthentication.AppKey")} onClick={copyToClipboard} className="form-control" name="Key" defaultValue={mfaSetupDetails.setupKey} disabled={!isActive} />
                                <small className="text-success">{copySuccess}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step d-flex">
                    <div className="step-number d-inline-block mr-2"><span>3</span></div>
                    <p className="d-inline-block">{t("TwoFactorAuthentication.StepThreeMessage")}</p>
                </div>
                <div className="d-flex flex-column flex-sm-row pl-4 align-items-center align-items-sm-start">
                    <Formik
                        initialValues={{
                            sixDigitCode: ""
                        }}
                        validationSchema={sixDigitCodeValidationSchema}
                        onSubmit={async (values, actions) => {

                            setIsBusy(true);

                            try {
                                const newUserDetails = await userService.validateAppMfaCode(values.sixDigitCode);

                                if (newUserDetails) {

                                    await refreshTokens(newUserDetails.accessToken, newUserDetails.refreshToken);
                                    await loadUser(newUserDetails);

                                    setRedirectToHomepage(true);
                                    showUserNotification({
                                        title: t("TwoFactorAuthentication.SetupCompletedTitle"),
                                        message: t("TwoFactorAuthentication.SetupCompletedMessage")
                                    });

                                    return;
                                } else {
                                    actions.setFieldError("sixDigitCode", t("TwoFactorAuthentication.InvalidCodeError"));
                                }
                            }
                            catch (e) {
                                console.log(e);
                                actions.setFieldError("sixDigitCode", t("TwoFactorAuthentication.InvalidCodeError"));
                            }
                            finally {
                                setIsBusy(false);
                            }
                        }}
                    >

                        {({ isSubmitting }) => (
                            <Form translate="yes" className="d-flex flex-column flex-md-row">

                                <Field
                                    name="sixDigitCode"
                                    disabled={!isActive || isSubmitting}
                                    component={InputWithValidations}
                                    placeholder={t("TwoFactorAuthentication.EnterSixDigitPlaceholder")}
                                    className="form-control six-digit-code"
                                    maxLength={6}
                                    autoComplete="off"
                                />

                                <Button
                                    color="primary"
                                    className="btn btn-primary btn-block ml-4 mt-3 mt-md-0 btn btn-primary"
                                    type="submit"
                                    disabled={!isActive || isSubmitting}
                                >{t("TwoFactorAuthentication.CompleteSetUpButton")}</Button>

                            </Form>
                        )}

                    </Formik>

                </div>
                <button className="btn btn-link pl-4 pt-4" onClick={() => goToStep?.(2)} disabled={!isActive}>{t("TwoFactorAuthentication.CantDownloadLink")}</button>

            </div>
        </div>

    );
};

const SetupStep = connect(null, mapDispatchToProps)(SetupStepComponent);

const SmsSetupStepComponent = ({
    nextStep,
    isActive,
    updatePhoneNumber,
    previousStep
}: Partial<StepWizardChildProps> & {
    updatePhoneNumber: (value: string) => void;
}) => {

    const bem = bemNames.create("auth-wizard");
    const t = useTranslation();
    const [isBusy, setIsBusy] = useState(false);
    const [value, setValue] = useState("");
    return (
        <div className={bem.e("small-panel w-100 p-4")}>

            <AreaLoader show={isBusy} />

            <Formik
                initialValues={{
                    phoneNumber: value
                }}
                onSubmit={async (values, actions) => {

                    setIsBusy(true);

                    try {

                        updatePhoneNumber(value);

                        nextStep?.();
                    } catch (e) {
                        actions.setFieldError("code", t("TwoFactorAuthentication.InvalidCodeError"));
                    }

                    setIsBusy(false);
                }}
            >

                {({ isSubmitting, resetForm, values, errors, setFieldValue }) => (
                    <Form translate="yes">

                        <h2>{t("TwoFactorAuthentication.SmsSetupTitle")}</h2>

                        <p className="pt-1">{t("TwoFactorAuthentication.SmsSetupWhichNumberMessage")}</p>

                        <PhoneInput
                            country="gb"
                            onlyCountries={["gb", "de", "nl"]}
                            value={value}
                            onChange={setValue}
                            countryCodeEditable={false}
                            inputClass="form-control"
                            aria-label={t("TwoFactorAuthentication.SmsSetupPlaceholder")}
                            placeholder={t("TwoFactorAuthentication.SmsSetupPlaceholder")}
                            inputStyle={{ width: "100%" }}
                            inputProps={{ autoFocus: true }}
                            disabled={!isActive || isSubmitting}
                            disableDropdown={!isActive || isSubmitting}
                        />

                        {errors && errors.phoneNumber && errors.phoneNumber.length > 0 &&
                            <div className="invalid-feedback text-center d-block">
                                {errors.phoneNumber}
                            </div>
                        }

                        <p className="mt-4">{t("TwoFactorAuthentication.SmsSetupSecurityMessage")}</p>

                        <div className="d-flex justify-content-between pt-5">

                            <Button
                                color="primary"
                                className="btn-block mt-5"
                                type="reset"
                                disabled={!isActive || isSubmitting}
                                onClick={() => {
                                    setValue("+44");
                                    resetForm();
                                    previousStep?.();
                                }}
                            >{t("TwoFactorAuthentication.SmsSetupBackButton")}</Button>

                            <Button
                                color="primary"
                                className="btn-block mt-5"
                                type="submit"
                                disabled={!isActive || isSubmitting}
                            >{t("TwoFactorAuthentication.SmsSetupSubmitButton")}</Button>
                        </div>

                    </Form>
                )}

            </Formik>

        </div>
    );
};

const SmsSetupStep = connect(mapStateToProps, mapDispatchToProps)(SmsSetupStepComponent);

const SmsAuthStepComponent = ({
    goToStep,
    phoneNumber,
    notifyError,
    refreshTokens,
    loadUser,
    user,
    isActive,
    selectedLanguage,
    showUserNotification
}: Partial<StepWizardChildProps> & {
    phoneNumber: string;
} & NotificationStoreProps & AuthStoreProps & UserStoreProps & LocalizationStoreProps) => {
    const bem = bemNames.create("auth-wizard");
    const t = useTranslation();

    const [isBusy, setIsBusy] = useState(false);
    const [redirectToHomepage, setRedirectToHomepage] = useState<boolean>(false);

    const mfaPhoneNumber = getClaimValue(user, ClaimTypes.MfaPhoneNumber);
    const mfaSetupCompleted = !!getClaimValue(user, ClaimTypes.MfaAuthType);

    useEffect(() => {


        async function a() {

            const phoneNo = mfaPhoneNumber ?? phoneNumber;

            if (isActive && phoneNo) {

                setIsBusy(true);

                try {
                    const result = await userService.sendSmsCode(selectedLanguage?.languageCode ?? "en-UK", phoneNo);
                } catch (e) {
                    setIsBusy(false);
                    notifyError(getErrorNotification(t("NotificationMessage.InvalidMobileNumberMessage")));
                    goToStep?.(2);
                }

                setIsBusy(false);
            }
        }
        a();
    }, [isActive])

    if (redirectToHomepage) {
        var path = getHomepage(serviceUtils.loggedInUser());
        return <Redirect to={{ pathname: path }} />;
    }

    return (
        <div className={bem.e("small-panel w-100 p-4")}>
            <AreaLoader show={isBusy} />

            <Formik
                initialValues={{
                    code: ""
                }}
                onSubmit={async (values, actions) => {

                    setIsBusy(true);

                    try {
                        const newUserDetails = await userService.validateSmsCode(values.code, mfaPhoneNumber ?? phoneNumber);

                        if (newUserDetails) {

                            await refreshTokens(newUserDetails.accessToken, newUserDetails.refreshToken);
                            await loadUser(newUserDetails);

                            if (!mfaSetupCompleted) {
                                showUserNotification({
                                    title: t("TwoFactorAuthentication.SetupCompletedTitle"),
                                    message: t("TwoFactorAuthentication.SetupCompletedMessage")
                                });
                            }

                            setRedirectToHomepage(true);

                            return;
                        } else {
                            actions.setFieldError("code", t("TwoFactorAuthentication.InvalidCodeError"));
                        }

                    } catch (e) {
                        actions.setFieldError("code", t("TwoFactorAuthentication.InvalidCodeError"));
                    }

                    setIsBusy(false);
                }}
            >

                {({ isSubmitting, setFieldValue, errors }) => (
                    <Form translate="yes">

                        <h2>{t("TwoFactorAuthentication.SmsAuthenticateTitle")}</h2>
                        <p className="pt-1">{t("TwoFactorAuthentication.SmsAuthenticateConfirmMessage")} +********{(mfaPhoneNumber ?? phoneNumber).substring(8)}</p>

                        <div className="d-flex flex-column justify-content-center">

                            <Field
                                name="code"
                                aria-label='Enter code'
                                type='text'
                                disabled={!isActive || isSubmitting}
                                className="custom-input"
                                maxLength={6}
                                autoComplete="off"
                                autoFocus={true}
                            />

                            {errors && errors.code && errors.code.length > 0 &&
                                <div className="invalid-feedback text-center d-block">
                                    {errors.code}
                                </div>
                            }

                        </div>

                        <p className="mt-4">{t("TwoFactorAuthentication.SmsAuthenticateNotRecievedMessage")}
                            <Button
                                className='py-0 px-1 border-0 align-baseline'
                                color="link"
                                onClick={() => { goToStep?.(2); setFieldValue("code", ""); }}
                                disabled={!isActive || isSubmitting}>{t("TwoFactorAuthentication.SmsAuthenticateResendLink")}
                            </Button>
                        </p>

                        <div className="d-flex justify-content-center pt-5">
                            <Button
                                color="primary"
                                className="mt-5"
                                type="submit"
                                disabled={!isActive || isSubmitting}
                            >{mfaSetupCompleted
                                ? t("TwoFactorAuthentication.SmsSetupSubmitButton")
                                : t("TwoFactorAuthentication.SmsAuthenticateSubmitButton")}</Button>
                        </div>

                    </Form>
                )}

            </Formik>

        </div>
    );
};

const SmsAuthStep = connect(mapStateToProps, mapDispatchToProps)(SmsAuthStepComponent);


const AppAuthStepComponent = ({
    isActive,
    loadUser,
    refreshTokens
}: Partial<StepWizardChildProps> & AuthStoreProps & UserStoreProps) => {
    const bem = bemNames.create("auth-wizard");
    const t = useTranslation();

    const [isBusy, setIsBusy] = useState(false);
    const [redirectToHomepage, setRedirectToHomepage] = useState<boolean>(false);

    const sixDigitCodeValidationSchema = Yup.object().shape({
        code: Yup.string()
            .matches(/^[0-9]+$/, t("TwoFactorAuthentication.OnlyDigitsError"))
            .min(6, t("TwoFactorAuthentication.SixDigitsOnlyError"))
            .max(6, t("TwoFactorAuthentication.SixDigitsOnlyError"))
            .required(t("TwoFactorAuthentication.SixDigitsRequiredError"))
    });

    if (redirectToHomepage) {
        var path = getHomepage(serviceUtils.loggedInUser());
        return <Redirect to={{ pathname: path }} />;
    }

    return (
        <div className={bem.e("small-panel w-100 p-4")}>
            <AreaLoader show={isBusy} />

            <Formik
                initialValues={{
                    code: ""
                }}
                validationSchema={sixDigitCodeValidationSchema}
                onSubmit={async (values, actions) => {

                    setIsBusy(true);

                    try {
                        const newUserDetails = await userService.validateAppMfaCode(values.code);

                        if (newUserDetails) {

                            await refreshTokens(newUserDetails.accessToken, newUserDetails.refreshToken);

                            await loadUser(newUserDetails);

                            setRedirectToHomepage(true);

                            return;
                        } else {
                            actions.setFieldError("code", t("TwoFactorAuthentication.InvalidCodeError"));
                        }

                    } catch (e) {
                        console.log(e);
                        actions.setFieldError("code", t("TwoFactorAuthentication.InvalidCodeError"));
                    }
                    finally {
                        setIsBusy(false);
                    }
                }}
            >

                {({ isSubmitting, errors }) => (
                    <Form translate="yes" className="text-center">

                        <h2><strong>{t("TwoFactorAuthentication.AuthenticateAccountTitle")}</strong></h2>
                        <p className="pt-1">{t("TwoFactorAuthentication.AuthenticateAccountAppMessage")}</p>

                        <div className="d-flex flex-column justify-content-center">
                            <Field
                                name="code"
                                aria-label={t("TwoFactorAuthentication.AuthenticateAccountTitle")}
                                type='text'
                                className="custom-input"
                                disabled={!isActive || isSubmitting}
                                maxLength={6}
                                autoComplete="off"
                                autoFocus={true}
                            />

                            {errors && errors.code && errors.code.length > 0 &&
                                <div className="invalid-feedback text-center d-block">
                                    {errors.code}
                                </div>
                            }
                        </div>

                        <div className="d-flex justify-content-center pt-5">
                            <Button
                                color="primary"
                                className="btn btn-primary btn-primary"
                                type="submit"
                                disabled={!isActive || isSubmitting}
                            >{t("TwoFactorAuthentication.AuthenticateAccountSubmitButton")}</Button>
                        </div>

                    </Form>
                )}

            </Formik>
        </div>
    );
};

const AppAuthStep = connect(null, mapDispatchToProps)(AppAuthStepComponent);


export const MfaSetupPage = () => {

    const bem = bemNames.create("auth-wizard");
    const [phoneNumber, setPhoneNumber] = useState<string>("");

    return (
        <div className="d-flex justify-content-center pt-2 pt-sm-4">
            <div className="card border-0" style={{ overflow: "hidden", boxShadow: "0px 4px 6px #00000029" }}>

                <div className={bem.b("container")}>
                    <div className='row d-block'>
                        <StepWizard
                            isHashEnabled={true}
                        >
                            <SetupStep hashKey="download-app" />
                            <SmsSetupStep updatePhoneNumber={v => setPhoneNumber(v)} hashKey="sms-setup" />
                            <SmsAuthStep phoneNumber={phoneNumber} hashKey="sms-authenticate" />
                            <AppAuthStep hashKey="app-authenticate" />
                        </StepWizard>
                    </div>
                </div>

            </div>
        </div>
    )
}