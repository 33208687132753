import React, { ChangeEvent } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap';
import { useState } from 'react';

import { BsGrid } from "react-icons/bs";
import { IoCardOutline, IoPhonePortraitOutline, IoPrintOutline } from "react-icons/io5";
import { useTranslation } from "react-multi-lang";



interface Props {
    filters: Array<String>;
    appliedFilters: any;
}


export function IconFilter({
    appliedFilters,
    filters
}: Props) {

    const t = useTranslation();

    const [state, setState] = useState({
        filterValue: "0"
    })
    const { filterValue } = state;

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavBar = () => {
        setIsOpen(v => !v);
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist()
        const { id, name, value, type } = e.target

        if (type === 'radio') {
            setState(prev => ({ ...prev, filterValue: id }))
        } else {
            setState(prev => ({ ...prev, [name]: value }))
        }
    }

    appliedFilters(filterValue);

    return (
        <>
            <Dropdown className="d-block d-sm-none w-100 text-center" isOpen={isOpen} toggle={toggleNavBar}>
                <DropdownToggle caret>
                    {filterValue === "0" &&
                        <>{t("Projects.ProjectFilterAll")}</>
                    }
                    {filterValue === "1" &&
                        <>{t("Projects.ProjectFilterPhysical")}</>
                    }
                    {filterValue === "2" &&
                        <>{t("Projects.ProjectFilterECode")}</>
                    }
                    {filterValue === "3" &&
                        <>{t("Projects.ProjectFilterPrintReady")}</>
                    }
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => setState({ filterValue: "0" })}>
                        {t("Projects.ProjectFilterAll")}
                    </DropdownItem>
                    <DropdownItem onClick={() => setState({ filterValue: "1" })}>
                        {t("Projects.ProjectFilterPhysical")}
                    </DropdownItem>
                    <DropdownItem onClick={() => setState({ filterValue: "2" })}>
                        {t("Projects.ProjectFilterECode")}
                    </DropdownItem>
                    <DropdownItem onClick={() => setState({ filterValue: "3" })}>
                        {t("Projects.ProjectFilterPrintReady")}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <div className="icon-filter d-none d-sm-flex">
                <label className="radio-label">

                    <input
                        type="radio"
                        name="filter"
                        id="0"
                        checked={filterValue === '0'}
                        onChange={onChange}
                    />
                    <span className="text-uppercase"><BsGrid fontSize={16} />{t("Projects.ProjectFilterAll")}</span>
                </label>
                {filters.includes("1") &&
                    <label className="radio-label">
                        <input
                            type="radio"
                            name="filter"
                            id="1"
                            checked={filterValue === '1'}
                            onChange={onChange}
                        />
                        <span className="text-uppercase"><IoCardOutline />{t("Projects.ProjectFilterPhysical")}</span>
                    </label>
                }
                {filters.includes("2") &&
                    <label className="radio-label">
                        <input
                            type="radio"
                            name="filter"
                            id="2"
                            checked={filterValue === '2'}
                            onChange={onChange}
                        />
                        <span className="text-uppercase"><IoPhonePortraitOutline />{t("Projects.ProjectFilterECode")}</span>
                    </label>
                }
                {filters.includes("3") &&
                    <label className="radio-label">
                        <input
                            type="radio"
                            name="filter"
                            id="3"
                            checked={filterValue === '3'}
                            onChange={onChange}
                        />
                        <span className="text-uppercase"><IoPrintOutline />{t("Projects.ProjectFilterPrintReady")}</span>
                    </label>
                    }
            </div>
        </>
    )
}