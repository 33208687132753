import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-multi-lang';
import { HttpStatusCodes } from 'Utilities';

export const UnauthorizedPage = () => {

    const t = useTranslation();

    return (
        <div>

            <Alert color="warning text-center">

                <h4 className="text-warning">{HttpStatusCodes.Unauthorized}</h4>
                <hr />
                <h4 className="text-warning">{t("ErrorPages.UnauthorizedMessage")}</h4>

            </Alert>

        </div>
    );
}