import { ActionType, createAction, createReducer } from "typesafe-actions";
import { DigitalOrderDetails, DistributorDetails, PaymentMethod,  FileUploadObject, ECodeOrderItem } from "Models";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface OrderState {
    deliveryMethod?: DeliveryMethod;
    order: DigitalOrderDetails;
    currentWizardStep?: number;
    distributorDetails: DistributorDetails;
    attemptGoToStepNumber?: number;    
}

export const initialState: OrderState = {
    order: {
        paymentMethod: PaymentMethod.BacsChaps,
        bulkItems: [{ value: "", quantity: "" }],
        fileUploadECodeItems: [],
        fileUploadPrintReadyItems: [],
        fileUploadPhysicalItems: [],
        fileUploadBulkOrderItems: [],
        itemsTotalValue: 0,
        referenceOrPONumber: "",
        otherReference: "",
        invoiceAddress: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            cityTown: "",
            postCode: ""
        },
        saveInvoiceAddress: false,
        fileUploadListValid: false,
        printReadyFileUploadListValid: false,
        ecodeBulkFileUploadListValid : false,
        templateName: "",
        templateId: undefined,
        templateHtml: "",
        emailTemplateId: undefined,
        emailTemplateName: "",
        smsTemplateId: undefined,
        smsTemplateName: "",
        fileName: "",
        codeType: "",
        paymentPlusCheckoutId: ""
    },
    distributorDetails: {
        emailAddress: "",
        firstName: "",
        lastName: "",
    }    
}

export enum DeliveryMethod {
    Form = 0,
    Email = 1
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    typeof actionCreators.setDeliveryMethod |
    typeof actionCreators.setCurrentStep |
    typeof actionCreators.setOrder |
    typeof actionCreators.attemptGoToStep |
    typeof actionCreators.setDistributorDetails |
    typeof actionCreators.setReferenceOrPONumber |
    typeof actionCreators.setOtherReference

type ActionsType = ActionType<
    typeof actionCreators.setDeliveryMethod |
    typeof actionCreators.setCurrentStep |
    typeof actionCreators.setOrder |
    typeof actionCreators.attemptGoToStep |
    typeof actionCreators.setDistributorDetails |
    typeof actionCreators.setReferenceOrPONumber |
    typeof actionCreators.setOtherReference
>;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// Action definitions

const actionCreators = {
    setDeliveryMethod: createAction("SET_DELIVERY_METHOD")<DeliveryMethod | undefined>(),
    setCurrentStep: createAction("SET_CURRENT_STEP")<number>(),
    setOrder: createAction("SET_ORDER")<DigitalOrderDetails>(),
    setDistributorDetails: createAction("SET_DISTRIBUTOR_DETAILS")<DistributorDetails>(),
    attemptGoToStep: createAction("ATTEMPT_NEXT_STEP")<number>(),
    setReferenceOrPONumber: createAction("SET_REFERENCE_NUMBER")<string>(),
    setOtherReference: createAction("SET_OTHER_REFERENCE")<string>() 
};

export const actions = {
    ...actionCreators
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer = createReducer<OrderState, ActionsType>(initialState)
    .handleAction(actionCreators.setDeliveryMethod, (state: OrderState, action) => {
        return {
            ...state,
            deliveryMethod: action.payload
        }
    })
    .handleAction(actionCreators.setCurrentStep, (state: OrderState, action) => {
        return {
            ...state,
            currentWizardStep: action.payload
        }
    })
    .handleAction(actionCreators.setOrder, (state: OrderState, action) => {
        return {
            ...state,
            order: action.payload
        }
    })
    .handleAction(actionCreators.attemptGoToStep, (state: OrderState, action) => {
        return {
            ...state,
            attemptGoToStepNumber: action.payload
        }
    })
    .handleAction(actionCreators.setDistributorDetails, (state: OrderState, action) => {
        return {
            ...state,
            distributorDetails: action.payload
        }
    })    
    .handleAction(actionCreators.setReferenceOrPONumber, (state: OrderState, action) => {
        return {
            ...state,
            order: {
                ...state.order,
                referenceOrPONumber: action.payload
            }
        }
    })
    .handleAction(actionCreators.setOtherReference, (state: OrderState, action) => {
        return {
            ...state,
            order: {
                ...state.order,
                otherReference: action.payload
            }
        }
    });